import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Edit } from "@mui/icons-material";
import DoubleCheck from "src/assets/icons/double-check.svg?react";
import CheckIcon from "src/assets/icons/check.svg?react";
import { useNavigate } from "react-router-dom";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

export type ActivitiesHeaderProps = {
    ingredient: Ingredient;
    toggleEditMode: boolean;
    onActionClick: () => void;
    numberOfSuppliers: number;
};

export const ActivitiesHeader = (props: ActivitiesHeaderProps) => {
    const { t } = useTranslation();
    const { selectedCompany } = useFoodpilotContext();
    const { ingredient, toggleEditMode, onActionClick, numberOfSuppliers } = props;

    const navigate = useNavigate();
    if (selectedCompany === null) return;

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "100px" }}>
            <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                <Typography variant="h3">{t("Fournisseurs")}</Typography>
                <Typography variant="huge">-</Typography>
                <Typography variant="huge">{numberOfSuppliers}</Typography>
            </Box>
            {ingredient.canModify && (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Button
                        variant="ternary"
                        startIcon={<DoubleCheck />}
                        onClick={() => {
                            const url = URLHandler.ingredient.edit(selectedCompany.id, ingredient.id, 1);
                            navigate(url);
                        }}
                        disabled={toggleEditMode}
                    >
                        {t("ingredient.activityForm.addSuppliers")}
                    </Button>
                    <Button
                        variant="primary"
                        startIcon={toggleEditMode ? <CheckIcon /> : <Edit />}
                        onClick={() => onActionClick()}
                    >
                        {toggleEditMode ?
                            t("ingredient.activityForm.validateModifications")
                        :   t("ingredient.activityForm.modifyData")}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
