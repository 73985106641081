import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FoodsCheckbox, PrimaryDialog } from "@foodpilot/foods";
import { Material } from "src/api/sure-api/usePackagingMaterialSet";
import { ProductFormPackagingMaterial, ProductFormProductPackaging } from "../../ProductForm.types";

type MaterialModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    productPackaging: ProductFormProductPackaging;
    allMaterialSets: Material[];
    onValidate: (value: ProductFormProductPackaging) => void;
};
export const MaterialModal = (props: MaterialModalProps): JSX.Element | null => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { isOpen, setOpen, productPackaging, allMaterialSets } = props;

    const currentSelectedIds = productPackaging.packagingMaterials.map((packMat) => packMat.packagingMaterialSetId);
    const originalSelectedItems = allMaterialSets.filter((matSet) => currentSelectedIds.includes(matSet.id));
    const [selectedItems, setSelectedItems] = useState(originalSelectedItems);

    useEffect(() => {
        setSelectedItems(originalSelectedItems);
    }, [productPackaging]);

    const title = <Typography variant="h3">{t("Ajouter un matériau")}</Typography>;
    const content = (
        <div style={{ padding: "1.5rem", backgroundColor: theme.custom.grey[200] }}>
            <FoodsCheckbox
                selectedItems={selectedItems}
                values={allMaterialSets}
                onChange={(selectedValues) => setSelectedItems(selectedValues)}
            />
        </div>
    );

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setOpen}
            options={{
                title: title,
                content: content,
            }}
            onValidate={() => {
                const newPackMats: ProductFormPackagingMaterial[] = selectedItems.map((item) => {
                    const foundOriginal = productPackaging.packagingMaterials.find(
                        (packMat) => packMat.packagingMaterialSetId === item.id,
                    );
                    const newPackMat: ProductFormPackagingMaterial = {
                        "@id": foundOriginal?.["@id"],
                        packagingMaterialSetId: item.id,
                        proportion: foundOriginal?.proportion ?? null,
                        weight: foundOriginal?.weight ?? null,
                    };
                    return newPackMat;
                });
                props.onValidate({
                    ...productPackaging,
                    packagingMaterials: newPackMats,
                });
            }}
            onCancel={() => {
                setSelectedItems(originalSelectedItems);
            }}
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};
