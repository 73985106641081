import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { z } from "zod";
import { zodParseWithLog } from "../apiErrorLogs";

type Options = {
    name?: string;
};

export const useProductReference = (options?: Options) => {
    const { name = null } = options || {};

    const queryResult = useQuery({
        queryKey: ["productReferenceKey", name],
        queryFn: async () => {
            const url = "/api/product_references";
            const response = await sureApiCall.get(url, {
                params: {
                    name,
                },
            });
            return response;
        },
        select: (request) => {
            const productReference = request.data["hydra:member"];
            const data = zodParseWithLog(productReference, ProductReferenceSchema.array(), "productReference");
            return data;
        },
    });

    return {
        productsReference: queryResult.data,
        ...queryResult,
    };
};

export type ProductReference = {
    id: number;
    name: string;
};

const ProductReferenceSchema: z.ZodSchema<ProductReference> = z.object({
    id: z.number(),
    name: z.string(),
});
