import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { useTranslation } from "react-i18next";
import { QuestionnaireUpdateFormBase } from "./QuestionnaireUpdateFormBase";
import { QuestionnaireUpdateFormSsqAdmin } from "./QuestionnaireUpdateFormSsqAdmin";
import { QuestionnaireUpdateFormSsqSupplier } from "./QuestionnaireUpdateFormSsqSupplier";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";

export type QuestionnaireUpdateFormReferenceProps = {
    ingredient: Ingredient;
    ingredientReference: IngredientReference;
};
export const QuestionnaireUpdateFormReference = (props: QuestionnaireUpdateFormReferenceProps) => {
    const { ingredient, ingredientReference } = props;
    const { isUserOfTypeSupplier } = useFoodpilotContext();
    const { t } = useTranslation();

    const { ssq, isLoading } = useGetOneSsq(ingredientReference?.ssq?.id);

    if (isLoading) return <QuestionnaireUpdateFormBase ingredient={ingredient} isLoading={true} />;

    if (!ssq) return t("Le questionnaire pour cet ingrédient n'a pas été trouvé");

    const isSupplier = isUserOfTypeSupplier();

    const QuestionnaireUpdateFormSsq =
        isSupplier ? QuestionnaireUpdateFormSsqSupplier : QuestionnaireUpdateFormSsqAdmin;

    return <QuestionnaireUpdateFormSsq ingredient={ingredient} ssq={ssq} />;
};
