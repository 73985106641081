import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

// ReactNode because either `cell.getValue()` returns the type of the column
// Or `cell.renderedValue` returns a type `ReactNode`.
type GridValue = ReactNode;

type GridTextProps = {
    value: GridValue;
    defaultValue?: string;
};
export const GridText = (props: GridTextProps) => {
    const { value, defaultValue = "-" } = props;

    const isValueEmpty = value === undefined || value === "" || value === null;
    const valueToDisplay = isValueEmpty ? defaultValue : value;
    return (
        <Typography
            variant="body"
            sx={{
                // color: "#000",
                textOverflow: "ellipsis",
            }}
        >
            {valueToDisplay}
        </Typography>
    );
};

type GridTextListProps = {
    value: string[] | undefined;
    defaultValue?: string;
    separator?: string;
};
export const GridTextList = (props: GridTextListProps) => {
    const { value, defaultValue = "-", separator = " " } = props;

    const valueToDisplay = value !== undefined && value.length > 0 ? value : defaultValue;
    if (Array.isArray(valueToDisplay)) {
        const valueToDisplayArray = valueToDisplay.join(separator);
        return (
            <Typography
                variant="body"
                sx={{
                    color: "#000",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }}
            >
                {valueToDisplayArray}
            </Typography>
        );
    }

    return (
        <Typography variant="body" sx={{ color: "#000" }}>
            {valueToDisplay}
        </Typography>
    );
};
