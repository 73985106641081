import { useQuery } from "react-query";
import { inexwebApiCall } from "src/api/axiosClient/sureApi";
import { AccountingSchema, SelectedAccount, accountingZodToAccounting } from "src/types";
import { getInexwebInfo } from "src/utils/token";

export const useAccounts = () => {
    const { company } = getInexwebInfo();

    return useQuery({
        queryKey: ["accounts", { company }],
        queryFn: () => getAccounts(),
        select: (data): SelectedAccount[] => {
            const res = AccountingSchema.parse(data);

            return accountingZodToAccounting(res).map((account) => ({
                id: account.number,
                label: `${account.number} - ${account.label}`,
            }));
        },
        staleTime: 60 * 1000,
    });
};

export const getAccounts = async () => {
    const response = await inexwebApiCall.get(`/api/inexweb/accounting/accounts`);
    return response.data;
};
