import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ScoreBox } from "src/components/Score/ScoreBox";
import { Score } from "src/types";
import { Loading } from "src/components/utils/Loading";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Company } from "src/api/client-api/useUserNew";

export type CompanyScoresProps = {
    company: Company;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isRefetching?: boolean;
};
export const CompanyScores = (props: CompanyScoresProps) => {
    const { company, variations, setVariations, isRefetching = false } = props;
    const { scoreTypes, scores, getScoresByType } = useFoodpilotContext();
    const { t } = useTranslation();

    if (!scoreTypes || !scores) {
        return <Loading />;
    }

    const companiesScores = getScoresByType("company").filter((st) => st.displayMode !== "rules");

    if (companiesScores.length === 0) {
        return (
            <Stack gap="28px" sx={{ padding: "44px 52px" }}>
                <Box sx={{ textAlign: "center", padding: "28px" }}>{t("supplier.no-score")}</Box>
            </Stack>
        );
    }

    return (
        <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", padding: "1rem 0", flexWrap: "wrap" }}>
                {companiesScores.map((score: Score, index: number) => (
                    <ScoreBox
                        key={index}
                        score={score}
                        value={company.scores?.[score.id]}
                        variations={variations}
                        setVariations={setVariations}
                        isRefetching={isRefetching}
                        prevScore={company?.prevScores?.[score.id]}
                        prevCampaignName={company?.prevCampaignName}
                    />
                ))}
            </Box>
        </Box>
    );
};
