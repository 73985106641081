import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

type CreateCompanySector = {
    companySector: CompanySectorWrite;
};

export const useCreateCompanySector = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: CreateCompanySector) => {
            const { companySector } = options;

            const response =
                companySector.id === undefined ?
                    await loggedInApiCall.post("/api/company_sectors", companySector)
                :   await loggedInApiCall.put("/api/company_sectors/" + companySector.id, companySector);

            return response;
        },
        onSuccess: () => queryClient.invalidateQueries(["sectorsValue"]),
    });

    return query;
};

export type CompanySectorWrite = {
    id?: number;
    name: string;
    ssqId?: number;
};
