import z from "zod";
import { useQuery } from "react-query";

import { sureApiCall } from "../axiosClient/sureApi";
import { zodParseWithLog } from "../apiErrorLogs";

export const useConsumptionReference = () => {
    const query = useQuery({
        cacheTime: 0,
        staleTime: 0,
        queryKey: "allConsumptions",
        queryFn: async () => {
            return await sureApiCall.get("/api/consumptions");
        },
        select: (request) => {
            const consumptions = request.data["hydra:member"];
            const data = zodParseWithLog(consumptions, ConsumptionSchema.array(), "allConsumptionsRef");
            return data;
        },
    });

    return {
        consumptionReference: query.data,
        ...query,
    };
};

export type ConsumptionReference = {
    id: number;
    label: string;
};

const ConsumptionSchema: z.ZodSchema<ConsumptionReference> = z.object({
    id: z.number(),
    label: z.string(),
});
