import { RoundNumberInput } from "@foodpilot/foods";
import { Box } from "@mui/material";
import { ProductFormProductIngredient } from "src/pages/Product/ProductForm/ProductForm.types";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { TextLink } from "src/components/Link/TextLink";
import { URLHandler } from "src/services/URLHandler";

type LineTitleProps = {
    ingredient: ProductFormProductIngredient;
    setIngredient: (newIngredient: ProductFormProductIngredient) => void;
};
export const LineTitle = (props: LineTitleProps) => {
    const { selectedCompany } = useFoodpilotContext();
    const { ingredient, setIngredient } = props;

    if (selectedCompany === null) return;
    return (
        <Box
            sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 200px 125px",
            }}
        >
            <Box sx={{ gridColumn: "1" }}>
                <TextLink
                    url={URLHandler.ingredient.scores(selectedCompany.id, ingredient.ingredient.id)}
                    text={ingredient.ingredient.name}
                />
            </Box>
            <Box sx={{ gridColumn: "2" }}>
                <RoundNumberInput
                    sx={{ minWidth: "100px" }}
                    placeholder={"0"}
                    value={ingredient.usedQuantity}
                    unit={"g"}
                    onChange={(e) => {
                        const newUsedQuantity = e.target.value;
                        const newIngredient: ProductFormProductIngredient = {
                            ...ingredient,
                            usedQuantity: newUsedQuantity === "" ? null : Number(newUsedQuantity),
                        };
                        setIngredient(newIngredient);
                    }}
                />
            </Box>
            <Box sx={{ gridColumn: "3" }}>
                <RoundNumberInput
                    sx={{ minWidth: "100px" }}
                    placeholder={"0"}
                    value={ingredient.quantity}
                    unit={"g"}
                    onChange={(e) => {
                        const newIngredientQuantity = e.target.value;
                        const newIngredient: ProductFormProductIngredient = {
                            ...ingredient,
                            quantity: newIngredientQuantity === "" ? null : Number(newIngredientQuantity),
                        };
                        setIngredient(newIngredient);
                    }}
                />
            </Box>
        </Box>
    );
};
