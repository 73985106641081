import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CMSContentMedia, useGetAllMediaContent } from "src/api/client-api/cms/useGetAllMedias";

export type SelectMediaProps = {
    selectedMediaIRI: IRI;
    selectNewMedia: (newMedia: CMSContentMedia) => void;
};
export const SelectMedia = (props: SelectMediaProps) => {
    const { selectedMediaIRI, selectNewMedia } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const { allMedias = [] } = useGetAllMediaContent();

    const selectedMedia = allMedias?.find((item) => item["@id"] === selectedMediaIRI);
    return (
        <Stack padding={theme.spacing(1)}>
            <Typography variant={"big-medium"}>{t("settings.media.selectInfo")}</Typography>
            <Stack flexDirection={"row"} overflow={"scroll"} gap={theme.spacing(3)} padding={theme.spacing(2)}>
                {selectedMedia !== undefined && <DisplayImage media={selectedMedia} isSelected />}
                {allMedias.map((media) => {
                    if (media["@id"] === selectedMedia?.["@id"]) return;

                    return <DisplayImage selectNewMedia={selectNewMedia} media={media} />;
                })}
            </Stack>
        </Stack>
    );
};

type DisplayImageProps = {
    media: CMSContentMedia;
    isSelected?: boolean;
    selectNewMedia?: (newMedia: CMSContentMedia) => void;
};
const DisplayImage = (props: DisplayImageProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { media, selectNewMedia, isSelected = false } = props;

    return (
        <Stack gap={theme.spacing(3)} justifyContent={"center"} alignItems={"center"}>
            <img alt={media.alt} src={media.url} width={300} height={300} />
            {isSelected === false ?
                <Button
                    variant={"primary"}
                    onClick={() => {
                        selectNewMedia?.(media);
                    }}
                >
                    {t("settings.media.selectImage")}
                </Button>
            :   <Typography variant="big-bold">{t("settings.media.selectedImage")}</Typography>}
        </Stack>
    );
};
