import { FormBox, TextField, WhiteBox } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { RSEFormProps } from "src/types";
import { HelpPlease } from "./components/HelpPlease";

type Page3Props = {
    onError: (hasError: boolean) => void;
};

export const Page3 = (props: Page3Props) => {
    const { onError } = props;
    const {
        control,
        getValues,
        formState: { errors },
    } = useFormContext<RSEFormProps>();

    const [previousFiscalYear, fiscalYear] = getValues(["previousFiscalYear", "fiscalYear"]);
    const validate = (v: number | null) => {
        const r = !isNaN(v || 0);
        onError(!r);
        return r;
    };

    return (
        <Box sx={{ display: "flex", gap: "1rem", position: "relative" }}>
            <Box sx={{ flex: 2.5 }}>
                <FormBox sx={{ padding: 6 }}>
                    <Typography variant="big-bold">Chiffre d'affaires de l'entreprise</Typography>
                    <Box sx={{ height: 20 }} />
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Controller
                                control={control}
                                name="period.turnover"
                                rules={{ validate }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        title={`${fiscalYear?.code}`}
                                        placeholder="0"
                                        value={value?.toString()}
                                        onChange={onChange}
                                        sx={{
                                            backgroundColor: errors.period?.turnover && "#FFF0ED",
                                            borderColor: errors.period?.turnover && "red",
                                        }}
                                    />
                                )}
                            />
                            <Typography sx={{ mt: 1.5, ml: -5 }} variant="body">
                                &nbsp;€
                            </Typography>
                        </Box>
                        {errors.period?.turnover && (
                            <Typography variant="body" color={"red"} component={"p"}>
                                Veuillez saisir une valeur numérique.
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ height: 20 }} />
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Controller
                                control={control}
                                name="previousPeriod.turnover"
                                rules={{ validate }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        title={`${previousFiscalYear?.code}`}
                                        placeholder="0"
                                        value={value?.toString()}
                                        onChange={onChange}
                                        sx={{
                                            backgroundColor: errors.previousPeriod?.turnover && "#FFF0ED",
                                            borderColor: errors.previousPeriod?.turnover && "red",
                                        }}
                                    />
                                )}
                            />
                            <Typography sx={{ mt: 1.5, ml: -5 }} variant="body">
                                &nbsp;€
                            </Typography>
                        </Box>
                        {errors.previousPeriod?.turnover && (
                            <Typography variant="body" color={"red"} component={"p"}>
                                Veuillez saisir une valeur numérique.
                            </Typography>
                        )}
                    </Box>
                </FormBox>
                <Box sx={{ height: 20 }} />
                <WhiteBox sx={{ p: 6 }}>
                    <Typography variant="big-bold">Production de l'entreprise</Typography>
                    <Typography sx={{ mt: 2, mb: 4 }} variant="body" component="p">
                        Cette information correspond à la quantité de produits commercialisés. Elle servira à
                        contextualiser l’évolution du CA pour minorer ou majorer l’impact carbone de la production
                        vendue.
                    </Typography>
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Controller
                                control={control}
                                name="period.production"
                                rules={{ validate }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        title={`${fiscalYear?.code}`}
                                        placeholder="0"
                                        value={value?.toString()}
                                        onChange={onChange}
                                        sx={{
                                            backgroundColor: errors.period?.production && "#FFF0ED",
                                            borderColor: errors.period?.production && "red",
                                        }}
                                    />
                                )}
                            />
                            <Typography sx={{ mt: 1.5, ml: -5 }} variant="body">
                                &nbsp;unités d'œuvre
                            </Typography>
                        </Box>
                        {errors.period?.production && (
                            <Typography variant="body" color={"red"} component={"p"}>
                                Veuillez saisir une valeur numérique.
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ height: 20 }} />
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Controller
                                control={control}
                                name="previousPeriod.production"
                                rules={{ validate }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        title={`${previousFiscalYear?.code}`}
                                        placeholder="0"
                                        value={value?.toString()}
                                        onChange={onChange}
                                        sx={{
                                            backgroundColor: errors.previousPeriod?.production && "#FFF0ED",
                                            borderColor: errors.previousPeriod?.production && "red",
                                        }}
                                    />
                                )}
                            />
                            <Typography sx={{ mt: 1.5, ml: -5 }} variant="body">
                                &nbsp;unités d'œuvre
                            </Typography>
                        </Box>
                        {errors.previousPeriod?.production && (
                            <Typography variant="body" color={"red"} component={"p"}>
                                Veuillez saisir une valeur numérique.
                            </Typography>
                        )}
                    </Box>
                </WhiteBox>
                <Box sx={{ height: 20 }} />
                <WhiteBox sx={{ p: 6 }}>
                    <Typography variant="big-bold">Effectif de l'entreprise</Typography>
                    <Box sx={{ height: 20 }} />
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Controller
                                control={control}
                                name="period.headCount"
                                rules={{ validate }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        title={`${fiscalYear?.code}`}
                                        placeholder="0"
                                        value={value?.toString()}
                                        onChange={onChange}
                                        sx={{
                                            backgroundColor: errors.period?.headCount && "#FFF0ED",
                                            borderColor: errors.period?.headCount && "red",
                                        }}
                                        description="y compris vos CDD et apprentis"
                                    />
                                )}
                            />
                            <Typography sx={{ ml: -5 }} variant="body">
                                &nbsp;ETP
                            </Typography>
                        </Box>
                        {errors.period?.headCount && (
                            <Typography variant="body" color={"red"} component={"p"}>
                                Veuillez saisir une valeur numérique.
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ height: 20 }} />
                    <Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Controller
                                control={control}
                                name="previousPeriod.headCount"
                                rules={{ validate }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        title={`${previousFiscalYear?.code}`}
                                        placeholder="0"
                                        value={value?.toString()}
                                        onChange={onChange}
                                        sx={{
                                            backgroundColor: errors.previousPeriod?.headCount && "#FFF0ED",
                                            borderColor: errors.previousPeriod?.headCount && "red",
                                        }}
                                        description="y compris vos CDD et apprentis"
                                    />
                                )}
                            />
                            <Typography sx={{ ml: -5 }} variant="body">
                                &nbsp;ETP
                            </Typography>
                        </Box>
                        {errors.previousPeriod?.headCount && (
                            <Typography variant="body" color={"red"} component={"p"}>
                                Veuillez saisir une valeur numérique.
                            </Typography>
                        )}
                    </Box>
                </WhiteBox>
            </Box>
            <HelpPlease />
        </Box>
    );
};
