import { FC, useEffect } from "react";
import { useApplicationParameters } from "src/api/client-api/appParams";

export const Head: FC = (): null => {
    const { applicationParameters } = useApplicationParameters();

    const jimoHandler = (window: Window) => {
        const s = document.createElement("script");

        s.type = "text/javascript";
        s.async = true;
        s.src = "https://undercity.usejimo.com/jimo-invader.js";
        window["JIMO_PROJECT_ID"] = "cd2c259b-8f76-4939-bb87-1ec37bc29ba5";
        document.getElementsByTagName("head")[0].appendChild(s);
    };

    const freshDeskHandler = (window: Window, widgetId: string) => {
        const s = document.createElement("script");
        window["fwSettings"] = {
            widget_id: widgetId,
        };

        s.type = "text/javascript";
        s.async = true;
        s.src = `https://euc-widget.freshworks.com/widgets/${widgetId}.js`;

        document.getElementsByTagName("head")[0].appendChild(s);
    };

    useEffect(() => {
        jimoHandler(window);

        const widgetId = applicationParameters?.freshdeskWidgetId;
        if (widgetId) {
            freshDeskHandler(window, widgetId);
        }
    }, [applicationParameters]);

    return null;
};
