import { AxiosError } from "axios";
import { FieldErrors } from "src/api/axiosClient/errorHandler.ts";

export interface RFC2616Error {
    detail: string;
    title: string;
    type: string;
    violations: {
        propertyPath: string;
        message: string;
        code: string;
    }[];
}

export const isRFC2616Error = (error: AxiosError): boolean => {
    const payload = error.response?.data as RFC2616Error | undefined;
    return (
        error.response !== undefined &&
        error.response.status === 422 &&
        payload?.["type"] === "https://tools.ietf.org/html/rfc2616#section-10" &&
        payload?.violations !== undefined
    );
};

export const mapRfc2616Payload = (payload: RFC2616Error): FieldErrors => {
    const fieldErrors: FieldErrors = {
        errors: [],
    };

    if (!payload.violations) {
        return fieldErrors;
    }

    if (payload.violations.length === 0) {
        return fieldErrors;
    }

    payload.violations.forEach((violation) => {
        fieldErrors.errors.push({
            fieldPath: violation.propertyPath,
            message: violation.message,
        });
    });

    return fieldErrors;
};
