import { AnchoredPopover } from "@foodpilot/foods";
import { StackedBarChart, Shortcut, AssignmentIndOutlined, Edit, ContentCopy, Delete } from "@mui/icons-material";
import { SxProps, Stack, MenuItem, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { useProductDelete } from "src/api/client-api/products/useDeleteProduct";
import { useProductDuplicate } from "src/api/client-api/products/useDuplicateProduct";
import { CREATE, PRODUCT, useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { GridProduct, productGridKey } from "src/api/client-api/products/useGetAllproduct";
import { URLHandler } from "src/services/URLHandler";

type ActionsPopoverProps = {
    allProducts: GridProduct[];
    selectedRowItemId: number | null;
    anchor: HTMLElement | null;
    setAnchor: (value: HTMLElement | null) => void;
    closeWindow: () => void;
};
export const ActionsPopover = (props: ActionsPopoverProps) => {
    const { allProducts, anchor, setAnchor, selectedRowItemId } = props;
    const isRowMenuOpen = Boolean(anchor);

    return (
        <AnchoredPopover
            variant="themed"
            anchor={anchor}
            open={isRowMenuOpen}
            onClose={() => setAnchor(null)}
            anchorPosition="rightside"
        >
            <RowMenu selectedRowItemId={selectedRowItemId} allProducts={allProducts} closeWindow={props.closeWindow} />
        </AnchoredPopover>
    );
};

type RowMenuProps = {
    selectedRowItemId: number | null;
    allProducts: GridProduct[];
    closeWindow: () => void;
};

const RowMenu = (props: RowMenuProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { selectedRowItemId, allProducts } = props;
    const queryClient = useQueryClient();
    const deleteProduct = useProductDelete();
    const duplicateProduct = useProductDuplicate();
    const { canUser, selectedCompany } = useFoodpilotContext();

    const itemStyle: SxProps = {
        padding: "5px",
    };

    if (selectedRowItemId === null || selectedCompany === null) {
        return <div>{t("Aucune commande n'est disponible pour le produit")}</div>;
    }

    const mappedProducts = Object.groupBy(allProducts, (item) => item.id);
    const chosenProduct = mappedProducts[selectedRowItemId] ?? null;

    if (chosenProduct === null) return;
    if (chosenProduct.length <= 0) return;

    const firstFoundProduct = chosenProduct[0];

    return (
        <>
            <Stack direction="column" gap="20px" useFlexGap>
                {firstFoundProduct.canView && (
                    <Fragment>
                        <MenuItem
                            sx={itemStyle}
                            onClick={() => {
                                const url = URLHandler.product.scores(selectedCompany.id, selectedRowItemId);
                                navigate(url);
                            }}
                        >
                            <StackedBarChart />
                            <Typography sx={theme.custom.rowMenuElement}>{t("Scores du produit")}</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                const url = URLHandler.product.ssq(selectedCompany.id, selectedRowItemId);
                                navigate(url);
                            }}
                            sx={itemStyle}
                        >
                            <AssignmentIndOutlined />
                            <Typography sx={theme.custom.rowMenuElement}>
                                {t("Questionnaires relatifs au produit")}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                const url = URLHandler.product.information(selectedCompany.id, selectedRowItemId);
                                navigate(url);
                            }}
                            sx={itemStyle}
                        >
                            <Shortcut />
                            <Typography sx={theme.custom.rowMenuElement}>{t("Informations sur le produit")}</Typography>
                        </MenuItem>
                    </Fragment>
                )}

                {canUser(CREATE, PRODUCT) && (
                    <MenuItem
                        onClick={() => {
                            const product = allProducts?.find((p) => p.id === selectedRowItemId);
                            if (product !== undefined) {
                                const options = {
                                    productId: product.id,
                                    dupSuffix: t("copy"),
                                };
                                duplicateProduct.mutate(options, {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries(["productGridValues"]);
                                        props.closeWindow();
                                    },
                                });
                            }
                        }}
                        sx={itemStyle}
                    >
                        <ContentCopy />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Dupliquer le produit")}</Typography>
                    </MenuItem>
                )}

                {firstFoundProduct.canModify && (
                    <MenuItem
                        onClick={() => {
                            const url = URLHandler.product.edit(selectedCompany.id, selectedRowItemId, 0);
                            navigate(url);
                        }}
                        sx={itemStyle}
                    >
                        <Edit />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Modifier le produit")}</Typography>
                    </MenuItem>
                )}
                {firstFoundProduct.canDelete && (
                    <MenuItem
                        onClick={() => {
                            deleteProduct.mutate(selectedRowItemId, {
                                onSuccess: () => {
                                    queryClient.invalidateQueries([productGridKey]);
                                    props.closeWindow();
                                },
                            });
                        }}
                        sx={itemStyle}
                    >
                        <Delete />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Supprimer le produit")}</Typography>
                    </MenuItem>
                )}
            </Stack>
        </>
    );
};
