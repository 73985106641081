import { doesScoreExist, ScoreDimensionValue, Scores } from "src/api/client-api/post/scoreSchema";
import { Score } from "src/types";

export type ScoreVariation = {
    id: number;
    label: string;
    group: string;
    scoreId: number;
    onClick: () => void;
};

export class ScoreService {
    public static getAllScoreVariations(
        scoresEnabled: Score[],
        entityScores: Scores | undefined,
        scoreAction?: (scoreId: number, scoreVariationId: number | null) => void,
    ): ScoreVariation[] {
        const allScoreWithVariations = scoresEnabled.flatMap((score) => {
            const firstScoreBlock: ScoreVariation = {
                id: score.id,
                scoreId: score.id,
                label: score.title,
                group: score.title,
                onClick: () => {
                    scoreAction?.(score.id, null);
                },
            };

            const entityVariations = entityScores?.[score.id].variations ?? [];
            const scoreVariations: ScoreVariation[] = entityVariations.map((scoreVariation) => {
                return {
                    id: scoreVariation.id,
                    scoreId: score.id,
                    label: scoreVariation.label,
                    group: score.title,
                    onClick: () => {
                        scoreAction?.(score.id, scoreVariation.id);
                    },
                };
            });
            const opts = [firstScoreBlock, ...scoreVariations];
            return opts;
        });

        return allScoreWithVariations;
    }

    public static getScoreProperties = (
        scoreId: number | undefined,
        scores: Scores,
    ): Record<string, ScoreDimensionValue> => {
        if (scoreId === undefined) {
            return {};
        }
        const scoreProperties = scores?.[scoreId];
        if (scoreProperties !== undefined && doesScoreExist(scoreProperties)) {
            const foundProperties = scoreProperties.dimensions.find((dimension) => dimension.label === "properties");
            if (foundProperties !== undefined) {
                return foundProperties.values;
            }
        }

        return {};
    };

    // public static getScoreValue (
    // ) {

    // }
}
