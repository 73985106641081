import React from "react";

import { Permission as PermissionType } from "src/api/client-api/permissions/useGetAllPermissions.ts";

export type PermissionProps = {
    permissionName: string;
    list: PermissionType[] | undefined;
};

export const Permission = ({ permissionName, list }: PermissionProps) => {
    return (
        <div>
            {permissionName}: {list?.map((permission) => permission.slug).join(", ")}
        </div>
    );
};
