import { CategoryBox, Typography } from "@foodpilot/foods";

type GridCategoryBoxProps = {
    value: string[] | undefined;
    defaultValue?: string;
};
export const GridCategoryBox = (props: GridCategoryBoxProps) => {
    const { value, defaultValue = "-" } = props;

    const valueToDisplay = value !== undefined && value.length > 0 ? value : defaultValue;
    if (Array.isArray(valueToDisplay)) {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "8px",
                    paddingRight: "20px",
                }}
            >
                {valueToDisplay.map((item, idx) => (
                    <CategoryBox key={idx} text={item} />
                ))}
            </div>
        );
    }

    return (
        <Typography variant="body" sx={{ color: "#000" }}>
            {valueToDisplay}
        </Typography>
    );
};
