import { styled, tooltipClasses } from "@mui/material";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip/Tooltip";

const _FormTooltip = styled(({ className, ...props }: TooltipProps) => {
    return <Tooltip {...props} arrow classes={{ popper: className }} />;
});
export const FormTooltip = _FormTooltip((style) => {
    return {
        [`& .${tooltipClasses.arrow}`]: {
            color: style.theme.custom.grey[200],
        },
        [`& .${tooltipClasses.tooltip}`]: {
            color: style.theme.custom.grey[2800],
            padding: "12px 16px 12px 16px",
            borderRadius: "8px",
            border: `1px solid ${style.theme.custom.grey[500]}`,
            backgroundColor: style.theme.custom.grey[200],
        },
    };
});
