import { Stack, Box } from "@mui/material";
import { useProductListContext } from "../ProductListContext";
import { ColumnsPopover } from "../Popover/ColumnsPopover";
import { ProductPopover } from "../Popover/ProductPopover";
import { TitleSearch } from "src/components/Grid/TitleSearch";
import { useTranslation } from "react-i18next";
import { SwitchGammeButton } from "src/components/Button/SwitchGammeButton.tsx";
import { useGetAllProductLines } from "src/api/client-api/productLines/useProductLines.tsx";
import { CampaignSelector } from "src/components/Selector";

export const GridHeader = () => {
    const { t } = useTranslation();
    const { totalItems, delayedSearchProduct, resetSearch, currentGamme, setCurrentGamme } = useProductListContext();

    const { productLines } = useGetAllProductLines();
    const title = currentGamme ? currentGamme : t("Tous les produits");

    return (
        <Box sx={{ padding: "0px 32px" }}>
            <Stack direction="row" useFlexGap justifyContent="space-between" alignItems="center">
                <Stack direction="row" useFlexGap gap="12px">
                    <TitleSearch
                        title={title}
                        search={delayedSearchProduct}
                        totalItems={totalItems ?? 0}
                        resetSearch={resetSearch}
                    />
                    <SwitchGammeButton
                        label={" "}
                        allItems={productLines}
                        selectedItem={currentGamme}
                        setSelectedItem={setCurrentGamme}
                        noSelectedItemPlaceholder={t("Tous les produits")}
                    />
                </Stack>
                <Stack direction="row" useFlexGap gap="12px">
                    <CampaignSelector />
                    <ColumnsPopover />
                    <ProductPopover />
                </Stack>
            </Stack>

            <Stack
                direction="row"
                useFlexGap
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginTop: "32px" }}
            />
        </Box>
    );
};
