import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FoodsCheckbox, PrimaryDialog } from "@foodpilot/foods";
import { PackagingReference } from "src/api/sure-api/usePackagingReference";
import { ProductFormProductPackaging } from "../../ProductForm.types";

type PackagingModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    currentProductPackagings: ProductFormProductPackaging[];
    allPackagings: PackagingReference[];
    onValidate: (values: ProductFormProductPackaging[]) => void;
};

export const PackagingModal = (props: PackagingModalProps): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { isOpen, setOpen, currentProductPackagings, allPackagings } = props;

    const currentSelectedIds = currentProductPackagings.map((currPack) => currPack.packagingFormId);
    const originalSelectedItems = allPackagings.filter((allT) => currentSelectedIds.includes(allT.id));
    const [selectedItems, setSelectedItems] = useState(originalSelectedItems);

    useEffect(() => {
        setSelectedItems(originalSelectedItems);
    }, [allPackagings, currentProductPackagings]);

    const title = <Typography variant="big-bold">{t("Ajouter un emballage")}</Typography>;
    const content = (
        <div style={{ padding: "1.5rem", backgroundColor: theme.custom.grey[200] }}>
            <FoodsCheckbox
                selectedItems={selectedItems}
                values={allPackagings}
                onChange={(selectedValues) => setSelectedItems(selectedValues)}
            />
        </div>
    );

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setOpen}
            options={{
                title: title,
                content: content,
            }}
            onValidate={() => {
                const newPackagings: ProductFormProductPackaging[] = selectedItems.map((item) => {
                    const foundOriginal = currentProductPackagings.find(
                        (currPack) => currPack.packagingFormId === item.id,
                    );
                    const newPack: ProductFormProductPackaging = {
                        "@id": foundOriginal?.["@id"],
                        packagingFormId: item.id,
                        packagingMaterials: foundOriginal?.packagingMaterials ?? [],
                    };
                    return newPack;
                });
                props.onValidate(newPackagings);
            }}
            onCancel={() => {
                setSelectedItems(originalSelectedItems);
            }}
            fullWidth
            maxWidth="xs"
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};
