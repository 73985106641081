import { useState } from "react";
import { Box, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { FoodsCheckbox } from "@foodpilot/foods";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { DefaultValueButton } from "src/components/FormProperty/DefaultValueButton.tsx";
import { useTranslation } from "react-i18next";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { FormTooltip } from "../FormTooltip";

export const Checkbox = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { property, post, element, canEdit = true, isSubproperty } = props;

    const options =
        property.dataList?.options.sort(
            (optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position,
        ) ?? [];

    const defaultValueIds = (property.defaultValue ?? "").split("|");
    const genericValueIds = (property?.genericValue ?? "").split("|");

    const defaultValueOptions = options.filter(
        (option: DataListOption) => (defaultValueIds ?? []).indexOf(option.value) > -1,
    );
    const genericValueOptions = options.filter(
        (option: DataListOption) => (genericValueIds ?? []).indexOf(option.value) > -1,
    );

    const defaultValueLabel = defaultValueOptions.map((option) => option.label).join("; ");

    const initialValueIds = element && !property.readonly ? element?.value.split("|") : defaultValueIds;
    const initialValue = options.filter((option: DataListOption) => (initialValueIds ?? []).indexOf(option.value) > -1);

    const [value, setValue] = useState<DataListOption[] | undefined>(initialValue);
    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    if (!post) return <CircularProgress />;

    const updatePropertyValue = (value?: DataListOption[], isGenericValue?: boolean) => {
        const sanitizedValue = value ? value.map((option: DataListOption) => option.value).join("|") : "_empty_";

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: sanitizedValue,
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
        });
    };

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    return (
        <FormTooltip title={tooltipText} arrow placement="right">
            <Box>
                <Stack direction="column">
                    <Stack direction="row" alignItems="center">
                        <FoodsCheckbox
                            values={options}
                            selectedItems={value}
                            onChange={(selectedValues: DataListOption[]) => {
                                setValue(selectedValues);
                                updatePropertyValue(selectedValues);
                            }}
                            getId={(option: DataListOption) => option.value}
                            getName={(option: DataListOption) => option.label}
                            FormControlLabelProps={{
                                sx: {
                                    gap: "3px",
                                    ".MuiTypography-root": {
                                        ...theme.typography.body,
                                    },
                                },
                            }}
                            CheckboxProps={{
                                disabled: !canEdit || property.readonly || genericValueSelected,
                            }}
                        />
                        {!property.readonly && defaultValueOptions.length > 0 && !genericValueSelected && (
                            <DefaultValueButton
                                onClick={() => {
                                    setValue(defaultValueOptions);
                                    updatePropertyValue(defaultValueOptions);
                                }}
                            />
                        )}
                    </Stack>
                    {defaultValueOptions.length > 0 && !genericValueSelected && (
                        <Typography variant="caption" color={theme.custom.grey[1500]}>
                            {!property.readonly ?
                                t("Valeur par défaut :") + " " + defaultValueLabel
                            :   t("Cette valeur est en lecture seule")}
                        </Typography>
                    )}
                    {!property.readonly && genericValueOptions.length > 0 && (
                        <Box>
                            <GenericValueCheckbox
                                isSelected={genericValueSelected}
                                onChange={() => {
                                    setGenericValueSelected((prevState) => !prevState);
                                    setValue(!genericValueSelected ? genericValueOptions : undefined);
                                    updatePropertyValue(
                                        !genericValueSelected ? genericValueOptions : undefined,
                                        !genericValueSelected,
                                    );
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Box>
        </FormTooltip>
    );
};
