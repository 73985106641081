import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, Link as RouterLink, createSearchParams } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridRowModesModel,
    GridRowModes,
    GridActionsCellItem,
    GridRowId,
} from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useTranslation } from "react-i18next";
import { useGetAllSectors, CompanySector, Company } from "src/api/client-api/companySectors/useSupplierSectors";
import { CompanyType, useGetAllSupplier } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { useSupplierDelete } from "src/api/client-api/suppliers/useDeleteSupplier";
import { useSnackbar } from "src/utils/useSnackbar";
import { URLHandler } from "src/services/URLHandler";

type ProvidersListProps = {
    type?: CompanyType[];
};
export const ProvidersList = (props: ProvidersListProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { suppliers } = useGetAllSupplier();
    const { companySectors } = useGetAllSectors();

    const providers = suppliers;

    const deleteSupplier = useSupplierDelete();
    const snackbar = useSnackbar();

    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [companySectorsRows, setCompanySectorsRows] = useState<GridRowsProp[]>([]);
    const [companySectorsColumns, setCompanySectorsColumns] = useState<GridColDef[][]>([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [displayDialogNo, setDisplayDialogNo] = useState(false);
    const [idToDelete, setIdToDelete] = useState<{ id: number | null }>({ id: null });

    const handleSaveClick = useCallback(
        (id: GridRowId) => {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        },
        [rowModesModel],
    );

    const handleDeleteClick = useCallback(
        (id: number) => {
            if (!providers) return;

            setIdToDelete({ id: id });

            const provider = providers.find((provider) => provider.id === id);
            console.log("company", id, provider);

            if (provider && provider.users && provider.users.length > 0) {
                setDisplayDialogNo(true);
            } else {
                setDisplayDialog(true);
            }
        },
        [providers],
    );

    const handleCancelClick = useCallback(
        (id: GridRowId) => {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow!.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
        },
        [rowModesModel, rows],
    );

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: "col1",
                headerName: t("Nom de l'entreprise"),
                flex: 2,
                renderCell: (params) => (
                    <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                        {params.value}
                    </Typography>
                ),
                editable: true,
            },
            {
                field: "col2",
                headerName: t("Dernière mise à j."),
                flex: 1,
                renderCell: (params) => {
                    const newDate = new Date(params.value);
                    return newDate.toLocaleDateString("fr-fr", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    });
                },
            },
            {
                field: "actions",
                type: "actions",
                headerName: t("Actions"),
                width: 200,
                cellClassName: "actions",
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={() => handleSaveClick(id)}
                                key={id}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={() => handleCancelClick(id)}
                                color="inherit"
                                key={id}
                            />,
                        ];
                    }

                    return [
                        <RouterLink to={URLHandler.settings.companies.view(Number(id))} key={id}>
                            <GridActionsCellItem sx={{ color: "#000" }} icon={<ArrowOutwardIcon />} label="Open page" />
                        </RouterLink>,
                        <GridActionsCellItem
                            icon={
                                <IconButton component={RouterLink} to={URLHandler.settings.providers.edit(Number(id))}>
                                    <EditIcon />
                                </IconButton>
                            }
                            label="Edit"
                            className="textPrimary"
                            color="inherit"
                            key={id}
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleDeleteClick(parseInt(id.toString()))}
                            color="inherit"
                            key={id}
                        />,
                    ];
                },
            },
        ];
    }, [t, handleCancelClick, handleSaveClick, handleDeleteClick, rowModesModel]);

    type CompanySectorsRows = {
        id: number;
        col1: string;
        col2?: string;
        actions: number;
        col7: { companySectorIndex?: number; index: number };
    };

    useEffect(() => {
        if (!companySectors) return;

        const companySectorsRows: CompanySectorsRows[][] = [];
        const companySectorsColumns: GridColDef[][] = [];

        companySectors.forEach((companySector: CompanySector, companySectorIndex: number) => {
            const rows: CompanySectorsRows[] = [];
            const companies = companySector.companies;

            if (!companies) return;

            companies.forEach((company: Company, index) => {
                const row: CompanySectorsRows = {
                    id: company.id,
                    col1: company.name,
                    col2: company.updatedAt,
                    actions: company.id,
                    col7: { companySectorIndex, index },
                };

                rows.push(row);
            });
            companySectorsRows.push(rows);
            companySectorsColumns.push(columns);
        });

        const rows: CompanySectorsRows[] = [];

        if (providers)
            providers
                .filter((provider) => !provider.companySector)
                .forEach((company, index) => {
                    const row: CompanySectorsRows = {
                        id: company.id,
                        col1: company.name,
                        col2: company.updatedAt,
                        actions: company.id,
                        col7: { index },
                    };

                    rows.push(row);
                });

        companySectorsRows.push(rows);
        companySectorsColumns.push(columns);

        setCompanySectorsColumns(companySectorsColumns);
        setCompanySectorsRows(companySectorsRows);
    }, [companySectors, providers, columns]);

    const renderConfirmDialog = () => {
        return (
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>Êtes-vous sûr ?</DialogTitle>
                <DialogContent dividers>
                    {t("Cliquez sur 'oui' pour supprimer définitivement l'entreprise.")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={async () => {
                            if (idToDelete.id) {
                                deleteSupplier.mutate(idToDelete.id, {
                                    onSuccess: () => {
                                        window.location.href = URLHandler.settings.providers.list();
                                    },
                                    onError: () => {
                                        snackbar.setSnackbarProps({
                                            message: t(
                                                "La suppression du fournisseur n'est pas possible car un utilisateur lui est rattaché.",
                                            ),
                                            type: "error",
                                        });
                                    },
                                });
                                setDisplayDialog(false);
                            }
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderNoDialog = () => {
        return (
            <Dialog maxWidth="xs" open={displayDialogNo}>
                <DialogTitle>Suppression impossible</DialogTitle>
                <DialogContent dividers>{t("supplier.deletionError")}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialogNo(false);
                        }}
                    >
                        {t("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("Fournisseurs")}
                    </Typography>
                </Stack>
                <Box>
                    <Button
                        startIcon={<AddIcon />}
                        disableElevation
                        variant="primary"
                        sx={{
                            py: 1,
                        }}
                        onClick={() => {
                            navigate({
                                pathname: URLHandler.settings.providers.create(),
                                search: createSearchParams({
                                    fromProviders: "true",
                                }).toString(),
                            });
                        }}
                    >
                        {t("Ajouter un fournisseur")}
                    </Button>
                </Box>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            {renderConfirmDialog()}
            {renderNoDialog()}
            {companySectors &&
                companySectors.map((companySector, companySectorIndex) => {
                    return (
                        <Box key={`plist-${companySectorIndex}`}>
                            <Box sx={{ py: 2 }}></Box>
                            <Box key={companySectorIndex} sx={{ flex: "1 1 auto" }}>
                                <Typography variant="h3" sx={{ fontWeight: 700 }}>
                                    {companySector.name}
                                </Typography>
                                {companySectorsRows[companySectorIndex] && (
                                    <DataGrid
                                        autoHeight
                                        rowHeight={80}
                                        rows={companySectorsRows[companySectorIndex]}
                                        columns={companySectorsColumns[companySectorIndex]}
                                        sx={getGridStyles(companySector?.companies?.length ?? 0, rows.length ?? 0)}
                                    />
                                )}
                            </Box>
                        </Box>
                    );
                })}
            <Box key={`plist-notfound`}>
                <Box sx={{ py: 2 }}></Box>
                <Box sx={{ flex: "1 1 auto" }}>
                    <Typography variant="h3" sx={{ fontWeight: 700 }}>
                        {t("Sans secteur d'activité")}
                    </Typography>
                    {companySectors && companySectorsRows[companySectors.length] && (
                        <DataGrid
                            autoHeight
                            rowHeight={80}
                            rows={companySectorsRows[companySectors.length]}
                            columns={companySectorsColumns[companySectors.length]}
                            sx={getGridStyles(companySectorsRows[companySectors.length].length ?? 0, rows.length ?? 0)}
                        />
                    )}
                </Box>
            </Box>
        </Container>
    );
};

const getGridStyles = (length: number, rowLength: number) => {
    return {
        border: "none",
        backgroundColor: "transparent",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            marginBottom: "2px",
            border: 0,
            backgroundColor: "transparent",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#797A77",
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        "& .MuiDataGrid-row.Mui-selected": {
            background: " #FFFFFF",
            border: 0,
        },
        "& .MuiDataGrid-cell": {
            border: 0,
            paddingLeft: "20px",
            outline: "none !important",
        },
        "& .MuiDataGrid-footerContainer": {
            display: "none",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: rowLength * 7,
            height: `${length * 100}px !important`,
            boxSizing: "content-box",
        },
    };
};
