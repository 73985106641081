import { MainScores } from "src/api/client-api/post/scoreSchema";
import { Unit } from "src/api/sure-api/ssq/useGetOneSsq";

export type ChapterName =
    | "acidification"
    | "climate_change"
    | "ecotoxicity_freshwater"
    | "eutrophication_fresh_water"
    | "eutrophication_marine"
    | "eutrophication_terrestrial"
    | "human_toxicity_cancer"
    | "human_toxicity_non_cancer"
    | "ionising_radiation"
    | "land_use"
    | "ozone_depletion"
    | "particulate_matter"
    | "photochemical_ozone_formation"
    | "resource_use_fossils"
    | "resource_use_minerals_metals"
    | "water_use";

export function isChapterName(value: any): value is ChapterName {
    return false; //TODO temporarily until proper refactor is in place

    const validChapterNames: ChapterName[] = [
        "acidification",
        "climate_change",
        "ecotoxicity_freshwater",
        "eutrophication_fresh_water",
        "eutrophication_marine",
        "eutrophication_terrestrial",
        "human_toxicity_cancer",
        "human_toxicity_non_cancer",
        "ionising_radiation",
        "land_use",
        "ozone_depletion",
        "particulate_matter",
        "photochemical_ozone_formation",
        "resource_use_fossils",
        "resource_use_minerals_metals",
        "water_use",
    ];
    return validChapterNames.includes(value);
}

export type Chapter = {
    label: string;
    score: number;
    result?: number; // [ds] that's how the sure /calculation returns "score" for chapters
    unit_abbreviation: string;
};

export type ScoreVariations = {
    [formulaId: number]: {
        score: number;
    };
};

export type ChaptersScore = {
    chapters: Record<ChapterName, Chapter>;
    score: number | null;
    variations?: ScoreVariations;
};

export type ChaptersScoreMetadata = {
    id: number;
    label: string | null;
    default_display: boolean;
    type: string;
    unit: string;
    unit_abbreviation: string;
    score_id: number;
};

export type ChaptersScoreWithMetadata = ChaptersScore & ChaptersScoreMetadata;

export type Score = {
    id: number;
    title: string;
    type: string;
    displayMode?: string;
    unit?: Unit;
    label?: string;
    precision?: number;
};

export type FormattedScore = {
    chaptersScore?: ChaptersScoreWithMetadata;
    headingsScore: Record<string, ChaptersScore>;
    lcaStepsScore: Record<string, ChaptersScore>;
    properties: Record<string, ChaptersScore>;
    scoreVariations: Record<string, ChaptersScoreWithMetadata>;
};

export type FormattedScores = Record<string, FormattedScore>;

export type PostGrid = {
    id: number;
    formattedScores?: FormattedScores;
};

export type ValueWithScores = {
    scores: MainScores;
};
