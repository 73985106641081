import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CMSContentBlock, CMSPageSection } from "src/api/client-api/cms/useGetOneContent";
import { URLHandler } from "src/services/URLHandler";
import { EditHeader } from "../_Common/EditHeader";
import { useTranslation } from "react-i18next";
import { EditLayout } from "../_Common/EditLayout";
import { ConfirmationFooter } from "../_Common/ConfirmationFooter";
import { FoodsIcon, RoundInput } from "@foodpilot/foods";
import { useToggleSectionVisibility } from "src/api/client-api/cms/sections/useToggleSection";
import { useState } from "react";
import { SectionWrite, useCreateOneSection } from "src/api/client-api/cms/sections/useCreateOneSection";
import { DraggingRows } from "src/components/Dragging";
import { VerticalDivider } from "../../../Common/VerticalDivider";
import { useSectionsConfig } from "src/api/client-api/cms/sections/sectionsConfig";
import { AddIndicatorsModal } from "./Form/AddIndicatorsModal";
import { useDeleteOneBlock } from "src/api/client-api/cms/blocks/useDeleteOneBlock";
import { useReorderBlocks } from "src/api/client-api/cms/blocks/useReorderBlocks";

type IndicatorForm = {
    id: UUID;
    title: string;
    visible: boolean;
};

const getIndicatorForm = (section: CMSPageSection): IndicatorForm => {
    const firstBlock: IndicatorForm = {
        id: section.id,
        visible: section.visible,
        title: section.title,
    };
    return firstBlock;
};

const indicatorFormToSection = (pageId: IRI, form: IndicatorForm): SectionWrite => {
    const section: SectionWrite = {
        id: form.id,
        title: form.title,
        page: pageId,
    };
    return section;
};

type EditIndicatorsProps = {
    originalPageId: string;
    section: CMSPageSection;
};
export const EditIndicators = (props: EditIndicatorsProps) => {
    const { originalPageId, section } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const deleteBlock = useDeleteOneBlock();
    const reorderBlock = useReorderBlocks();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedExistingBlock, setSelectedExistingBlock] = useState<CMSContentBlock | null>(null);

    const toggleVisibility = useToggleSectionVisibility();
    const createSection = useCreateOneSection();

    const indicatorForm = getIndicatorForm(section);
    const [indicatorFormState, setIndicatorFormState] = useState<IndicatorForm>(indicatorForm);

    const blocksRows = section.blocks.map((block) => {
        return {
            id: block.id,
            title: block.title,
            link: block.ctaLink,
            position: block.position,
            editAction: () => {
                setSelectedExistingBlock(block);
                setIsOpen(true);
            },
            deleteAction: () => {
                deleteBlock.mutate({ deleteId: block.id, allBlocksToReorder: section.blocks });
            },
        };
    });
    const blockConfig = useSectionsConfig();
    const config = blockConfig[section.type];

    // const firstBlock = section.blocks[1];
    return (
        <Stack gap={theme.spacing(3)}>
            <EditHeader
                title={config.title}
                subtitle={config.subtitle}
                isActivated={indicatorFormState.visible}
                disableAction={() => {
                    toggleVisibility.mutate(
                        {
                            sectionId: section.id,
                            currentSectionState: section.visible,
                        },
                        {
                            onSuccess: () => {
                                const newVisible = !indicatorFormState.visible;
                                setIndicatorFormState({
                                    ...indicatorFormState,
                                    visible: newVisible,
                                });
                            },
                        },
                    );
                }}
                goBackAction={() => {
                    const url = URLHandler.settings.cms.page(originalPageId);
                    navigate(url);
                }}
            />
            {/* <MainContentLayout> */}
            {/* <Box sx={{
                    maxWidth: "300px",
                }}>
                    <img
                        src={firstBlock.media?.url ?? ""}
                        style={{
                            maxWidth: "100%",
                            height: "auto",
                            width: "auto",
                        }}
                    />
                </Box> */}
            {/* <CMSArticles blocks={section.blocks} /> */}
            {/* </MainContentLayout> */}
            <EditLayout>
                <Typography variant="big-medium">{t("cms.section.articles.addTitle")}</Typography>
                <RoundInput
                    value={indicatorFormState.title}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setIndicatorFormState({
                            ...indicatorFormState,
                            title: newValue,
                        });
                    }}
                />
                <ConfirmationFooter
                    saveAction={() => {
                        const newSection = indicatorFormToSection(section.page, indicatorFormState);
                        createSection.mutate({ section: newSection });
                    }}
                    resetContent={() => {}}
                />
            </EditLayout>
            <EditLayout>
                <AddIndicatorsModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentSection={{
                        "@id": section["@id"],
                        totalExistingElements: section.blocks.length,
                    }}
                    selectedExistingBlock={selectedExistingBlock}
                />
                <Typography variant="big-medium">{t("cms.section.indicators.add.info")}</Typography>
                <DraggingRows
                    rows={blocksRows}
                    renderRow={(row, rowIndex) => {
                        return (
                            <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                key={`index-${rowIndex}`}
                                sx={{ border: `2px solid ${theme.custom.grey[2000]}`, width: "100%" }}
                                padding={theme.spacing(1)}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={theme.spacing(4)}
                                    marginLeft={theme.spacing(1)}
                                >
                                    <Typography variant="big-bold">{row.title}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                                    <Button
                                        variant="text"
                                        startIcon={<FoodsIcon size={2} icon={"edit"} />}
                                        onClick={row.editAction}
                                    >
                                        {"Modifier"}
                                    </Button>
                                    <VerticalDivider />
                                    <Box onClick={row.deleteAction}>
                                        <FoodsIcon size={4} icon="delete" />
                                    </Box>
                                </Stack>
                            </Stack>
                        );
                    }}
                    onHoverDrop={(reorderedRows) => {
                        reorderBlock.mutate({ allBlocksToReorder: reorderedRows });
                    }}
                />
                {section.blocks.length >= 6 ?
                    <Typography variant="big-bold">{t("cms.section.indicators.maxlimit")}</Typography>
                :   <Button
                        variant="primary"
                        onClick={() => {
                            setSelectedExistingBlock(null);
                            setIsOpen(true);
                        }}
                    >
                        {t("cms.section.indicators.add.button")}
                    </Button>
                }
            </EditLayout>
        </Stack>
    );
};
