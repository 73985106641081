import { Box, Link, useTheme } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { BigPopoverLayout, SearchBox } from "@foodpilot/foods";
import { useMemo, useState } from "react";

export type ISelectProductLineProps<T> = {
    onClose: () => void;
    allItems: T[] | undefined;
    selectedItem: string | undefined;
    setSelectedItem: (newGamme: string | undefined) => void;
    noSelectedItemPlaceholder: string;
    libelle?: string;
};

type MandatoryKeys = {
    id: number;
    name: string;
};
export const SelectProductLinePopover = <T extends MandatoryKeys>(props: ISelectProductLineProps<T>) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { allItems = [], setSelectedItem, selectedItem, onClose, noSelectedItemPlaceholder } = props;

    const [temporarySelectedItem, setTempSelectedItem] = useState(selectedItem);
    const [search, setSearch] = useState<string | null>(null);

    const filteredGammes = useMemo(() => {
        if (search === "" || search === null) return allItems;

        const lowerCaseSearch = search.toLowerCase();

        return allItems.filter((gamme) => {
            const lowerCaseGammeName = gamme.name.toLowerCase();
            return lowerCaseGammeName.includes(lowerCaseSearch);
        });
    }, [allItems, search]);

    return (
        <BigPopoverLayout
            bottomActions
            headerText={props.libelle ?? t("Changer de gamme")}
            headerIcon={<></>}
            onClose={onClose}
            onCancel={onClose}
            onValidate={() => {
                setSelectedItem(temporarySelectedItem);
                onClose();
            }}
            cancelLabel={t("glossary.cancel")}
            validateLabel={t("glossary.save")}
        >
            <SearchBox
                variant="inline"
                value={search || ""}
                onChange={(val: string) => {
                    setSearch(val);
                }}
            />
            <Link
                onClick={() => setTempSelectedItem(undefined)}
                sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: !temporarySelectedItem ? theme.palette.primary[600] : theme.custom.grey[2800],
                    lineHeight: "3",
                    padding: "0.4rem 0.8rem 0",
                    display: "inline-block",
                }}
            >
                {noSelectedItemPlaceholder}
            </Link>
            {!temporarySelectedItem && (
                <Check sx={{ color: theme.palette.primary[600], width: "16px", height: "16px" }} />
            )}
            <Box
                component="ul"
                sx={{
                    listStyle: "none",
                    margin: "0",
                    padding: "0",
                    marginTop: "10px",
                }}
            >
                {filteredGammes.map((gamme) => {
                    const isSelected = gamme.name === temporarySelectedItem;
                    return (
                        <RenderNode
                            key={"fg-" + gamme.id}
                            setSelectedItem={setTempSelectedItem}
                            currentItem={gamme.name}
                            isSelected={isSelected}
                        />
                    );
                })}
            </Box>
        </BigPopoverLayout>
    );
};

export type IRenderNodeProps<T> = {
    isSelected: boolean;
    setSelectedItem: (newItem: string) => void;
    currentItem: string;
};

const RenderNode = <T extends MandatoryKeys>(props: IRenderNodeProps<T>) => {
    const theme = useTheme();
    const { isSelected, setSelectedItem, currentItem } = props;
    return (
        <Box
            component="li"
            sx={{
                padding: "0.7rem 0.4rem",
                fontWeight: "bold",
                margin: "0 0.8rem",
                position: "relative",
                fontSize: "0.9rem",
                borderLeft: `2px dotted ${theme.custom.grey[900]}`,
                "&:before": {
                    position: "absolute",
                    left: "-5px",
                    top: "1.2rem",
                    height: "0.5rem",
                    width: "0.5rem",
                    background: theme.custom.grey[900],
                    borderRadius: "100%",
                    display: "block",
                    content: "''",
                },
            }}
        >
            <Link
                onClick={() => setSelectedItem(currentItem)}
                sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                    color: isSelected ? theme.palette.primary[600] : theme.custom.grey[2800],
                }}
            >
                {currentItem}
            </Link>
            {isSelected && <Check sx={{ color: theme.palette.primary[600], width: "16px", height: "16px" }} />}
        </Box>
    );
};
