import { MRTGrid } from "@foodpilot/foods";
import { createMRTColumnHelper, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IngredientList, useGetAllIngredient } from "src/api/client-api/ingredients/useGetAllIngredients";
import { GridLink } from "src/components/Grid/GridLink";
import { GridText, GridTextList } from "src/components/Grid/GridText";
import { SupplierFormIngredientSupplier } from "../../../SupplierForm.types";

type RowNumber = number;
type IsSelected = boolean;

// 2 different states because the MRT library needs its own state !
export type RowSelectionState = {
    rowSelection: Record<RowNumber, IsSelected>;
    existingIngredientsMapping: Record<RowNumber, SupplierFormIngredientSupplier | undefined>;
    ingredientListMapping: Record<RowNumber, IngredientList>;
};

const getInitialSelection = (
    ingredientList: IngredientList[] | undefined,
    existingIngredients: SupplierFormIngredientSupplier[],
) => {
    const emptyRowSelectionState: RowSelectionState = {
        rowSelection: {},
        existingIngredientsMapping: {},
        ingredientListMapping: {},
    };

    if (ingredientList === undefined) return emptyRowSelectionState;

    return ingredientList.reduce((acc, ingredient, rowNumber) => {
        const foundExistingIng = existingIngredients.find((ing) => {
            return ing.ingredient["@id"] === ingredient["@id"];
        });
        const isFound = foundExistingIng !== undefined;

        acc["rowSelection"][rowNumber] = isFound;
        acc["existingIngredientsMapping"][rowNumber] = foundExistingIng;
        acc["ingredientListMapping"][rowNumber] = ingredient;

        return acc;
    }, emptyRowSelectionState);
};

type SupplierIngredientsGridProps = {
    existingIngredientSuppliers: SupplierFormIngredientSupplier[];
    setNewIngredientSuppliers: (newValues: SupplierFormIngredientSupplier[]) => void;
};
export const SupplierIngredientsGrid = (props: SupplierIngredientsGridProps) => {
    const { t } = useTranslation();
    const { existingIngredientSuppliers, setNewIngredientSuppliers } = props;

    const [ingredientPage, setIngredientPage] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const { ingredients, totalItems } = useGetAllIngredient({
        page: ingredientPage.pageIndex + 1,
        itemsPerPage: ingredientPage.pageSize,
    });

    const initialSelection = getInitialSelection(ingredients, existingIngredientSuppliers);
    const [rowSelection, setRowSelection] = useState(initialSelection["rowSelection"]);

    useEffect(() => {
        setRowSelection(initialSelection["rowSelection"]);
    }, [ingredients]);

    useEffect(() => {
        // The RowSelection is a Record<RowNumber, ...>;
        // The problem comes from MRT actually deleting the key, instead of setting it to False
        // when we unselect the element.
        // So we want to make sure the keys who don't exist are still set to at least FALSE;
        const rowSelectionFixed = Array(ingredientPage.pageSize)
            .fill(undefined)
            .map((_, index) => index);
        const rowSelectionArray = rowSelectionFixed.map((row) => {
            const rowString = row.toString();
            const selectedRow = rowSelection[row];
            if (selectedRow !== undefined) {
                return [rowString, selectedRow];
            }
            return [rowString, false];
        });
        if (rowSelectionArray.length === 0) {
            // Means the component is not loaded yet.
            return;
        }

        const existingIngredientCopy = [...existingIngredientSuppliers];
        const newProductIngredients = rowSelectionArray.reduce((previousValue, [rowNumber, isSelected]) => {
            if (isSelected === false) {
                // Since it's NOT selected, we want to DELETE the item if it existed.
                const row = Number(rowNumber);
                const existingIng = initialSelection["existingIngredientsMapping"][row];
                if (existingIng === undefined) return previousValue;

                return previousValue.filter((item) => item.ingredient["@id"] !== existingIng.ingredient["@id"]);
            } else {
                // The item is actively selected.
                const row = Number(rowNumber);
                const existingIng = initialSelection["existingIngredientsMapping"][row];

                // Does it already exists ?
                if (existingIng !== undefined) return previousValue;

                // Now we're sure we just selected it.
                const ingredientList = initialSelection["ingredientListMapping"][row];

                const newIngredient: SupplierFormIngredientSupplier = {
                    ingredient: {
                        "@id": ingredientList["@id"],
                        name: ingredientList.name,
                        ingredientLine: ingredientList.ingredientLine,
                        ingredientReferenceId: ingredientList.ingredientReferenceId,
                    },
                    quantity: null,
                    unit: null,
                };

                return [...previousValue, newIngredient];
            }
        }, existingIngredientCopy);

        // Here, we have the Full previous selected ingredients
        // In addition of the new ones;
        // In deletion of those that were deleted;
        setNewIngredientSuppliers(newProductIngredients);
    }, [rowSelection]);

    const columnHelper = createMRTColumnHelper<IngredientList>();
    const mrt_columns = [
        columnHelper.accessor("name", {
            header: t("Nom"),
            Cell: (props) => <GridLink>{props.renderedCellValue}</GridLink>,
        }),
        columnHelper.accessor((row) => row.ingredientLine?.name ?? "-", {
            id: "ingredientLine",
            header: t("Filière"),
            Cell: (value) => <GridText value={value.cell.getValue()} />,
        }),
        columnHelper.accessor("ingredientSuppliers", {
            header: t("Fournisseurs"),
            Cell: (value) => {
                const listOfSuppliers =
                    value.cell.getValue()?.flatMap((item) => (item.supplier ? item.supplier : [])) ?? [];
                const suppliersName = listOfSuppliers.map((supplier) => supplier.name);
                return <GridTextList value={suppliersName} />;
            },
        }),
    ];

    if (ingredients === undefined) {
        return null;
    }

    return (
        <MRTGrid
            columns={mrt_columns}
            data={ingredients}
            globalFilterFn="contains"
            enableRowSelection={true}
            enablePagination={true}
            onRowSelectionChange={setRowSelection}
            state={{
                isLoading: ingredients === undefined,
                rowSelection: rowSelection,
                pagination: ingredientPage,
            }}
            rowCount={totalItems}
            manualPagination
            onPaginationChange={setIngredientPage}
        />
    );
};
