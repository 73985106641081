import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import ns_de from "public/locales/de/default_ns.json";
import ns_en from "public/locales/en/default_ns.json";
import ns_es from "public/locales/es/default_ns.json";
import ns_fr from "public/locales/fr/default_ns.json";
import ns_it from "public/locales/it/default_ns.json";
import { LanguageService } from "./services/LanguageService";

// This type is used for the LanguageService to make a Typesafe system
export type Resources = {
    de: { default_ns: typeof ns_de };
    en: { default_ns: typeof ns_en };
    es: { default_ns: typeof ns_es };
    fr: { default_ns: typeof ns_fr };
    it: { default_ns: typeof ns_it };
};
export const defaultNS = "default_ns";
export const resources: Resources = {
    de: { default_ns: ns_de },
    en: { default_ns: ns_en },
    es: { default_ns: ns_es },
    fr: { default_ns: ns_fr },
    it: { default_ns: ns_it },
} as const;

const fallbackLng = "fr";

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: LanguageService.getCurrentLanguage(resources, fallbackLng),
        fallbackLng: fallbackLng,
        ns: ["default_ns"],
        defaultNS,
        resources,
        nsSeparator: false,
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
