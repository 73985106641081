import { ContextualContentBox, FormBox } from "@foodpilot/foods";
import { Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ProductInformation } from "./ProductInformation";
import { ProductFormType } from "../../ProductForm.types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AgribalyseInfoDrawer } from "src/components/Drawer/AgribalyseInfo";

type Page1Props = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};

export const Page1 = (props: Page1Props) => {
    return (
        <Box sx={{ display: "flex", gap: "1rem" }}>
            <FormBox sx={{ flex: 3 }}>
                <ProductInformation product={props.product} setProduct={props.setProduct} />
            </FormBox>
            <FormBox
                sx={{
                    position: "sticky",
                    top: "120px",
                    flex: 1.5,
                    padding: 0,
                }}
            >
                <AgribalyseBoxInfo />
            </FormBox>
        </Box>
    );
};

const AgribalyseBoxInfo = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    return (
        <Box>
            <ContextualContentBox
                size="small"
                title={t("Pourquoi associer votre produit à une référence Agribalyse® ?")}
            >
                <Box>
                    <Typography variant="body">{t("info.agribalyse-1")}</Typography>
                    <Button variant="text" onClick={() => setOpen(true)} endIcon={<ArrowForwardIcon />}>
                        {t("En savoir plus")}
                    </Button>
                </Box>
            </ContextualContentBox>
            <AgribalyseInfoDrawer isOpen={open} setOpen={setOpen} />
        </Box>
    );
};
