import { SolidGreyBox, WhitePopoverOnIcon } from "@foodpilot/foods";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningIcon from "src/assets/icons/warning.svg?react";

type ProductWeightReminderProps = {
    productWeight: number;
    currentWeight: number;
};
export const ProductWeightReminder = (props: ProductWeightReminderProps) => {
    const { productWeight, currentWeight } = props;
    const { t } = useTranslation();

    const tooltipBody = (
        <div style={{ padding: "24px 20px" }}>
            <Typography variant="body-bold">
                {t("Le poids des ingrédients ne correspond pas au poids net du produit") + ` (${productWeight}g).`}
            </Typography>
            <br />
            <Typography variant="body">
                {t(
                    "Vous devez ajuster la part ou le poids des ingrédients dans la recette pour tomber sur le poids net juste",
                )}
            </Typography>
        </div>
    );

    const isCorrect = productWeight === currentWeight;

    // We decide if the product is correct before the weight formatting to display on the screen.
    // Since the Weight can be deduced from a % in the Front-end,
    // we want to put a maximum of 2 decimals. Ex : 23.23, and not 23.23455633566.
    const computeWeightToDisplay = (weight: number): number => {
        const roundedNumber = Math.floor(weight * 100) / 100;

        if (roundedNumber === weight) return weight;
        return roundedNumber;
    };

    return (
        <div
            style={{
                position: "relative",
                height: "fit-content",
                width: "fit-content",
            }}
        >
            <SolidGreyBox
                sx={{
                    width: "fit-content",
                }}
            >
                <Typography variant="body" textAlign={"center"}>
                    {t("Poids net de tous les ingrédients :")}
                </Typography>
                <Typography variant="h3" textAlign={"center"} style={{ display: "flex", justifyContent: "center" }}>
                    {`${computeWeightToDisplay(currentWeight)}g`}
                    {!isCorrect && <WhitePopoverOnIcon icon={<WarningIcon />} body={tooltipBody} size={"380px"} />}
                </Typography>
            </SolidGreyBox>
        </div>
    );
};
