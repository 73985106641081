import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { z } from "zod";
import { zodParseWithLog } from "src/api/apiErrorLogs";

type Options = {
    page?: number;
    itemsPerPage?: number;
    search?: string;
    email?: string;
    company?: string;
};
export const useAllUsers = (options?: Options) => {
    const { page = 1, itemsPerPage = 200, search = "", email = "", company = "" } = options || {};

    const query = useQuery({
        queryKey: ["allUsers", page, itemsPerPage, search, email, company],
        queryFn: async () => {
            return await loggedInApiCall.get("/api/users", {
                params: {
                    page,
                    itemsPerPage,
                    q: search,
                    email,
                    "companies.name": company,
                },
            });
        },
        select: (request) => {
            const totalItems = request.data["hydra:totalItems"];
            const user = request.data["hydra:member"];

            const data = zodParseWithLog(user, UserSchemaZod.array(), "allUsers");

            data.forEach((user) => {
                user.roles = user.roles.filter((role) => role !== "ROLE_USER");
            });

            return {
                items: data,
                totalItems: totalItems,
            };
        },
    });

    return {
        users: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};

export type UserCompanySector = {
    id: number;
    name: string;
    ssqId?: number;
};
const UserCompanySectorZod: z.ZodSchema<UserCompanySector> = z.object({
    id: z.number(),
    name: z.string(),
    ssqId: z.number().optional(),
});

export type UserCompany = {
    "@id": IRI;
    id: number;
    name: string;
    companySector?: UserCompanySector;
};
const UserCompanyZod: z.ZodSchema<UserCompany> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    companySector: UserCompanySectorZod.optional(),
});

export type User = {
    "@id": IRI;
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    phone?: string;
    companies: UserCompany[];
    roles: Array<string>;
};

export const UserSchemaZod: z.ZodSchema<User> = z.object({
    "@id": z.string(),
    id: z.number(),
    email: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    phone: z.string().optional(),
    companies: UserCompanyZod.array(),
    roles: z.array(z.string()),
});
