import { useQuery } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { parseAndSelect } from "src/api/apiHelpers.ts";
import { RoleSchema } from "src/api/client-api/roles/useGetRole.ts";

export const getAllRolesKey = "allRoles" as const;

type Options = {
    itemsPerPage?: number;
    page?: number;
};
export const useGetAllRoles = (options?: Options) => {
    const { itemsPerPage = 20, page = 1 } = options || {};

    const query = useQuery({
        queryKey: getAllRolesKey,
        queryFn: async () => {
            const pagination = `itemsPerPage=${itemsPerPage}&page=${page}`;

            const url = `/api/roles?${pagination}`;
            return await loggedInApiCall.get(url);
        },
        select: (request) => parseAndSelect(request, RoleSchema.array(), getAllRolesKey),
    });

    return {
        allRoles: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};
