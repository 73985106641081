import { Stack, useTheme } from "@mui/material";
import { ReactNode } from "react";

type EditLayoutProps = {
    children: ReactNode;
};
export const EditLayout = (props: EditLayoutProps) => {
    const { children } = props;
    const theme = useTheme();

    return (
        <Stack
            margin={theme.spacing(2, 8)}
            padding={theme.spacing(2)}
            border={`1px solid black`}
            gap={theme.spacing(2)}
        >
            {children}
        </Stack>
    );
};
