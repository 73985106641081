import { BlockListContainer, ListHeader, SuggestionBlock, Typography } from "@foodpilot/foods";
import { Stack, useTheme } from "@mui/material";
import { ActionBlock } from "./ActionBlock";
import { EmptyPlanning } from "./EmptyPlanning";
import { Fragment } from "react/jsx-runtime";
import { PlanningLayout } from "../PlanningLayout";
import { PlanningListHeader } from "./PlanningListHeader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const ListPlanning = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const numberofActions = 0;
    const totalActions = 24;

    const [isPlanningStarted, setIsPlanningStarted] = useState(false);

    if (isPlanningStarted === false) {
        return <EmptyPlanning setIsPlanningStarted={setIsPlanningStarted} />;
    }

    return (
        <Fragment>
            <PlanningListHeader />
            <PlanningLayout>
                <BlockListContainer
                    header={
                        <ListHeader
                            title={
                                <Stack flexDirection="row" gap={theme.spacing(1)}>
                                    <Typography variant={"h3"}>{t("actionPlan.activeActions")}</Typography>
                                    <Typography variant={"huge"}>{"-"}</Typography>
                                    <Typography variant={"huge"}>{`${numberofActions} / ${totalActions}`}</Typography>
                                </Stack>
                            }
                            cancel={{
                                label: t("glossary.cancel"),
                                action: () => {
                                    setIsPlanningStarted(false);
                                },
                            }}
                            confirm={{
                                label: t("actionPlan.validate"),
                                action: function (): void {
                                    throw new Error("Function not implemented.");
                                },
                                disabled: undefined,
                            }}
                        />
                    }
                    blocks={[
                        <ActionBlock />,
                        <SuggestionBlock
                            suggestionTitle={<Typography variant="body-bold">{t("glossary.suggestion")}</Typography>}
                            text={<Typography variant="body">{"You should really improve !"}</Typography>}
                        />,
                    ]}
                />
            </PlanningLayout>
        </Fragment>
    );
};
