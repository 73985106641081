import { CustomToggleButton, FoodsIcon, Typography } from "@foodpilot/foods";
import { Box, Stack } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";
import { useProductContext } from "./ProductContext";
import { Loading } from "src/components/utils/Loading";

export const ProductQuestionnaire = () => {
    const { ssq, isLoading } = useProductContext();

    if (isLoading || !ssq) {
        return <Loading />;
    }

    if (ssq.monolithic) {
        return (
            <Stack gap={3}>
                <Stack maxWidth={"1280px"}>
                    <Outlet />
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack gap={3}>
            <Stack direction={"row"} gap={1}>
                <NavLink to={"summary"} style={{ alignSelf: "stretch" }}>
                    {({ isActive }) => (
                        <CustomToggleButton value selected={isActive} sx={{ height: "100%" }}>
                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <FoodsIcon icon="questionnaires" size={3} />
                                <Typography variant="h5" color={"inherit"}>
                                    Summary
                                </Typography>
                            </Stack>
                        </CustomToggleButton>
                    )}
                </NavLink>
                <NavLink to={"product"}>
                    {({ isActive }) => (
                        <CustomToggleButton value selected={isActive}>
                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <FoodsIcon icon="products" size={3} />
                                <Typography variant="h5" color={"inherit"}>
                                    Product
                                </Typography>
                            </Stack>
                        </CustomToggleButton>
                    )}
                </NavLink>
                <NavLink to={"ingredients"}>
                    {({ isActive }) => (
                        <CustomToggleButton value selected={isActive}>
                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <FoodsIcon icon="ingredients" size={3} />
                                <Typography variant="h5" color={"inherit"}>
                                    Ingredients
                                </Typography>
                            </Stack>
                        </CustomToggleButton>
                    )}
                </NavLink>
            </Stack>
            <Stack>
                <Outlet />
            </Stack>
        </Stack>
    );
};
