import { useParams } from "react-router-dom";
import { PageParams, SettingsCMSContext, SettingsCMSContextInitialValues } from "./context";
import { useGetOneContent } from "src/api/client-api/cms/useGetOneContent";
import { Loading } from "src/components/utils/Loading";

type SettingsCMSProviderProps = {
    children: JSX.Element;
};
export const SettingsCMSProvider = (props: SettingsCMSProviderProps) => {
    const { children } = props;
    const { pageId, sectionId, blockId } = useParams();

    const pageParams: PageParams = {
        pageId: pageId,
        sectionId: sectionId,
        blockId: blockId,
    };
    const { content, isFetched } = useGetOneContent({ contentId: pageId });

    if (isFetched === false) {
        return <Loading />;
    }

    const items: SettingsCMSContextInitialValues = {
        content: content,
        isContentFetched: isFetched,
        pageParams,
    };

    return <SettingsCMSContext.Provider value={items}>{children}</SettingsCMSContext.Provider>;
};
