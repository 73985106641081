import { useQuery } from "react-query";
import { Score } from "src/types";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { z } from "zod";
import { zodParseWithLog } from "src/api/apiErrorLogs";

export const getAllScoreKey = "getAllScore" as const;

export const useGetAllScore = () => {
    const query = useQuery({
        queryKey: [getAllScoreKey],
        cacheTime: 0,
        queryFn: async () => {
            return await sureApiCall.get(`/api/scores`);
        },
        select: (response) => {
            const scores = response.data["hydra:member"];
            const data = zodParseWithLog(scores, ScoreSchema.array(), getAllScoreKey);
            return data;
        },
    });

    return {
        scores: query.data,
        ...query,
    };
};

export const UnitSchema = z.object({
    id: z.number(),
    title: z.string(),
    abbreviation: z.string(),
});

export const ScoreSchema: z.ZodSchema<Score> = z.object({
    id: z.number(),
    title: z.string(),
    type: z.string(),
    displayMode: z.string(),
    unit: UnitSchema.optional(),
    label: z.string().optional(),
    precision: z.number().optional(),
});
