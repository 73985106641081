import { useResolvedPath, useMatch, Outlet, useOutletContext } from "react-router-dom";
import { Sections } from "./ProductDetails/ProductDetails";

type SectionContext = { section: Sections["section"] };

export const SectionResolver = () => {
    const { pathname } = useResolvedPath("."); // resolved to the parent path
    const route = useMatch(`${pathname}/:path/*`);
    const section = route?.params.path ?? "scores";

    // type cast is kinda safe here : if section does not exist in the section tabs
    // a 404 would have been thrown before
    return <Outlet context={{ section } as SectionContext} />;
};

export function useSectionResolverContext() {
    return useOutletContext<SectionContext>();
}
