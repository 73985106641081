import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ForkliftIcon from "src/assets/icons/forklift-black.svg?react";
import ThreeDotsIcon from "src/assets/icons/three-dots-black.svg?react";

type ProductIngredient = {
    ingredient?: {
        name: string;
    };
    usedQuantity?: number | null;
    quantity?: number | null;
};

type ProductRecipeProps = {
    productIngredients: ProductIngredient[];
    suppliersName: string[];
};
export const ProductRecipeSummary = (props: ProductRecipeProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { productIngredients, suppliersName } = props;

    if (productIngredients.length === 0) {
        return (
            <Typography
                variant="h3"
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {t("Pas d'ingrédient associé à ce produit")}
            </Typography>
        );
    }

    const noValue = "-";
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
            }}
        >
            <Box sx={{ width: "70%", display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <ThreeDotsIcon
                        style={{
                            background: theme.custom.grey[400],
                            borderRadius: "100%",
                            width: "32px",
                            height: "32px",
                            padding: "5px",
                        }}
                    />
                    <Typography
                        variant="h4"
                        sx={{ marginLeft: "12px", display: "flex", height: "32px", alignItems: "center" }}
                    >
                        {t("Ingrédients du produit et leur part dans la recette")}
                    </Typography>
                </Box>
                <Box
                    component="table"
                    sx={{
                        width: "100%",
                        display: "table",
                        "&>tr": {
                            display: "flex",
                        },
                        "&>tr>td": {
                            flex: 1,
                            borderBottom: `1px solid ${theme.custom.grey[500]}`,
                            padding: "16px 0px",
                        },
                        "&>tr>th": {
                            flex: 1,
                            borderBottom: `1px solid ${theme.custom.grey[500]}`,
                            padding: "16px 0px",
                            textAlign: "left",
                        },
                    }}
                >
                    <tr>
                        <th>
                            <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                                {t("Nom de l'ingrédient")}
                            </Typography>
                        </th>
                        <th>
                            <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                                {t("pages.product.product_form.pages.page2.ingredient_box.quantity_used")}
                            </Typography>
                        </th>
                        <th>
                            <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                                {t("Poids dans la recette")}
                            </Typography>
                        </th>
                    </tr>
                    {productIngredients.map((productIngredient, index) => {
                        const { ingredient, quantity, usedQuantity } = productIngredient;
                        const title = ingredient?.name ?? noValue;
                        return (
                            <tr key={`pi-line-recipe-${title}-${index}`}>
                                <td>
                                    <Typography variant="body-bold">{title}</Typography>
                                </td>
                                <td>
                                    {usedQuantity ?
                                        <Typography variant="body-medium">
                                            {usedQuantity} {"g"}
                                        </Typography>
                                    :   <Typography variant="body-medium">{noValue}</Typography>}
                                </td>
                                <td>
                                    {quantity ?
                                        <Typography variant="body-medium">
                                            {quantity} {"g"}
                                        </Typography>
                                    :   <Typography variant="body-medium">{noValue}</Typography>}
                                </td>
                            </tr>
                        );
                    })}
                </Box>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ marginLeft: "16px", marginRight: "16px" }} />
            <Box sx={{ width: "30%", display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <ForkliftIcon
                        style={{
                            background: theme.custom.grey[400],
                            borderRadius: "100%",
                            width: "32px",
                            height: "32px",
                            padding: "5px",
                        }}
                    />
                    <Typography
                        variant="h4"
                        sx={{ marginLeft: "12px", display: "flex", height: "32px", alignItems: "center" }}
                    >
                        {t("Fournisseurs")}
                    </Typography>
                </Box>
                <ul>
                    {suppliersName.map((supplier) => (
                        <li key={`suppliers-product-${supplier}`}>{supplier}</li>
                    ))}
                </ul>
            </Box>
        </Box>
    );
};
