import { WhiteBox } from "@foodpilot/foods";
import { Box, Button, MenuItem, Select, Typography, styled } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useFiscalYears } from "src/api/sure-api/inexweb/useFiscalYears";
import { FiscalYear, RSEFormProps } from "src/types";
import { Loading } from "src/components/utils/Loading";
import { useInitCategories } from "./InexwebForm";
import { useState } from "react";
import _CheckIcon from "src/assets/icons/check.svg?react";
import { HelpPlease } from "./components/HelpPlease";

const CheckIcon = styled(_CheckIcon)(({ theme }) => ({
    path: {
        fill: theme.custom.grey[2800],
    },
}));

type Page2Props = {
    onFiscalYearSelected: () => void;
};

export const Page2 = (props: Page2Props) => {
    const { onFiscalYearSelected } = props;
    const [displayOkConnection, setDisplayOkConnection] = useState(true);
    const handleOnIGetIt = () => setDisplayOkConnection(false);
    const { control, setValue, resetField } = useFormContext<RSEFormProps>();
    const { data: fiscalYears, isLoading } = useFiscalYears();
    const initCategories = useInitCategories();

    const clearAllAccountingData = () => {
        const fieldsToReset: Array<keyof RSEFormProps> = ["period", "previousPeriod"];

        fieldsToReset.forEach((field) => resetField(field));
        setValue("categories", initCategories);
    };

    return (
        <Box sx={{ display: "flex", gap: "1rem", position: "relative" }}>
            <Box sx={{ flex: 2.5 }}>
                {displayOkConnection && (
                    <>
                        <WhiteBox sx={{ p: 6, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <CheckIcon />
                                <Typography variant="big-bold">
                                    La connexion avec Inexweb a bien été établie.
                                </Typography>
                            </Box>
                            <Button onClick={handleOnIGetIt}>J'ai compris</Button>
                        </WhiteBox>
                        <Box sx={{ height: 20 }} />
                    </>
                )}
                <WhiteBox sx={{ p: 6 }}>
                    <Typography variant="big-bold">Exercice comptable</Typography>
                    <Typography sx={{ mt: 2 }} variant="body" component="p">
                        Récupérez automatiquement les données associées à l'exercice comptable sélectionné.
                    </Typography>
                    <Box sx={{ height: 20 }} />
                    {isLoading && <Loading />}
                    {!isLoading && fiscalYears && (
                        <Controller
                            control={control}
                            name="fiscalYearIndex"
                            rules={{ required: true }}
                            render={({ field: { value, onChange, ...field } }) => (
                                <Select
                                    value={value}
                                    onChange={async (v) => {
                                        clearAllAccountingData();
                                        setValue("fiscalYear", fiscalYears[+v.target.value]);
                                        setValue("previousFiscalYear", fiscalYears[+v.target.value + 1]);
                                        onChange(+v.target.value);
                                        onFiscalYearSelected();
                                    }}
                                    sx={{
                                        borderRadius: "24px",
                                        width: "20%",
                                        height: "30px",
                                    }}
                                    {...field}
                                >
                                    {fiscalYears
                                        .filter((year: FiscalYear) => {
                                            const lastYear = year.code.split("-").shift()?.trim() || "0";
                                            return parseInt(lastYear) >= 2020; // cf. legacy code src/pages/Inexweb.tsx
                                        })
                                        .map((y, i) => (
                                            <MenuItem key={i} value={i}>
                                                <Typography variant="body">{y.code}</Typography>
                                            </MenuItem>
                                        ))}
                                </Select>
                            )}
                        />
                    )}
                </WhiteBox>
            </Box>
            <HelpPlease />
        </Box>
    );
};
