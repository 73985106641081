import { Fragment } from "react";
import { Box, Typography, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

type SelectedItemsProps<T> = {
    getId: (item: T) => number | string;
    getName: (item: T) => string;
    list: T[];
    onDelete: (item: T) => void;
};
const SelectedItems = <T,>({ getName, list, onDelete }: SelectedItemsProps<T>) => {
    const { t } = useTranslation();

    if (list.length === 0) {
        return <Typography variant="body">{t("no_selected_items")}</Typography>;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                flexWrap: "wrap",
            }}
        >
            {list.map((item, idx: number) => {
                return (
                    <Fragment key={`${idx}`}>
                        <Chip
                            label={getName(item)}
                            variant="outlined"
                            onDelete={() => onDelete(item)}
                            sx={{ background: "#ffffff" }}
                        />
                    </Fragment>
                );
            })}
        </Box>
    );
};

export default SelectedItems;
