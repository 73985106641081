export const formatDateCampaign = (start: string, end: string, separator: string = "-") => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startDateYear = startDate.getFullYear().toString();
    const endDateYear = endDate.getFullYear().toString();

    let label = startDateYear;

    if (startDateYear !== endDateYear) label = label.concat(` ${separator} `).concat(endDateYear);

    return label;
};
