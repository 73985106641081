import { Box, Button, Typography, useTheme } from "@mui/material";
import Factory from "src/assets/icons/black-factory.svg?react";
import { TextTitleGreyIcon, LineGroupBox, FormBox } from "@foodpilot/foods";
import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TransformationModal } from "./TransformationModal";
import { useTransformationReference } from "src/api/sure-api/useTransformationProcess";
import { ProductFormType } from "../../ProductForm.types";
import DoubleCheck from "src/assets/icons/double-check.svg?react";
import TransformationStep from "src/assets/icons/transformation-step.svg?react";

type Page4Props = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};

export const Page4 = (props: Page4Props) => {
    return (
        <Box sx={{ display: "flex", gap: "1rem" }}>
            <FormBox sx={{ flex: 1 }}>
                <Page4Left {...props} />
            </FormBox>

            <FormBox sx={{ flex: 1 }}>
                <Page4Right />
            </FormBox>
        </Box>
    );
};

const Page4Left = (props: Page4Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { product, setProduct } = props;
    const [isOpen, setOpen] = useState(false);

    const { productTransformations } = product;
    const { transformationReference } = useTransformationReference();

    if (transformationReference.length === 0) {
        return null;
    }

    return (
        <Fragment>
            <TransformationModal
                isOpen={isOpen}
                setOpen={setOpen}
                transformationReference={transformationReference}
                productTransformations={productTransformations}
                onValidate={(values) =>
                    setProduct({
                        ...product,
                        productTransformations: values,
                    })
                }
            />
            <Box sx={{ marginBottom: "10px" }}>
                <TextTitleGreyIcon title={t("Etapes de transformation")} icon={<TransformationStep />} titleSize="h3" />
            </Box>
            <Box sx={{ borderTop: `1px solid ${theme.custom.grey[500]}` }}>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        marginBlock: "18px",
                    }}
                >
                    <Button
                        variant="ternary"
                        startIcon={<DoubleCheck />}
                        onClick={() => setOpen(true)}
                        sx={{ width: "100% " }}
                    >
                        <Typography variant="body-bold">
                            {t("Ajouter ou retirer des étapes de transformation")}
                        </Typography>
                    </Button>
                </Box>
                {productTransformations.map((transformation, idx) => {
                    const isFirst = idx === 0;
                    const isLast = idx === productTransformations.length - 1;
                    const foundTransformation = transformationReference.find(
                        (trans) => trans.id === transformation.productTransformationProcessId,
                    );
                    return (
                        <LineGroupBox
                            key={`${idx}-${transformation.productTransformationProcessId}`}
                            isFirst={isFirst}
                            isLast={isLast}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",

                                    paddingInline: "1rem",
                                    minHeight: "4.5rem",
                                }}
                            >
                                <Typography variant="body">{foundTransformation?.label ?? t("Non trouvé")}</Typography>
                            </div>
                        </LineGroupBox>
                    );
                })}
            </Box>
        </Fragment>
    );
};

const Page4Right = () => {
    const { t } = useTranslation();
    return (
        <div>
            <TextTitleGreyIcon title={t("production_sites")} titleSize="h3" icon={<Factory />} />
            <Typography
                variant="big-bold"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // fontSize: "3rem",
                    marginBlock: "3rem",
                }}
            >
                {t("Coming Soon!")}
            </Typography>
        </div>
    );
};
