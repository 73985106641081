import { CompanySectorWrite } from "src/api/client-api/companySectors/useCreateCompanySector";
import { CompanySector, CompanySectorForm } from "src/api/client-api/companySectors/useSupplierSectors";

export class CompanySectorService {
    static getCompanySectorToCompanySectorForms = (existingCompanySectors?: CompanySector[]): CompanySectorForm[] => {
        return (
            existingCompanySectors?.map((companySector) => {
                return {
                    id: companySector.id,
                    name: companySector.name,
                    ssqId: companySector.ssqId,
                    updatedAt: companySector.updatedAt,
                };
            }) || []
        );
    };

    static getCompanySectorToCompanySectorWrite = (companySector: CompanySectorForm): CompanySectorWrite => {
        const CompanySectorWrite: CompanySectorWrite = {
            id: companySector.id,
            name: companySector.name,
            ssqId: companySector.ssqId,
        };

        return CompanySectorWrite;
    };
}
