import { Link, LinkProps } from "@mui/material";

export const GridLink = (props: LinkProps) => {
    const { children, onClick, sx } = props;

    return (
        <Link
            onClick={onClick}
            sx={{
                textDecoration: "none",
                cursor: "pointer",
                ...sx,
            }}
        >
            {children}
        </Link>
    );
};
