import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Divider } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";
import { NewIngredientFromSupplier, useGetOneSupplier } from "src/api/client-api/suppliers/useGetOneSupplier";

export const Company = () => {
    const { id } = useParams();
    const { t } = useTranslation();

    const { supplier: company, isLoading } = useGetOneSupplier(id ?? "A completly bad ID");

    const ingredients = company?.ingredientSuppliers?.map((ingredient) => ingredient.ingredient);

    const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
        page: 0,
        pageSize: 10,
    });

    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [rowsIngredients, setRowsIngredients] = useState<GridRowsProp>([]);

    type CompanyUserRows = {
        id: number;
        firstname: string;
        lastname: string;
        email: string;
    };

    type CompanyIngredientRows = {
        id: number;
        ingredient: string;
    };

    useEffect(() => {
        if (!company || !ingredients) return;

        const rows: CompanyUserRows[] = [];

        company?.users?.forEach((user) => {
            rows.push({
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
            });
        });
        setRows(rows);

        const rowsIngredients: CompanyIngredientRows[] = [];
        ingredients.forEach((ingredient: NewIngredientFromSupplier) => {
            rowsIngredients.push({
                id: ingredient.id,
                ingredient: ingredient.name,
            });
        });

        setRowsIngredients(rowsIngredients);
    }, [company, ingredients]);

    const handlePaginationModelChange = (paginationModel: { page: number; pageSize: number }) => {
        setPaginationModel(paginationModel);
    };

    const columns: GridColDef[] = [
        {
            field: "firstname",
            headerName: t("First Name"),
            flex: 2,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h4"
                        sx={{
                            whiteSpace: "break-spaces",
                            overflow: "hidden",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            lineClamp: "2",
                            "-webkit-box-orient": "vertical",
                        }}
                    >
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: "lastname",
            headerName: t("Last Name"),
            flex: 1,
        },
        { field: "email", headerName: t("Email"), flex: 1 },
    ];

    const columnsIngredients: GridColDef[] = [
        {
            field: "ingredient",
            headerName: "Ingrédient",
            flex: 1,
        },
    ];

    if (!company) return;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
                backgroundColor: "#EAEAEA",
            }}
        >
            <h1>{company.name}</h1>

            <h2>Ingrédients</h2>
            <DataGrid
                autoHeight
                rowHeight={40}
                rows={rowsIngredients}
                columns={columnsIngredients}
                pagination
                loading={isLoading}
                onPaginationModelChange={handlePaginationModelChange}
                rowCount={paginationModel.pageSize}
                paginationMode="server"
                paginationModel={paginationModel}
                disableRowSelectionOnClick
            />

            <Divider sx={{ mb: 2 }} />

            <h2>{t("Utilisateurs enregistrés")}</h2>
            <DataGrid
                autoHeight
                rowHeight={40}
                rows={rows}
                columns={columns}
                pagination
                loading={isLoading}
                onPaginationModelChange={handlePaginationModelChange}
                rowCount={paginationModel.pageSize}
                paginationMode="server"
                paginationModel={paginationModel}
                disableRowSelectionOnClick
            />
        </Container>
    );
};
