import { useDrop, useDrag } from "react-dnd";
import type { Identifier } from "dnd-core";
import { RefObject, useEffect, useRef } from "react";

type DragItem = {
    index: number;
};

export type DndOptions = {
    currentIndex: number;
    // ref: RefObject<HTMLDivElement>;
    onHoverMove: (dragIndex: number, hoverIndex: number) => void;
    onHoverDrop?: () => void;
};

export const useDragAndDrop = (options: DndOptions) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: "DraggableColumn",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(dragItem: DragItem, monitor) {
            // const ref = options.ref.current;
            const _ref = ref.current;
            if (!_ref) return;

            const dragIndex = dragItem.index;
            const hoverIndex = options.currentIndex;
            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = _ref.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            if (clientOffset === null) return;

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY >= hoverMiddleY) return;

            options.onHoverMove(dragIndex, hoverIndex);
            dragItem.index = hoverIndex;
        },
    });

    const [_, drag] = useDrag({
        type: "DraggableColumn",
        item: () => {
            return { index: options.currentIndex };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (_draggedItem) => {
            options.onHoverDrop?.();
        },
    });

    useEffect(() => {
        drag(drop(ref));
    }, [ref]);

    drag(drop(ref));

    return { handlerId, ref };
};
