import { Stack, useTheme, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WhiteBox } from "@foodpilot/foods";
import { useOAuth2Dance } from "src/utils/useOAuth2Dance";

export const OAuthLogin = () => {
    const { providerName } = useParams<{ providerName: string }>();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isError, setError] = useState(false);
    const [oauthError, setOauthError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { getAuth } = useOAuth2Dance();

    useEffect(() => {
        if (providerName) {
            getAuth(providerName);
        } else {
            setError(true);
            setOauthError("Provider name is missing.");
        }
    }, [providerName]);

    return (
        <WhiteBox padding="40px" paddingTop="32px" boxShadow="0 2px 8px 0 rgba(42, 43, 39, 0.04)" position="relative">
            <Stack>
                <LoginHeader />
                <Button onClick={() => getAuth(providerName ?? "")}>Login with {providerName}</Button>
            </Stack>
        </WhiteBox>
    );
};

const LoginHeader = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack justifyContent="center" alignItems="center" gap="16px">
            <Typography variant="h2" align="center">
                {t("login-title")}
            </Typography>
            <Typography variant="body" align="center" sx={{ color: theme.custom.grey[2000] }}>
                {t("login-info")}
            </Typography>
        </Stack>
    );
};
