import z from "zod";
import { useQuery } from "react-query";
import { sureApiCall } from "../axiosClient/sureApi";
import { zodParseWithLog } from "../apiErrorLogs";

export const useTransformationReference = () => {
    const query = useQuery({
        cacheTime: 0,
        staleTime: 0,
        queryKey: "allTransformationsProcesses",
        queryFn: async () => {
            return await sureApiCall.get("/api/transformation_processes");
        },
        select: (request) => {
            const transformations = request.data["hydra:member"];
            const data = zodParseWithLog(
                transformations,
                TransformationReferenceSchema.array(),
                "allTransformationRef",
            );
            return data;
        },
    });

    return {
        transformationReference: query.data ?? [],
        ...query,
    };
};

export type TransformationReference = {
    id: number;
    label: string;
};

const TransformationReferenceSchema: z.ZodSchema<TransformationReference> = z.object({
    id: z.number(),
    label: z.string(),
});
