import { OptionsPopoverOption, ScoreCard, StepProps, TrajectoryCards, TrajectoryCardsProps } from "@foodpilot/foods";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { doesScoreExist, ScoreOrEmpty } from "src/api/client-api/post/scoreSchema";
import { useActionsContext } from "src/context/Actions/useActionsContext";

const makeScore = (
    score: ScoreOrEmpty | undefined,
    campaign: string,
    precision: number | undefined,
): StepProps | undefined => {
    if (score === undefined) {
        return undefined;
    }

    if (doesScoreExist(score)) {
        const roundedScore = Number(score.score.toFixed(precision));
        return {
            year: campaign,
            value: roundedScore,
            unit: score.unit,
            incomplete: false,
        };
    }

    return {
        year: campaign,
        value: null,
        unit: "",
        incomplete: false,
    };
};

export type ScoreOptions = {
    id: number;
    label: string;
    group: string;
    scoreId: number;
    onClick: () => void;
};

export type ScoreComparisonCardProps = {
    selectedOption: ScoreOptions;
    allOptions: ScoreOptions[];

    position: TrajectoryCardsProps["position"];
    disableFooter?: boolean;
    precision?: number;
};
export const ScoreComparisonCard = (props: ScoreComparisonCardProps) => {
    const { position, disableFooter = false, allOptions, selectedOption, precision } = props;

    const { start, current, target } = useActionsContext();
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

    const data = allOptions.map((score) => {
        return {
            ...score,
            onClick: (option: OptionsPopoverOption) => {
                score.onClick();
                setSelectedItemId(Number(option.id));
            },
        };
    });

    useEffect(() => {
        const previouslySelectedVariation = allOptions.find((score) => score.id === selectedOption.id);
        // const previouslySelectedVariation = dd.selectedVariation[scoreId];
        if (previouslySelectedVariation) {
            return setSelectedItemId(previouslySelectedVariation.id);
        }
        if (data.length > 0) {
            return setSelectedItemId(data[0].id);
        }
    }, []);

    const _startScore = start.scores?.[selectedOption.scoreId];
    const startScore = makeScore(_startScore, start.campaign, precision);

    const _currentScore = current?.scores?.[selectedOption.scoreId];
    const currentScore = makeScore(_currentScore, current?.campaign ?? "", precision);

    const _targetScore = target.scores?.[selectedOption.scoreId];
    const targetScore = makeScore(_targetScore, target.campaign, precision);

    if (startScore === undefined || targetScore === undefined) {
        // Not supposed to be empty.
        return;
    }

    return (
        <Box sx={{ width: "100%" }}>
            <ScoreCard
                header={{
                    selectedItemId: selectedItemId,
                    options: data,
                }}
                disableFooter={disableFooter}
            >
                <TrajectoryCards
                    steps={{
                        start: startScore,
                        current: currentScore ?? null,
                        target: targetScore,
                    }}
                    position={position}
                    improvement={"increase"}
                />
            </ScoreCard>
        </Box>
    );
};
