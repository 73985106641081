import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { z } from "zod";

export const csrThemeQueryKey = "getAllCsrTheme" as const;

type Options = {
    page?: number;
    itemsPerPage?: number;
};
export const useGetAllCsrTheme = (options?: Options) => {
    const { page = 1, itemsPerPage = 20 } = options || {};

    const query = useQuery({
        queryKey: [csrThemeQueryKey, page, itemsPerPage],
        staleTime: 0,
        queryFn: async () => {
            const pagination = `itemsPerPage=${itemsPerPage}&page=${page}`;

            const url = `/api/csr_themes?${pagination}`;
            const req = loggedInApiCall.get(url);
            return req;
        },
        select: (request) => {
            const totalItems = request.data["hydra:totalItems"];
            const data = zodParseWithLog(request.data["hydra:member"], CsrThemeListSchema.array(), csrThemeQueryKey);
            return {
                items: data,
                totalItems: totalItems,
            };
        },
    });

    return {
        csrThemes: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};

type CsrThemeParent = {
    "@id": IRI;
    title: string;
};

type CsrThemeChildren = {
    "@id": IRI;
    title: string;
};

export type CsrThemeList = {
    "@id": IRI;
    id: number;
    code: string;
    title: string;
    parent?: CsrThemeParent;
    children?: CsrThemeChildren[];
};

export const CsrThemeListSchema: z.ZodSchema<CsrThemeList> = z.object({
    "@id": z.string(),
    id: z.number(),
    code: z.string(),
    title: z.string(),
    parent: z
        .object({
            "@id": z.string(),
            title: z.string(),
        })
        .optional(),
    children: z
        .object({
            "@id": z.string(),
            title: z.string(),
        })
        .array()
        .optional(),
});
