import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { IngredientReferenceSchema } from "./useGetAllIngredientReference";
import { zodParseWithLog } from "src/api/apiErrorLogs";

export const useGetSomeIngredientReference = (ingredientReferenceIds: number[] | undefined) => {
    const itemsPerPage = ingredientReferenceIds?.length;
    const query = useQuery({
        retry: 0,
        enabled: ingredientReferenceIds !== undefined,
        queryKey: ["getSomeIngredientReferences", ingredientReferenceIds, itemsPerPage],
        queryFn: async () => {
            const response = await sureApiCall.get(`/api/ingredient_references`, {
                params: {
                    id: ingredientReferenceIds,
                    page: 1,
                    itemsPerPage,
                },
            });
            return response;
        },
        select: (request) => {
            const ingredientReference = request.data["hydra:member"];
            const data = zodParseWithLog(ingredientReference, IngredientReferenceSchema.array(), "someIngredientRef");
            return data;
        },
    });

    return {
        ingredientReferences: query.data,
        ...query,
    };
};
