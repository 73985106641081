import { useEffect, useState } from "react";

export type ScoreId = number;
export type VariationId = number;
export type VariationMapping = Record<ScoreId, VariationId>;

export type LastScoreSelection = {
    scoreId: number;
    scoreVariationId: number | null;
};

export type InitVariations = {
    defaultScoreId?: LastScoreSelection;
    variations?: VariationMapping;
};

type SelectVariationCallback = (scoreId: number, newVariationId: number | null) => void;

export type VariationQuery = {
    variations: VariationMapping;
};
export type VariationState = {
    selectVariation: SelectVariationCallback;
    selectedVariations: VariationMapping;
    lastSelectedVariation: LastScoreSelection | null;
};
export type VariationHook = {
    query: VariationQuery;
    state: VariationState;
};

export const useVariations = (initVariations?: InitVariations): VariationHook => {
    const { defaultScoreId = null, variations = {} } = initVariations ?? {};

    const [lastSelectedVariation, _setLastSelectedVariation] = useState<LastScoreSelection | null>(defaultScoreId);
    const [variationState, _setVariationState] = useState<VariationMapping>(variations);
    const setVariationState = (scoreId: number, variationId: number | null) => {
        const newVariationState = { ...variationState };

        if (variationId === null) {
            // Since I'm not asking for a Variation of my score,
            // the backend asks that don't pass the argument,
            delete newVariationState[scoreId];
        } else {
            newVariationState[scoreId] = variationId;
        }

        _setVariationState(newVariationState);
    };

    const selectVariation = (scoreId: number, variationId: number | null) => {
        _setLastSelectedVariation({
            scoreId: scoreId,
            scoreVariationId: variationId,
        });
    };

    useEffect(() => {
        // Alters the global variation state, once you select a new variation;
        if (lastSelectedVariation) {
            setVariationState(lastSelectedVariation.scoreId, lastSelectedVariation.scoreVariationId);
        }
    }, [lastSelectedVariation]);

    return {
        query: {
            variations: variationState,
        },
        state: {
            selectedVariations: variationState,
            selectVariation,
            lastSelectedVariation,
        },
    };
};
