import { FoodsIcon } from "@foodpilot/foods";
import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { CompanySelector } from "src/components/Selector";

export const Header = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
            }}
        >
            <FoodsIcon icon="diagnostic" size={8} />
            <Typography variant="h1">{`${t("page.diagnostics.title")}`}</Typography>
            <Box
                sx={{
                    borderLeft: `1px solid ${theme.custom.grey[500]}`,
                    width: "1px",
                    height: "36px",
                }}
            />
            <CompanySelector />
        </Box>
    );
};
