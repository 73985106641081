import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { ProductService } from "src/pages/Product/ProductService";

type ProductDupOptions = {
    productId: number;
    dupSuffix?: string;
};
export function useProductDuplicate() {
    const query = useMutation({
        mutationFn: async (options: ProductDupOptions) => {
            const { productId, dupSuffix = "Copy" } = options;
            const req = await loggedInApiCall.get(`/api/products/${productId}`);

            const newProduct = ProductService.getProductToCreate(req.data);
            const productToDuplicate = ProductService.productFormToProductWrite(newProduct);

            delete productToDuplicate["id"];
            productToDuplicate.title = `${newProduct.title} - ${dupSuffix}`;

            const response = await loggedInApiCall.post("/api/products", productToDuplicate);
            return response;
        },
    });

    return query;
}
