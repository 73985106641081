import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FoodsCheckbox, PrimaryDialog } from "@foodpilot/foods";
import { TransformationReference } from "src/api/sure-api/useTransformationProcess";
import { ProductFormProductTransformations } from "../../ProductForm.types";

type TransformationModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    transformationReference: TransformationReference[];
    productTransformations: ProductFormProductTransformations[];
    onValidate: (selectedProductTransformations: ProductFormProductTransformations[]) => void;
};
export const TransformationModal = (props: TransformationModalProps): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { isOpen, setOpen, transformationReference, productTransformations } = props;

    const currentSelectedIds = productTransformations.map((t) => t.productTransformationProcessId);
    const originalSelectedItems = transformationReference.filter((allT) => currentSelectedIds.includes(allT.id));
    const [selectedItems, setSelectedItems] = useState(originalSelectedItems);

    useEffect(() => {
        setSelectedItems(originalSelectedItems);
    }, [productTransformations]);

    const title = <Typography variant="big-bold">{t("Sélectionner vos processus de transformations")}</Typography>;
    const content = (
        <div style={{ padding: "1.5rem", backgroundColor: theme.custom.grey[200] }}>
            <FoodsCheckbox
                selectedItems={selectedItems}
                values={transformationReference}
                onChange={(selectedValues) => setSelectedItems(selectedValues)}
                getName={(item) => item.label}
            />
        </div>
    );

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setOpen}
            fullWidth
            maxWidth={"sm"}
            options={{
                title: title,
                content: content,
            }}
            onValidate={() => {
                const newTransformations: ProductFormProductTransformations[] = selectedItems.map((item) => {
                    const foundOriginal = productTransformations.find(
                        (origin) => origin.productTransformationProcessId === item.id,
                    );
                    if (foundOriginal) {
                        return {
                            "@id": foundOriginal["@id"],
                            productTransformationProcessId: foundOriginal.productTransformationProcessId,
                            weight: foundOriginal.weight,
                        };
                    }

                    return {
                        productTransformationProcessId: item.id,
                        weight: null,
                    };
                });
                props.onValidate(newTransformations);
            }}
            onCancel={() => {
                setSelectedItems(originalSelectedItems);
            }}
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};
