import { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridActionsCellItem } from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import { Loading } from "src/components/utils/Loading";
import { useSnackbar } from "src/utils/useSnackbar";
import { useGetAllCsrTheme } from "src/api/client-api/csrTheme/useGetAllCsrTheme";
import { useCsrThemeDelete } from "src/api/client-api/csrTheme/useDeleteCsrTheme";
import { GridCategoryBox } from "src/components/Grid/GridCategoryBox";
import { URLHandler } from "src/services/URLHandler";

export const CsrTheme = () => {
    const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
        page: 0,
        pageSize: 10,
    });

    const { csrThemes, totalItems, isLoading } = useGetAllCsrTheme({
        itemsPerPage: paginationModel.pageSize,
        // API Plateforme pagination begin at 1, MUI Grid pagination at 0
        page: paginationModel.page + 1,
    });

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const deleteCsr = useCsrThemeDelete();

    const [displayDialog, setDisplayDialog] = useState(false);
    const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

    const handlePaginationModelChange = (paginationModel: { page: number; pageSize: number }) => {
        setPaginationModel(paginationModel);
    };

    const handleDeleteClick = (id: number | string) => () => {
        if (typeof id === "string") return;

        const foundElem = csrThemes?.find((item) => item.id === id);
        if (foundElem !== undefined) {
            if (foundElem.children !== undefined && foundElem.children.length > 0) {
                snackbar.forceClose();
                snackbar.setSnackbarProps({
                    type: "info",
                    message: "Cannot delete element with children",
                });
                return;
            }
        }
        setIdToDelete(id);
        setDisplayDialog(true);
    };

    const columns: GridColDef[] = [
        {
            field: "title",
            headerName: "Titre",
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
        },
        {
            field: "code",
            headerName: "Code",
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
        },
        {
            field: "parent",
            headerName: "Parent",
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
        },
        {
            field: "children",
            headerName: "Enfant",
            flex: 2,
            renderCell: (params) => <GridCategoryBox value={params.value} />,
            editable: false,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 200,
            cellClassName: "actions",
            getActions: ({ id }) => {
                console.log("The id :", id);
                return [
                    <GridActionsCellItem
                        icon={
                            <IconButton component={RouterLink} to={URLHandler.settings.csrTheme.edit(Number(id))}>
                                <EditIcon />
                            </IconButton>
                        }
                        label="Edit"
                        className="textPrimary"
                        color="inherit"
                        key={id}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id.valueOf())}
                        color="inherit"
                        key={id}
                    />,
                ];
            },
        },
    ];

    const EditToolbar = () => {
        const handleClick = () => {
            navigate(URLHandler.settings.csrTheme.create());
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {"Ajouter un CsrTheme"}
                </Button>
            </GridToolbarContainer>
        );
    };

    if (csrThemes === undefined) return <Loading />;

    const rows = csrThemes.map((csrTheme) => {
        return {
            id: csrTheme.id,
            title: csrTheme.title,
            code: csrTheme.code,
            parent: csrTheme.parent?.title,
            children: csrTheme.children?.map((item) => item.title),
        };
    });

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {"CsrTheme"}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>Êtes-vous sûr ?</DialogTitle>
                <DialogContent dividers>
                    {t("Cliquez sur 'oui' pour supprimer définitivement l'entreprise.")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={() => {
                            if (idToDelete) {
                                deleteCsr.mutate(idToDelete, {
                                    onError: () => {
                                        snackbar.setSnackbarProps({
                                            message: "Unknown error...",
                                            type: "error",
                                        });
                                    },
                                });
                            }
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={rows}
                columns={columns}
                loading={isLoading}
                editMode="row"
                isRowSelectable={() => false}
                onPaginationModelChange={handlePaginationModelChange}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={totalItems}
                slots={{
                    toolbar: EditToolbar,
                }}
                sx={getGridStyle(rows.length ?? 0)}
            />
        </Container>
    );
};

const getGridStyle = (length: number) => {
    return {
        border: "none",
        backgroundColor: "transparent",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            marginBottom: "2px",
            border: 0,
            backgroundColor: "transparent",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#797A77",
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        "& .MuiDataGrid-row.Mui-selected": {
            background: " #FFFFFF",
            border: 0,
        },
        "& .MuiDataGrid-cell": {
            border: 0,
            paddingLeft: "20px",
            outline: "none !important",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: length * 3,
            height: `fit-content`,
            boxSizing: "content-box",
        },
    };
};
