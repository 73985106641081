import { FoodsSelect } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

export const CampaignSelector = () => {
    const { t } = useTranslation();

    const { campaigns, currentCampaign, setCurrentCampaign } = useFoodpilotContext();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    whiteSpace: "nowrap",
                }}
            >
                {t("campaignSelect.label")} :
            </Typography>
            <FoodsSelect
                selectedOption={currentCampaign}
                options={campaigns}
                onChange={(selectedCampaign) => setCurrentCampaign(selectedCampaign)}
                getId={(campaign) => campaign.id}
                getName={(campaign) => campaign.label}
                closeOnSelect
                disableSearch
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
                BoxProps={{
                    width: "130px",
                }}
            />
        </Box>
    );
};
