import { ActionPropertiesWithAnswers } from "src/context/Actions/ActionService";
import { Text } from "./Properties/Text";
import { Checkbox } from "./Properties/Checkbox";
import { Radio } from "./Properties/Radio";
import { Select } from "./Properties/Select";

export type PropertySwitchProps = {
    propertyWithElements: ActionPropertiesWithAnswers | undefined;
};
export const PropertySwitch = (props: PropertySwitchProps): JSX.Element | null => {
    const { propertyWithElements } = props;

    if (propertyWithElements === undefined) return null;

    const type = propertyWithElements.property.field?.type;

    if (type === "text") {
        return <Text propertyWithElements={propertyWithElements} />;
    }

    if (type === "checkbox") {
        return <Checkbox propertyWithElements={propertyWithElements} />;
    }

    if (type === "radio") {
        return <Radio propertyWithElements={propertyWithElements} />;
    }

    if (type === "select") {
        return <Select propertyWithElements={propertyWithElements} />;
    }

    return null;
};
