import { TableRow, TableCell, Typography } from "@mui/material";

type GrandTotalsProps = {
    previousGrandTotal: number;
    grandTotal: number;
};

export const GrandTotals = (props: GrandTotalsProps) => {
    const { previousGrandTotal, grandTotal } = props;

    if (previousGrandTotal === 0 && grandTotal === 0) return null;

    return (
        <TableRow>
            <TableCell sx={{ border: "none" }}>
                <Typography variant="big-bold">Total</Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
                <Typography>
                    {previousGrandTotal.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        currencyDisplay: "symbol",
                    })}
                </Typography>
            </TableCell>
            <TableCell sx={{ border: "none" }}>
                <Typography>
                    {grandTotal.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        currencyDisplay: "symbol",
                    })}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
