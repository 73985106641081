import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FoodsToggleButton, SearchBox } from "@foodpilot/foods";

type GridPageHeaderProps = {
    mainBoardName: string;
    listItemName: string;
    title: string;
    filter?: string;
    setFilter?: (newFilter: string) => void;
};
export const GridPageHeader = (props: GridPageHeaderProps) => {
    const { t } = useTranslation();
    const { mainBoardName, listItemName, filter = null, setFilter, title } = props;

    const buttons = [
        { id: 1, label: mainBoardName, disabled: true },
        { id: 2, label: listItemName },
    ];

    return (
        <Stack direction="row" useFlexGap alignItems="center" justifyContent="space-between">
            <Stack direction="row" useFlexGap alignItems="center">
                <Typography variant="h1" sx={{ marginRight: "8px" }}>
                    {title}
                </Typography>
                <FoodsToggleButton buttons={buttons} initialSelected={buttons[1]} />
            </Stack>
            {setFilter && (
                <SearchBox
                    variant="standalone"
                    value={filter}
                    placeholder={t("Rechercher un élément")}
                    onChange={setFilter}
                />
            )}
        </Stack>
    );
};
