import { Avatar, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import DeleteIcon from "src/assets/icons/delete-icon.svg?react";
import { IRI } from "src/types";

export type UserInterface = {
    "@id": IRI;
    firstname: string;
    lastname: string;
};

type DisplayUsersProps = {
    users: UserInterface[];
    onDelete?: (userId: string) => void;
};
export const DisplayUsers = (props: DisplayUsersProps) => {
    const { users, onDelete } = props;
    const { t } = useTranslation();

    if (users.length === 0) {
        return <Typography variant="body">{t("Aucun utilisateur ajouté")}</Typography>;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                flexWrap: "wrap",
            }}
        >
            {users.map((user, idx) => {
                return (
                    <Fragment key={`${idx}-contributors-supplier`}>
                        <UserSmallCard user={user} onDelete={onDelete} />
                    </Fragment>
                );
            })}
        </Box>
    );
};

type UserSmallCardProps = {
    user: UserInterface;
    onDelete?: (userId: string) => void;
};
const UserSmallCard = (props: UserSmallCardProps) => {
    const { user, onDelete } = props;
    return (
        <Box
            sx={{
                display: "flex",
                gap: "5px",
                padding: "8px",
                border: `1px solid #E7E6E4`,
                borderRadius: "8px",
                width: "fit-content",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Avatar alt={`${user.firstname} ${user.lastname}`} variant={"small"} />
            <Typography variant="body-bold">{user.firstname}</Typography>
            <Typography variant="body-bold">{user.lastname}</Typography>
            {onDelete && <DeleteIcon onClick={() => onDelete(user["@id"])} />}
        </Box>
    );
};
