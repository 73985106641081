import { FoodsToggleButton, FoodsToggleButtonType } from "@foodpilot/foods";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Score } from "src/types";
import { UnitSwitcher } from "src/components/Score/UnitSwitcher.tsx";

export type ScatterHeaderProps = {
    score: Score;
    buttonChoices: FoodsToggleButtonType[];
    selectedVisualisationChoice: number;
    setVisualisationChoices: (index: number) => void;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
};

export const ScatterHeader = (props: ScatterHeaderProps) => {
    const { t } = useTranslation();
    const { score, buttonChoices, selectedVisualisationChoice, variations = {}, setVariations = () => {} } = props;

    return (
        <Box sx={{ borderBottom: "1px solid #E7E6E4" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding="2rem">
                <Stack direction="row" alignItems="center" gap="20px">
                    <Typography variant="h3">{score.title}</Typography>
                    <UnitSwitcher
                        score={score}
                        value={undefined}
                        displayedVariations={variations}
                        setDisplayedVariations={setVariations}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" gap="1rem">
                    <Typography variant="h5">{t("view_according_to")}</Typography>
                    <FoodsToggleButton
                        buttons={buttonChoices}
                        initialSelected={buttonChoices[selectedVisualisationChoice]}
                        buttonGroup={true}
                    />
                </Stack>
            </Stack>
        </Box>
    );
};
