import z from "zod";
import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";

type Options = {
    enabled?: boolean;
};

export const useGetOneIngredientLine = (ingredientLinesId: string, options?: Options) => {
    const { enabled = true } = options || {};
    const query = useQuery({
        queryKey: ["getIngredient_lines", ingredientLinesId],
        cacheTime: 0,
        enabled: enabled,
        queryFn: async () => {
            return await loggedInApiCall.get<IngredientLine>(`/api/ingredient_lines/${ingredientLinesId}`);
        },
        select: (request) => {
            const ingredientLine = request.data;
            return zodParseWithLog(ingredientLine, IngredientLineZodSchema, "ingredientLine");
        },
    });

    return {
        ingredientLine: query.data,
        ...query,
    };
};

// INGREDIENTS
type IngredientLine = {
    "@id": string;
    id: number;
    name: string;
};
const IngredientLineZodSchema: z.ZodSchema<IngredientLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});
