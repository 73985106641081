import { ActivitiesFormData } from "./Activities.type";
import { SectionGrid } from "src/components/SectionGrid/SectionGrid";
import { EmptyRowsFallback } from "src/components/Grid/EmptyRowsFallback";
import { useTranslation } from "react-i18next";
import { useActivitiesColumns } from "./useActivitiesColumns";

export type SuppliersListProps = {
    activities: ActivitiesFormData;
    toggleEditMode: boolean;
    onActivitiesUpdate: (value: ActivitiesFormData) => void;
};

export const SuppliersList = (props: SuppliersListProps) => {
    const { t } = useTranslation();
    const { activities } = props;
    const columns = useActivitiesColumns(props.toggleEditMode, props.activities, props.onActivitiesUpdate);

    return (
        <SectionGrid
            mode="table"
            columns={columns}
            data={activities.suppliers}
            enablePagination={false}
            renderEmptyRowsFallback={() => <EmptyRowsFallback message={t("Il n'y a aucun thématique à afficher")} />}
        />
    );
};
