import { BlockOptions, SelectBlock } from "@foodpilot/foods";
import { useState } from "react";
import { DataListOption } from "src/api/sure-api/actions/useGetAllActions";
import { ActionPropertiesWithAnswers } from "src/context/Actions/ActionService";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type SelectProps = {
    propertyWithElements: ActionPropertiesWithAnswers;
};
export const Select = (props: SelectProps) => {
    const { currentCampaign, targetCampaign } = useFoodpilotContext();
    const { propertyWithElements } = props;

    const options = propertyWithElements.property.dataList?.options ?? [];
    options.sort((optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position);

    const _previousValue = propertyWithElements.previousElement?.value;
    const previousValue = options.find((option: DataListOption) => option.value === _previousValue);

    const _initialValue = propertyWithElements.currentElement?.value;
    const initialValue = options.find((option: DataListOption) => option.value === _initialValue) ?? null;

    const [value, setValue] = useState<DataListOption | null>(initialValue);

    const previousComparisonValue = {
        year: `Value in ${currentCampaign?.label}`,
        value: previousValue?.label ?? "",
    };

    const blockOptions: BlockOptions = {
        rightSubtitle: `Objectif ${targetCampaign?.label}`,
        title: propertyWithElements.property.title ?? "",
        comparisonValues: {
            start: previousComparisonValue,
            current: undefined,
        },
    };

    return (
        <SelectBlock
            selectedOption={value}
            options={options}
            blockOptions={blockOptions}
            onChange={(newValue) => {
                setValue(newValue);

                //  Make the request to /calculate
                //  UpdatePropertyValue(newValue);
            }}
            getId={(option) => option.value}
            getName={(option) => option.label}
            textOptions={{
                placeholder: "",
                searchPlaceholder: "",
                emptyOptionsText: "",
                defaultValueText: undefined,
                noResult: "",
            }}
        />
    );
};
