import { Box, useTheme } from "@mui/material";
import { ProductListContextProvider, useProductListContext } from "./ProductListContext";
import { ProductGrid } from "./ProductGrid/ProductGrid";
import { useTranslation } from "react-i18next";
import { GridPageHeader } from "src/components/Grid/PageGridHeader";
import { GridHeader } from "./ProductGrid/GridHeader";

const _ProductList = (): JSX.Element => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { searchProduct, setSearchProduct } = useProductListContext();

    return (
        <Box sx={{ padding: "40px 52px" }}>
            <GridPageHeader
                title={t("Produit")}
                mainBoardName={t("Tableau de bord")}
                listItemName={t("Liste des produits")}
                filter={searchProduct}
                setFilter={setSearchProduct}
            />
            <Box
                component="div"
                sx={{
                    borderRadius: "16px",
                    border: `1px solid ${theme.custom.grey[500]}`,
                    background: "#FFF",
                    paddingTop: "32px",
                    marginTop: "40px",
                }}
            >
                <GridHeader />
                <ProductGrid />
            </Box>
        </Box>
    );
};

const ProductList = (): JSX.Element => {
    return (
        <ProductListContextProvider>
            <_ProductList />
        </ProductListContextProvider>
    );
};
export default ProductList;
