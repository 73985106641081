import { ProductLine, ProductLineForm } from "../../../api/client-api/productLines/useProductLines";
import { ProductLineWrite } from "../../../api/client-api/productLines/useCreateProductLine";

export class ProductLineService {
    static getProductLinesToProductLineForms = (existingProductLines?: ProductLine[]): ProductLineForm[] => {
        return (
            existingProductLines?.map((productLine) => {
                return { id: productLine.id, name: productLine.name, products: productLine.products };
            }) || []
        );
    };

    static getProductLineToProductLineWrite = (productLine: ProductLineForm): ProductLineWrite => {
        const productLineWrite: ProductLineWrite = {
            id: productLine.id,
            name: productLine.name,
        };

        return productLineWrite;
    };
}
