import z from "zod";
import { useQuery } from "react-query";

import { sureApiCall } from "../axiosClient/sureApi";
import { zodParseWithLog } from "../apiErrorLogs";

export const useDistributionReference = () => {
    const query = useQuery({
        cacheTime: 0,
        staleTime: 0,
        queryKey: "allDistributions",
        queryFn: async () => {
            return await sureApiCall.get("/api/distribution_channels");
        },
        select: (request) => {
            const distributions = request.data["hydra:member"];
            const data = zodParseWithLog(distributions, DistributionReferenceSchema.array(), "allDistributionRef");
            return data;
        },
    });

    return {
        distributionReference: query.data,
        ...query,
    };
};

export type DistributionReference = {
    id: number;
    label: string;
};

const DistributionReferenceSchema: z.ZodSchema<DistributionReference> = z.object({
    id: z.number(),
    label: z.string(),
});
