import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IndicatorBlock, IndicatorFormFields } from "./IndicatorFormFields";
import { CMSContentBlock } from "src/api/client-api/cms/useGetOneContent";
import { BlockWrite, useCreateOneBlock } from "src/api/client-api/cms/blocks/useCreateOneBlock";

const blockToIndicatorBlock = (block: CMSContentBlock | null, totalExistingElements: number): IndicatorBlock => {
    if (block === null) {
        return {
            id: undefined,
            title: "",
            subtitle: "",
            description: "",
            position: totalExistingElements,
        };
    }

    return {
        id: block.id,
        title: block.title,
        subtitle: block.subtitle ?? "",
        description: block.description ?? "",
        position: block.position,
    };
};

const indicatorBlockToWrite = (sectionIRI: IRI, indicatorBlock: IndicatorBlock): BlockWrite => {
    const blockWrite: BlockWrite = {
        id: indicatorBlock.id,
        title: indicatorBlock.title,
        subtitle: indicatorBlock.subtitle,
        description: indicatorBlock.description,
        position: indicatorBlock.position,
        section: sectionIRI,
        ctaLink: "",
        ctaLabel: "",
    };
    return blockWrite;
};

type AddIndicatorsModalProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    currentSection: {
        "@id": IRI;
        totalExistingElements: number;
    };
    selectedExistingBlock: CMSContentBlock | null;
};
export const AddIndicatorsModal = (props: AddIndicatorsModalProps) => {
    const { isOpen, setIsOpen, selectedExistingBlock, currentSection } = props;
    const { t } = useTranslation();

    const createBlock = useCreateOneBlock();
    const articleBlock = blockToIndicatorBlock(selectedExistingBlock, currentSection.totalExistingElements);
    const [indicatorBlockForm, setIndicatorBlockForm] = useState<IndicatorBlock>(articleBlock);

    useEffect(() => {
        const articleBlock = blockToIndicatorBlock(selectedExistingBlock, currentSection.totalExistingElements);
        setIndicatorBlockForm(articleBlock);
    }, [selectedExistingBlock]);

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setIsOpen}
            options={{
                title: <ModalTitle />,
                content: (
                    <IndicatorFormFields
                        indicatorBlockForm={indicatorBlockForm}
                        setIndicatorBlockForm={setIndicatorBlockForm}
                    />
                ),
            }}
            onValidate={() => {
                if (indicatorBlockForm === null) {
                    // Invalid data. Snackbar ?
                    return;
                }
                const newBlock: BlockWrite = indicatorBlockToWrite(currentSection["@id"], indicatorBlockForm);
                createBlock.mutate({ newBlock: newBlock });
            }}
            fullWidth
            maxWidth="xs"
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};

function ModalTitle() {
    const { t } = useTranslation();
    return <Typography variant="big-bold">{t("cms.indicators.addModal.title")}</Typography>;
}
