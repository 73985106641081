import { Fragment, useState } from "react";
import { ProductFormType } from "../../ProductForm.types";
import { NoIngredients } from "./NoIngredients";
import { IngredientsFormGrid } from "./IngredientsFormGrid/IngredientsFormGrid";
import { IngredientsModal } from "./IngredientModal/IngredientsModal";

type Page2Props = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};

export const Page2 = (props: Page2Props) => {
    const { product, setProduct } = props;
    const [isOpen, setOpen] = useState(false);
    const openModal = () => setOpen(true);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
            }}
        >
            <IngredientsModal
                productIngredients={product.productIngredients}
                isOpen={isOpen}
                setOpen={setOpen}
                onValidate={(newIngredients) => {
                    setProduct({
                        ...product,
                        productIngredients: newIngredients,
                    });
                }}
            />
            {product.productIngredients.length > 0 ?
                <Fragment>
                    <IngredientsFormGrid
                        ingredients={product.productIngredients}
                        productWeight={product.netWeight ?? 0}
                        openModal={openModal}
                        setProductIngredient={(newProductIngredients) => {
                            setProduct({
                                ...product,
                                productIngredients: newProductIngredients,
                            });
                        }}
                    />
                </Fragment>
            :   <NoIngredients openModal={openModal} />}
        </div>
    );
};
