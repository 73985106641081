import { LocalStorage } from "../LocalStorage";
import { companySlug } from "../URLHandler";

export type CompanyMandatoryFields = {
    "@id": string;
    id: number;
    name: string;
    organisation?: string;
    companySector?: {
        id: number;
        name: string;
    };
};

export class CompaniesService {
    static getCurrentCompanyId(): number | null {
        const companyId = LocalStorage.getItem("company");
        if (companyId === null) {
            return null;
        }
        return Number(companyId);
    }

    static switchCompany(companyId: number) {
        LocalStorage.setItem("company", companyId.toString());
    }

    static resetCompany() {
        LocalStorage.deleteItem("company");
    }

    static isCompanyIdValid(companyId: number | null, allCompanies: CompanyMandatoryFields[]): boolean {
        if (companyId === null) {
            return false;
        }

        const foundCompany = allCompanies.findIndex((company) => company.id === companyId);
        if (foundCompany === -1) {
            return false;
        }
        return true;
    }

    static selectCurrentCompany<T extends CompanyMandatoryFields>(allCompanies: T[], companyId?: number): T | null {
        const currentId = companyId === undefined ? this.getCurrentCompanyId() : companyId;

        const selectedCompany = allCompanies.find((company) => company.id === currentId);

        if (selectedCompany === undefined) {
            return null;
        }

        this.switchCompany(selectedCompany.id);
        return selectedCompany;
    }

    static switchCompanyURL(companyId: number, currentUrl: string) {
        const splitReplace = currentUrl.split("/");
        const companyIndex = splitReplace.findIndex((item) => item === companySlug);
        if (companyIndex < 0) {
            console.error("Err, no company in URI...");
            return;
        }
        const newUrl = splitReplace
            .map((item, index) => {
                if (index === companyIndex + 1) {
                    return companyId.toString();
                }
                return item;
            })
            .join("/");

        return newUrl;
    }
}
