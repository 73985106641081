import { Box, Typography } from "@mui/material";
import { ArrowUpward, ArrowDownward, SvgIconComponent } from "@mui/icons-material";

export type ScoreChangeIndicatorProps = {
    value?: number;
    inverse?: boolean;
};

export const ScatterScoreChangeIndicator = (props: ScoreChangeIndicatorProps): JSX.Element => {
    const percentage: number = props.value ? Math.round(props.value * 100) : 0;
    const ScoreChangeIcon: SvgIconComponent = percentage > 0 ? ArrowUpward : ArrowDownward;

    return (
        <>
            {!!percentage && (
                <Box
                    sx={{
                        background: percentage * (props.inverse ? 1 : -1) > 0 ? "#EE3C15" : "#17BF3C",
                        borderRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        padding: "2px 8px 2px 6px",
                    }}
                >
                    <ScoreChangeIcon sx={{ color: "#FFF", fontSize: "14px" }} />

                    <Typography
                        sx={{
                            color: "#FFF",
                            fontSize: "12px",
                            fontWeight: 600,
                        }}
                    >
                        {percentage}%
                    </Typography>
                </Box>
            )}
        </>
    );
};
