import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDistributionReference } from "src/api/sure-api/useDistributionReference";

type DistributionSummary = {
    distributionChannelId?: number | null;
};
export const ProductDistributionSummary = (props: DistributionSummary) => {
    const { distributionChannelId } = props;
    const { t } = useTranslation();

    const { distributionReference } = useDistributionReference();

    if (distributionChannelId === undefined || distributionChannelId === null) {
        return (
            <Typography
                variant="h3"
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                }}
            >
                {t("Pas de mode distribution associé à ce produit")}
            </Typography>
        );
    }

    const foundDistribution = distributionReference?.find((distribution) => distribution.id === distributionChannelId);

    const noValue = "-";
    return (
        <Box>
            <Typography variant="h4">{t("Mode de distribution du produit:")}</Typography>
            <Typography variant="body">{foundDistribution?.label ?? noValue}</Typography>
        </Box>
    );
};
