import { useRef, useState } from "react";
import { WhitePopover, FoodsSelect } from "@foodpilot/foods";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { formatUnitAbbreviation } from "src/utils/formatting";
import { FormPropertyProps } from "../../FormProperty";
import { FormPropertyHint } from "../../FormPropertyHint";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { DefaultValueButton } from "src/components/FormProperty/DefaultValueButton.tsx";
import { FormTooltip } from "../../FormTooltip";

type SsqSelectProps = FormPropertyProps & {
    options: DataListOption[];
};

export const SsqSelect = (props: SsqSelectProps) => {
    const { t } = useTranslation();
    const { property, post, isSubproperty } = props;

    if (post === undefined) return;

    return (
        <Stack direction="column" gap="8px">
            <_SsqSelect {...props} />
            {property.readonly && (
                <Typography variant="small-medium">{t("Cette valeur est en lecture seule")}</Typography>
            )}
        </Stack>
    );
};

const _SsqSelect = (props: SsqSelectProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { property, element, post, canEdit, options } = props;

    const defaultValueOption = options.find((option) => option.value === property?.defaultValue) ?? null;
    const genericValueOption = options.find((option) => option.value === property?.genericValue) ?? null;

    const initialValue = element && !property.readonly ? element.value ?? "" : defaultValueOption?.value;
    const _selectedOption = options.find((option) => option.value === initialValue) ?? null;
    const selectedOption = useRef(_selectedOption);

    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);
    const [value, setValue] = useState<DataListOption | null>(selectedOption.current);

    const unit = formatUnitAbbreviation(property.unit);

    if (post === undefined) return;

    const updatePropertyValue = (value: DataListOption | null, isGenericValue?: boolean) => {
        const sanitizedValue = value?.value ?? "_empty_";

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: sanitizedValue,
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
        });
    };

    if (post === undefined) return;

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    return (
        <FormTooltip title={tooltipText} arrow placement="right">
            <Box>
                <Stack direction="column" gap="1rem">
                    <Stack direction="row" gap="1.5rem" alignItems="center">
                        <FoodsSelect
                            selectedOption={value}
                            options={options}
                            onChange={(selectedValue: DataListOption | null) => {
                                setValue(selectedValue);
                                updatePropertyValue(selectedValue);
                            }}
                            getId={(item) => item.position}
                            getName={(item) => item.label}
                            disabled={canEdit === false || property.readonly || genericValueSelected}
                            textOptions={{
                                placeholder: t("component.select.placeholder"),
                                searchPlaceholder: t("component.select.searchPlaceholder"),
                                emptyOptionsText: t("component.select.noOptions"),
                                defaultValueText: t("component.select.defaultValue"),
                                noResult: t("component.select.noResultFound"),
                            }}
                            noValue={t("component.select.unselectValue")}
                            unit={unit}
                        />
                        {!property.readonly &&
                            defaultValueOption !== null &&
                            defaultValueOption !== undefined &&
                            !genericValueSelected && (
                                <DefaultValueButton
                                    onClick={() => {
                                        if (value?.value === defaultValueOption.value) {
                                            return;
                                        }

                                        setValue(defaultValueOption);
                                        updatePropertyValue(defaultValueOption);
                                    }}
                                />
                            )}
                    </Stack>
                    {defaultValueOption !== null && !genericValueSelected && (
                        <Typography variant="caption" color={theme.custom.grey[1500]}>
                            {!property.readonly ?
                                t("Valeur par défaut :") + " " + (defaultValueOption?.label ?? "")
                            :   t("Cette valeur est en lecture seule")}
                        </Typography>
                    )}
                    {!property.readonly && genericValueOption && (
                        <Box>
                            <GenericValueCheckbox
                                isSelected={genericValueSelected}
                                onChange={() => {
                                    setGenericValueSelected((prevState) => !prevState);
                                    setValue(!genericValueSelected ? genericValueOption : null);
                                    updatePropertyValue(
                                        !genericValueSelected ? genericValueOption : null,
                                        !genericValueSelected,
                                    );
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Box>
        </FormTooltip>
    );
};
