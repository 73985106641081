import { useContext } from "react";
import { ActionsContext } from "./context";

export const useActionsContext = () => {
    const context = useContext(ActionsContext);

    if (context) {
        return context;
    }

    throw new Error(`useActionsContext must be used within the context`);
};
