export const getEnv = (value: string): string => {
    // We may want to use the proper type.
    // https://vitejs.dev/guide/env-and-mode#intellisense-for-typescript
    // For now, I don't think It is very useful.
    // A proper guard like this one should be enough if we stick to it.
    const envValue = import.meta.env[value];
    if (typeof envValue === "string") {
        return envValue;
        // The env variable is properly resolved.
    }

    throw new Error(`Missing an Env Variable : ${value}`);
};
