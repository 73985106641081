import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { IngredientFormIngredientSupplier, IngredientFormType } from "./IngredientForm/IngredientForm.types";
import { IngredientWrite, IngredientWriteIngredientSupplier } from "src/api/client-api/ingredients/useCreateIngredient";
import { formatStringForBackend } from "src/utils/formatting";

export class IngredientService {
    static getIngredientToCreate = (existingIngredient?: Ingredient): IngredientFormType => {
        if (existingIngredient === undefined) {
            const newIngredient: IngredientFormType = {
                name: null,
                clientId: null,
                ingredientReferenceId: null,
                ingredientLine: null,
                organic: null,
                ingredientSuppliers: [],
            };
            return newIngredient;
        }

        const ingSuppliers = existingIngredient.ingredientSuppliers?.map((existingIngSup) => {
            const supplier = existingIngSup.supplier;
            const existingToCreate: IngredientFormIngredientSupplier = {
                supplier: {
                    "@id": supplier["@id"],
                    name: supplier.name,
                    clientId: supplier.clientId ?? null,
                    sectors: supplier.sectors ?? [],
                },
            };
            return existingToCreate;
        });
        const formattedIngredient: IngredientFormType = {
            id: existingIngredient.id,
            name: existingIngredient.name,
            clientId: existingIngredient.clientId ?? null,
            ingredientReferenceId: existingIngredient.ingredientReferenceId ?? null,
            ingredientLine: existingIngredient.ingredientLine ?? null,
            organic: existingIngredient.organic ?? null,
            ingredientSuppliers: ingSuppliers ?? [],
        };
        return formattedIngredient;
    };

    private static makeIngredientSuppliers = (
        ingredientSuppliers: IngredientFormIngredientSupplier[],
    ): IngredientWriteIngredientSupplier[] => {
        const newIngredientSuppliers = ingredientSuppliers.map((ingSupplier) => {
            const ingSuppId = ingSupplier["@id"];
            if (ingSuppId) {
                const existingIngSupp: IngredientWriteIngredientSupplier = ingSuppId;
                return existingIngSupp;
            }

            const newIngSupplier: IngredientWriteIngredientSupplier = {
                supplier: ingSupplier.supplier["@id"],
                grossSales: null,
                quantity: null,
                unit: null,
            };
            return newIngSupplier;
        });

        return newIngredientSuppliers;
    };

    static ingredientFormToIngredientWrite = (ingredientForm: IngredientFormType): IngredientWrite => {
        const newIngSupliers = IngredientService.makeIngredientSuppliers(ingredientForm.ingredientSuppliers);

        const ingredientToCreate: IngredientWrite = {
            id: ingredientForm.id,

            name: formatStringForBackend(ingredientForm.name),
            clientId: formatStringForBackend(ingredientForm.clientId),
            ingredientReferenceId: ingredientForm.ingredientReferenceId,
            ingredientLine: ingredientForm.ingredientLine?.["@id"] ?? null,
            organic: ingredientForm.organic,
            ingredientSuppliers: newIngSupliers,
        };

        return ingredientToCreate;
    };

    static makeSectorsFromIngredient = (ingredient: Ingredient): Ingredient => {
        const sectorFromParentIngredient = ingredient.ingredientLine?.name;

        // Going through ingredientSuppliers.supplier to aggregate all the Sectors
        // from the Ingredient he's linked to.
        const ingredientSuppliersWithSectors = ingredient.ingredientSuppliers?.map((ingredientSupplier) => {
            const nestedIngSup = ingredientSupplier.supplier.ingredientSuppliers;

            const sectorsFromSuppliers = nestedIngSup.reduce((previousValue, supplier) => {
                // If it's a string, we want the parentIngredient sector, because
                // It's an IRI that means we're looking at the parent object.
                const currentSector =
                    typeof supplier === "string" ? sectorFromParentIngredient : (
                        supplier.ingredient?.ingredientLine?.name
                    );

                if (currentSector !== undefined) previousValue.add(currentSector);
                return previousValue;
            }, new Set<string>());

            return {
                ...ingredientSupplier,
                supplier: {
                    ...ingredientSupplier.supplier,
                    sectors: Array.from(sectorsFromSuppliers ?? []),
                },
            };
        });

        const ingredientWithSectorsFromSuppliers = {
            ...ingredient,
            ingredientSuppliers: ingredientSuppliersWithSectors,
        };
        return ingredientWithSectorsFromSuppliers;
    };
}
