import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getAllBrandsKey } from "./useGetAllBrand";

export const useBrandDelete = () => {
    const queryClient = useQueryClient();

    const query = useMutation({
        mutationFn: async (id: number) => {
            const response = await loggedInApiCall.delete("/api/brands/" + id);
            return response;
        },
        onSuccess: () => queryClient.invalidateQueries([getAllBrandsKey]),
    });

    return query;
};
