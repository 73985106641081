import z, { ZodError } from "zod";

// https://zod.dev/?id=inferring-the-inferred-type
// This is just how it's described in the Docs.
// Kilian:
// I'm not fully convinced, but it seems like a fair solution
// because the `.parse()` would still be VALIDATING the data... so casting it with `as` just after seems safe.
// ----
export const zodParseWithLog = <T extends z.ZodTypeAny>(valueToParse: unknown, zodSchema: T, extraInfoText: string) => {
    try {
        const data = zodSchema.parse(valueToParse) as z.infer<T>;
        return data;
    } catch (err) {
        if (err instanceof ZodError) {
            console.error(`Zod Error Explanation (key: ${extraInfoText}) :`, err.issues);
        }
        throw new Error("Contact your admin with the log above.");
    }
};
