import { GenericAccordion } from "@foodpilot/foods";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InfoOutlined } from "@mui/icons-material";
import { ModifyButton } from "./ModifyButton";
import { SupplierFormType } from "../../SupplierForm.types";

type InfoSectionProps = {
    supplier: SupplierFormType;
};
export const InfoSection = (props: InfoSectionProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { supplier } = props;

    return (
        <GenericAccordion
            headerIcon={<InfoOutlined />}
            headerText={t("general_information")}
            controls={<ModifyButton pageNumber={0} />}
        >
            <Box
                component="table"
                sx={{
                    display: "table",
                    width: "100%",
                    "&>tr>td": {
                        width: "430px",
                        borderBottom: `1px solid ${theme.custom.grey[500]}`,
                        padding: "20px 0px",
                    },
                }}
            >
                <tr>
                    <td>
                        <Typography variant="big-bold">{t("Nom du fournisseur")}</Typography>
                    </td>
                    <td>
                        <Typography>{supplier.name ?? "-"}</Typography>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Typography variant="big-bold">{t("Code fournisseur")}</Typography>
                    </td>
                    <td>
                        <Typography>{supplier.clientId ?? "-"}</Typography>
                    </td>
                </tr>
            </Box>
        </GenericAccordion>
    );
};
