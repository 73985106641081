import { WhiteBox } from "@foodpilot/foods";
import { Box, Button, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import _Hint from "src/assets/icons/hint.svg?react";
import { useState } from "react";

const HintIcon = styled(_Hint)(({ theme }) => ({
    path: {
        fill: theme.custom.grey[2800],
    },
}));

export type HintBoxProps = {
    title: string;
    content: string;
};

export const HintBox = (props: HintBoxProps) => {
    const [showIndicatorsNotice, setShowIndicatorsNotice] = useState(true);
    const { t } = useTranslation();

    return (
        showIndicatorsNotice && (
            <WhiteBox sx={{ p: 6, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
                    <HintIcon />
                    <Box>
                        <Typography variant="h5">{props.title}</Typography>
                        <Typography variant="small">{props.content}</Typography>
                    </Box>
                </Box>
                <Button endIcon={<Close />} onClick={() => setShowIndicatorsNotice(false)}>
                    {t("Ne plus afficher")}
                </Button>
            </WhiteBox>
        )
    );
};
