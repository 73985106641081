import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

type EmptyRowsFallbackProps = {
    message?: string;
};
export const EmptyRowsFallback = (props: EmptyRowsFallbackProps) => {
    const { t } = useTranslation();

    return (
        <Stack minHeight="96px" alignItems="center" justifyContent="center">
            <Typography variant="body1" textAlign="center">
                {props.message ?? t("Il n'y a aucun record à afficher")}
            </Typography>
        </Stack>
    );
};
