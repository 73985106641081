import { useNavigate, useRevalidator } from "react-router-dom";
import { Box, Container, Button, TextField, FormLabel, MenuItem } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { Loading } from "src/components/utils/Loading";
import { BrandWrite, BrandWriteSchema, useCreateBrand } from "src/api/client-api/brand/useCreateBrand";
import { useGetAllSupplier } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { Brand } from "src/api/client-api/brand/useGetOneBrand";
import { URLHandler } from "src/services/URLHandler";

export type BrandCreateProps = {
    existingBrand?: Brand;
};

export const BrandCreate = (props: BrandCreateProps) => {
    const { existingBrand } = props;

    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const { t } = useTranslation();

    const createBrand = useCreateBrand();
    const { suppliers } = useGetAllSupplier({
        itemsPerPage: 200,
        type: ["branch", "main"],
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<BrandWrite>({
        mode: "onChange",
        resolver: zodResolver(BrandWriteSchema),
    });

    const onSubmitHandler: SubmitHandler<BrandWrite> = (values) => {
        createBrand.mutate(
            { brand: values },
            {
                onSuccess: () => {
                    revalidator.revalidate();
                    navigate(URLHandler.settings.brands.list());
                },
                onError: (error) => {
                    console.error(error);
                },
            },
        );
    };

    if (suppliers === undefined) return <Loading />;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <h1>{"Brands"}</h1>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="id"
                        control={control}
                        defaultValue={existingBrand?.id}
                        render={({ field: { ...field } }) => <input type="hidden" {...field}></input>}
                    />
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={existingBrand?.name}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={"Nom"}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            minWidth: "400px",
                            flexDirection: "column",
                        }}
                    >
                        <FormLabel>{t("Entreprise parent")}</FormLabel>
                        <Controller
                            name="company"
                            control={control}
                            defaultValue={existingBrand?.company["@id"]}
                            render={({ field: { ref, ...field } }) => (
                                <TextField
                                    select
                                    variant="outlined"
                                    inputRef={ref}
                                    error={Boolean(errors.company)}
                                    helperText={errors.company?.message}
                                    {...field}
                                >
                                    {suppliers &&
                                        suppliers.flatMap((supplier, index) => {
                                            if (supplier["@id"] === existingBrand?.["@id"]) {
                                                return [];
                                            }
                                            return [
                                                <MenuItem key={index} value={supplier["@id"]}>
                                                    {supplier.name}
                                                </MenuItem>,
                                            ];
                                        })}
                                </TextField>
                            )}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button sx={{ marginTop: "1rem", width: "10rem" }} variant="primary" type="submit">
                        {t("Enregistrer")}
                    </Button>
                </Box>
            </form>
        </Container>
    );
};
