import { Container, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { RoundInput } from "@foodpilot/foods";
import { UploadImg } from "./UploadImg";

export const UpsertMedia = () => {
    const [filename, setFileName] = useState("");

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {"CMS"}
                    </Typography>
                </Stack>
            </Stack>
            <Stack>
                <Typography variant="big-medium">{"File name"}</Typography>
                <RoundInput
                    value={filename}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setFileName(newValue);
                    }}
                />
                <UploadImg label={"Your new image"} fileName={filename} originalValue={undefined} />
            </Stack>
        </Container>
    );
};
