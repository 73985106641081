import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ComingSoonBadge = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Stack alignItems="center" border={`1px solid ${theme.custom.grey[500]}`} borderRadius="4px" padding="2px 8px">
            <Typography variant="caption" fontWeight={600}>
                {t("Disponible bientôt")}
            </Typography>
        </Stack>
    );
};
