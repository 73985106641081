import { Box, Button, Link, Typography } from "@mui/material";
import { FormBox, MRTGrid } from "@foodpilot/foods";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoIngredient } from "./NoIngredient";
import { createMRTColumnHelper } from "material-react-table";
import {
    IngredientReference,
    useGetAllIngredientReference,
} from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { GridText } from "src/components/Grid/GridText";
import ManageElemIcon from "src/assets/icons/manage-elements-black.svg?react";
import { SupplierIngredientsModal } from "./SupplierIngredientsModal/SupplierIngredientsModal";
import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { SupplierFormIngredientSupplier, SupplierFormType } from "../../SupplierForm.types";

type Page3Props = {
    supplier: SupplierFormType;
    setSupplier: (newSupplier: SupplierFormType) => void;
    existingSupplier?: Supplier;
};

const Page3 = (props: Page3Props) => {
    const { supplier, setSupplier } = props;
    const [isOpen, setOpen] = useState(false);
    const openModal = () => setOpen(true);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
            }}
        >
            <SupplierIngredientsModal
                existingIngredientSuppliers={supplier.ingredientSuppliers}
                isOpen={isOpen}
                setOpen={setOpen}
                onValidate={(newIngredients) => {
                    setSupplier({
                        ...supplier,
                        ingredientSuppliers: newIngredients,
                    });
                }}
            />
            {supplier.ingredientSuppliers.length > 0 ?
                <Fragment>
                    <DisplayIngredientsFromSuppliers ingredients={supplier.ingredientSuppliers} openModal={openModal} />
                </Fragment>
            :   <NoIngredient openModal={openModal} />}
        </div>
    );
};

type DisplayIngredientsFromSuppliersProps = {
    ingredients: SupplierFormIngredientSupplier[];
    openModal: () => void;
};
const DisplayIngredientsFromSuppliers = (props: DisplayIngredientsFromSuppliersProps) => {
    const { ingredients, openModal } = props;
    const { t } = useTranslation();
    const { ingredientsReference } = useGetAllIngredientReference();
    const refIngIdToRefIng = ingredientsReference?.reduce(
        (acc, ing) => {
            acc[ing.id] = ing;
            return acc;
        },
        {} as Record<number, IngredientReference>,
    );

    const columnHelper = createMRTColumnHelper<SupplierFormIngredientSupplier>();
    const mrt_columns = [
        columnHelper.accessor((row) => row.ingredient?.name ?? "-", {
            enableSorting: false,
            id: "name",
            header: t("Nom"),
            Cell: (value) => (
                <Link
                    // href={`/ingredients/valid/url/to/go`}
                    sx={{ color: "inherit", textDecoration: "none" }}
                >
                    {value.renderedCellValue}
                </Link>
            ),
        }),
        columnHelper.accessor(
            (row) => {
                const refId = row.ingredient.ingredientReferenceId;
                if (refId === undefined) return "-";

                const ingToShow = refIngIdToRefIng?.[refId];
                if (ingToShow === undefined) return "--"; // shouldnt happen but just for TS.

                return ingToShow.name;
            },
            {
                id: "ingredient-ref",
                header: "Ingrédient de ref.",
                Cell: (value) => (
                    <Typography variant="body" sx={{ color: "#000" }}>
                        {value.renderedCellValue}
                    </Typography>
                ),
            },
        ),
        columnHelper.accessor((row) => row.ingredient.ingredientLine?.name ?? "-", {
            id: "ingredientLine",
            header: t("Filière"),
            Cell: (value) => <GridText value={value.cell.getValue()} />,
        }),
    ];

    return (
        <Box style={{ display: "flex", gap: "2rem" }}>
            <FormBox sx={{ flex: 3 }}>
                <Box sx={{ marginBottom: "2rem", display: "flex", flexDirection: "row-reverse" }}>
                    <Button variant="ternary" onClick={openModal} startIcon={<ManageElemIcon />}>
                        <Typography variant="body-bold">{t("Associer ou dissocier des ingrédients")}</Typography>
                    </Button>
                </Box>
                <MRTGrid
                    enableSorting={false}
                    enableRowSelection={false}
                    columns={mrt_columns}
                    data={ingredients}
                    state={{
                        isLoading: refIngIdToRefIng === undefined,
                    }}
                />
            </FormBox>
        </Box>
    );
};
export default Page3;
