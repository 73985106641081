import { MRT_PaginationState, createMRTColumnHelper, MRT_SortingState } from "material-react-table";
import { useTranslation } from "react-i18next";
import { FoodsIcon, MRTGrid, Typography } from "@foodpilot/foods";
import { useEffect, useState } from "react";
import { IngredientList, useGetAllIngredient } from "src/api/client-api/ingredients/useGetAllIngredients";
import { ProductFormProductIngredient } from "src/pages/Product/ProductForm/ProductForm.types";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";

type RowNumber = number;
type IsSelected = boolean;

// 2 different states because the MRT library needs its own state !
export type RowSelectionState = {
    rowSelection: Record<RowNumber, IsSelected>;
    existingIngredientsMapping: Record<RowNumber, ProductFormProductIngredient | undefined>;
    ingredientListMapping: Record<RowNumber, IngredientList>;
};

const getInitialSelection = (
    ingredientList: IngredientList[] | undefined,
    existingIngredient: ProductFormProductIngredient[],
) => {
    const emptyRowSelectionState: RowSelectionState = {
        rowSelection: {},
        existingIngredientsMapping: {},
        ingredientListMapping: {},
    };

    if (ingredientList === undefined) return emptyRowSelectionState;

    return ingredientList.reduce((acc, ingredient, rowNumber) => {
        const foundExistingIng = existingIngredient.find((ing) => {
            return ing.ingredient["@id"] === ingredient["@id"];
        });
        const isFound = foundExistingIng !== undefined;

        acc["rowSelection"][rowNumber] = isFound;
        acc["existingIngredientsMapping"][rowNumber] = foundExistingIng;
        acc["ingredientListMapping"][rowNumber] = ingredient;

        return acc;
    }, emptyRowSelectionState);
};

export type IngredientGridProps = {
    delayedSearch: string;
    existingIngredient: ProductFormProductIngredient[];
    setNewIngredients: (ingredients: ProductFormProductIngredient[]) => void;
};
export function IngredientListGrid(props: IngredientGridProps) {
    const { setNewIngredients, existingIngredient, delayedSearch } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    const [ingredientPage, setIngredientPage] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 6,
    });
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: "name", desc: false }]);

    const { ingredients, isLoading, totalItems } = useGetAllIngredient({
        cacheTime: 0,
        page: ingredientPage.pageIndex + 1,
        itemsPerPage: ingredientPage.pageSize,
        search: delayedSearch,
        sorting: sorting,
    });

    const initialSelection = getInitialSelection(ingredients, existingIngredient);
    const [rowSelection, setRowSelection] = useState(initialSelection["rowSelection"]);

    useEffect(() => {
        setRowSelection(initialSelection["rowSelection"]);
    }, [ingredients]);

    useEffect(() => {
        // The RowSelection is a Record<RowNumber, ...>;
        // The problem comes from MRT actually deleting the key, instead of setting it to False
        // when we unselect the element.
        // So we want to make sure the keys who don't exist are still set to at least FALSE;
        const rowSelectionFixed = Array(ingredientPage.pageSize)
            .fill(undefined)
            .map((_, index) => index);
        const rowSelectionArray = rowSelectionFixed.map((row) => {
            const rowString = row.toString();
            const selectedRow = rowSelection[row];
            if (selectedRow !== undefined) {
                return [rowString, selectedRow];
            }
            return [rowString, false];
        });
        // const rowSelectionArray = Object.entries(rowSelection);
        if (rowSelectionArray.length === 0) {
            // Means the component is not loaded yet.
            return;
        }

        const existingIngredientCopy = [...existingIngredient];
        const newProductIngredients = rowSelectionArray.reduce((previousValue, [rowNumber, isSelected]) => {
            if (isSelected === false) {
                // Since it's NOT selected, we want to DELETE the item if it existed.
                const row = Number(rowNumber);
                const existingIng = initialSelection["existingIngredientsMapping"][row];
                if (existingIng === undefined) return previousValue;

                return previousValue.filter((item) => item.ingredient["@id"] !== existingIng.ingredient["@id"]);
            } else {
                // The item is actively selected.
                const row = Number(rowNumber);
                const existingIng = initialSelection["existingIngredientsMapping"][row];

                // Does it already exists ?
                if (existingIng !== undefined) return previousValue;

                // Now we're sure we just selected it.
                const ingredientList = initialSelection["ingredientListMapping"][row];
                const suppliersName = ingredientList.ingredientSuppliers?.map((ingSup) => {
                    return { name: ingSup.supplier.name, id: ingSup.supplier.id };
                });
                const newIngredient: ProductFormProductIngredient = {
                    ingredient: {
                        "@id": ingredientList["@id"],
                        id: ingredientList.id,
                        name: ingredientList.name,
                        suppliersName: suppliersName ?? [],
                    },
                    quantity: null,
                    usedQuantity: null,
                };

                return [...previousValue, newIngredient];
            }
        }, existingIngredientCopy);

        // Here, we have the Full previous selected ingredients
        // In addition of the new ones;
        // In deletion of those that were deleted;
        setNewIngredients(newProductIngredients);
    }, [rowSelection]);

    //column setup
    const columnHelper = createMRTColumnHelper<IngredientList>();
    const noValue = "-";
    const columns = [
        columnHelper.accessor("name", {
            header: t("Nom de l'ingrédient"),
            Cell: (value) => (
                <Link
                    to={`/ingredients/${value.row.original.id}/details/scores`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: theme.palette.primary[700],
                        textDecoration: "none",
                        display: "flex",
                        justifyContent: "left",
                        gap: "5px",
                        alignItems: "center",
                    }}
                >
                    {value.renderedCellValue}
                    <FoodsIcon icon="openNewTab" size={2} />
                </Link>
            ),
        }),
        columnHelper.accessor((row) => row.clientId ?? noValue, {
            id: "clientId",
            header: t("unique_identifier"),
            Cell: (value) => (
                <Typography variant="body" sx={{ color: "#000" }}>
                    {value.renderedCellValue}
                </Typography>
            ),
        }),
        columnHelper.accessor((row) => row.ingredientLine?.name ?? noValue, {
            id: "ingredientLine.name",
            header: t("Filière"),
            Cell: (value) => (
                <Typography variant="body" sx={{ color: "#000" }}>
                    {value.renderedCellValue}
                </Typography>
            ),
        }),
    ];

    return (
        <MRTGrid
            columns={columns}
            data={ingredients ?? []}
            enablePagination={true}
            enableBottomToolbar={false}
            enableRowSelection={true}
            onRowSelectionChange={setRowSelection}
            initialState={{
                pagination: { pageIndex: 0, pageSize: 8 },
                rowSelection: rowSelection,
            }}
            state={{
                globalFilter: delayedSearch,
                rowSelection: rowSelection,
                isLoading: isLoading,
                pagination: ingredientPage,
                sorting: sorting,
            }}
            rowCount={totalItems}
            manualPagination={true}
            onPaginationChange={setIngredientPage}
            manualSorting={true}
            onSortingChange={setSorting}
        />
    );
}
