import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { PostSchema } from "./postSchema";
import { useSnackbarWrapperApiGenericValidationErrorHandler } from "src/api/axiosClient/errorHandler.ts";
import { isAxiosError } from "axios";

export const useGetCompanyPost = (companyId?: number, campaignId?: number) => {
    const apiErrorHandler = useSnackbarWrapperApiGenericValidationErrorHandler();

    const query = useQuery({
        queryKey: ["getCompanyPost", companyId, campaignId],
        retry: 0,
        queryFn: async () => {
            let url = `/api/companies/${companyId}/ssq_post`;

            if (campaignId) {
                const params = new URLSearchParams({ campaign: campaignId.toString() });
                url = url.concat("?").concat(params.toString());
            }

            return await loggedInApiCall.get(url.toString(), {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        enabled: companyId !== undefined,
        select: (response) => {
            if (!response) {
                return;
            }

            const post = response.data;
            const data = zodParseWithLog(post, PostSchema, "getCompanyPost");
            return data;
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                apiErrorHandler(error);
            }
        },
    });

    return {
        post: query.data,
        ...query,
    };
};
