import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { Ingredient } from "./useGetOneIngredient";
import { IngredientService } from "src/pages/Ingredient/IngredientService";

type IngredientDupOptions = {
    ingredientId: number;
    dupSuffix?: string;
};
export const useIngredientDuplicate = () => {
    const query = useMutation({
        mutationFn: async (options: IngredientDupOptions) => {
            const { ingredientId, dupSuffix = "Copy" } = options;

            const req = await loggedInApiCall.get<Ingredient>(`/api/ingredients/${ingredientId}`);
            const ingredientForm = IngredientService.getIngredientToCreate(req.data);
            const ingredientToDuplicate = IngredientService.ingredientFormToIngredientWrite(ingredientForm);

            delete ingredientToDuplicate["id"];

            ingredientToDuplicate.name = `${ingredientToDuplicate.name} - ${dupSuffix}`;

            const response = await loggedInApiCall.post("/api/ingredients", ingredientToDuplicate);
            return response;
        },
    });

    return query;
};
