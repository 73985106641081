import { TableRow, TableCell, Typography, Box, IconButton, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

type AccountRowProps = {
    label: string;
    previousTotal: number;
    total: number;
    handleOnDelete: () => void;
};

export const AccountRow = (props: AccountRowProps) => {
    const theme = useTheme();
    const { label, previousTotal, total, handleOnDelete } = props;
    return (
        <TableRow>
            <TableCell>
                <Typography variant="big-bold">{label}</Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {previousTotal.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        currencyDisplay: "symbol",
                    })}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {total.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        currencyDisplay: "symbol",
                    })}
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                    <IconButton sx={{ border: 1, borderColor: theme.custom.grey[500] }} onClick={handleOnDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
};
