import { WhiteBox } from "@foodpilot/foods";
import { Typography, Box } from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";
import { RSEFormProps } from "src/types";
import { AccountTable } from "./AccountTable";

type EnergiesProps = {
    categoryIndex: number;
};

export const Energies = (props: EnergiesProps) => {
    const { categoryIndex } = props;

    const { control } = useFormContext<RSEFormProps>();
    const { fields } = useFieldArray({ control, name: `categories.${categoryIndex}.energies` });

    return fields.map((energy, energyIndex) => (
        <WhiteBox key={energy.id} sx={{ p: 6, mb: 2 }}>
            <Typography variant="big-bold">{energy.energyType.label}</Typography>
            <Box sx={{ height: 20 }} />
            <AccountTable energyType={energy.energyType} categoryIndex={categoryIndex} energyIndex={energyIndex} />
        </WhiteBox>
    ));
};
