import { useQueryClient } from "react-query";
import { SectionBox } from "@foodpilot/foods";
import { ActivitiesHeader } from "./ActivitiesData/ActivitiesHeader";
import { Statistic, SuppliersStatistics } from "./ActivitiesData/SuppliersStatistics";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import { ActivitiesService } from "./ActivitiesData/ActivitiesService";
import { ActivitiesFormData, ActivitiesFormIngredientSupplier } from "./ActivitiesData/Activities.type";
import { SuppliersList } from "./ActivitiesData/SuppliersList";
import { Box, Typography } from "@mui/material";
import { useCreateIngredient } from "src/api/client-api/ingredients/useCreateIngredient";
import { useIngredientContext } from "./ExistingIngredientContext";

export type IngredientActivitiesDataProps = unknown;
export const IngredientActivitiesData = (_props: IngredientActivitiesDataProps) => {
    const [toggleEditMode, setToggleEditMode] = useState(false);
    const { ingredient } = useIngredientContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const activitiesIngredientSupplier = ActivitiesService.getIngredientToActivities(ingredient);
    const [activities, setActivities] = useState<ActivitiesFormData>(activitiesIngredientSupplier);

    const statistics = useComputeStatistics(activities.suppliers);

    const updateIngredient = useCreateIngredient();

    if (ingredient === undefined) return;

    return (
        <Fragment>
            <Typography variant="h2">{t("Données d’activité des fournisseurs de l’ingrédient")}</Typography>
            <SectionBox
                header={
                    <ActivitiesHeader
                        ingredient={ingredient}
                        toggleEditMode={toggleEditMode}
                        onActionClick={() => {
                            const newValue = !toggleEditMode;

                            if (!newValue) {
                                const ingredientWrite = ActivitiesService.getActivitiesFormDataToIngredientWrite(
                                    ingredient,
                                    activities,
                                );

                                updateIngredient.mutate(
                                    { ingredient: ingredientWrite },
                                    {
                                        onSuccess: () => {
                                            queryClient.invalidateQueries({
                                                queryKey: ["getOneIngredient", String(ingredient.id)],
                                            });
                                        },
                                    },
                                );
                            }

                            setToggleEditMode(newValue);
                        }}
                        numberOfSuppliers={activities.suppliers.length}
                    />
                }
            >
                <Box sx={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
                    <SuppliersStatistics statistics={statistics} />
                    <SuppliersList
                        activities={activities}
                        toggleEditMode={toggleEditMode}
                        onActivitiesUpdate={setActivities}
                    />
                </Box>
            </SectionBox>
        </Fragment>
    );
};

type ComputeStatistics = {
    totalGrossSales: number;
    totalQuantity: number;
};

const useComputeStatistics = (ingredientSupplier: ActivitiesFormIngredientSupplier[]) => {
    const { t } = useTranslation();

    const computedStatistics = ingredientSupplier.reduce<ComputeStatistics>(
        (acc, curr) => {
            acc.totalGrossSales += curr.grossSales ?? 0;
            acc.totalQuantity += curr.quantity ?? 0;
            return acc;
        },
        { totalGrossSales: 0, totalQuantity: 0 },
    );

    const averageTotalPrice =
        computedStatistics.totalQuantity <= 0 ?
            0
        :   computedStatistics.totalGrossSales / computedStatistics.totalQuantity;

    const statitics: Statistic[] = [
        { title: t("ingredient.activityForm.quantityTotal"), value: computedStatistics.totalQuantity, unit: "T" },
        { title: t("ingredient.activityForm.grossSalesTotal"), value: computedStatistics.totalGrossSales, unit: "€" },
        {
            title: t("ingredient.activityForm.averagePriceTotal"),
            value: averageTotalPrice,
            unit: "€/T",
        },
    ];

    return statitics;
};
