import { RadioBoolean, RadioBooleanProps } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";

type RadioBooleanField = RadioBooleanProps & {
    title?: string;
    description?: string;
    required?: boolean;
};
export const RadioBooleanField = (props: RadioBooleanField) => {
    const { title, description, required, ...radioProps } = props;
    const requiredText = required ? "*" : "";
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            {title && <Typography variant="h5">{`${title}${requiredText} :`}</Typography>}
            {description && <Typography variant="body">{description}</Typography>}
            <RadioBoolean {...radioProps} />
        </Box>
    );
};
