import { TextTitleGreyIcon } from "@foodpilot/foods";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";

import MilkBottle from "src/assets/icons/milk-bottle-small.svg?react";
import DoubleCheck from "src/assets/icons/double-check.svg?react";
import { useTranslation } from "react-i18next";

type HeaderProps = {
    openModal: () => void;
};
export const Header = (props: HeaderProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Box sx={{ marginBottom: "10px" }}>
                <TextTitleGreyIcon
                    title={"Ingrédients et leur part dans la recette"}
                    icon={<MilkBottle />}
                    titleSize="h3"
                />
            </Box>
            <Box sx={{ borderTop: `1px solid ${theme.custom.grey[500]}` }}>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        marginBlock: "18px",
                    }}
                >
                    <Button variant="ternary" startIcon={<DoubleCheck />} onClick={props.openModal}>
                        <Typography variant="body-bold">{t("Ajouter ou retirer des ingrédients")}</Typography>
                    </Button>
                </Box>
            </Box>
        </Fragment>
    );
};
