import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

type CreatePage = {
    newPage: ContentPage;
};
export const useCreateContentPage = () => {
    // const queryClient = useQueryClient();

    const query = useMutation({
        mutationFn: async (options: CreatePage) => {
            const { newPage } = options;

            const response = await loggedInApiCall.post("/api/cms/contents/pages", newPage);
            // await loggedInApiCall.patch(`/api/cms/contents/pages/${newPage.id}`, newPage);

            return response;
        },
        // onSuccess: () => queryClient.invalidateQueries(["allProductLines"]),
    });

    return query;
};

export type ContentPage = {
    id: string;
    slug: string;
    title: string;
    tags: IRI[];
};
