import {
    MRT_Table,
    MRT_TableInstance,
    MRT_TableOptions,
    MRT_RowData,
    useMaterialReactTable,
} from "material-react-table";
import { useTheme } from "@mui/material";

export type SectionGridProps<T extends MRT_RowData> = MRT_TableOptions<T> & {
    mode?: "table" | "list";
};

export function SectionGrid<T extends MRT_RowData>(props: SectionGridProps<T>): JSX.Element {
    const theme = useTheme();

    let config: MRT_TableOptions<T> = {
        enableDensityToggle: false,
        enableRowActions: true,
        enableColumnActions: false,
        enableSorting: false,
        positionActionsColumn: "last",
        muiTableProps: {
            sx: {
                border: `1px solid ${theme.custom.grey[500]}`,
                borderRadius: "8px",
                overflow: "hidden",
            },
        },
        muiTableHeadRowProps: {
            sx: {
                boxShadow: "none",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                background: theme.custom.grey[200],
                color: theme.custom.grey[2000],
                fontSize: "12px",
                fontWeight: 500,
                padding: "12px 28px",
                align: "center",
                verticalAlign: "middle",
            },
        },
        muiTableBodyRowProps: {
            sx: {
                "&:last-child:not(:hover) > td:after": {
                    display: "none",
                },
            },
        },
        muiTableBodyCellProps: (value) => {
            return {
                sx: {
                    borderBottom: "none",
                    padding: "28px",
                    ":last-child": {
                        width: "1%",
                    },
                    ":after": {
                        content: '""',
                        height: "1px",
                        background: "#E7E5E4",
                        position: "absolute",
                        left: value.column.getIsFirstColumn() ? "28px" : 0,
                        right: value.column.getIsLastColumn() ? "28px" : 0,
                        bottom: 0,
                    },
                },
            };
        },
        localization: {
            actions: "",
        },
        ...props,
    };

    if (props.mode === "list") {
        config = {
            ...config,
            muiTableProps: {
                sx: {
                    overflow: "hidden",
                },
            },
            muiTableHeadRowProps: {
                sx: {
                    display: "none",
                },
            },
            muiTableBodyCellProps: () => {
                return {
                    sx: {
                        padding: "28px",
                        ":last-child": {
                            width: "1%",
                        },
                    },
                };
            },
        };
    }

    const table: MRT_TableInstance<T> = useMaterialReactTable(config);

    return <MRT_Table table={table} />;
}
