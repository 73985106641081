import { Stack, CircularProgress } from "@mui/material";
import { FormPropertyProps } from "../../FormProperty";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { FormUnit } from "@foodpilot/foods";
import { useScoreContext } from "src/context/ScoreContext";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { SelectTreeView } from "./TreeView";
import { SsqSelect } from "./SsqSelect";

export const Select = (props: FormPropertyProps) => {
    const { displayedScoreId, displayedVariationId, formattedScores } = useScoreContext();
    const { property, post, isSubproperty, isMultivalued } = props;

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const propertyScore = formattedScore?.properties?.[property.id];

    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const propertyVariations = propertyScore?.variations ?? {};
    const propertyVariation = propertyVariations?.[displayedVariationId ?? 0];

    if (!post) return <CircularProgress />;

    const options =
        property.dataList?.options.sort(
            (optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position,
        ) ?? [];

    const groupedOptions = Object.groupBy(options, (opt) => opt.groupOption ?? "undefined");
    const isGrouped = Object.keys(groupedOptions).length > 1;

    return (
        <Stack direction="column" gap="8px">
            <Stack direction="row" alignItems="center" gap="8px">
                {isGrouped ?
                    <SelectTreeView {...props} groupedOptions={groupedOptions} options={options} />
                :   <SsqSelect {...props} options={options} />}
                {!!propertyScore?.score && isSubproperty && !isMultivalued && (
                    <FormUnit
                        value={Number((propertyVariation?.score ?? propertyScore?.score).toFixed(3))}
                        unit={scoreVariation?.unit_abbreviation ?? scoreVariation?.unit}
                    />
                )}
            </Stack>
        </Stack>
    );
};
