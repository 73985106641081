import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getAllRolesKey } from "./useGetAllRoles.ts";

export const useRoleDelete = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (code: string) => {
            return await loggedInApiCall.delete(`/api/roles/${code}`);
        },
        onSuccess: () => queryClient.invalidateQueries([getAllRolesKey]),
    });
};
