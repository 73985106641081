import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { CMSSectionPageType } from "./sections/sectionsConfig";

export const getOneCMSContentKey = "getOneContentKey" as const;

type Options = {
    contentId: string | undefined;
};
export const useGetOneContent = (options: Options) => {
    const { contentId } = options;

    const query = useQuery({
        queryKey: [getOneCMSContentKey],
        enabled: contentId !== undefined,
        retry: 0,
        queryFn: async () => {
            const url = `/api/cms/contents/${contentId}`;

            return await loggedInApiCall.get<CMSContent>(url);
        },
        select: (request) => {
            const data = request.data;
            return data;
        },
    });

    return {
        content: query.data,
        ...query,
    };
};

export type CMSContentBlock = {
    "@id": IRI;
    id: UUID;
    title: string;
    subtitle?: string;
    description?: string;
    ctaLink: string;
    ctaLabel: string;
    media?: {
        "@id": IRI;
        url: string;
        alt: string;
        dimensions: [number, number];
    };
    position: number;
    section: IRI;
    visible?: boolean;
};

export type CMSPageSection = {
    "@id": IRI;
    id: UUID;
    type: CMSSectionPageType;
    title: string;
    position: number;
    visible: boolean;
    blocks: CMSContentBlock[];
    page: IRI;
};

export type CMSContent = {
    "@id": IRI;
    slug: string;
    title: string;
    sections: CMSPageSection[];
};
