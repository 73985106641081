import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { ApplicationParameterKeys } from "./useApplicationParameters";

type UpsertAppParam = {
    key: ApplicationParameterKeys;
};

export const useDeleteAppParam = () => {
    const query = useMutation({
        mutationFn: async (options: UpsertAppParam) => {
            const { key } = options;

            const url = `/api/application_parameters/${key}`;
            await loggedInApiCall.delete(url);
        },
        onSuccess: () => {
            // For now, in order to immediately apply the changes, we want to reload the page;
            // Maybe later, we'll find a way to do it more properly.
            location.reload();
        },
    });

    return query;
};
