import { AxiosError } from "axios";
import { FieldErrors } from "src/api/axiosClient/errorHandler.ts";

export interface RFC7807Error {
    "@context": string;
    "@type": string;
    "hydra:title": string;
    "hydra:description": string;
    violations: {
        propertyPath: string;
        message: string;
        code: string;
    }[];
}

export const isRFC7807Error = (error: AxiosError): boolean => {
    const payload = error.response?.data as RFC7807Error | undefined;
    return (
        error.response !== undefined &&
        error.response.status === 422 &&
        payload?.["@type"] === "ConstraintViolationList" &&
        payload?.violations !== undefined
    );
};

export const mapRfc7807Payload = (payload: RFC7807Error): FieldErrors => {
    const fieldErrors: FieldErrors = {
        errors: [],
    };

    if (!payload.violations) {
        return fieldErrors;
    }

    if (payload.violations.length === 0) {
        return fieldErrors;
    }

    payload.violations.forEach((violation) => {
        fieldErrors.errors.push({
            fieldPath: violation.propertyPath,
            message: violation.message,
        });
    });

    return fieldErrors;
};
