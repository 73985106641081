import { ChangeEvent, useEffect, useState } from "react";
import { useRevalidator } from "react-router-dom";
import { Box, Container, Input, Button, TextField, Typography, Divider } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useGetSettings } from "src/api/client-api/settings/useSettings";
import {
    SettingsWriteSchemaZod,
    useUpdateFile,
    useUpdateSettings,
} from "src/api/client-api/settings/useUpdateSettings";
import { Loading } from "src/components/utils/Loading";
import { useSnackbar } from "src/utils/useSnackbar";

const themes = ["foodpilot", "winepilot", "inextenso"];

export const SettingsInfos = () => {
    const { settings, isLoading } = useGetSettings();
    const revalidator = useRevalidator();

    const [contentUrl, setContentUrl] = useState<string | undefined>(undefined);
    const [coverUrl, setCoverUrl] = useState<string | undefined>(undefined);

    const updateSettings = useUpdateSettings();
    const updateFile = useUpdateFile();

    const snackbar = useSnackbar();

    const { t } = useTranslation();

    type Schema = z.infer<typeof SettingsWriteSchemaZod>;

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Schema>({
        mode: "onChange",
        resolver: zodResolver(SettingsWriteSchemaZod),
    });

    useEffect(() => {
        if (!settings) return;

        setCoverUrl(settings.coverUrl);
        setContentUrl(settings.contentUrl);
    }, [settings]);

    if (isLoading) return <Loading />;

    const onFileUpload = (
        event: ChangeEvent<HTMLInputElement>,
        type: "cover" | "image",
        formDataKey: "file" | "cover",
    ) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            updateFile.mutate(
                { file: file, type: type, formDataKey: formDataKey },
                {
                    onSuccess: (response) => {
                        switch (type) {
                            case "cover":
                                setCoverUrl(response.data.contentUrl);
                                break;
                            case "image":
                                setContentUrl(response.data.contentUrl);
                                break;
                        }

                        snackbar.setSnackbarProps({
                            message: t("settings.success"),
                            type: "success",
                        });
                    },
                },
            );
        }
    };

    const onSubmitHandler: SubmitHandler<Schema> = (values) => {
        updateSettings.mutate({ settings: values }, { onSuccess: () => revalidator.revalidate() });
    };

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Typography variant="h1">Paramètres</Typography>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Controller
                    name="id"
                    defaultValue={settings?.id}
                    control={control}
                    render={({ field: { ...field } }) => <input type="hidden" {...field}></input>}
                />
                <Controller
                    name="title"
                    control={control}
                    defaultValue={settings?.title}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            sx={{ width: "100%", margin: "0.5rem 0 0.5rem 0" }}
                            label={t("Titre")}
                            type="text"
                            variant="outlined"
                            error={Boolean(errors.title)}
                            helperText={errors.title?.message}
                            inputRef={ref}
                            {...field}
                        />
                    )}
                />

                <Controller
                    name="crispToken"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            sx={{ width: "100%", margin: "0.5rem 0 0.5rem 0" }}
                            label="Token crisp"
                            type="text"
                            variant="outlined"
                            defaultValue={settings?.crispToken}
                            error={Boolean(errors.crispToken)}
                            helperText={errors.crispToken?.message}
                            inputRef={ref}
                            {...field}
                        />
                    )}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Button
                        sx={{
                            marginTop: "1rem",
                            width: "10rem",
                        }}
                        variant="primary"
                        type="submit"
                    >
                        {t("Enregistrer")}
                    </Button>
                </Box>
            </form>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <Typography variant="h3">{t("settings.ressources")}</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "1rem 0",
                }}
            >
                <Typography variant="h4">{t("Logo")}</Typography>
                <img alt="" src={contentUrl} width="180" />
                <Input type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => onFileUpload(e, "image", "file")} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "1rem 0",
                }}
            >
                <Typography variant="h4">{t("Cover")}</Typography>
                <img alt="" src={coverUrl} width="180" />
                <Input type="file" onChange={(e: ChangeEvent<HTMLInputElement>) => onFileUpload(e, "cover", "cover")} />
            </Box>
        </Container>
    );
};
