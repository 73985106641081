import React, { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

import { Loading } from "src/components/utils/Loading";
import { useSnackbar } from "src/utils/useSnackbar";
import { useGetAllRoles } from "src/api/client-api/roles/useGetAllRoles.ts";
import { useRoleDelete } from "src/api/client-api/roles/useDeleteRole.ts";
import { Permission } from "src/pages/Settings/Roles/Permission.tsx";
import { getGridStyle } from "src/utils/commonStyles.ts";
import { URLHandler } from "src/services/URLHandler";

export const entityToObjectName = (entity: string) => {
    const parts = entity.split("\\");
    return parts[parts.length - 1];
};

export const RolesList = () => {
    const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
        page: 0,
        pageSize: 10,
    });

    const { allRoles, totalItems, isLoading } = useGetAllRoles({
        itemsPerPage: paginationModel.pageSize,
        // API Plateforme pagination begin at 1, MUI Grid pagination at 0
        page: paginationModel.page + 1,
    });

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const deleteBrand = useRoleDelete();

    const [displayDialog, setDisplayDialog] = useState(false);
    const [idToDelete, setIdToDelete] = useState<string | undefined>(undefined);

    const handlePaginationModelChange = (paginationModel: { page: number; pageSize: number }) => {
        setPaginationModel(paginationModel);
    };

    const handleDeleteClick = (id: string) => () => {
        setIdToDelete(id);
        setDisplayDialog(true);
    };

    const columns: GridColDef[] = [
        {
            field: "code",
            headerName: t("Role"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
        },
        {
            field: "permissions",
            headerName: t("permissions"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
            sortable: false,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 200,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <IconButton component={RouterLink} to={URLHandler.settings.roles.edit(id.toString())}>
                                <EditIcon />
                            </IconButton>
                        }
                        label="Edit"
                        className="textPrimary"
                        color="inherit"
                        key={id}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id.toString())}
                        color="inherit"
                        key={id}
                    />,
                ];
            },
        },
    ];

    const EditToolbar = () => {
        const handleClick = () => {
            navigate(URLHandler.settings.roles.create());
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t("settings.roles.addRole")}
                </Button>
            </GridToolbarContainer>
        );
    };

    if (allRoles === undefined) return <Loading />;

    const rows = allRoles.map((role) => {
        const groupedPermissions = Object.groupBy(role.permission, (permission) => permission.object);

        return {
            id: role.code,
            code: role.code,
            permissions: Object.entries(groupedPermissions).map(([object, permissions]) => {
                return <Permission key={object} permissionName={entityToObjectName(object)} list={permissions} />;
            }),
        };
    });

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("Roles")}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>{t("common.areYouSure")}</DialogTitle>
                <DialogContent dividers>{t("settings.roles.deleteRole")}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={() => {
                            if (idToDelete) {
                                deleteBrand.mutate(idToDelete, {
                                    onError: () => {
                                        snackbar.setSnackbarProps({
                                            message: "Unknown error...",
                                            type: "error",
                                        });
                                    },
                                });
                            }
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={rows}
                columns={columns}
                loading={isLoading}
                editMode="row"
                isRowSelectable={() => false}
                onPaginationModelChange={handlePaginationModelChange}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={totalItems}
                slots={{
                    toolbar: EditToolbar,
                }}
                sx={getGridStyle(rows.length ?? 0)}
            />
        </Container>
    );
};
