import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { formatNumber } from "src/utils/formatting.ts";
import EmptySetIcon from "src/assets/icons/empty-set.svg?react";
import { ComparisonBlock, NumberWithUnit } from "@foodpilot/foods";

export type ScoreProps = {
    unit: string;
    value?: number | null;
    quantity?: number;
    prevScoreValue?: number | null;
    prevCampaignName?: string;
    referenceScoreValue?: number;
};
export const Score = (props: ScoreProps) => {
    const { t, i18n } = useTranslation();
    const { value, unit, quantity, prevScoreValue, prevCampaignName, referenceScoreValue } = props;

    return (
        <Stack gap="20px">
            <Stack direction="row" alignItems="baseline" gap="8px">
                {value ?
                    <NumberWithUnit value={formatNumber(value, i18n.resolvedLanguage)} unit={unit} size="inter" />
                :   <EmptySetIcon />}

                {quantity && (
                    <>
                        <Typography component="span" variant="body-medium">
                            {t("for")}
                        </Typography>
                        <NumberWithUnit value={formatNumber(quantity)} unit={"T."} size="small" />
                    </>
                )}
            </Stack>
            {value && prevScoreValue && prevCampaignName && (
                <Stack direction="row" gap="8px">
                    <ComparisonBlock
                        currentValue={value}
                        layout={{
                            direction: "horizontal",
                            size: "M",
                        }}
                        comparedTo={{
                            value: prevScoreValue,
                            unit: unit,
                            label: prevCampaignName,
                        }}
                        improvement={"increase"}
                    />
                </Stack>
            )}
            {value && referenceScoreValue && (
                <Stack direction="row" gap="8px" marginTop="-8px">
                    <ComparisonBlock
                        currentValue={value}
                        layout={{
                            direction: "horizontal",
                            size: "M",
                        }}
                        comparedTo={{
                            value: referenceScoreValue,
                            unit: unit,
                            label: t("score_reference.the_reference"),
                        }}
                        improvement={"increase"}
                    />
                </Stack>
            )}
        </Stack>
    );
};
