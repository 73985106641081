import { Stack, Typography, Button, useTheme } from "@mui/material";
import { FormUnit } from "@foodpilot/foods";
import { FormProperty, FormPropertyProps } from "../../FormProperty";
import { PostElement } from "src/api/client-api/post/postSchema";
import { PostElementWrite } from "src/api/client-api/property/useUpdateProperty";
import TrashIcon from "src/assets/icons/trash.svg?react";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq";
import { useScoreContext } from "src/context/ScoreContext";
import { Score } from "src/types";

export type MultivalueElementProps = FormPropertyProps & {
    properties: Property[];
    position: number;
    posts: (PostElement | PostElementWrite)[];
    deleteElement: (position: number) => void;
    totalScore?: number;
    unit: string;
};

type PropertyData = {
    property: Property;
    score: number;
    post: PostElement | PostElementWrite | undefined;
    multivalueVariationScore?: number;
};

type PreparedPropertiesData = Array<PropertyData>;

export const MultivalueElement = (props: MultivalueElementProps) => {
    const theme = useTheme();
    const { position, properties, totalScore, unit, deleteElement, posts, canEdit } = props;
    const { displayedScoreId, displayedVariationId, formattedScores, scores } = useScoreContext();

    const sureScore = scores.find((score: Score) => score.id === displayedScoreId);
    const displayedScore = displayedScoreId ? formattedScores[displayedScoreId] : undefined;

    const defaultPrecision = 3;
    const precision = sureScore?.precision ?? defaultPrecision;

    let multivalueVariationScoresNumber = 0;
    const preparedPropertiesData: PreparedPropertiesData = [];
    properties.forEach((childProperty: Property) => {
        const propertyScore = displayedScore?.["properties"][childProperty.id];
        const multivalueScores = propertyScore?.["multivalues"]?.[position];

        const multivalueVariation =
            displayedVariationId ? multivalueScores?.["variations"][displayedVariationId] : multivalueScores;

        const multivalueVariationScore = multivalueVariation?.score;

        const precision = sureScore?.precision ?? 3;
        const score = multivalueVariationScore ? Number(multivalueVariationScore.toFixed(precision)) : 0;

        const post = posts.find(
            (element: PostElement | PostElementWrite) => childProperty.id === element.ssqPropertyId,
        );

        if (multivalueVariationScore !== undefined) {
            multivalueVariationScoresNumber++;
        }

        const propertyData: PropertyData = {
            property: childProperty,
            score: score,
            post: post,
            multivalueVariationScore: multivalueVariationScore,
        };

        preparedPropertiesData.push(propertyData);
    });

    return (
        <Stack
            minHeight="68px"
            padding="24px"
            paddingRight={`${36 + 2 * 16}px`}
            borderTop={position > 0 ? `1px solid ${theme.custom.grey[500]}` : undefined}
            position="relative"
            gap="24px"
        >
            {totalScore !== undefined && (
                <FormUnit
                    value={Number(totalScore.toFixed(precision))}
                    unit={unit}
                    sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                    }}
                />
            )}

            {preparedPropertiesData.map((propertyData: PropertyData) => {
                return (
                    <Stack key={propertyData.property.id} direction="column" gap="8px">
                        <Typography variant="h5">{propertyData.property.title} :</Typography>

                        <Stack direction="row" alignItems="center" gap="16px">
                            <FormProperty
                                {...props}
                                property={propertyData.property}
                                element={propertyData.post}
                                position={Number(position)}
                                isSubproperty={true}
                                isMultivalued={true}
                            />

                            {propertyData.multivalueVariationScore !== undefined &&
                                multivalueVariationScoresNumber > 1 && (
                                    <FormUnit value={propertyData.score} unit={unit} />
                                )}
                        </Stack>
                    </Stack>
                );
            })}

            {canEdit && (
                <Button
                    variant="ternary"
                    onClick={() => deleteElement(Number(position))}
                    startIcon={<TrashIcon />}
                    sx={{
                        minWidth: "36px",
                        height: "36px",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 0,
                        position: "absolute",
                        top: "50%",
                        right: "16px",
                        transform: "translateY(-50%)",
                        ".MuiButton-startIcon": {
                            margin: 0,
                        },
                    }}
                />
            )}
        </Stack>
    );
};
