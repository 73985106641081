import axios from "axios";
import { IExtraFinancialReport } from "./types";
import { sureApiCall } from "./axiosClient/sureApi";
import { loggedInApiCall } from "./axiosClient/userApi";

export const getExtraFinancialReportFn = async (userPath: string) => {
    let query = "";
    if (userPath !== undefined) {
        query = `?user=${encodeURIComponent(userPath)}`;
    }

    const response = await axios.get<IExtraFinancialReport[]>(`/api/extra_financial_reports${query}`, {
        headers: {
            Accept: "application/json",
        },
    });

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const addExtraFinancialReportFn = async (report: any) => {
    const response = await axios.post<IExtraFinancialReport>(`/api/extra_financial_reports`, report, {
        headers: {
            Accept: "application/json",
        },
    });

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const updateExtraFinancialReportFn = async (report: any) => {
    const response = await axios.put<IExtraFinancialReport>(`/api/extra_financial_reports/${report.id}`, report, {
        headers: {
            Accept: "application/json",
        },
    });

    return response.data;
};

export const addReportFn = async (report: any) => {
    const response = await sureApiCall.post<any>(`/api/inexweb/feed-report`, report, {
        headers: {
            Accept: "application/json",
        },
    });

    console.log("response", response);

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getReportFn = async (reportToken: string) => {
    const response = await sureApiCall.get<any>(`/api/inexweb/report?report-token=${reportToken}&pause=10`, {
        responseType: "blob",
        headers: {
            Accept: "application/json",
        },
    });

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const addReportNewFn = async (report: any) => {
    const response = await sureApiCall.post<any>(`/api/inexweb/feed-report-new`, report);

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const addMetricFn = async (metric: any) => {
    const response = await loggedInApiCall.post<any>(`/api/usage_metrics`, metric);

    return response.data;
};
