import { DoughnutChart, FoodsIcon, IconMapping, LinearProgress, ScoreCard, ScoreCardProps } from "@foodpilot/foods";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useProductContext } from "../ProductContext";
import { NavLink } from "react-router-dom";
import { doesScoreExist } from "src/api/client-api/post/scoreSchema";
import { Loading } from "src/components/utils/Loading";
import { useEffect } from "react";
import { completionRate } from "../Ingredients/Ingredients";

const DIMENSION = "components";

export const Summary = () => {
    const {
        product,
        ssq,
        post,
        scoreState,
        allScoreWithVariations,
        productScores,
        switchToDimension,
        ingredientPosts,
    } = useProductContext();

    useEffect(() => {
        switchToDimension(DIMENSION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!product) return <Loading />;

    const totalCompletionRate = (product.productIngredients ?? []).reduce(
        (acc, pi) => acc + completionRate(pi.ingredient, ingredientPosts),
        0,
    );
    const ingredientCount = (product.productIngredients ?? []).length;
    const totalIngredientCompletion = totalCompletionRate / (ingredientCount ?? 1);
    const totalCompletion = ssq && post ? post.completions.ssq.result : 0;

    const selectedItemId = scoreState.lastSelectedVariation?.scoreId ?? allScoreWithVariations[0].id;
    const scoreCardHeader: ScoreCardProps["header"] = {
        selectedItemId,
        options: allScoreWithVariations,
    };
    const score = product.scores[selectedItemId];
    const precision = productScores.find((ps) => ps.id === selectedItemId)?.precision;

    const dimension = score.dimensions
        .filter((d) => d.label === DIMENSION)
        .map((d) => d.values)
        .flatMap((c) => Object.values(c));

    const chartValues = dimension.map((v) => Number((v.score ?? 0).toFixed(precision)));
    const chartLabels = dimension.map((v) => v.label);

    return (
        <Stack direction={"row"} gap={4} px={4} py={3.5} sx={{ backgroundColor: "white" }} borderRadius={2}>
            <Stack flex={3} divider={<Divider />} gap={3}>
                <StatsRow
                    percentComplete={totalCompletion}
                    questionnaireCount={1}
                    icon="products"
                    title="Produit"
                    to="../product"
                />
                <StatsRow
                    percentComplete={totalIngredientCompletion}
                    questionnaireCount={product?.productIngredients?.length ?? 0}
                    icon="ingredients"
                    title="Ingrédients"
                    to="../ingredients"
                />
            </Stack>
            <Box flex={1}>
                <ScoreCard header={scoreCardHeader} disableFooter>
                    <DoughnutChart
                        legend={{ position: "bottom" }}
                        values={chartValues}
                        labels={chartLabels}
                        colors={["#9de1f3", "#ff9343"]}
                    >
                        <Stack>
                            <Typography variant={"h1"} fontWeight={800}>
                                {doesScoreExist(score) ? Number(score.score?.toFixed(precision)) : null}
                            </Typography>
                            <Typography variant="body2" sx={{ color: (theme) => theme.custom.grey[1400] }}>
                                {doesScoreExist(score) ? score.unit : ""}
                            </Typography>
                        </Stack>
                    </DoughnutChart>
                </ScoreCard>
            </Box>
        </Stack>
    );
};

type StatsRowProps = {
    icon: keyof IconMapping;
    title: string;
    percentComplete: number;
    questionnaireCount: number;
    to: string;
};
const StatsRow = (props: StatsRowProps) => {
    const { icon, percentComplete, questionnaireCount, title, to } = props;
    return (
        <Stack direction={"row"} alignItems={"center"} gap={8} justifyContent={"space-between"}>
            <Stack flex={1} direction={"row"} alignItems={"center"} gap={1.5}>
                <Stack
                    sx={{ backgroundColor: (theme) => theme.custom.grey[400], borderRadius: "28px", padding: "8px" }}
                >
                    <FoodsIcon icon={icon} size={3} />
                </Stack>
                <Typography variant="h5">{title}</Typography>
            </Stack>
            <Box flex={1}>
                <Typography variant="body" sx={{ textTransform: "lowercase", textWrap: "nowrap" }}>
                    {/* TODO: translation w/ count argument */}
                    {questionnaireCount} {t("Questionnaire")}
                    {questionnaireCount > 1 ? "s" : ""}
                </Typography>
            </Box>
            <Box flexBasis={"450px"}>
                <LinearProgress value={percentComplete} />
            </Box>
            <Stack flex={1} direction={"row"} justifyContent={"end"}>
                <Stack
                    sx={{
                        backgroundColor: (theme) => theme.custom.grey[400],
                        borderRadius: "99px",
                        width: "38px",
                        height: "38px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <NavLink to={to} style={{ marginTop: "5px", color: "inherit" }}>
                        <FoodsIcon icon="arrowRight" size={3} />
                    </NavLink>
                </Stack>
            </Stack>
        </Stack>
    );
};
