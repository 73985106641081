import { Post, PostElement } from "src/api/client-api/post/postSchema";
import { SsqPropertyAction } from "src/api/sure-api/actions/useGetAllActions";

export type ActionPropertiesWithAnswers = {
    property: SsqPropertyAction;
    previousElement: PostElement | null;
    currentElement: PostElement | null;
    targetElement: PostElement | null;
};
type SsqPropertyId = number;
export type ActionPropertiesWithAnswersMapping = Partial<Record<SsqPropertyId, ActionPropertiesWithAnswers>>;

export class ActionService {
    static matchByPropertyId(
        ssqProperties: SsqPropertyAction[],
        previousElements: PostElement[],
        currentElements?: PostElement[],
        targetElements?: PostElement[],
    ): ActionPropertiesWithAnswersMapping {
        const acc: ActionPropertiesWithAnswersMapping = {};
        const propertiesWithElements = ssqProperties.reduce((acc, ssqProperty) => {
            if (ssqProperty.field?.type === "group") {
                ssqProperty.children?.map((subProperty) => {
                    const elementsForSubproperty = this.fetchElementForProperty(
                        subProperty,
                        previousElements,
                        currentElements,
                        targetElements,
                    );
                    acc[subProperty.id] = elementsForSubproperty;
                });
            } else {
                const elementsForProperty = this.fetchElementForProperty(
                    ssqProperty,
                    previousElements,
                    currentElements,
                    targetElements,
                );
                acc[ssqProperty.id] = elementsForProperty;
            }

            return acc;
        }, acc);

        return propertiesWithElements;
    }

    private static fetchElementForProperty(
        ssqProperty: SsqPropertyAction,
        previousElements: PostElement[],
        currentElements?: PostElement[],
        targetElements?: PostElement[],
    ): ActionPropertiesWithAnswers {
        // Keep first
        const foundElement = previousElements.find((e) => e.ssqPropertyId === ssqProperty.id);
        const currentElement = currentElements?.find((e) => e.ssqPropertyId === ssqProperty.id);
        const targetElement = targetElements?.find((e) => e.ssqPropertyId === ssqProperty.id);

        return {
            property: ssqProperty,
            previousElement: foundElement ?? null,
            currentElement: currentElement ?? null,
            targetElement: targetElement ?? null,
        };
    }
}
