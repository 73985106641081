import { ReactNode, useEffect, useState } from "react";
import { useGetSettings } from "src/api/client-api/settings/useSettings";
import { ScoreType, useGetAllScoreType } from "src/api/client-api/scoreType/useGetAllScoreType";
import { useGetAllScore } from "src/api/sure-api/score/useGetAllScore";
import {
    COMPANY,
    CREATE,
    DELETE,
    FoodpilotContext,
    FoodpilotContextInitialValues,
    INGREDIENT,
    LIST,
    MODIFY,
    PRODUCT,
    VIEW,
} from "./FoodpilotContext";
import { Campaign, useGetAllCampaigns } from "src/api/client-api/useCampaign";
import { useSelectCompany } from "./useSelectCompany";
import { Score } from "src/types";

import { useUserNew } from "src/api/client-api/useUserNew";

const currentCampaignKey = "currentCampaignId";

type ProtectedContextProviderProps = {
    children: ReactNode;
};

const SLUGS = [LIST, VIEW, MODIFY, DELETE, CREATE] as const;
export type Slugs = (typeof SLUGS)[number];

const ENTITIES = [PRODUCT, INGREDIENT, COMPANY];
export type Entities = (typeof ENTITIES)[number];

export const ProtectedContextProvider = (props: ProtectedContextProviderProps) => {
    const { children } = props;
    const [currentCampaign, _setCurrentCampaign] = useState<Campaign | null>(null);
    const [selectedCompanyVariations, setSelectedCompanyVariations] = useState<Record<number, number>>({});

    const { user, isRefetching: areCompaniesRefetching } = useUserNew({
        campaign: currentCampaign?.id,
        variations: selectedCompanyVariations,
    });

    const companies = user?.companies ?? null;
    const { selectedCompany } = useSelectCompany(companies);
    const { settings } = useGetSettings();
    const { scoreTypes } = useGetAllScoreType();
    const { scores } = useGetAllScore();
    const { campaigns } = useGetAllCampaigns({ orderByDate: true });

    // The first one is good enough for our use-case;
    // We're not supposed to have multiple ones;
    const targetCampaign = campaigns.find((c) => c.isTarget === true);

    const setCurrentCampaign = (campaign: Campaign | null) => {
        _setCurrentCampaign(campaign);

        if (campaign !== null) {
            localStorage.setItem(currentCampaignKey, campaign.id.toString());
        } else {
            localStorage.removeItem(currentCampaignKey);
        }
    };
    const canUser = (slug: Slugs, entity: Entities) => {
        if (user === undefined) {
            return false;
        }

        const userRoles = user.roles;
        const userPermissions = user.permissions;

        if (userRoles.includes("ROLE_ADMIN")) {
            return true;
        }

        const target = [slug, entity];

        return userPermissions.some((permission) => permission.every((element, index) => element === target[index]));
    };

    const isUserOfTypeSupplier = () => {
        const companyType = selectedCompany ? selectedCompany.type : null;

        if (!companyType) return false;

        if (["provider", "supplier"].includes(companyType)) return true;

        return false;
    };

    const getScoresByType = (type: ScoreType["type"]): Score[] => {
        const scoreType = scoreTypes?.find((scoreType: ScoreType) => scoreType.type === type);
        const scoreIds = scoreType?.scoreIds ?? [];

        if (!scoreType || !scores) return [];

        scoreIds.sort((a, b) => a - b);

        return scores.filter((score: Score) => {
            return scoreIds.indexOf(score.id) > -1;
        });
    };

    useEffect(() => {
        if (campaigns === undefined || campaigns.length === 0) return;
        const storedCampaignRaw = localStorage.getItem(currentCampaignKey);
        const storedCampaignId = storedCampaignRaw ? parseInt(storedCampaignRaw) : null;

        const foundCampaign = campaigns.find((campaign) => {
            return campaign.id === storedCampaignId;
        });
        if (foundCampaign === undefined) {
            const defaultCampaign = campaigns.find((c) => c.active) ?? [...campaigns].reverse()[0];

            setCurrentCampaign(defaultCampaign);
        } else {
            setCurrentCampaign(foundCampaign);
        }
        if (storedCampaignId !== null) {
            // Here, we found a campaign stored, that has probably been deleted;
        }
    }, [campaigns]);

    if (user === undefined || selectedCompany === null) {
        // Wait before going into App.
        return;
    }
    const items: FoodpilotContextInitialValues = {
        user: {
            trustedUser: user,
        },
        canUser,
        settings,
        scoreTypes,
        scores,
        campaigns,
        targetCampaign,
        currentCampaign,
        setCurrentCampaign,
        selectedCompany,
        selectedCompanyVariations,
        setSelectedCompanyVariations,
        areCompaniesRefetching,
        isUserOfTypeSupplier,
        getScoresByType,
    };

    return <FoodpilotContext.Provider value={items}>{children}</FoodpilotContext.Provider>;
};
