import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { z } from "zod";

export const getAllScoreTypeKey = "getAllScoreType" as const;

export const useGetAllScoreType = () => {
    const query = useQuery({
        queryKey: [getAllScoreTypeKey],
        cacheTime: 0,
        queryFn: async () => {
            return await loggedInApiCall.get(`/api/score_types`, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        select: (response) => {
            const scoreTypes = response.data;
            const data = zodParseWithLog(scoreTypes, ScoreTypeSchema.array(), getAllScoreTypeKey);
            return data;
        },
    });

    return {
        scoreTypes: query.data,
        ...query,
    };
};

const ScoreTypeSchema = z.object({
    type: z.union([z.literal("product"), z.literal("ingredient"), z.literal("company")]),
    scoreIds: z.number().array().optional(),
});

export type ScoreType = z.infer<typeof ScoreTypeSchema>;
