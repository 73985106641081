import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { useMaterialReference } from "src/api/sure-api/usePackagingMaterialSet";
import { usePackagingReference } from "src/api/sure-api/usePackagingReference";

type PPackaging = {
    packagingFormId: number;
    packagingMaterials: {
        packagingMaterialSetId: number;
        weight?: number | null;
        proportion?: number | null;
    }[];
};

type ProductPackagingProps = {
    productPackagings: PPackaging[];
};
export const ProductPackagingsSummary = (props: ProductPackagingProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { productPackagings } = props;

    const { packagingsReference } = usePackagingReference();
    const { materialsReference } = useMaterialReference();

    if (productPackagings.length === 0) {
        return (
            <Typography
                variant="h3"
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {t("Pas d'emballage associé à ce produit")}
            </Typography>
        );
    }

    const noValue = "-";
    return (
        <>
            <Typography variant="h4">{t("Liste des emballages du produit")}</Typography>
            <Box
                component="table"
                sx={{
                    display: "table",
                    width: "100%",
                    "&>tr": {
                        display: "flex",
                    },
                    "&>tr>td": {
                        flex: 1,
                        borderBottom: `1px solid ${theme.custom.grey[500]}`,
                        padding: "16px 0px",
                    },
                    "&>tr>th": {
                        flex: 1,
                        borderBottom: `1px solid ${theme.custom.grey[500]}`,
                        padding: "16px 0px",
                        textAlign: "left",
                    },
                }}
            >
                <tr>
                    <th style={{ minWidth: "340px" }}>
                        <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                            {t("Type d'emballage")}
                        </Typography>
                    </th>
                    <th>
                        <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                            {t("Matériaux")}
                        </Typography>
                    </th>
                    <th>
                        <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                            {t("Poids")}
                        </Typography>
                    </th>
                    <th>
                        <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                            {t("Part de matériau recyclé")}
                        </Typography>
                    </th>
                </tr>
                {productPackagings.map((p, iPack) => {
                    const packagingType = packagingsReference.find((form) => form.id === p.packagingFormId);

                    const emptyPackagingData = {
                        packagingMaterialSetId: -1,
                        weight: "-",
                        proportion: "-",
                    };
                    const materials = p.packagingMaterials.length > 0 ? p.packagingMaterials : [emptyPackagingData];
                    return (
                        <Fragment key={`summary-${iPack}`}>
                            {materials.map((packMat, iPMat) => {
                                const matRef = materialsReference.find(
                                    (set) => set.id === packMat.packagingMaterialSetId,
                                );
                                return (
                                    <tr key={`sub-summary-${iPack}-${iPMat}`}>
                                        <td style={{ minWidth: "340px", width: "fit-content" }}>
                                            <Typography variant="big-bold">{packagingType?.name ?? noValue}</Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body-medium">{matRef?.name ?? noValue}</Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body-medium">{packMat.weight ?? noValue}</Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body-medium">
                                                {packMat.proportion ?? noValue}
                                            </Typography>
                                        </td>
                                    </tr>
                                );
                            })}
                        </Fragment>
                    );
                })}
            </Box>
        </>
    );
};
