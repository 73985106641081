import { TypographyList } from "@foodpilot/foods";
import { Typography, Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

type IngredientSupplier = {
    supplier: {
        name?: string | null;
        clientId?: string | null;
        sectors?: string[] | null;
    };
};

type SupplierSection = {
    ingredientSuppliers?: IngredientSupplier[];
};
export const SupplierSummary = (props: SupplierSection) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { ingredientSuppliers = [] } = props;

    if (ingredientSuppliers.length === 0) {
        return (
            <Typography
                variant="h3"
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {t("Pas de fournisseurs associé à cet ingrédient")}
            </Typography>
        );
    }

    const noValue = "-";
    return (
        <Box
            component="table"
            sx={{
                width: "100%",
                display: "table",
                "&>tr>td": {
                    minWidth: "180px",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                    padding: "16px 0px",
                },
                "&>tr>th": {
                    minWidth: "180px",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                    padding: "16px 0px",
                    textAlign: "left",
                },
            }}
        >
            <tr>
                <th>
                    <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                        {t("Nom")}
                    </Typography>
                </th>
                <th>
                    <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                        {t("Code fournisseur")}
                    </Typography>
                </th>
                <th>
                    <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                        {t("Filière")}
                    </Typography>
                </th>
            </tr>
            {ingredientSuppliers?.map((ingredientSupp, index) => (
                <tr key={index}>
                    <td>
                        <Typography variant="body-bold">{ingredientSupp.supplier.name ?? noValue}</Typography>
                    </td>
                    <td>
                        <Typography variant="body-medium">{ingredientSupp.supplier.clientId ?? noValue}</Typography>
                    </td>
                    <td>
                        <TypographyList
                            variant="body-medium"
                            value={ingredientSupp.supplier.sectors ?? [noValue]}
                            maxLength={30}
                        />
                    </td>
                </tr>
            ))}
        </Box>
    );
};
