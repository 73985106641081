import { Score } from "src/types";
import { ScoreType } from "src/api/client-api/scoreType/useGetAllScoreType";

// If you want to getScoreIdsByType and then getScoresByIds it's simpler to use FoodpilotContext.getScoresByType
export const getScoresByIds = (ids: number[], scores: Score[] | undefined): Score[] => {
    if (!scores) {
        return [];
    }

    return scores.flatMap((score: Score) => {
        if (ids.indexOf(score.id) > -1) {
            return score;
        }

        return [];
    });
};

export const getScoreIdsByType = (type: ScoreType["type"], scoreTypes?: ScoreType[]): number[] => {
    const scoreType = scoreTypes?.find((scoreType: ScoreType) => scoreType.type === type);

    return scoreType?.scoreIds ?? [];
};
