import z from "zod";
import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { MainScores, MainScoresSchema } from "src/api/client-api/post/scoreSchema";
import { mrtSortingStateToObject, SortType } from "src/api/apiHelpers";

type Options = {
    cacheTime?: number;
    page?: number;
    itemsPerPage?: number;
    search?: string;
    ingredientLine?: string;
    campaignId?: number;
    sorting?: Array<SortType>;
};

export const ingredientGridKey = "allIngredientsGrid" as const;

export const useGetAllIngredient = (options?: Options) => {
    const {
        cacheTime = 0,
        page = 1,
        itemsPerPage = 20,
        search = "",
        ingredientLine = "",
        sorting = [],
        campaignId,
    } = options || {};

    const query = useQuery({
        queryKey: [ingredientGridKey, page, itemsPerPage, search, ingredientLine, campaignId, sorting],
        staleTime: 0,
        cacheTime: cacheTime,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const url = "/api/ingredients";

            return await loggedInApiCall.get(url, {
                params: {
                    itemsPerPage: itemsPerPage,
                    page: page,
                    q: search !== "" ? search : undefined,
                    campaign: campaignId,
                    "ingredientLine.name": ingredientLine !== "" ? ingredientLine : undefined,
                    order: mrtSortingStateToObject(sorting),
                },
            });
        },
        select: (request) => {
            const totalItems = request.data["hydra:totalItems"];
            const ingredient = request.data["hydra:member"];
            const data = zodParseWithLog(ingredient, IngredientListSchema.array(), "allIngredients");
            return {
                items: data,
                totalItems: totalItems,
            };
        },
    });

    return {
        ingredients: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};

type Domain = {
    "@id": IRI;
    name: string;
};
const DomainSchema: z.ZodSchema<Domain> = z.object({
    "@id": z.string(),
    name: z.string(),
});

type IngredientLine = {
    "@id": IRI;
    id: number;
    name: string;
};
const IngredientLineZodSchema: z.ZodSchema<IngredientLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

type SupplierFromIngredientSupplier = {
    id: number;
    name: string;
};
const SupplierFromIngredientSupplierSchema: z.ZodSchema<SupplierFromIngredientSupplier> = z.object({
    id: z.number(),
    name: z.string(),
});

export type IngredientSupplier = {
    "@id": IRI;
    id: number;
    supplier: SupplierFromIngredientSupplier;
    quantity?: number;
    unit?: string;
};
const IngredientSupplierSchema: z.ZodSchema<IngredientSupplier> = z.object({
    "@id": z.string(),
    id: z.number(),
    supplier: SupplierFromIngredientSupplierSchema,
    quantity: z.number().optional(),
    unit: z.string().optional(),
});

export type Campaign = {
    "@id": string;
};

export const CampaignSchema: z.ZodSchema<Campaign> = z.object({
    "@id": z.string(),
});

export type PostGrid = {
    id: number;
    scores: MainScores;
    campaign?: Campaign;
};

export const PostGridSchema: z.ZodSchema<PostGrid> = z.object({
    id: z.number(),
    scores: MainScoresSchema,
    campaign: CampaignSchema.optional(),
});

export type IngredientList = {
    "@id": IRI;
    id: number;
    ingredientLine?: IngredientLine;
    ingredientSuppliers?: IngredientSupplier[];
    name: string;
    externalId?: number;
    ingredientReferenceId?: number;
    clientId?: string;
    organic?: boolean;
    canView: boolean;
    canModify: boolean;
    canDelete: boolean;
    scores: MainScores;
};

const IngredientListSchema: z.ZodSchema<IngredientList> = z.object({
    "@id": z.string(),
    id: z.number(),
    clientId: z.string().optional(),
    domains: DomainSchema.array().optional(),
    externalId: z.number().optional(),
    ingredientSuppliers: IngredientSupplierSchema.array().optional(),
    ingredientLine: IngredientLineZodSchema.optional(),
    ingredientReferenceId: z.number().optional(),
    name: z.string(),
    organic: z.boolean().optional(),
    canView: z.boolean(),
    canModify: z.boolean(),
    canDelete: z.boolean(),
    scores: MainScoresSchema,
});
