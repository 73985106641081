import { Resources } from "src/i18n";
import { LocalStorage } from "./LocalStorage";

export class LanguageService {
    static getCurrentLanguage(resources: Resources, fallbackLng: string): string {
        const selectedLanguage = LocalStorage.getItem("language");

        if (selectedLanguage === null) return fallbackLng;

        const existingLanguages = Object.keys(resources);
        if (existingLanguages.includes(selectedLanguage)) {
            // The saved language actually exist;
            return selectedLanguage;
        }

        return fallbackLng;
    }
}
