import { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridActionsCellItem } from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import { Loading } from "src/components/utils/Loading";
import { useSnackbar } from "src/utils/useSnackbar";
import { useBrandDelete } from "src/api/client-api/brand/useDeleteBrand";
import { useGetAllBrands } from "src/api/client-api/brand/useGetAllBrand";
import { getGridStyle } from "src/utils/commonStyles.ts";
import { URLHandler } from "src/services/URLHandler";

export const BrandList = () => {
    const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
        page: 0,
        pageSize: 10,
    });

    const { allBrands, totalItems, isLoading } = useGetAllBrands({
        itemsPerPage: paginationModel.pageSize,
        // API Plateforme pagination begin at 1, MUI Grid pagination at 0
        page: paginationModel.page + 1,
    });

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const deleteBrand = useBrandDelete();

    const [displayDialog, setDisplayDialog] = useState(false);
    const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

    const handlePaginationModelChange = (paginationModel: { page: number; pageSize: number }) => {
        setPaginationModel(paginationModel);
    };

    const handleDeleteClick = (id: number | string) => () => {
        if (typeof id === "string") return;

        setIdToDelete(id);
        setDisplayDialog(true);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Nom",
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
        },
        {
            field: "company",
            headerName: "Entreprise",
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 200,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <IconButton component={RouterLink} to={URLHandler.settings.brands.edit(Number(id))}>
                                <EditIcon />
                            </IconButton>
                        }
                        label="Edit"
                        className="textPrimary"
                        color="inherit"
                        key={id}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id.valueOf())}
                        color="inherit"
                        key={id}
                    />,
                ];
            },
        },
    ];

    const EditToolbar = () => {
        const handleClick = () => {
            navigate(URLHandler.settings.brands.create());
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {"Ajouter une Brand"}
                </Button>
            </GridToolbarContainer>
        );
    };

    if (allBrands === undefined) return <Loading />;

    const rows = allBrands.map((brand) => {
        return {
            id: brand.id,
            name: brand.name,
            company: brand.company.name,
        };
    });

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {"Brand"}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>Êtes-vous sûr ?</DialogTitle>
                <DialogContent dividers>
                    {t("Cliquez sur 'oui' pour supprimer définitivement l'entreprise.")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={() => {
                            if (idToDelete) {
                                deleteBrand.mutate(idToDelete, {
                                    onError: () => {
                                        snackbar.setSnackbarProps({
                                            message: "Unknown error...",
                                            type: "error",
                                        });
                                    },
                                });
                            }
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={rows}
                columns={columns}
                loading={isLoading}
                editMode="row"
                isRowSelectable={() => false}
                onPaginationModelChange={handlePaginationModelChange}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={totalItems}
                slots={{
                    toolbar: EditToolbar,
                }}
                sx={getGridStyle(rows.length ?? 0)}
            />
        </Container>
    );
};
