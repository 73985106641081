import z from "zod";
import { useQuery } from "react-query";
import { sureApiCall } from "../axiosClient/sureApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "../apiErrorLogs";

export const usePackagingReference = () => {
    const query = useQuery({
        cacheTime: 0,
        staleTime: 0,
        queryKey: "allPackagingForms",
        queryFn: async () => {
            return await sureApiCall.get("/api/packaging_forms");
        },
        select: (request) => {
            const packagings = request.data["hydra:member"];
            const data = zodParseWithLog(packagings, PackagingFormSchema.array(), "allPackagingRef");
            return data;
        },
    });

    return {
        packagingsReference: query.data ?? [],
        ...query,
    };
};

export type PackagingReference = {
    "@id": IRI;
    id: number;
    name: string;
};

export const PackagingFormSchema: z.ZodSchema<PackagingReference> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});
