import { ArticleProps, ArticlesBox } from "@foodpilot/foods";
import { CMSContentBlock } from "src/api/client-api/cms/useGetOneContent";

type CMSArticlesProps = {
    blocks: CMSContentBlock[];
};

const blockToArticle = (blocks: CMSContentBlock[]): ArticleProps[] => {
    const blocksForArticles = blocks.map((block, idx) => {
        const isFirst = idx === 0;

        const articleBlock: ArticleProps = {
            title: block.title,
            description: block.description ?? "",
            imageSrc: block.media?.url ?? "",
            onClick: () => {
                alert(`Go to ${block.ctaLink}`);
            },
            isFirst: isFirst,
        };

        return articleBlock;
    });

    return blocksForArticles;
};

export const CMSArticles = (props: CMSArticlesProps) => {
    const articleBlocks = blockToArticle(props.blocks);

    return <ArticlesBox articles={articleBlocks} />;
};
