import { DimensionQuery, DimensionState, InitDimensions, useDimensions } from "./useDimensions";
import { InitVariations, useVariations, VariationQuery, VariationState } from "./useVariations";

export type Campaign = string | number;

export type EntityBasedScoreParams = {
    campaign?: Campaign;

    initDimensions?: InitDimensions;
    initVariations?: InitVariations;
};

export type ScoreState = VariationState & DimensionState;
export type ScoreQuery = DimensionQuery &
    VariationQuery & {
        campaign: Campaign | null;
    };

export type EntityBasedScoreArgs = {
    query: ScoreQuery;
    state: ScoreState;
};

export const useEntityBasedScoreParams = (initOptions: EntityBasedScoreParams): EntityBasedScoreArgs => {
    const { campaign = null, initDimensions, initVariations } = initOptions;

    const dimensions = useDimensions(initDimensions);
    const variations = useVariations(initVariations);

    return {
        query: {
            campaign,
            dimensions: dimensions.query.dimensions,
            variations: variations.query.variations,
        },
        state: {
            ...dimensions.state,
            ...variations.state,
        },
    };
};
