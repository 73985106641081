import { useCallback, useState, useRef } from "react";
import useLocalStorageState from "use-local-storage-state";

const OAUTH_STATE_KEY = "react-use-oauth2-state-key";
const POPUP_HEIGHT = 700;
const POPUP_WIDTH = 600;
const OAUTH_RESPONSE = "react-use-oauth2-response";

// https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50
const generateState = () => {
    const validChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let array: any = new Uint8Array(40);
    window.crypto.getRandomValues(array);
    array = array.map((x: any) => validChars.codePointAt(x % validChars.length));
    const randomState = String.fromCharCode.apply(null, array);
    return randomState;
};

const saveState = (state: any) => {
    sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

const removeState = () => {
    sessionStorage.removeItem(OAUTH_STATE_KEY);
};

const openPopup = (url: string) => {
    // To fix issues with window.screen in multi-monitor setups, the easier option is to
    // center the pop-up over the parent window.
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
    return window.open(url, "OAuth2 Popup", `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`);
};

const closePopup = (popupRef: any) => {
    popupRef.current?.close();
};

const cleanup = (intervalRef: any, popupRef: any, handleMessageListener: any) => {
    clearInterval(intervalRef.current);
    closePopup(popupRef);
    removeState();
    window.removeEventListener("message", handleMessageListener);
};

const enhanceAuthorizeUrl = (
    authorizeUrl: string,
    clientId: string,
    redirectUri: string,
    scope: string,
    state: string,
) => {
    return `${authorizeUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
};

const objectToQuery = (object: any) => {
    return new URLSearchParams(object).toString();
};

const formatExchangeCodeForTokenServerURL = (
    serverUrl: string,
    clientId: string,
    code: string,
    redirectUri: string,
    state: string,
) => {
    return `${serverUrl}?${objectToQuery({
        client_id: clientId,
        code,
        redirect_uri: redirectUri,
        state,
    })}`;
};

export const useOAuth2 = (props: any) => {
    const { authorizeUrl, clientId, redirectUri, scope = "", responseType, onSuccess, onError } = props;

    const popupRef: any = useRef();
    const intervalRef = useRef<string | number | NodeJS.Timeout | undefined>();
    const [{ loading, error }, setUI] = useState<{
        loading: boolean;
        error: string | null;
    }>({ loading: false, error: null });
    const [data, setData, { removeItem, isPersistent }] = useLocalStorageState<any>(
        `${responseType}-${authorizeUrl}-${clientId}-${scope}`,
        {
            defaultValue: null,
        },
    );

    const exchangeCodeForTokenServerURL = responseType === "code" && props.exchangeCodeForTokenServerURL;
    const exchangeCodeForTokenMethod = responseType === "code" && props.exchangeCodeForTokenMethod;

    const getAuth = useCallback(() => {
        // 1. Init
        setUI({
            loading: true,
            error: null,
        });

        // 2. Generate and save state
        const state = generateState();
        console.log("state", state);

        saveState(state);

        // 3. Open popup
        popupRef.current = openPopup(enhanceAuthorizeUrl(authorizeUrl, clientId, redirectUri, scope, state));

        // 4. Register message listener
        async function handleMessageListener(message: MessageEvent<any>) {
            const type = message?.data?.type;
            if (type !== OAUTH_RESPONSE) {
                return;
            }
            try {
                if ("error" in message.data) {
                    const errorMessage = message.data?.error || "Unknown Error occured.";
                    setUI({
                        loading: false,
                        error: errorMessage,
                    });
                    if (onError) await onError(errorMessage);
                } else {
                    let payload = message?.data?.payload;
                    const company = payload?.company;
                    const company_corporate_name = payload?.company_corporate_name;
                    const company_identification_number = payload?.company_identification_number;
                    if (responseType === "code" && exchangeCodeForTokenServerURL) {
                        const response = await fetch(
                            formatExchangeCodeForTokenServerURL(
                                exchangeCodeForTokenServerURL,
                                clientId,
                                payload?.code,
                                redirectUri,
                                state,
                            ),
                            {
                                method: exchangeCodeForTokenMethod || "POST",
                            },
                        );
                        payload = await response.json();
                        const payloadJson = {
                            ...payload,
                            company: company,
                            company_corporate_name,
                            company_identification_number,
                        };
                        payload = JSON.stringify(payloadJson);
                    }
                    setUI({
                        loading: false,
                        error: null,
                    });
                    setData(payload);
                    if (onSuccess) {
                        await onSuccess(payload);
                    }
                }
            } catch (genericError: any) {
                console.error(genericError);
                setUI({
                    loading: false,
                    error: genericError.toString(),
                });
            } finally {
                // Clear stuff ...
                cleanup(intervalRef, popupRef, handleMessageListener);
            }
        }
        window.addEventListener("message", handleMessageListener);

        // 4. Begin interval to check if popup was closed forcefully by the user
        intervalRef.current = setInterval(() => {
            const popupClosed = !popupRef.current || !popupRef.current.window || popupRef.current.window.closed;
            if (popupClosed) {
                // Popup was closed before completing auth...
                setUI((ui) => ({
                    ...ui,
                    loading: false,
                }));
                console.warn("Warning: Popup was closed before completing authentication.");
                clearInterval(intervalRef.current);
                removeState();
                window.removeEventListener("message", handleMessageListener);
            }
        }, 250);

        // Remove listener(s) on unmount
        return () => {
            window.removeEventListener("message", handleMessageListener);
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [
        authorizeUrl,
        clientId,
        redirectUri,
        scope,
        responseType,
        exchangeCodeForTokenServerURL,
        exchangeCodeForTokenMethod,
        onSuccess,
        onError,
        setUI,
        setData,
    ]);

    const logout = useCallback(() => {
        removeItem();
        setUI({ loading: false, error: null });
    }, [removeItem]);

    return { data, loading, error, getAuth, logout, isPersistent };
};
