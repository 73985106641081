import { ReactElement, ReactNode } from "react";

type WrapIfProps = {
    condition: boolean;
    wrapper: (children: ReactElement) => ReactNode;
    children: ReactElement;
};
export const WrapIf = (props: WrapIfProps) => {
    const { condition, wrapper, children } = props;

    if (condition) {
        return wrapper(children);
    }

    return children;
};
