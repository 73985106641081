import { useParams } from "react-router-dom";
import { Post } from "src/api/client-api/post/postSchema";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { FoodsPage } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QuestionnaireUpdateFormBase } from "./QuestionnaireUpdateFormBase";
import { getVisibleHeadings } from "src/utils/ssq";
import { Company } from "src/api/client-api/useUserNew";
import { useQuestionnaireUpdatePages } from "src/components/QuestionnaireUpdate/useQuestionnaireUpdatePages";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { formatDateCampaign } from "src/utils/campaigns";
import { getScoreIdsByType } from "src/utils/scores";
import { URLHandler } from "src/services/URLHandler";

type QuestionnaireUpdateFormSsqPostProps = {
    company: Company;
    ssq: Ssq;
    post?: Post;
    isPostLoading: boolean;
    changePost: (callback: (post: Post) => void) => void;
};
export const QuestionnaireUpdateFormSsqPost = (props: QuestionnaireUpdateFormSsqPostProps) => {
    const { headingId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { scoreTypes, scores, currentCampaign, selectedCompany } = useFoodpilotContext();
    const { company, ssq, post, isPostLoading, changePost } = props;

    const headings: Heading[] = getVisibleHeadings(ssq);
    const companyScoresIds = getScoreIdsByType("company", scoreTypes);

    const formattedSsqTitle =
        currentCampaign ?
            ssq.title.concat(" - ").concat(formatDateCampaign(currentCampaign.startDate, currentCampaign.endDate, "/"))
        :   ssq.title;

    const pages: FoodsPage[] = useQuestionnaireUpdatePages({
        headings,
        post,
        isPostLoading,
        scores,
        scoresIds: companyScoresIds,
        changePost,
    });

    const headingsIds: number[] = headings.map((heading) => Number(heading.id));
    const headingIndex = headingsIds.findIndex((id) => id === Number(headingId));

    if (headingIndex < 0) return t("Thématique non trouvée dans le questionnaire");
    if (selectedCompany === null) return;

    const ssqUrl = URLHandler.company.ssq(selectedCompany.id);
    return (
        <QuestionnaireUpdateFormBase
            company={company}
            title={formattedSsqTitle}
            pages={pages}
            startingPage={headingIndex ?? 0}
            onPageChange={(newPageIndex: number, previousPageIndex: number) => {
                if (!newPageIndex && !previousPageIndex) {
                    navigate(ssqUrl);
                    return;
                }

                const headingId = headingsIds[newPageIndex];
                const headingUrl = URLHandler.company.ssqHeading(selectedCompany.id, headingId);
                navigate(headingUrl);
            }}
            onValidate={() => navigate(ssqUrl)}
            onClose={() => navigate(ssqUrl)}
        />
    );
};
