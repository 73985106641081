import { ReactElement, useEffect, useState } from "react";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridRowModesModel,
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowId,
    GridRowModel,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { ProductLineForm, useGetAllProductLines } from "src/api/client-api/productLines/useProductLines";
import { Loading } from "src/components/utils/Loading";
import { useCreateProductLine } from "src/api/client-api/productLines/useCreateProductLine";
import { useDeleteProductLine } from "src/api/client-api/productLines/useDeleteProductLine";
import { ProductLineService } from "./Services/ProductLineService";

export const ProductLineList = (): ReactElement => {
    const { t } = useTranslation();

    const { productLines, isLoading } = useGetAllProductLines();

    const [productLinesForm, setProductLinesForm] = useState<ProductLineForm[] | undefined>(undefined);

    useEffect(() => {
        if (!productLinesForm) return;
        const initRows: ProductLineRow[] = [];
        productLinesForm.forEach((productLine, plineIndex) => {
            initRows.push({
                id: productLine.id,
                name: productLine.name,
                col3: { plineIndex },
            });
        });

        setRows(initRows);
    }, [productLinesForm]);

    useEffect(() => {
        if (!productLines) return;

        setProductLinesForm(ProductLineService.getProductLinesToProductLineForms(productLines));
    }, [productLines]);

    const createProductLine = useCreateProductLine();
    const deleteProductLine = useDeleteProductLine();

    const [rows, setRows] = useState<GridRowsProp>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [displayDialog, setDisplayDialog] = useState(false);
    const [displayDialogNo, setDisplayDialogNo] = useState(false);
    const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

    type ProductLineRow = { id: number | undefined; name: string; col3: { plineIndex: number } };

    useEffect(() => {
        if (!productLinesForm || !isLoading) return;
    }, [productLinesForm, isLoading]);

    if (isLoading) return <Loading />;

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: number | string) => () => {
        if (typeof id === "string") return;

        if (!productLinesForm) return;

        setIdToDelete(id);
        const productLine = productLinesForm.find((productLine) => productLine.id === id);

        if (productLine && productLine.products && productLine.products.length > 0) {
            setDisplayDialogNo(true);
        } else {
            setDisplayDialog(true);
        }
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        const currentProductLine: ProductLineForm | undefined = productLinesForm?.find(
            (productLine) => productLine.id === newRow.id,
        );

        const newProductLine: ProductLineForm = { ...currentProductLine, name: newRow.name };

        const productLineWrite = ProductLineService.getProductLineToProductLineWrite(newProductLine);

        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

        createProductLine.mutate({ productLine: productLineWrite });

        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const renderNoDialog = () => {
        return (
            <Dialog maxWidth="xs" open={displayDialogNo}>
                <DialogTitle>Suppression impossible</DialogTitle>
                <DialogContent dividers>
                    {t(
                        "Vous avez des produits associés à cette gamme. Veuillez les réassigner avant de supprimer la gamme.",
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialogNo(false);
                        }}
                    >
                        {t("Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderConfirmDialog = () => {
        return (
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>Êtes-vous sûr ?</DialogTitle>
                <DialogContent dividers>{t("settings.productLine.clickToRemoveProductLine")}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={async () => {
                            if (idToDelete) deleteProductLine.mutate(idToDelete);
                            setIdToDelete(undefined);
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: t("Nom de la gamme"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: true,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem key={id} icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />,
                        <GridActionsCellItem
                            key={id}
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key={id}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key={id}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id.valueOf())}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    type EditToolbarProps = {
        setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
        setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
    };

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const id = randomId();
            setRows((oldRows) => [...oldRows, { id, name: "", age: "", isNew: true }]);
            setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" } }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t("Ajouter une gamme")}
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("Gammes")}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            {renderConfirmDialog()}
            {renderNoDialog()}
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                sx={getGridStyle(productLines?.length ?? 0, rows.length ?? 0)}
            />
        </Container>
    );
};

const getGridStyle = (length: number, rowLength: number) => {
    return {
        border: "none",
        backgroundColor: "transparent",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            marginBottom: "2px",
            border: 0,
            backgroundColor: "transparent",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#797A77",
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        "& .MuiDataGrid-row.Mui-selected": {
            background: " #FFFFFF",
            border: 0,
        },
        "& .MuiDataGrid-cell": {
            border: 0,
            paddingLeft: "20px",
            outline: "none !important",
        },
        "& .MuiDataGrid-footerContainer": {
            display: "none",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: rowLength * 7,
            height: `${(length ?? 0) * 100}px !important`,
            boxSizing: "content-box",
        },
    };
};
