import { useNavigate, useRevalidator } from "react-router-dom";
import { Box, Container, Button, TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { Loading } from "src/components/utils/Loading";

import {
    useGetAllIngredientLines,
    IngredientLine,
} from "src/api/client-api/ingredientLines/useGetAllIngredientLine.ts";
import {
    IngredientLineWrite,
    IngredientLineWriteSchema,
    useCreateIngredientLine,
} from "src/api/client-api/ingredientLines/useCreateIngredientLine.ts";
import { URLHandler } from "src/services/URLHandler";

export type IngredientLineCreateProps = {
    existingIngredientLine?: IngredientLine;
};

export const IngredientLineCreate = (props: IngredientLineCreateProps) => {
    const { existingIngredientLine } = props;

    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const { t } = useTranslation();

    const { allIngredientLines } = useGetAllIngredientLines({
        itemsPerPage: 200, // Will need to be fixed in the future
    });
    const createIngredientLine = useCreateIngredientLine();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IngredientLineWrite>({
        mode: "onChange",
        resolver: zodResolver(IngredientLineWriteSchema),
    });

    const onSubmitHandler: SubmitHandler<IngredientLineWrite> = (values) => {
        createIngredientLine.mutate(
            { ingredientLine: values },
            {
                onSuccess: () => {
                    revalidator.revalidate();
                    navigate(URLHandler.settings.ingredientLine.list());
                },
                onError: (error) => {
                    console.error(error);
                },
            },
        );
    };

    if (allIngredientLines === undefined) return <Loading />;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <h1>{"Filière"}</h1>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="id"
                        control={control}
                        defaultValue={existingIngredientLine?.id}
                        render={({ field: { ref, ...field } }) => <input type="hidden" {...field}></input>}
                    />
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={existingIngredientLine?.name}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={"Nom"}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            minWidth: "400px",
                            flexDirection: "column",
                        }}
                    ></Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button sx={{ marginTop: "1rem", width: "10rem" }} variant="primary" type="submit">
                        {t("Enregistrer")}
                    </Button>
                </Box>
            </form>
        </Container>
    );
};
