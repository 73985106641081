import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { QuestionnaireUpdateFormSsqPost } from "./QuestionnaireUpdateFormSsqPost";
import { useGetProductPost } from "src/api/client-api/post/useGetProductPost";
import { Product } from "src/api/client-api/products/useGetOneProduct";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { ScoreProvider } from "src/context/ScoreContext";
import { Post } from "src/api/client-api/post/postSchema.ts";

type QuestionnaireUpdateFormSsqProps = {
    product: Product;
    ssq: Ssq;
};
export const QuestionnaireUpdateFormSsq = (props: QuestionnaireUpdateFormSsqProps) => {
    const { product, ssq } = props;

    const { currentCampaign, selectedCompany } = useFoodpilotContext();

    const companyId = selectedCompany ? selectedCompany.id : null;

    const { post, isLoading, refetch } = useGetProductPost(product.id, companyId, currentCampaign?.id);

    const refetchPost = (callback: (post: Post) => void) => {
        refetch().then((response) => {
            if (response.data) {
                callback(response.data);
            }
        });
    };

    return (
        <ScoreProvider type="product" formattedScores={post?.formattedScores}>
            <QuestionnaireUpdateFormSsqPost
                product={product}
                ssq={ssq}
                post={post}
                isPostLoading={isLoading}
                changePost={refetchPost}
            />
        </ScoreProvider>
    );
};
