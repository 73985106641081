import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { z } from "zod";

export const useGetAllSsq = () => {
    const query = useQuery({
        queryKey: ["getAllSsq"],
        cacheTime: 0,
        queryFn: async () => {
            return await sureApiCall.get(`/api/ssqs?itemsPerPage=200`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
        },
        select: (response) => {
            if (!response) {
                return;
            }

            const ssqs = response.data;
            const data = zodParseWithLog(ssqs, SsqListSchema, "getAllSsq");
            return data;
        },
    });

    return {
        ssqs: query.data,
        ...query,
    };
};

const BasePropertySchema = z.object({
    id: z.number(),
    hidden: z.boolean().optional(),
});

type Property = z.infer<typeof BasePropertySchema> & {
    children: Property[];
};

const PropertySchema: z.ZodType<Property> = BasePropertySchema.extend({
    children: z.lazy(() => PropertySchema.array()),
});

const BaseHeadingSchema = z.object({
    hidden: z.boolean().optional(),
    position: z.number(),
    level: z.number(),
    properties: PropertySchema.array(),
});

type Heading = z.infer<typeof BaseHeadingSchema> & {
    children: Heading[];
};

const HeadingSchema: z.ZodType<Heading> = BaseHeadingSchema.extend({
    children: z.lazy(() => HeadingSchema.array()),
});

export const SsqListSchema = z
    .object({
        id: z.number(),
        title: z.string(),
        headings: HeadingSchema.array(),
    })
    .array();

export type SsqList = z.infer<typeof SsqListSchema>;
