import { HeroBlock, FoodsIcon, RoundInput } from "@foodpilot/foods";
import { Stack, Typography, Box, Button, useTheme } from "@mui/material";
import { useState } from "react";
import { BlockWrite, useCreateOneBlock } from "src/api/client-api/cms/blocks/useCreateOneBlock";
import { MainContentLayout } from "src/components/Layout/MainContentLayout";
import { SelectMedia } from "../../../Common/SelectMedia";
import { EditLayout } from "../_Common/EditLayout";
import { CMSPageSection } from "src/api/client-api/cms/useGetOneContent";
import { EditHeader } from "../_Common/EditHeader";
import { ConfirmationFooter } from "../_Common/ConfirmationFooter";
import { useToggleSectionVisibility } from "src/api/client-api/cms/sections/useToggleSection";
import { useNavigate } from "react-router-dom";
import { URLHandler } from "src/services/URLHandler";
import { useSnackbar } from "src/utils/useSnackbar";
import { useTranslation } from "react-i18next";
import { useSectionsConfig } from "src/api/client-api/cms/sections/sectionsConfig";

type HeroBlock = {
    id: UUID | undefined;
    title: string;
    subtitle: string;
    description: string;
    ctaLink: string;
    ctaLabel: string;
    media: {
        "@id": IRI;
        url: string;
        alt: string;
        dimensions: [number, number];
    };
};

type HeroForm = {
    sectionIRI: IRI;
    visible: boolean;
    position: number;
    block: HeroBlock;
};

const getHeroForm = (section: CMSPageSection): HeroForm => {
    if (section.blocks.length === 0) {
        const defaultBlock: HeroForm = {
            sectionIRI: section["@id"],
            visible: section.visible,
            position: section.position,
            block: {
                id: undefined,
                title: "",
                subtitle: "",
                description: "",
                ctaLink: "",
                ctaLabel: "",
                media: {
                    "@id": "",
                    url: "",
                    alt: "",
                    dimensions: [0, 0],
                },
            },
        };
        return defaultBlock;
    }

    const block = section.blocks[0];
    const firstBlock: HeroForm = {
        sectionIRI: section["@id"],
        visible: section.visible,
        position: section.position,
        block: {
            id: block.id,
            title: block.title,
            subtitle: block.subtitle ?? "",
            description: block.description ?? "",
            ctaLink: block.ctaLink,
            ctaLabel: block.ctaLabel,
            media: {
                "@id": block.media?.["@id"] ?? "",
                url: block.media?.url ?? "",
                alt: block.media?.alt ?? "",
                dimensions: block.media?.dimensions ?? [0, 0],
            },
        },
    };
    return firstBlock;
};

const formToWrite = (form: HeroForm): BlockWrite => {
    const blockForm = form.block;
    const blockToWrite: BlockWrite = {
        id: blockForm.id,
        title: blockForm.title,
        subtitle: blockForm.subtitle,
        description: blockForm.description,
        ctaLink: blockForm.ctaLink,
        ctaLabel: blockForm.ctaLabel,
        media: blockForm.media["@id"],
        section: form.sectionIRI,
        position: 0,
    };
    return blockToWrite;
};

type EditHeroProps = {
    originalPageId: string;
    section: CMSPageSection;
};
export const EditHero = (props: EditHeroProps) => {
    const { originalPageId, section } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const sectionsConfig = useSectionsConfig();

    const createBlock = useCreateOneBlock();
    const toggleVisibility = useToggleSectionVisibility();

    const block = getHeroForm(section);
    const [blockState, setBlockState] = useState<HeroForm>(block);

    const ctaLink = blockState.block.ctaLink;
    const outsideLink = ctaLink.startsWith("https://") ? ctaLink : `https://${ctaLink}`;

    const config = sectionsConfig[section.type];
    return (
        <Stack gap={theme.spacing(3)}>
            <EditHeader
                title={config.title}
                subtitle={config.subtitle}
                isActivated={blockState.visible}
                deleteAction={() => {
                    alert("Not available for now");
                }}
                disableAction={() => {
                    toggleVisibility.mutate(
                        {
                            sectionId: section.id.toString(),
                            currentSectionState: section.visible,
                        },
                        {
                            onSuccess: () => {
                                const newVisible = !blockState.visible;
                                setBlockState({
                                    ...blockState,
                                    visible: newVisible,
                                });
                            },
                        },
                    );
                }}
                goBackAction={() => {
                    const url = URLHandler.settings.cms.page(originalPageId);
                    navigate(url);
                }}
            />
            <MainContentLayout>
                <HeroBlock
                    title={blockState.block.title ?? ""}
                    subtitle={blockState.block.subtitle ?? ""}
                    description={
                        <Typography variant="body">
                            <Box dangerouslySetInnerHTML={{ __html: blockState.block.description ?? "" }} />
                        </Typography>
                    }
                    callToAction={
                        <Button
                            sx={{
                                display: "inline-flex",
                                gap: "12px",
                                padding: "24px",
                            }}
                            variant="primary"
                            href={outsideLink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Typography fontWeight={700} fontSize="0.875rem">
                                {blockState.block.ctaLabel}
                            </Typography>
                            <FoodsIcon size={2} icon={"arrowRight"} />
                        </Button>
                    }
                    imageUrl={blockState.block.media.url}
                />
            </MainContentLayout>
            <EditLayout>
                <Stack gap={theme.spacing(3)} marginBottom={theme.spacing(1)}>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("glossary.title")}</Typography>
                        <RoundInput
                            value={blockState.block.title}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock: HeroBlock = {
                                    ...blockState.block,
                                    title: newValue,
                                };
                                setBlockState({
                                    ...blockState,
                                    block: newBlock,
                                });
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("glossary.subtitle")}</Typography>
                        <RoundInput
                            value={blockState.block.subtitle}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock: HeroBlock = {
                                    ...blockState.block,
                                    subtitle: newValue,
                                };
                                setBlockState({
                                    ...blockState,
                                    block: newBlock,
                                });
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("glossary.description")}</Typography>
                        <RoundInput
                            value={blockState.block.description}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock: HeroBlock = {
                                    ...blockState.block,
                                    description: newValue,
                                };
                                setBlockState({
                                    ...blockState,
                                    block: newBlock,
                                });
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("cms.common.ctaLabel")}</Typography>
                        <RoundInput
                            value={blockState.block.ctaLabel}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock: HeroBlock = {
                                    ...blockState.block,
                                    ctaLabel: newValue,
                                };
                                setBlockState({
                                    ...blockState,
                                    block: newBlock,
                                });
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"50%"}>
                        <Typography variant="body-medium">{t("glossary.externalLink")}</Typography>
                        <RoundInput
                            placeholder="https://"
                            value={blockState.block.ctaLink}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock: HeroBlock = {
                                    ...blockState.block,
                                    ctaLink: newValue,
                                };
                                setBlockState({
                                    ...blockState,
                                    block: newBlock,
                                });
                            }}
                        />
                    </Stack>
                    <SelectMedia
                        selectedMediaIRI={blockState.block.media["@id"]}
                        selectNewMedia={(newMedia) => {
                            const newBlock: HeroBlock = {
                                ...blockState.block,
                                media: newMedia,
                            };
                            setBlockState({
                                ...blockState,
                                block: newBlock,
                            });
                        }}
                    />
                </Stack>
            </EditLayout>
            <ConfirmationFooter
                saveAction={() => {
                    const blockToWrite = formToWrite(blockState);
                    createBlock.mutate(
                        {
                            newBlock: blockToWrite,
                        },
                        {
                            onSuccess: () => {
                                snackbar.forceClose();
                                snackbar.setSnackbarProps({
                                    type: "info",
                                    message: t("snackbar.cms.addHero.success"),
                                });
                            },
                        },
                    );
                }}
                resetContent={() => {}}
            />
        </Stack>
    );
};
