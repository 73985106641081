import { Box } from "@mui/material";
import { ScatterScoreBox } from "./ScatterScoreBox/ScatterScoreBox";
import { EmptyScore, Score } from "src/api/client-api/post/scoreSchema";
import { ReferenceScore } from "src/types/applicationTypes/referenceScore.ts";
import { Score as SureScore } from "src/types";

export type ScatterBoxProps = {
    sureScore: SureScore;
    title: string;
    score: Score | EmptyScore;
    reference?: number;
    isRefetching?: boolean;
    referenceScore: ReferenceScore | null;
    prevScore?: Score | EmptyScore;
    prevCampaignName?: string;
    variations: Record<number, number>;
};

export const ScatterBox = (props: ScatterBoxProps) => {
    const {
        score,
        title,
        reference,
        referenceScore,
        prevScore,
        variations,
        sureScore,
        prevCampaignName = "",
        isRefetching = false,
    } = props;

    return (
        <Box sx={{ flex: 1 }}>
            <ScatterScoreBox
                title={title}
                score={score}
                difference={reference || 0}
                isRefetching={isRefetching}
                referenceScore={referenceScore}
                prevScore={prevScore}
                prevCampaignName={prevCampaignName}
                variations={variations}
                sureScore={sureScore}
            />
        </Box>
    );
};
