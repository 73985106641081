import { ReactNode, createContext, useContext, useState } from "react";

import { Snackbar, Alert, AlertColor } from "@mui/material";

type SnackbarProps = {
    message?: string;
    type?: AlertColor;
};

export type SnackbarInitialValues = {
    setSnackbarProps: (value: SnackbarProps) => void;
    snackbarProps: SnackbarProps | null;
    forceClose: () => void;
};

const SnackbarContext = createContext<SnackbarInitialValues>({
    setSnackbarProps: () => {},
    snackbarProps: null,
    forceClose: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

export const FoodpilotSnackbar = ({ children }: { children: ReactNode }) => {
    const [isOpen, setOpen] = useState(false);
    const [snackbarProps, _setSnackbarProps] = useState<SnackbarProps | null>(null);

    const forceClose = () => setOpen(false);

    const setSnackbarProps = (value: SnackbarProps) => {
        _setSnackbarProps(value);
        setOpen(true);
    };

    const items: SnackbarInitialValues = {
        setSnackbarProps,
        snackbarProps,
        forceClose,
    };

    const resetSnackbarProps = () => {
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={items}>
            {children}
            <Snackbar
                open={isOpen}
                onClose={(_event, reason) => {
                    if (reason === "clickaway") {
                        // Don't close on clickaway
                        // Kilian : We have to investigate this, but it looks like
                        // even in the Docs, they cancel this behavior.
                        // The problem we encounter is :
                        // -> Sometimes, when we OPEN the Snackbar, the clickaway is IMMEDIATLY fired.
                        // -> Therefore, causing it to not appear...
                        return;
                    }
                    resetSnackbarProps();
                }}
                autoHideDuration={2500}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={() => resetSnackbarProps()}
                    severity={snackbarProps?.type}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarProps?.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
