import { DottedBox, Button, FormBox, FoodsIcon } from "@foodpilot/foods";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConsumptionModal } from "./ConsumptionModal";
import { ProductFormType } from "../../ProductForm.types";
import { ConsumptionReference, useConsumptionReference } from "src/api/sure-api/useConsumptionReference";
import { Loading } from "src/components/utils/Loading";
import DoubleCheck from "src/assets/icons/double-check.svg?react";

type Page6Props = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};
export const Page6 = (props: Page6Props) => {
    const { t } = useTranslation();

    const { product, setProduct } = props;
    const { productConsumptions, title } = product;

    const [isOpen, setOpen] = useState(false);
    const { consumptionReference, isLoading } = useConsumptionReference();

    if (isLoading === true) {
        <Loading />;
    }

    if (consumptionReference === undefined) {
        // Error state
        return <Typography variant="body-medium">{t("Erreur inatendue")}</Typography>;
    }

    const consumptionsToDisplay = consumptionReference.filter((cons) => productConsumptions.includes(cons.id));
    return (
        <Box>
            <ConsumptionModal
                isOpen={isOpen}
                setOpen={setOpen}
                currentConsumptions={productConsumptions}
                allConsumptions={consumptionReference}
                infoText={title}
                onValidate={(newSelectedConsumptionIds) => {
                    setProduct({
                        ...product,
                        productConsumptions: newSelectedConsumptionIds,
                    });
                }}
            />
            {consumptionsToDisplay.length === 0 ?
                <NoConsumptions onOpen={() => setOpen(true)} />
            :   <DisplayConsumptions consumptions={consumptionsToDisplay} onOpen={() => setOpen(true)} />}
        </Box>
    );
};

type DisplayConsumptionsProps = {
    consumptions: ConsumptionReference[];
    onOpen: () => void;
};
const DisplayConsumptions = (props: DisplayConsumptionsProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { consumptions, onOpen } = props;

    return (
        <FormBox>
            <Typography variant="h3" sx={{ marginBottom: "25px" }}>
                {t("Modes de consommation du produit :")}
            </Typography>
            <Box sx={{ borderTop: `1px solid ${theme.custom.grey[500]}` }}>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        marginBlock: "18px",
                    }}
                >
                    <Button variant="ternary" startIcon={<DoubleCheck />} onClick={onOpen}>
                        <Typography variant="body-bold">{t("Ajouter ou retirer des modes de consommation")}</Typography>
                    </Button>
                </Box>
                <Box sx={{ width: "100%", display: "flex", gap: "10px", flexDirection: "column" }}>
                    {consumptions.map((value, index) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",

                                    paddingLeft: "28px",
                                    paddingBlock: "15px",
                                    border: `1px solid ${theme.custom.grey[500]}`,
                                    borderRadius: "8px",
                                }}
                            >
                                <Typography key={`consumption-reference-${value.id}`} variant="h4">
                                    {value.label}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </FormBox>
    );
};

type NoConsumptionsProps = {
    onOpen: () => void;
};
const NoConsumptions = (props: NoConsumptionsProps) => {
    const { t } = useTranslation();
    const { onOpen } = props;
    return (
        <DottedBox>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2rem",
                }}
            >
                <Typography variant="big-medium">{t("no_mode_of_consumption")}</Typography>
                <Button variant="text" startIcon={<FoodsIcon icon="plus" size={2} />} onClick={onOpen}>
                    {t("Ajouter un mode de consommation")}
                </Button>
            </div>
        </DottedBox>
    );
};
