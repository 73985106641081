import { useTranslation } from "react-i18next";
import { useProductReference } from "src/api/sure-api/useProductReference";
import { FoodsSelectServerPagination, Typography } from "@foodpilot/foods";
import { FormControl } from "@mui/material";
import { useDelayedSearch } from "src/utils/useDelayedSearch";

type ProductRefField = {
    productReferenceId: number | null;
    setNewProductRef: (newRef: number | null) => void;
};
export const ProductRefField = (props: ProductRefField) => {
    const { productReferenceId, setNewProductRef } = props;
    const { t } = useTranslation();
    const { search, setSearch, delayedSearch } = useDelayedSearch();
    const { productsReference = [] } = useProductReference({
        name: delayedSearch,
    });
    const isSearchFound = (value: string) => {
        if (delayedSearch === "") {
            return true;
        }

        return value.toLowerCase().includes(delayedSearch.toLowerCase());
    };

    const selectedProduct = productsReference.find((option) => option.id === productReferenceId) ?? null;

    return (
        <FormControl size="small">
            <Typography variant="h5" sx={{ paddingBottom: "0.5rem" }}>
                {`${t("Produit associé")}*:`}
            </Typography>
            <FoodsSelectServerPagination
                onChange={(data) => {
                    if (data === null) {
                        return setNewProductRef(null);
                    }
                    setNewProductRef(data.id);
                }}
                options={productsReference}
                selectedOption={selectedProduct}
                getId={(item) => item.id}
                getName={(item) => item.name}
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
                search={search}
                setSearch={setSearch}
                isSearchFound={isSearchFound}
                BoxProps={{ width: "75%" }}
                BoxOptionsProps={{ width: "450px" }}
            />
        </FormControl>
    );
};
