import { Button, ContextualContentBox, FoodsIcon, Typography } from "@foodpilot/foods";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import CiteoLogo from "src/assets/clientsLogo/citeo-logo.svg?react";

export const CiteoBlock = () => {
    const { t } = useTranslation();

    return (
        <ContextualContentBox>
            <Stack display={"flex"} flexDirection={"column"} gap={"24px"}>
                <CiteoLogo />
                <Typography variant="h3">{t("partnership.citeo.header")}</Typography>
                <Typography variant="body-medium">{t("partnership.citeo.description")}</Typography>
                <Button
                    variant="primary"
                    sx={{ width: "151px" }}
                    endIcon={<FoodsIcon size={1} icon={"arrowRight"} />}
                    // Open in new Tab
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://tree.citeo.com/fr/"
                >
                    {t("En savoir plus")}
                </Button>
            </Stack>
        </ContextualContentBox>
    );
};
