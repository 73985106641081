import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { useSupplierColumns } from "./useSupplierColumns";
import { useGridColumns, GridColumnsType } from "src/utils/useGridColumns";
import { SupplierGrid, useGetAllSupplier } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useDelayedSearch } from "src/utils/useDelayedSearch";

type GammeSelection = string | undefined;
type SupplierListContextInitialValues = {
    supplierPage: MRT_PaginationState;
    setSupplierPage: Dispatch<SetStateAction<MRT_PaginationState>>;

    sorting: MRT_SortingState;
    setSorting: Dispatch<SetStateAction<MRT_SortingState>>;

    totalItems: number | undefined;

    suppliers: SupplierGrid[] | undefined;
    mrt_columns: GridColumnsType<SupplierGrid>;
    isLoading: boolean;

    currentGamme: GammeSelection;
    setCurrentGamme: (arg1: GammeSelection) => void;

    searchSupplier: string;
    delayedSearchSupplier: string;
    setSearchSupplier: (arg1: string) => void;
    resetSearch: () => void;

    gridKey: string;
};
const SupplierListContext = createContext<SupplierListContextInitialValues | null>(null);

type SupplierListContextProviderProps = { children: ReactNode };
export const SupplierListContextProvider = (props: SupplierListContextProviderProps) => {
    const { children } = props;

    const gridKey = "supplier-grid";

    const mrt_supplierColumns = useSupplierColumns();
    const mrt_columns = useGridColumns(mrt_supplierColumns, gridKey);

    const [supplierPage, setSupplierPage] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: "name", desc: false }]);

    const [currentGamme, setCurrentGamme] = useState<GammeSelection>(undefined);

    const search = useDelayedSearch();
    const { suppliers, isLoading, totalItems } = useGetAllSupplier({
        cacheTime: 0,
        page: supplierPage.pageIndex + 1,
        itemsPerPage: supplierPage.pageSize,
        search: search.delayedSearch,
        ingredientLine: currentGamme,
        sorting: sorting,
    });

    const items: SupplierListContextInitialValues = {
        mrt_columns,

        supplierPage,
        setSupplierPage,
        totalItems,

        sorting,
        setSorting,

        currentGamme,
        setCurrentGamme,

        suppliers,
        isLoading,

        searchSupplier: search.search,
        delayedSearchSupplier: search.delayedSearch,
        setSearchSupplier: search.setSearch,
        resetSearch: search.resetSearch,

        gridKey: gridKey,
    };

    return <SupplierListContext.Provider value={items}>{children}</SupplierListContext.Provider>;
};

export const useSupplierListContext = () => {
    const context = useContext(SupplierListContext);

    if (context) {
        return context;
    }

    throw new Error(`useSupplierListContext must be used within an SupplierListContextProvider`);
};
