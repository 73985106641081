import { WhiteBox } from "@foodpilot/foods";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import QuestionnaireIcon from "src/assets/icons/questionnaire-huge.svg?react";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";
import { useIngredientContext } from "../../ExistingIngredientContext";

export const NoSupplier = () => {
    const { selectedCompany } = useFoodpilotContext();
    const { ingredient } = useIngredientContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <WhiteBox
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    marginBlock: "80px",
                    maxWidth: "600px",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                }}
            >
                <QuestionnaireIcon />
                <Typography variant="h3" sx={{ textAlign: "justify", textAlignLast: "center" }}>
                    {t("ingredient.questionnaire.nosupplier")}
                </Typography>
                <Button
                    variant="primary"
                    onClick={() => {
                        if (selectedCompany === null) return;
                        if (ingredient === undefined) return;

                        const url = URLHandler.ingredient.edit(selectedCompany.id, ingredient.id, 1);
                        navigate(url);
                    }}
                >
                    {t("ingredient.goToSuppliers")}
                </Button>
            </Box>
        </WhiteBox>
    );
};
