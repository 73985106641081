import { Box, BoxProps, CircularProgress } from "@mui/material";
import { FormattedScores } from "src/types";
import { Post } from "src/api/client-api/post/postSchema";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { getPostFormattedScores } from "src/utils/ssq";
import { ScoresVariationsSection } from "./ScoresVariationsSection";
import { useTranslation } from "react-i18next";

export type ScoresSectionProps = BoxProps & {
    post?: Post;
    headings?: Heading[];
    formattedScores?: FormattedScores;
    scoresIds?: number[];
};
export const ScoresSection = (props: ScoresSectionProps) => {
    const { t } = useTranslation();
    const initialFormattedScores = getPostFormattedScores(props.post);
    const { headings, formattedScores = initialFormattedScores, scoresIds, ...boxProps } = props;

    if (!scoresIds) {
        return <CircularProgress sx={{ margin: "0 auto" }} />;
    }

    if (!scoresIds.length) {
        return (
            <Box textAlign="center" sx={{ align: "center" }}>
                {t("noScoresToDisplayData")}
            </Box>
        );
    }

    return <ScoresVariationsSection scoresIds={scoresIds} headings={headings} {...boxProps} />;
};
