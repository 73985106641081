import { Stack, Typography } from "@mui/material";
import { FoodsIllustrations, WhiteBox } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

export const UnderConstruction = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <WhiteBox>
            <Stack gap="32px" alignItems="center" padding="32px">
                <FoodsIllustrations illustration="underConstruction" />
                <Typography variant="h3">{t("bred.under_construction")}</Typography>
            </Stack>
        </WhiteBox>
    );
};
