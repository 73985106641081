import { useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { publicApiCall } from "src/api/axiosClient/userApi";

const POPUP_HEIGHT = 700;
const POPUP_WIDTH = 600;
const OAUTH_SUCCESS = "oauth_success";
const OAUTH_ERROR = "oauth_error";

const openPopup = (url: string) => {
    // To fix issues with window.screen in multi-monitor setups, the easier option is to
    // center the pop-up over the parent window.
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
    return window.open(url, "OAuth2 Popup", `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`);
};

const closePopup = (popupRef: any) => {
    popupRef.current?.close();
};

const cleanup = (intervalRef: any, popupRef: any, handleMessageListener: any) => {
    clearInterval(intervalRef.current);
    closePopup(popupRef);
    window.removeEventListener("message", handleMessageListener);
};

export const useOAuth2Dance = () => {
    const navigate = useNavigate();
    const popupRef: any = useRef();
    const intervalRef = useRef<string | number | NodeJS.Timeout | undefined>();
    const [{ loading, error }, setUI] = useState<{
        loading: boolean;
        error: string | null;
    }>({ loading: false, error: null });

    const getAuthorizedUrl = async (providerName: string) => {
        const response = await publicApiCall.get(`/api/auth/login/${providerName}`);
        if (response.data.authorizationUrl) {
            return response.data.authorizationUrl;
        } else {
            return null;
        }
    };

    const getAuth = async (providerName: string) => {
        setUI({
            loading: true,
            error: null,
        });
        const authorizedUrl = await getAuthorizedUrl(providerName);
        popupRef.current = openPopup(authorizedUrl);

        async function handleMessageListener(message: MessageEvent<any>) {
            const type = message?.data?.type;

            if (type === OAUTH_SUCCESS) {
                navigate("/");
            } else if (type === OAUTH_ERROR) {
                setUI({
                    loading: false,
                    error: "error",
                });
            }
        }
        window.addEventListener("message", handleMessageListener);

        // 4. Begin interval to check if popup was closed forcefully by the user
        intervalRef.current = setInterval(() => {
            const popupClosed = !popupRef.current || !popupRef.current.window || popupRef.current.window.closed;
            if (popupClosed) {
                // Popup was closed before completing auth...
                setUI((ui) => ({
                    ...ui,
                    loading: false,
                }));
                console.warn("Warning: Popup was closed before completing authentication.");
                clearInterval(intervalRef.current);
                window.removeEventListener("message", handleMessageListener);
            }
        }, 250);

        // Remove listener(s) on unmount
        return () => {
            window.removeEventListener("message", handleMessageListener);
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    };

    return { loading, error, getAuth };
};
