import { Badge, WhiteBox } from "@foodpilot/foods";
import { Box, Button, Container, Divider, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import LogoFoodpilot from "src/assets/icons/logo-foodpilot-progress-colored.svg?react";
import BlackCross from "src/assets/icons/SsqTitleIcon/black-cross.svg?react";
import DataAnalysis from "src/assets/icons/data_analysis.svg?react";
import logoInExtenso from "src/assets/images/logo-in-extenso.png";
import logoInExtensoInnovationCroissance from "src/assets/images/logo-in-extenso-innovation-croissance.png";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type Props = {
    onStart: () => void;
};

export const InexwebHome = (props: Props) => {
    const { onStart } = props;
    const theme = useTheme();
    const { user } = useFoodpilotContext();

    return (
        <Container component="main" maxWidth={false} sx={{ m: "0", p: "3rem !important", backgroundColor: "#F2F1F1" }}>
            <Stack direction="row" spacing={2} alignItems="center">
                <Typography component="h1" variant="h2">
                    Bonjour, {user.trustedUser.firstname} {user.trustedUser.lastname}
                </Typography>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <WhiteBox>
                <Stack
                    display={"flex"}
                    alignItems={"center"}
                    gap="32px"
                    marginInline={"auto"}
                    maxWidth={"60%"}
                    padding={"32px"}
                >
                    <DataAnalysis />
                    <Typography component="h1" variant="h2" textAlign={"center"}>
                        Générez le bilan RSE simplifié
                        <br /> de vos clients en quelques minutes
                    </Typography>
                    <Typography component="p" textAlign={"center"}>
                        À partir des données du bilan, aidez vos clients à prendre conscience de leurs
                        <br /> impacts environnementaux, et à répondre de façon solide à la demande de
                        <br /> leurs clients et donneurs d&apos;ordres.
                    </Typography>
                    <Button variant="primary" onClick={onStart}>
                        Commencer à générer un bilan
                    </Button>
                </Stack>
            </WhiteBox>
            <Box sx={{ py: 4 }}></Box>
            <Typography variant="h2" textAlign={"center"}>
                Comment ça marche ?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, gap: "1rem" }}>
                <WhiteBox sx={{ px: 8, py: 6 }}>
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        spacing={1}
                        alignItems={"center"}
                        marginBottom={1}
                    >
                        <Badge bgcolor={"orange"}>1</Badge>
                        <Typography variant="h3">Liez votre compte Inexweb</Typography>
                    </Stack>
                    <Typography textAlign="center">
                        Afin de récupérer les données des exercices
                        <br /> comptables de vos clients
                    </Typography>
                </WhiteBox>
                <WhiteBox sx={{ px: 8, py: 6 }}>
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        spacing={1}
                        alignItems={"center"}
                        marginBottom={1}
                    >
                        <Badge bgcolor={"orange"}>2</Badge>
                        <Typography variant="h3">Sélectionnez un client</Typography>
                    </Stack>
                    <Typography textAlign="center">
                        Pour lequel vous souhaitez générer un bilan
                        <br /> RSE simplifié
                    </Typography>
                </WhiteBox>
                <WhiteBox sx={{ px: 8, py: 6 }}>
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        spacing={1}
                        alignItems={"center"}
                        marginBottom={1}
                    >
                        <Badge bgcolor={"orange"}>3</Badge>
                        <Typography variant="h3">Configurez et générez</Typography>
                    </Stack>
                    <Typography textAlign="center">
                        Le bilan RSE simplifié, téléchargeable au
                        <br /> format PDF
                    </Typography>
                </WhiteBox>
            </Box>
            <Box sx={{ mb: 8 }} />
            <WhiteBox bgcolor={theme.custom.grey[400]}>
                <Stack direction="row" spacing={15} padding={"3rem"}>
                    <Stack direction="row" spacing={3} alignItems={"center"}>
                        <img src={logoInExtenso} alt="In Extenso logo" height={30} />
                        <SvgIcon component={BlackCross} />
                        <img src={logoInExtensoInnovationCroissance} alt="In Extenso logo" height={50} />
                        <SvgIcon component={BlackCross} />
                        <SvgIcon
                            component={LogoFoodpilot}
                            sx={{ width: "110px", height: "48px", flexShrink: "0", marginRight: "56px" }}
                            viewBox="0 0 110 48"
                        />
                    </Stack>
                    <Box>
                        <Typography>
                            InExtenso, InExtenso Innovation Croissance et Food Pilot s&apos;associent pour créer le
                            premier service de bilan RSE simplifié automatique et d&apos;accompagnement RSE des PME/TPE.
                        </Typography>
                    </Box>
                </Stack>
            </WhiteBox>
        </Container>
    );
};
