import z from "zod";
import { useQuery } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "../../apiErrorLogs";
import { Permission, PermissionSchema } from "src/api/client-api/permissions/useGetAllPermissions.ts";

export const getRoleKey = "getRole" as const;

type Options = {
    enabled?: boolean;
};

export const useGetRole = (roleCode: string, options?: Options) => {
    const { enabled = true } = options || {};

    const query = useQuery({
        queryKey: getRoleKey,
        enabled: enabled,
        cacheTime: 0,
        queryFn: async () => {
            const url = `/api/roles/${roleCode}`;
            return await loggedInApiCall.get(url);
        },
        select: (request) => {
            const role = request.data;
            return zodParseWithLog(role, RoleSchema, getRoleKey);
        },
    });

    return {
        role: query.data,
        ...query,
    };
};

export type Role = {
    "@id": IRI;
    code: string;
    permission: Permission[];
};

export const RoleSchema: z.ZodSchema<Role> = z.object({
    "@id": z.string(),
    code: z.string(),
    permission: z.array(PermissionSchema),
});
