import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useGetIngredientPost } from "src/api/client-api/post/useGetIngredientPost";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { QuestionnaireUpdateFormSsqPost } from "./QuestionnaireUpdateFormSsqPost";
import { ScoreProvider } from "src/context/ScoreContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Post } from "src/api/client-api/post/postSchema.ts";

type QuestionnaireUpdateFormSsqSupplierProps = {
    ingredient: Ingredient;
    ssq: Ssq;
};
export const QuestionnaireUpdateFormSsqSupplier = (props: QuestionnaireUpdateFormSsqSupplierProps) => {
    const { ingredient, ssq } = props;

    const { currentCampaign, selectedCompany } = useFoodpilotContext();

    const companyId = selectedCompany ? selectedCompany.id : null;

    const { post, isLoading, refetch } = useGetIngredientPost(ingredient.id, companyId, currentCampaign?.id);

    const refetchPost = (callback: (post: Post) => void) => {
        refetch().then((response) => {
            if (response.data) {
                callback(response.data);
            }
        });
    };

    return (
        <ScoreProvider type="ingredient" formattedScores={post?.formattedScores}>
            <QuestionnaireUpdateFormSsqPost
                ingredient={ingredient}
                ssq={ssq}
                post={post}
                isPostLoading={isLoading}
                changePost={refetchPost}
            />
        </ScoreProvider>
    );
};
