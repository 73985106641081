import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";
import { FormInput } from "src/components/FormInput";
import { getForgotPasswordFn } from "src/api/authApi";
import { WhiteBox } from "@foodpilot/foods";
import { Link, useLocation } from "react-router-dom";
import ArrowLeft from "src/assets/icons/arrow-left.svg?react";
import EnvelopeIcon from "src/assets/icons/envelope.svg?react";

const loginSchema = object({
    email: string().min(1, "Email address is required"),
});

export type ForgotPasswordInput = TypeOf<typeof loginSchema>;

export const ForgotPassword = () => {
    const theme = useTheme();
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const destination = urlSearchParams.get("destination");

    const methods = useForm<ForgotPasswordInput>({
        defaultValues: {
            email: "",
        },
        resolver: zodResolver(loginSchema),
    });

    //  API Login Mutation
    const {
        mutate: resetPassword,
        isLoading,
        isSuccess,
        data: resetPassworResponse,
    } = useMutation((userData: ForgotPasswordInput) => getForgotPasswordFn(userData), {
        onSuccess: (response) => {
            if (response === false) {
                const errorText = t("login.mailError");
                setError(errorText);
            }
        },
        onError: (error) => {
            // This is supposed to be an Api Error.
            console.log("Error :", error);
        },
    });

    const { handleSubmit } = methods;

    if (isSuccess && resetPassworResponse === true) {
        return <ForgotPasswordSuccess />;
    }

    return (
        <FormProvider {...methods}>
            <WhiteBox
                padding="40px"
                paddingTop="32px"
                boxShadow="0 2px 8px 0 rgba(42, 43, 39, 0.04)"
                position="relative"
            >
                {isLoading && (
                    <Stack
                        sx={{
                            background: "rgba(255, 255, 255, 0.5)",
                            position: "absolute",
                            inset: "calc(40px / 2)",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress />
                    </Stack>
                )}
                <Stack
                    component="form"
                    onSubmit={handleSubmit(
                        (values) => {
                            methods.clearErrors();
                            resetPassword(values);
                        },
                        (errors) => {
                            // We might want to spread errors in a different way.
                            setError(errors.email?.message ?? t("Une erreur s'est produite"));
                        },
                    )}
                    noValidate
                    autoComplete="off"
                    gap="36px"
                >
                    <Stack gap="16px">
                        <Typography variant="h2" align="center">
                            {t("reset-password-title")}
                        </Typography>
                        <Typography variant="body" align="center" sx={{ color: theme.custom.grey[2000] }}>
                            {t("reset-password-info")}
                        </Typography>
                    </Stack>

                    {error && (
                        <Typography variant="h4" align="center" color="error">
                            {error}
                        </Typography>
                    )}

                    <Stack gap="24px">
                        <FormInput type="email" name="email" label={t("reset-password-email")} />

                        <Button variant="primary" type="submit">
                            {t("reset-password-submit-email")}
                        </Button>

                        <Button
                            variant="text"
                            component={Link}
                            to={`/login${destination ? `?destination=${destination}` : ""}`}
                            startIcon={<ArrowLeft />}
                            sx={{ width: "100%", height: "auto" }}
                        >
                            {t("Revenir à la connexion")}
                        </Button>
                    </Stack>
                </Stack>
            </WhiteBox>
        </FormProvider>
    );
};

const ForgotPasswordSuccess = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const destination = urlSearchParams.get("destination");

    return (
        <WhiteBox padding="40px" boxShadow="0 2px 8px 0 rgba(42, 43, 39, 0.04)">
            <Stack gap="32px">
                <Stack gap="16px" alignItems="center">
                    <Stack
                        width="56px"
                        height="56px"
                        borderRadius="100%"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            backgroundColor: theme.palette.primary[50],
                        }}
                    >
                        <EnvelopeIcon />
                    </Stack>
                    <Typography variant="h2" align="center">
                        {t("Email de réinitialisation envoyé")}
                    </Typography>
                    <Typography variant="body" align="center" sx={{ color: theme.custom.grey[2000] }}>
                        {t(
                            "Suivez les instructions présentes dans cet email pour réinitialiser votre mot-de-passe et connectez-vous à nouveau à votre plateforme",
                        )}
                    </Typography>
                </Stack>

                <Button
                    variant="primary"
                    component={Link}
                    to={`/login${destination ? `?destination=${destination}` : ""}`}
                    startIcon={<ArrowLeft />}
                >
                    {t("Revenir à l’écran de connexion")}
                </Button>
            </Stack>
        </WhiteBox>
    );
};
