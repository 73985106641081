import { FormBase, Page } from "@foodpilot/foods";
import { Box, Button, Chip, Typography, styled } from "@mui/material";
import { Page1 } from "./Page1";
import { Page2 } from "./Page2";
import { Page3 } from "./Page3";
import { Page4 } from "./Page4";
import { Page4Bis } from "./Page4Bis";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useEnergyTypes } from "src/api/sure-api/inexweb/useEnergyTypes";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Category, RSEFormProps, ReportData } from "src/types";
import { ConfirmDialog } from "./components/ConfirmDialog";
import { getSureApiUrl } from "src/api/axiosClient/utils";
import _EditIcon from "src/assets/icons/black-edit.svg?react";
import WhiteInfo from "src/assets/icons/SsqTitleIcon/info.svg?react";
import { useCreateReportMutation } from "src/api/sure-api/inexweb/useCreateReportMutation";
import { GenerateReportDialog } from "./components/GenerateReportDialog";
import { getInexwebInfo, removeInexwebInfo } from "src/utils/token";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { createSearchParams } from "react-router-dom";
import { IUsageMetric } from "src/api/types.ts";
import { addMetricFn } from "src/api/inexwebApi.ts";
import dayjs from "dayjs";

const EditIcon = styled(_EditIcon)(({ theme }) => ({
    path: {
        fill: theme.palette.primary[700],
    },
}));

type Step = "init" | "siren" | "fiscal_year";

const steps: Record<Step, boolean[]> = {
    init: [false, true, false],
    siren: [false, true, false],
    fiscal_year: [true, true, false],
};

export const useInitCategories = (): Category[] => {
    const { data: energyTypes } = useEnergyTypes();

    return [
        {
            label: "Énergie",
            energies:
                energyTypes
                    ?.filter((energyType) => energyType.category === "gaz" || energyType.category === "electricity")
                    .map((e) => ({ energyType: e, accounts: [] })) || [],
        },
        {
            label: "Carburant",
            energies:
                energyTypes
                    ?.filter(
                        (energyType) =>
                            energyType.category === "fuel" &&
                            (energyType.code == "fuel" || energyType.code == "gazole"),
                    )
                    .map((e) => ({ energyType: e, accounts: [] })) || [],
        },

        {
            label: "Eau",
            energies:
                energyTypes
                    ?.filter((energyType: any) => energyType.category === "water")
                    .map((e) => ({ energyType: e, accounts: [] })) || [],
        },
    ];
};

export const InexwebForm = () => {
    const navigate = useNavigate();
    const { pageId } = useParams();
    const initCategories = useInitCategories();

    const minValue = 0;

    // pageId can be an invalid string.
    // If the string is invalid, result is NaN
    // It is not shown in the types.
    const currentPageIndex = Number.parseInt(pageId ?? `${minValue}`);

    const { company, token, company_corporate_name, company_identification_number } = getInexwebInfo();

    const methods = useForm<RSEFormProps>({
        mode: "all",
        defaultValues: {},
        values: {
            categories: initCategories,
            company: company_corporate_name || "",
            siret: company_identification_number || "",
        },
    });

    const [validateSteps, setValidateSteps] = useState(steps["init"]);

    const selectedFiscalYear = methods.watch("fiscalYear");

    const [reportUrl, setReportUrl] = useState<string | undefined>();
    const pages: Page[] = [
        {
            icon: 1,
            content: (
                <Page2
                    onFiscalYearSelected={() => {
                        console.log("selectedFiscalYear", selectedFiscalYear);
                        setValidateSteps([true, true, false]);
                    }}
                />
            ),
            title: "Exercice comptable",
        },
        {
            icon: 2,
            content: <Page3 onError={(hasError: boolean) => setValidateSteps([true, !hasError, false])} />,
            title: "Données d'activité",
        },
        {
            icon: reportUrl ? undefined : 3,
            content:
                reportUrl ?
                    <Page4Bis reportUrl={reportUrl} deleteReportUrl={() => setReportUrl(undefined)} />
                :   <Page4 onError={(hasError: boolean) => setValidateSteps([true, true, !hasError])} />,
            title: reportUrl ? undefined : "Volet environnemental",
        },
    ];
    const maxValue = pages.length - 1;

    const { user } = useFoodpilotContext();

    const { mutate } = useCreateReportMutation();

    const onSubmit: SubmitHandler<RSEFormProps> = (data) => {
        setOpenGenerateReport(true);
        const accounts = data.categories.flatMap((c) => c.energies).flatMap((e) => e.accounts);
        const periodEntries = accounts.flatMap((a) => a.entries);
        const previousPeriodEntries = accounts.flatMap((a) => a.previousEntries);

        const reportData: ReportData = {
            xCompany: company || "",
            companyName: data.company,
            period: {
                datestart: data.fiscalYear?.datestart || "",
                dateend: data.fiscalYear?.dateend || "",
                headCount: data.period?.headCount || 0,
                production: data.period?.production || 0,
                turnover: data.period?.turnover || 0,
                entries: periodEntries,
            },
            previousPeriod: {
                datestart: data.previousFiscalYear?.datestart || "",
                dateend: data.previousFiscalYear?.dateend || "",
                headCount: data.previousPeriod?.headCount || 0,
                production: data.previousPeriod?.production || 0,
                turnover: data.previousPeriod?.turnover || 0,
                entries: previousPeriodEntries,
            },
        };
        mutate(
            {
                externalAccountId: company || "",
                parameters: reportData,
                user: `/api/users/${user.trustedUser.id}`,
            },
            {
                onSuccess: (ResponseData) => {
                    setReportUrl(`${getSureApiUrl()}/${ResponseData.filename}`);
                    setOpenGenerateReport(false);
                    sendUsageMetric(reportData, data);
                },
            },
        );
    };

    const sendUsageMetric = async (reportData: ReportData, data: RSEFormProps) => {
        const metric: IUsageMetric = {
            user: `/api/users/${user.trustedUser.id}`,
            event: "pdf_generated",
            createdAt: dayjs().format(),
            payload: {
                financial_year: reportData?.period?.dateend,
                siret: data.siret,
                company: data.company,
            },
        };
        const result_metric = await addMetricFn(metric);
    };

    const handleOnPageForward = async () => {
        const nextPage = currentPageIndex + 1;
        if (nextPage > maxValue) {
            setOpenGenerateReport(true);
        }
        const pageToGo = nextPage >= maxValue ? maxValue : nextPage;

        const basePath = location.pathname.split("/");
        basePath.pop(); // This is supposed to be the number of the page
        const finalPath = `${basePath.join("/")}/${pageToGo}`;

        return navigate(finalPath);
    };

    const handleOnPageBack = () => {
        let previousPage = currentPageIndex;
        if (!reportUrl) previousPage -= 1;

        if (currentPageIndex === maxValue) {
            setReportUrl(undefined);
        }
        if (previousPage < 0) {
            removeInexwebInfo();
            navigate("/inexweb");
            return;
        }
        const pageToGo = previousPage < minValue ? minValue : previousPage;

        const basePath = location.pathname.split("/");
        basePath.pop(); // This is supposed to be the number of the page
        const finalPath = `${basePath.join("/")}/${pageToGo}`;

        return navigate(finalPath);
    };

    const handleOnEditCompany = () => {
        removeInexwebInfo();
        navigate({ pathname: "/inexweb", search: createSearchParams({ auth: "true" }).toString() });
    };

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openGenerateReport, setOpenGenerateReport] = useState(false);

    const handleConfirm = () => {
        removeInexwebInfo();
        navigate("/inexweb", { replace: true });
    };

    const fiscalYears = methods.watch("fiscalYear");

    useEffect(() => {
        if (!token || !company) {
            navigate("/inexweb", { replace: true });
            return;
        }
        if (!fiscalYears) {
            navigate("/inexweb/form/0");
            return;
        }
    }, [token, company, fiscalYears]);

    const warningsTexts = [
        "Veuillez saisir un numéro de SIREN valide",
        "Veuillez renseigner un exercice comptable",
        "Veuillez vérifier la validité des informations saisies",
        "Veuillez saisir au moins un élément dans une catégorie",
    ];

    return (
        <FormProvider {...methods}>
            <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto", padding: "40px 52px " }}>
                <FormBase
                    title="Bilan RSE simplifié"
                    // @ts-expect-error current foods version is wrong, just a wrong type here
                    subtitle={
                        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <Typography>{company_corporate_name}</Typography>
                            <Button onClick={handleOnEditCompany} startIcon={<EditIcon />}>
                                Changer d'entreprise
                            </Button>
                        </Box>
                    }
                    icon={<WhiteInfo />}
                    pageIndexToDisplay={currentPageIndex}
                    pages={pages}
                    onPageForward={handleOnPageForward}
                    onPageBack={handleOnPageBack}
                    nextPageText={
                        currentPageIndex === 3 ?
                            reportUrl ?
                                undefined
                            :   "Générer le bilan"
                        :   "Suivant"
                    }
                    warningText={warningsTexts[currentPageIndex]}
                    previousPageText={"Précédent"}
                    validateSteps={validateSteps}
                    validateStepsTexts={[]}
                    onClose={() => setOpenConfirm(true)}
                />
            </Box>
            {openConfirm && (
                <ConfirmDialog onConfirm={handleConfirm} open={openConfirm} onClose={() => setOpenConfirm(false)} />
            )}
            {openGenerateReport && (
                <GenerateReportDialog
                    open={openGenerateReport}
                    onClose={() => setOpenGenerateReport(false)}
                    onReadyToGenerate={() => methods.handleSubmit(onSubmit)()}
                />
            )}
        </FormProvider>
    );
};
