import { useState } from "react";

const getDimensionsQueryParam = (dimensions: Dimensions[]): Dimensions => {
    return dimensions.reduce((acc, path: Dimensions) => {
        acc.push(path.join(","));

        return acc;
    }, []);
};

export type Dimension = string;
export type Dimensions = Dimension[];

export type InitDimensions = {
    dimensions: Dimensions[];
};

export type DimensionState = {
    initialDimensions: Dimensions[];
    dimensions: Dimensions[];
    drilldownDimension: (newDimension: Dimension, dimensionIndex?: number) => void;
    switchToDimension: (newDimension: Dimension) => void;
    resetToInitial: () => void;
};

export type DimensionQuery = {
    dimensions: Dimensions;
};

export type DimensionHook = {
    query: DimensionQuery;
    state: DimensionState;
};
export const useDimensions = (initialDimensions?: InitDimensions): DimensionHook => {
    const { dimensions = [["main"]] } = initialDimensions ?? {};

    const [dimensionsState, _setDimensionsState] = useState<Dimensions[]>(dimensions);

    const drilldownDimension = (newDimension: Dimension, dimensionIndex?: number) => {
        const index = dimensionIndex ?? 0;
        if (index > dimensionsState.length - 1) {
            // We asked for an index that doesn't exist.
            console.error(`Err: Required index ${index} for state : ${dimensionsState}`);
            return;
        }
        const newItem = [...dimensionsState[index], newDimension];
        const newDimensionsState = dimensionsState.with(index, newItem);

        _setDimensionsState(newDimensionsState);
    };

    const switchToDimension = (newDimension: Dimension) => {
        const newDimensions = [[newDimension]];
        _setDimensionsState(newDimensions);
    };

    const resetToInitial = () => {
        _setDimensionsState(dimensions);
    };

    return {
        query: {
            dimensions: getDimensionsQueryParam(dimensionsState),
        },
        state: {
            initialDimensions: dimensions,
            dimensions: dimensionsState,
            drilldownDimension,
            switchToDimension,
            resetToInitial,
        },
    };
};
