import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

type CreateProductLine = {
    productLine: ProductLineWrite;
};
export const useCreateProductLine = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: CreateProductLine) => {
            const { productLine } = options;

            const response =
                productLine.id === undefined ?
                    await loggedInApiCall.post("/api/product_lines", productLine)
                :   await loggedInApiCall.put("/api/product_lines/" + productLine.id, productLine);

            return response;
        },
        onSuccess: () => queryClient.invalidateQueries(["allProductLines"]),
    });

    return query;
};

export type ProductLineWrite = {
    id?: number;
    name: string;
};
