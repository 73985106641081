import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

export function useIngredientDelete() {
    const query = useMutation({
        mutationFn: async (id: number) => {
            const response = await loggedInApiCall.delete("/api/ingredients/" + id);
            return response;
        },
    });

    return query;
}
