import { Box, Button, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReferenceIcon from "src/assets/icons/reference.svg?react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

export type NoReferenceIngredientProps = {
    ingredient: Ingredient;
};

export const NoReferenceIngredient = (props: NoReferenceIngredientProps) => {
    const { ingredient } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { selectedCompany } = useFoodpilotContext();

    if (selectedCompany === null) return;
    return (
        <Box
            sx={{
                width: "100%",
                border: "1px solid #E7E6E4",
                bgcolor: theme.palette.background.paper,
                borderRadius: "12px",
                padding: "3rem",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "3rem" }}>
                <Box>
                    <ReferenceIcon />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Typography variant="h4">
                        {t("Aucun ingrédient de référence n’est associé à l’ingrédient")}
                    </Typography>
                    <Typography variant="body-medium">{t("ingredient.noReferenceIngredient")}</Typography>
                </Box>
            </Box>
            {ingredient.canModify && (
                <Box
                    sx={{
                        borderLeft: "1px solid #E7E6E4",
                        paddingLeft: "3rem",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Button
                        variant="primary"
                        id="column-visibility-anchor"
                        onClick={() => {
                            const url = URLHandler.ingredient.edit(selectedCompany.id, ingredient.id, 0);
                            navigate(url);
                        }}
                        endIcon={<EditIcon />}
                    >
                        {t("Modifier l'ingrédient")}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
