import { GridPageHeader } from "src/components/Grid/PageGridHeader";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { GridHeader } from "./SuppliersList/SuppliersGrid/GridHeader";
import { SupplierGrid } from "./SuppliersList/SuppliersGrid";
import { SupplierListContextProvider, useSupplierListContext } from "./SuppliersList/SupplierListContext";

const _SuppliersList = () => {
    const { searchSupplier, setSearchSupplier } = useSupplierListContext();

    const { t } = useTranslation();

    return (
        <Box sx={{ padding: "40px 52px" }}>
            <GridPageHeader
                title={t("Fournisseurs")}
                mainBoardName={t("Tableau de bord")}
                listItemName={t("Liste des fournisseurs")}
                filter={searchSupplier}
                setFilter={setSearchSupplier}
            />
            <Box
                component="div"
                sx={{
                    borderRadius: "16px",
                    border: "1px solid #E7E6E4",
                    background: "#FFF",
                    paddingTop: "32px",
                    marginTop: "40px",
                }}
            >
                <GridHeader />
                <SupplierGrid />
            </Box>
        </Box>
    );
};

export const SuppliersList = () => {
    return (
        <SupplierListContextProvider>
            <_SuppliersList />
        </SupplierListContextProvider>
    );
};
