import { LightIndicatorBlock } from "@foodpilot/foods";
import { CMSContentBlock } from "src/api/client-api/cms/useGetOneContent";

type IndicatorBlockProps = {
    blocks: CMSContentBlock[];
};

export const IndicatorBlock = (props: IndicatorBlockProps) => {
    /* Props */

    const { blocks } = props;
    console.log(props);
    /* Validation */
    if (blocks.length === 0) {
        return <></>;
    }

    const { title, subtitle, description } = blocks[0];

    return description && <LightIndicatorBlock title={title} subtitle={subtitle} description={description} />;
};
