import { Box, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { FoodsIcon } from "@foodpilot/foods";

type MandatoryCompanyFields = {
    id: number;
    name: string;
    companySector?: {
        id: number;
        name: string;
    };
};

type CompanyListProps<T> = {
    selectedCompany: T;
    setSelectedCompany: (newCompany: T) => void;
    companies: T[];
};
export const CompanyList = <T extends MandatoryCompanyFields>(props: CompanyListProps<T>) => {
    const theme = useTheme();

    const selectedColor = theme.palette.primary[600];
    const defaultColor = undefined;

    return (
        <Box
            sx={{
                minWidth: "287px",
                margin: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
            }}
        >
            {props.companies.map((company, index: number) => {
                const isSelected = company.id === props.selectedCompany.id;
                return (
                    <CompanyLine
                        key={index}
                        onClick={() => {
                            props.setSelectedCompany(company);
                        }}
                    >
                        <Typography
                            sx={{
                                color: isSelected ? selectedColor : defaultColor,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}
                            variant={"body-bold"}
                        >
                            {company.name}
                        </Typography>
                        {isSelected && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",

                                    width: "16px",
                                    height: "16px",
                                }}
                            >
                                <FoodsIcon icon={"check"} size={2} />
                            </Box>
                        )}
                    </CompanyLine>
                );
            })}
        </Box>
    );
};

type CompanyLineProps = {
    children: ReactNode;
    onClick: () => void;
};
const CompanyLine = (props: CompanyLineProps) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                width: "100%",
                gap: "4px",

                color: theme.palette.primary[600],
            }}
            onClick={props.onClick}
        >
            {props.children}
        </Box>
    );
};
