import { FormBox } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DisplayUsers } from "./DisplayUsers";
import { SearchUserBar } from "./SearchUserBar";
import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { SupplierFormType } from "../../SupplierForm.types";

type Page2Props = {
    supplier: SupplierFormType;
    setSupplier: (newSupplier: SupplierFormType) => void;
    existingSupplier?: Supplier;
};
const Page2 = (props: Page2Props) => {
    const { supplier, setSupplier } = props;
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
            }}
        >
            <FormBox
                sx={{
                    flex: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                <Typography variant="big-medium">{t("Recherchez parmi les utilisateurs :")}</Typography>
                <SearchUserBar
                    selectedUsers={supplier.users}
                    addUser={(newUser) => {
                        const newUsers = [...supplier.users, newUser];
                        setSupplier({
                            ...supplier,
                            users: newUsers,
                        });
                    }}
                />
                <Box>
                    <DisplayUsers
                        users={supplier.users}
                        onDelete={(userId) =>
                            setSupplier({
                                ...supplier,
                                users: supplier.users.filter((user) => user["@id"] !== userId),
                            })
                        }
                    />
                </Box>
            </FormBox>
        </Box>
    );
};
export default Page2;
