import { Button, Typography } from "@mui/material";
import { FoodsIcon } from "@foodpilot/foods";
import { BreadcrumbType } from "src/components/Breadcrumb/Breadcrumbs";

type BreadcrumbProps = {
    breadcrumb: BreadcrumbType;
};
export const Breadcrumb = (props: BreadcrumbProps) => {
    const { breadcrumb } = props;
    const maxLabelLength = 30;

    const trimLabelIfNeeded = (label: string): string => {
        if (label.length > maxLabelLength) {
            return `${label.slice(0, maxLabelLength)}...`;
        }

        return label;
    };

    return (
        <li>
            <Button className="breadcrumb" onClick={breadcrumb.action}>
                <Typography variant="body" title={breadcrumb.label}>
                    {trimLabelIfNeeded(breadcrumb.label)}
                </Typography>
            </Button>
            <FoodsIcon icon="arrowRightShort" size={2} />
        </li>
    );
};
