import { useCreateContentPage } from "src/api/client-api/cms/useCreatePage";
import { useSnackbar } from "src/utils/useSnackbar";

export const HomeCMS_ID = "home" as const;

export const useCreateHomePage = () => {
    const snackbar = useSnackbar();
    const createPage = useCreateContentPage();

    const createHomepage = () => {
        const payload = {
            id: HomeCMS_ID,
            slug: "homepage",
            title: "My homepage",
            tags: [],
        };
        createPage.mutate(
            { newPage: payload },
            {
                onSuccess: (req) => {
                    snackbar.forceClose();
                    snackbar.setSnackbarProps({
                        type: "info",
                        message: "Page created",
                    });

                    window.location.href = "cms";
                },
                onError: (req) => {
                    snackbar.forceClose();
                    snackbar.setSnackbarProps({
                        type: "error",
                        message: "Unknown Error happened, contact admin",
                    });
                },
            },
        );
    };

    return { createHomepage };
};
