import { BoxProps } from "@mui/material";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { ScoresChart } from "src/components/Chart/ScoresChart";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useScoreContext } from "src/context/ScoreContext";
import { ChapterName, Score } from "src/types";
import { getScoreIdsByType, getScoresByIds } from "src/utils/scores";
import { getFormattedScoreByScoreId } from "src/utils/ssq";

type ScoreChartProps = BoxProps & {
    headings?: Heading[];
};

export const ScoreChart = (props: ScoreChartProps) => {
    const { headings } = props;
    const { scores = [], scoreTypes } = useFoodpilotContext();
    const { displayedScoreId, displayedVariationId = 0, formattedScores } = useScoreContext();
    const companyScoresIds = getScoreIdsByType("company", scoreTypes);
    const scoresToDisplay = getScoresByIds(companyScoresIds, scores);
    const initialScore = scoresToDisplay[0];

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const chaptersScore = formattedScore?.chaptersScore;
    const scoreVariations = formattedScore?.scoreVariations;

    const selectedScore = (scores ?? []).find((score: Score) => score.id === chaptersScore?.score_id) ?? initialScore;

    const displayedVariation = scoreVariations?.[displayedVariationId];

    return (
        <ScoresChart
            formattedScores={formattedScores}
            headings={headings}
            displayedScoreId={displayedScoreId}
            displayedVariationId={displayedVariation ? displayedVariationId : 0}
            displayMode={selectedScore?.displayMode as ChapterName}
            precision={selectedScore?.precision}
        />
    );
};
