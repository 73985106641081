import { ActionsBlock, ActionsBlockProps, BlockItemContainer } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

export type ActionBlockProps = unknown;
export const ActionBlock = (_props: ActionBlockProps) => {
    const { selectedCompany } = useFoodpilotContext();
    const { actions } = useActionsContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    if (actions === undefined) return null;

    const actionBlocks: ActionsBlockProps["actionBlocks"] = actions.map((actionItem) => {
        return {
            id: actionItem.id,
            actionTitle: actionItem.title,
            value: null,
            unit: "",
        };
    });

    return (
        <BlockItemContainer
            title={"Ma liste d'action"}
            value={null}
            unit={""}
            fields={[
                <ActionsBlock
                    actionBlocks={actionBlocks}
                    addAction={{
                        helperText: t("action.addAction"),
                        action: (option) => {
                            if (selectedCompany === null) return;

                            const url = URLHandler.actions.editPlanning(selectedCompany.id, option.id);
                            navigate(url);
                        },
                    }}
                    editAction={{
                        helperText: t("action.modifyAction"),
                        action: () => {},
                    }}
                    deleteAction={{
                        helperText: t("action.deleteAction"),
                        action: () => {},
                    }}
                />,
            ]}
        />
    );
};
