import { MRT_ColumnDef, createMRTColumnHelper } from "material-react-table";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MrtColumns } from "src/utils/useGridColumns";
import {
    ActivitiesFormData,
    ActivitiesFormIngredientSupplier,
} from "src/pages/Ingredient/IngredientDetails/ActivitiesData/Activities.type";
import { ReactNode } from "react";
import { formatNumber } from "src/utils/formatting";
import { RoundNumberInput } from "@foodpilot/foods";

type OmittedKeysOfActivitiesFormIngredientSupplier = Omit<ActivitiesFormIngredientSupplier, "@id" | "id" | "supplier">;
type ActivitiesFormIngredientSupplierKeys = keyof OmittedKeysOfActivitiesFormIngredientSupplier;

export const useActivitiesColumns = (
    toggleEditMode: boolean,
    activitiesData: ActivitiesFormData,
    setActivitiesData: (value: ActivitiesFormData) => void,
): MrtColumns<ActivitiesFormIngredientSupplier> => {
    const { t } = useTranslation();
    const theme = useTheme();

    const columnHelper = createMRTColumnHelper<ActivitiesFormIngredientSupplier>();

    const mrt_columns = [
        columnHelper.accessor("supplier.name", {
            header: t("ingredient.activityForm.supplier"),
            Cell: (value) => (
                <Link sx={{ color: `${theme.palette.primary[700]}`, textDecoration: "none" }} variant="h5">
                    {value.renderedCellValue}
                </Link>
            ),
        }),
        columnHelper.accessor("quantity", {
            header: t("ingredient.activityForm.quantity"),
            Cell: (value) =>
                toggleEditMode ?
                    <InputBox
                        value={value.renderedCellValue?.toString()}
                        unit={"T."}
                        updateActivities={(newValue) =>
                            updateActivitiesData(
                                value.row.original.id,
                                newValue,
                                "quantity",
                                activitiesData,
                                setActivitiesData,
                            )
                        }
                    />
                :   <SalesBox value={value.renderedCellValue} unit={"T."} />,
        }),
        columnHelper.accessor("grossSales", {
            header: t("ingredient.activityForm.grossSales"),
            Cell: (value) =>
                toggleEditMode ?
                    <InputBox
                        value={value.renderedCellValue?.toString()}
                        unit={"€"}
                        updateActivities={(newValue) =>
                            updateActivitiesData(
                                value.row.original.id,
                                newValue,
                                "grossSales",
                                activitiesData,
                                setActivitiesData,
                            )
                        }
                    />
                :   <SalesBox value={value.renderedCellValue} unit={"€"} />,
        }),
        columnHelper.accessor("averagePrice", {
            header: t("ingredient.activityForm.averagePrice"),
            Cell: (value) =>
                toggleEditMode ?
                    <InputBox
                        value={value.renderedCellValue?.toString()}
                        unit={"€/T."}
                        updateActivities={(newValue) =>
                            updateActivitiesData(
                                value.row.original.id,
                                newValue,
                                "averagePrice",
                                activitiesData,
                                setActivitiesData,
                            )
                        }
                    />
                :   <SalesBox value={value.renderedCellValue?.toString()} unit={"€/T."} />,
        }),
    ] as MRT_ColumnDef<ActivitiesFormIngredientSupplier, string>[];

    return mrt_columns;
};

type SalesBoxProps = {
    value: ReactNode | undefined;
    unit: string;
};

const SalesBox = (props: SalesBoxProps) =>
    props.value ?
        <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
            <Typography variant="h5">{formatNumber(props.value.toString())}</Typography>
            <Typography variant="body-medium" sx={{ color: "#A3A2A0" }}>
                {props.unit}
            </Typography>
        </Box>
    :   "-";

type InputBoxProps = {
    value: string | undefined;
    unit: string;
    updateActivities: (newValue: string) => void;
};

const InputBox = (props: InputBoxProps) => (
    <RoundNumberInput
        placeholder={"0"}
        value={props.value}
        unit={props.unit}
        onChange={(e) => {
            props.updateActivities(e.target.value);
        }}
    />
);

const updateActivitiesData = (
    originalId: number | undefined,
    value: string,
    key: ActivitiesFormIngredientSupplierKeys,
    activitiesData: ActivitiesFormData,
    setActivitiesData: (value: ActivitiesFormData) => void,
) => {
    if (!originalId) return;

    const supplierIndex = activitiesData.suppliers.findIndex((supplier) => supplier.id === originalId);

    if (supplierIndex === -1) return;

    const newSupplier = activitiesData.suppliers[supplierIndex];

    newSupplier[key] = parseInt(value);

    if (key === "averagePrice") {
        newSupplier["grossSales"] = (newSupplier["quantity"] ?? 0) * (newSupplier["averagePrice"] ?? 0);
    } else {
        newSupplier["averagePrice"] = (newSupplier["grossSales"] ?? 0) / (newSupplier["quantity"] ?? 0);
    }

    const updatedSupplierData = activitiesData.suppliers.toSpliced(supplierIndex, 1, newSupplier);

    setActivitiesData({ suppliers: updatedSupplierData });
};
