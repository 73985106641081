import { AccordionContent, AccordionProduct } from "@foodpilot/foods";
import { ProductFormProductIngredient } from "src/pages/Product/ProductForm/ProductForm.types";
import { LineTitle } from "./LineTitle";
import { LineContent } from "./LineContent";

type IngredientAccordionProps = {
    ingredients: ProductFormProductIngredient[];
    setProductIngredient: (newIngredients: ProductFormProductIngredient[]) => void;
};
export const IngredientAccordion = (props: IngredientAccordionProps) => {
    const { ingredients, setProductIngredient } = props;

    const pages: AccordionContent[] = ingredients.map((ingredient) => {
        return {
            title: (
                <LineTitle
                    ingredient={ingredient}
                    setIngredient={(newIngredient) => {
                        const newIngredientList = ingredients.map((ing) => {
                            if (ing.ingredient["@id"] === newIngredient.ingredient["@id"]) {
                                return newIngredient;
                            }
                            return ing;
                        });
                        setProductIngredient(newIngredientList);
                    }}
                />
            ),
            content: <LineContent ingredient={ingredient} />,
        };
    });
    return <AccordionProduct pages={pages} />;
};
