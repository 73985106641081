import { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Box, Container, Typography, Divider, IconButton, Button } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { getForgotPasswordFn } from "src/api/authApi";

import VerifiedIcon from "@mui/icons-material/Verified";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useGetOneUser } from "src/api/client-api/user/useGetOneUser";
import { Loading } from "src/components/utils/Loading";
import { URLHandler } from "src/services/URLHandler";

export const User = () => {
    const { id } = useParams();

    const { user, isLoading } = useGetOneUser(id ?? "Completly bad ID");

    const { t } = useTranslation();

    const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
        page: 0,
        pageSize: 10,
    });
    const [emailSent, setEmailSent] = useState(false);
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [roles, setRoles] = useState<GridRowsProp>([]);

    type RoleRows = {
        id: number;
        role: string;
    };

    type CompanyRows = {
        id: number;
        name: string;
        type: string;
    };

    useEffect(() => {
        if (!user) return;
        const newRoles: RoleRows[] = [];

        user?.roles?.forEach((role, index) => {
            newRoles.push({
                id: index,
                role: role,
            });
        });
        setRoles(newRoles);

        const rows: CompanyRows[] = [];

        if (user.company) {
            rows.push({
                id: user.company.id,
                name: user.company.name,
                type: user.company.type,
            });
        }

        setRows(rows);
    }, [user]);

    const handlePaginationModelChange = (paginationModel: { page: number; pageSize: number }) => {
        setPaginationModel(paginationModel);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: t("Name"),
            flex: 2,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h4"
                        sx={{
                            whiteSpace: "break-spaces",
                            overflow: "hidden",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            lineClamp: "2",
                            "-webkit-box-orient": "vertical",
                        }}
                    >
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: "type",
            headerName: t("Type"),
            flex: 1,
        },
    ];

    const columnsRoles: GridColDef[] = [
        {
            field: "role",
            headerName: t("Role"),
            flex: 2,
            renderCell: (params) => {
                return (
                    <Typography
                        variant="h4"
                        sx={{
                            whiteSpace: "break-spaces",
                            overflow: "hidden",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            lineClamp: "2",
                            "-webkit-box-orient": "vertical",
                        }}
                    >
                        {params.value}
                    </Typography>
                );
            },
        },
    ];

    if (!user) return <Loading />;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "rows",
                    alignItems: "center",
                }}
            >
                <Typography variant="h2" style={{ flex: 10 }}>
                    {user.firstname} {user.lastname} {user.is_verified && <VerifiedIcon />}
                </Typography>
                <IconButton component={RouterLink} to={URLHandler.settings.users.edit(Number(id))}>
                    <EditIcon
                        sx={{
                            flex: 1,
                            "&:hover": {
                                color: "orange",
                                cursor: "pointer",
                            },
                        }}
                    />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "rows",
                    alignItems: "center",
                }}
            >
                {!emailSent ?
                    <Button
                        variant="text"
                        onClick={() => {
                            getForgotPasswordFn({ email: user.email });
                            setEmailSent(true);
                        }}
                        sx={{
                            padding: "0 !important",
                        }}
                    >
                        <Typography variant="h4" sx={{ textDecoration: "underline" }}>
                            {t("Envoyer un mail de réinitialisation du mot de passe")}
                        </Typography>
                    </Button>
                :   <Typography variant="h4" color="secondary">
                        {t("Mail de réinitialisation de mot de passe envoyé à l'utilisateur")}
                    </Typography>
                }
            </Box>
            <Box sx={{ py: 1 }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "rows",
                    alignItems: "center",
                }}
            >
                <Typography>{user.email}</Typography>
            </Box>
            <Typography>{user.phone}</Typography>
            <Box sx={{ py: 2 }} />

            <h2>{t("Roles")}</h2>
            <DataGrid
                autoHeight
                rowHeight={40}
                rows={roles}
                columns={columnsRoles}
                pagination
                loading={isLoading}
                onPaginationModelChange={handlePaginationModelChange}
                paginationMode="server"
                paginationModel={paginationModel}
                disableRowSelectionOnClick
            />

            <Divider sx={{ mb: 2 }} />

            {user.company && (
                <>
                    <h2>{t("Entreprise")}</h2>
                    <DataGrid
                        autoHeight
                        rowHeight={40}
                        rows={rows}
                        columns={columns}
                        pagination
                        loading={isLoading}
                        onPaginationModelChange={handlePaginationModelChange}
                        paginationMode="server"
                        paginationModel={paginationModel}
                        disableRowSelectionOnClick
                    />
                </>
            )}
        </Container>
    );
};
