import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import {
    SupplierFormContributor,
    SupplierFormIngredientSupplier,
    SupplierFormType,
} from "./SupplierForm/SupplierForm.types";
import { SupplierWrite, SupplierWriteIngredientSupplier } from "src/api/client-api/suppliers/useCreateSupplier";
import { formatStringForBackend } from "src/utils/formatting";
import { SupplierGrid } from "src/api/client-api/suppliers/useGetAllSuppliers";

export class SupplierService {
    static getSupplierToCreate = (existingSupplier?: Supplier): SupplierFormType => {
        if (existingSupplier === undefined) {
            return {
                name: null,
                clientId: null,
                users: [],
                ingredientSuppliers: [],

                type: "provider",
            };
        }

        // Returns a supplier to create from an existing new one.
        const users = existingSupplier.users?.map((user) => {
            const userWrite: SupplierFormContributor = {
                "@id": user["@id"],
                firstname: user.firstname,
                lastname: user.lastname,
            };
            return userWrite;
        });

        const ingSuppliers = existingSupplier.ingredientSuppliers?.map((existingIngSup) => {
            const existingToCreate: SupplierFormIngredientSupplier = {
                "@id": existingIngSup["@id"],
                ingredient: {
                    "@id": existingIngSup.ingredient["@id"],
                    name: existingIngSup.ingredient.name,
                    ingredientReferenceId: existingIngSup.ingredient.ingredientReferenceId,
                    ingredientLine: existingIngSup.ingredient.ingredientLine,
                },
                quantity: null,
                unit: null,
            };
            return existingToCreate;
        });

        return {
            id: existingSupplier.id,
            name: existingSupplier.name,
            clientId: existingSupplier.clientId ?? null,
            users: users ?? [],
            ingredientSuppliers: ingSuppliers ?? [],

            // To replace with existingSupplier
            type: "provider",
        };
    };

    static supplierFormToSupplierWrite = (supplier: SupplierFormType): SupplierWrite => {
        const users = supplier.users?.flatMap((user) => {
            if (user["@id"] === undefined) return [];

            return [user["@id"]];
        });

        const ingredients = supplier.ingredientSuppliers.map((ingSupplier) => {
            const ingSuppId = ingSupplier["@id"];
            if (ingSuppId) {
                const existingIngSupp: SupplierWriteIngredientSupplier = ingSuppId;
                return existingIngSupp;
            }

            const newIngSupp: SupplierWriteIngredientSupplier = {
                ingredient: ingSupplier.ingredient["@id"],
                quantity: null,
                unit: null,
            };
            return newIngSupp;
        });

        const supplierToCreate: SupplierWrite = {
            id: supplier.id,
            name: formatStringForBackend(supplier.name),
            clientId: formatStringForBackend(supplier.clientId),
            type: supplier.type,

            users: users,
            ingredientSuppliers: ingredients,
        };

        return supplierToCreate;
    };

    static makeSupplierListWithSectors = (suppliers: SupplierGrid[]): SupplierGrid[] => {
        return suppliers.map((supplier) => {
            const sectorSet = new Set<string>([]);
            const sectors = supplier.ingredientSuppliers?.reduce((acc, ingredientSupplier) => {
                const sector = ingredientSupplier.ingredient.ingredientLine?.name;
                if (sector === undefined) return acc;

                acc.add(sector);
                return acc;
            }, sectorSet);

            return {
                ...supplier,
                sectors: Array.from(sectors ?? []),
            };
        });
    };
}
