import { Button, DottedBox, FoodsIcon, FormBox, Typography } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

type NoIngredientProps = {
    openModal: () => void;
};
export const NoIngredient = (props: NoIngredientProps) => {
    const { t } = useTranslation();
    return (
        <FormBox>
            <DottedBox>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "2rem",
                    }}
                >
                    <Typography variant="big-medium">{t("Aucun ingrédient associé")}</Typography>
                    <Button variant="text" startIcon={<FoodsIcon icon="plus" size={2} />} onClick={props.openModal}>
                        {t("Associer des ingrédients")}
                    </Button>
                </div>
            </DottedBox>
        </FormBox>
    );
};
