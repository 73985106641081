import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { useGetOneIngredientReference } from "src/api/sure-api/ingredientReference/useGetOneIngredientReference";
import { QuestionnaireUpdateFormReference } from "./QuestionnaireUpdateFormReference";
import { QuestionnaireUpdateFormBase } from "./QuestionnaireUpdateFormBase";
import { useTranslation } from "react-i18next";

type QuestionnaireUpdateFormProps = {
    ingredient: Ingredient;
};
export const QuestionnaireUpdateForm = (props: QuestionnaireUpdateFormProps) => {
    const { ingredient } = props;
    const { t } = useTranslation();

    const { ingredientReference, isLoading } = useGetOneIngredientReference(ingredient.ingredientReferenceId);

    if (isLoading) return <QuestionnaireUpdateFormBase ingredient={ingredient} isLoading={true} />;

    if (!ingredientReference) return t("Aucun ingrédient de référence n’est associé à l’ingrédient");

    return <QuestionnaireUpdateFormReference ingredient={ingredient} ingredientReference={ingredientReference} />;
};
