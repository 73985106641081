import { Fragment } from "react/jsx-runtime";
import { CompanyList } from "../Selector/CompanySelector/CompanyList";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { CompanyMandatoryFields } from "src/services/Companies/CompaniesService";
import { useSwitchCompany } from "src/services/Companies/useSwitchCompany";
import { Box, Typography } from "@mui/material";
import { FoodsIcon } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

type CompanySelectorSidebarProps = {
    allCompanies: CompanyMandatoryFields[];
};
export const CompanySelectorSidebar = (props: CompanySelectorSidebarProps) => {
    const { selectedCompany } = useFoodpilotContext();
    const { t } = useTranslation();
    const { allCompanies } = props;
    const { changeCompany } = useSwitchCompany();

    if (selectedCompany === null) return;
    return (
        <Fragment>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",

                    justifyContent: "left",
                    alignItems: "center",
                }}
            >
                <FoodsIcon size={4} icon="organization" />
                <Typography variant="h4">{t("sidebar.selectOrganization")}</Typography>
            </Box>
            <CompanyList
                companies={allCompanies}
                selectedCompany={selectedCompany}
                setSelectedCompany={(newCompany) => {
                    changeCompany(newCompany.id);
                }}
            />
        </Fragment>
    );
};
