import { FormProperty, FormPropertyProps } from "./";
import { FormPropertyBox } from "@foodpilot/foods";
import { FormattedScores, Score } from "src/types";
import { getFormattedScoreByScoreId, getScoreVariationValue } from "src/utils/ssq";
import { useScoreContext } from "../../context/ScoreContext";
import { Formula } from "src/api/sure-api/ssq/useGetOneSsq.ts";

export type FormPropertyContainerProps = FormPropertyProps & {
    formattedScores?: FormattedScores;
    canEdit: boolean;
};
export const FormPropertyContainer = (props: FormPropertyContainerProps) => {
    const { property } = props;
    const { displayedScoreId, displayedVariationId, formattedScores, scores } = useScoreContext();

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const propertyScore = formattedScore?.properties?.[property.id];
    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const score = scores.find((score: Score) => score.id === displayedScoreId);
    const value =
        propertyScore && score ? getScoreVariationValue(propertyScore, score, displayedVariationId) : undefined;
    const unit = scoreVariation?.unit_abbreviation ?? scoreVariation?.unit;
    const defaultPrecision = 3;

    // TODO refactor when proper calculation type is implemented and PEF is handled differently
    const eligibleFormulas = property.formulas.filter(
        (formula: Formula) =>
            formula.score === undefined || formula.score.id === displayedScoreId || score?.type === "pef",
    );
    const doDisplayIndicator = (eligibleFormulas.length > 0 || property.children.length > 0) && value != null;

    return (
        <FormPropertyBox
            title={property.title}
            hint={property.hint}
            indicator={
                doDisplayIndicator ?
                    {
                        value: Number(value.toFixed(score?.precision ?? defaultPrecision)),
                        unit: unit,
                    }
                :   undefined
            }
        >
            <FormProperty {...props} />
        </FormPropertyBox>
    );
};
