import { Box } from "@mui/material";
import { UnderConstruction } from "../components/UnderConstruction";
import { useTranslation } from "react-i18next";

export function DocumentationPage() {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: "40px 52px" }}>
            <h1>{t("Documentation")}</h1>
            <UnderConstruction />
        </Box>
    );
}
