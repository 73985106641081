import { BlockOptions, CheckboxBlock } from "@foodpilot/foods";
import { useState } from "react";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { ActionPropertiesWithAnswers } from "src/context/Actions/ActionService";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type CheckboxProps = {
    propertyWithElements: ActionPropertiesWithAnswers;
};
export const Checkbox = (props: CheckboxProps) => {
    const { currentCampaign, targetCampaign } = useFoodpilotContext();
    const { propertyWithElements } = props;

    const options = propertyWithElements.property.dataList?.options ?? [];
    options.sort((optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position);

    const previousValueIds = propertyWithElements.previousElement?.value.split("|");
    const previousValues = options.filter(
        (option: DataListOption) => (previousValueIds ?? []).indexOf(option.value) > -1,
    );

    const initialValueIds = propertyWithElements.currentElement?.value.split("|");
    const initialValue = options.filter((option: DataListOption) => (initialValueIds ?? []).indexOf(option.value) > -1);

    const [value, setValue] = useState<DataListOption[] | undefined>(initialValue);

    const previousComparisonValue = {
        year: `Value in ${currentCampaign?.label}`,
        value: previousValues.map((i) => i.label),
    };

    const blockOptions: BlockOptions = {
        rightSubtitle: `Objectif ${targetCampaign?.label}`,
        title: propertyWithElements.property.title ?? "",
        comparisonValues: {
            start: previousComparisonValue,
            current: undefined,
        },
    };

    return (
        <CheckboxBlock
            blockOptions={blockOptions}
            selectedItems={value}
            values={options}
            onChange={(selectedValues) => setValue(selectedValues)}
            getId={(option) => option.value}
            getName={(option) => option.label}
        />
    );
};
