import { MenuItem, MenuItemProps, useTheme } from "@mui/material";

export const CreateNewMenuItem = (props: MenuItemProps) => {
    const theme = useTheme();

    return (
        <MenuItem
            sx={{
                padding: 0,
                gap: "12px",
                "&.MuiButtonBase-root": {
                    opacity: 1,
                    borderRadius: 0,
                    "> *:first-child": {
                        maxWidth: "20px",
                    },
                    "> *:nth-child(2)": {
                        margin: 0,
                    },
                    ".MuiTouchRipple-root": {
                        display: "none",
                    },
                    "&.Mui-disabled > *:not(.MuiStack-root:last-child)": {
                        color: theme.custom.grey[1400],
                        fill: theme.custom.grey[1400],
                    },
                },
                "&:hover": {
                    background: "transparent",
                    color: theme.custom.grey[2000],
                },
            }}
            {...props}
        />
    );
};
