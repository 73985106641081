import { GenericAccordion } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import Contributors from "src/assets/icons/people.svg?react";
import { DisplayUsers, UserInterface } from "../Page2/DisplayUsers";
import { ModifyButton } from "./ModifyButton";

type ContributorsSectionProps = {
    users: UserInterface[];
};
export const ContributorsSection = (props: ContributorsSectionProps) => {
    const { t } = useTranslation();
    const { users } = props;

    return (
        <GenericAccordion
            headerIcon={<Contributors />}
            headerText={t("Contributeurs")}
            controls={<ModifyButton pageNumber={1} />}
        >
            <DisplayUsers users={users} />
        </GenericAccordion>
    );
};
