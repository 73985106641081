import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useGetAllMediaContent } from "src/api/client-api/cms/useGetAllMedias";
import { Loading } from "src/components/utils/Loading";
import { UpsertMedia } from "./Common/UpsertMedia";

export const MediaSettings = () => {
    const theme = useTheme();
    const { allMedias, isFetched } = useGetAllMediaContent();

    if (isFetched === false) return <Loading />;

    const maxImgContraints = 500;
    const existingMedias = allMedias === undefined ? [] : allMedias;
    return (
        <Stack flexDirection={"column"} padding={theme.spacing(4, 8)}>
            <Typography variant="huge-bold">{"Image admin"}</Typography>
            <Typography variant="body-medium">{"Image are adjusted to this list"}</Typography>
            <Stack gap={theme.spacing(3)} flexDirection={"row"} overflow={"scroll"}>
                {existingMedias.map((media) => {
                    return (
                        <img
                            src={media.url}
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                                width: maxImgContraints,
                                objectFit: "contain",
                            }}
                        />
                    );
                })}
            </Stack>
            <UpsertMedia />
        </Stack>
    );
};
