import React, { ReactElement, FC } from "react";
import { Box, Chip, Typography, Autocomplete, TextField } from "@mui/material";
import { IPeriodEntries } from "../../api/types";

type IAccountingSelectionProps = {
    accounts: any;
    periodEntries: IPeriodEntries;
    handleChange: any;
    handleDelete: any;
    type: string;
    subtype: string;
    title: string;
};

export const AccountingSelection: FC<IAccountingSelectionProps> = ({
    accounts,
    periodEntries,
    handleChange,
    handleDelete,
    type,
    subtype,
    title,
}): ReactElement => {
    const selectedAccounts: any = {};
    if (periodEntries && periodEntries[subtype] && periodEntries[subtype]["entries"]) {
        periodEntries[subtype]["entries"].map((entry: any) => {
            selectedAccounts[entry.account] = entry.account;
        });
    }

    return (
        <Box
            sx={{
                pt: 5,
            }}
        >
            <Typography variant="body1" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                {title}
            </Typography>
            <Autocomplete
                onChange={(e: React.SyntheticEvent<Element, Event>, value: string | null) =>
                    handleChange(type, subtype, e, value)
                }
                options={accounts}
                id={type}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Veuillez sélectionner le plan comptable"
                        helperText="Laisser vide si vous n'en avez pas"
                        variant="standard"
                    />
                )}
            />
            {selectedAccounts && Object.keys(selectedAccounts).length > 0 && (
                <Box>
                    {Object.keys(selectedAccounts).map((account: any, index: number) => {
                        return (
                            <Chip
                                key={index}
                                label={account}
                                sx={{ m: 1, p: 1 }}
                                color="primary"
                                onDelete={(value: string | null) => handleDelete(type, subtype, account, value)}
                            />
                        );
                    })}
                </Box>
            )}
            {periodEntries && periodEntries[subtype] && periodEntries[subtype]["entries"] && (
                <Box sx={{ border: "1px solid #000", p: 3 }}>
                    {periodEntries[subtype]["entries"]
                        .reduce((acc: any, entry: any) => {
                            console.log("entry", entry);
                            return acc + entry.debit - entry.credit;
                        }, 0)
                        .toFixed(2)}
                    {/* periodEntries[subtype]["entries"].map((entry: any) => {
              console.log("entry", entry);

              return (
                <Stack direction="row" spacing={2} key={entry.lineid}>
                  <Box>période: {entry.period}</Box>
                  <Box>Débit: {entry.debit}</Box>
                </Stack>
              );
            })} */}
                </Box>
            )}
        </Box>
    );
};
