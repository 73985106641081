import { CategoryBox } from "@foodpilot/foods";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useProductReference } from "src/api/sure-api/useProductReference";

type InfoProps = {
    title?: string | null;
    clientId?: string | null;
    productLine?: string | null;
    productReferenceId?: number | null;
    brandName?: string | null;
    netWeight?: number | null;
    tags: string[];
};
export const ProductInformationSummary = (props: InfoProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { title, clientId, productReferenceId, brandName, netWeight, tags, productLine } = props;
    const { productsReference, isLoading, isError } = useProductReference();

    if (productsReference === undefined) return;

    const productReference = productsReference.find((reference) => reference.id === productReferenceId);
    const noValue = "-";
    return (
        <Box
            component="table"
            sx={{
                display: "table",
                width: "100%",
                "&>tr>td": {
                    width: "430px",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                    padding: "20px 0px",
                },
            }}
        >
            <tr>
                <td>
                    <Typography variant="h4">{t("product_name")}</Typography>
                </td>
                <td>
                    <Typography variant="big-medium">{title ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="h4">{t("Identifiant unique")}</Typography>
                </td>
                <td>
                    <Typography variant="big-medium">{clientId ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="h4">{t("Produit de référence associé")}</Typography>
                </td>
                <td>
                    {isLoading ?
                        <CircularProgress size="18px" />
                    : isError ?
                        <div>{t("Une erreur est survenue")}</div>
                    :   productsReference !== undefined && (
                            <Typography variant="big-medium">{productReference?.name ?? noValue}</Typography>
                        )
                    }
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="h4">{t("Marque")}</Typography>
                </td>
                <td>
                    <Typography variant="big-medium">{brandName ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="h4">{t("Poids net")}</Typography>
                </td>
                <td>
                    <Typography variant="big-medium">{netWeight ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="h4">{t("Gamme")}</Typography>
                </td>
                <td>
                    <Typography variant="big-medium">{productLine ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="h4">{t("Catégories")}</Typography>
                </td>
                <td>
                    {tags.length === 0 ?
                        <Typography variant="big-medium">{noValue}</Typography>
                    :   <Box sx={{ display: "flex", gap: "10px" }}>
                            {tags.map((tag) => {
                                return <CategoryBox key={`${tag}-summary-tag`} text={tag} />;
                            })}
                        </Box>
                    }
                </td>
            </tr>
        </Box>
    );
};
