import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowsProp,
    GridRowModesModel,
} from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import { Loading } from "src/components/utils/Loading";
import { useSnackbar } from "src/utils/useSnackbar";
import { useGetAllIngredientLines } from "src/api/client-api/ingredientLines/useGetAllIngredientLine";
import { useDeleteIngredientLine } from "src/api/client-api/ingredientLines/useDeleteIngredientLine.ts";
import { URLHandler } from "src/services/URLHandler";

export const IngredientLineList = () => {
    const { allIngredientLines } = useGetAllIngredientLines();

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const deleteIngredientLine = useDeleteIngredientLine();
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
        page: 0,
        pageSize: 10,
    });
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [displayDialog, setDisplayDialog] = useState(false);
    const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

    type IngredientLinesListRows = {
        id: number;
        name: string;
    };

    useEffect(() => {
        if (!allIngredientLines) return;

        const rows: IngredientLinesListRows[] = [];
        allIngredientLines.forEach((ingredientLines) => {
            rows.push({
                id: ingredientLines.id,
                name: ingredientLines.name,
            });

            setRows(rows);
        });
    }, [allIngredientLines]);

    const handlePaginationModelChange = (paginationModel: { page: number; pageSize: number }) => {
        setPaginationModel(paginationModel);
    };

    const handleDeleteClick = (id: number | string) => () => {
        if (typeof id === "string") return;

        setIdToDelete(id);
        setDisplayDialog(true);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Nom",
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: false,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 200,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <IconButton component={RouterLink} to={URLHandler.settings.ingredientLine.edit(Number(id))}>
                                <EditIcon />
                            </IconButton>
                        }
                        label="Edit"
                        className="textPrimary"
                        color="inherit"
                        key={id}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id.valueOf())}
                        color="inherit"
                        key={id}
                    />,
                ];
            },
        },
    ];
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const EditToolbar = () => {
        const handleClick = () => {
            navigate(URLHandler.settings.ingredientLine.create());
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {"Ajouter une Filière"}
                </Button>
            </GridToolbarContainer>
        );
    };

    if (allIngredientLines === undefined) return <Loading />;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {"Filière"}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>Êtes-vous sûr ?</DialogTitle>
                <DialogContent dividers>
                    {t("Cliquez sur 'oui' pour supprimer définitivement l'entreprise.")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={() => {
                            if (idToDelete) {
                                deleteIngredientLine.mutate(idToDelete, {
                                    onError: () => {
                                        snackbar.setSnackbarProps({
                                            message: "Unknown error...",
                                            type: "error",
                                        });
                                    },
                                });
                            }
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                onRowModesModelChange={handleRowModesModelChange}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                sx={getGridStyle(10)}
            />
        </Container>
    );
};

const getGridStyle = (length: number) => {
    return {
        border: "none",
        backgroundColor: "transparent",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            marginBottom: "2px",
            border: 0,
            backgroundColor: "transparent",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#797A77",
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        "& .MuiDataGrid-row.Mui-selected": {
            background: " #FFFFFF",
            border: 0,
        },
        "& .MuiDataGrid-cell": {
            border: 0,
            paddingLeft: "20px",
            outline: "none !important",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: length * 3,
            height: `fit-content`,
            boxSizing: "content-box",
        },
    };
};
