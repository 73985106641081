import { Outlet } from "react-router-dom";
import { Forbiddenpage } from "../PageState/ForbiddenPage";
import { AppParamModeKeys, useClientSwitch } from "./useClientSwitch";

type RouterClientSwitchProps = {
    defaultAuthorization?: boolean;
    whitelistedMode: AppParamModeKeys[];
};
export const RouterClientSwitch = (props: RouterClientSwitchProps) => {
    const { defaultAuthorization = false, whitelistedMode } = props;
    const clientSwitch = useClientSwitch();

    const authorizedEnv = clientSwitch({
        defaultOption: defaultAuthorization,
        bred: whitelistedMode.includes("bred"),
        collective: whitelistedMode.includes("collective"),
        foodpilot: whitelistedMode.includes("foodpilot"),
        inexweb: whitelistedMode.includes("inexweb"),
    });

    if (authorizedEnv === false) {
        return <Forbiddenpage />;
    }

    return <Outlet />;
};
