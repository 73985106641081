import { FoodsSelectServerPagination, Typography } from "@foodpilot/foods";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useGetAllIngredientLines } from "src/api/client-api/ingredientLines/useGetAllIngredientLine";
import { IngredientFormType } from "../../IngredientForm.types";
import { useDelayedSearch } from "src/utils/useDelayedSearch";

type FilliereFieldProps = {
    ingredient: IngredientFormType;
    onChange: (newIngredient: IngredientFormType) => void;
    required?: boolean;
};
export const FilliereField = (props: FilliereFieldProps) => {
    const { ingredient, onChange, required } = props;

    const { search, setSearch, delayedSearch } = useDelayedSearch();
    const { allIngredientLines = [] } = useGetAllIngredientLines({
        name: delayedSearch,
    });
    const { t } = useTranslation();
    const isSearchFound = (value: string) => {
        if (delayedSearch === "") {
            return true;
        }

        return value.toLowerCase().includes(delayedSearch.toLowerCase());
    };

    const requiredText = required ? "*" : "";

    return (
        <FormControl size="small">
            <Typography variant="h5" sx={{ paddingBottom: "0.5rem" }}>
                {`${t("Filière")}${requiredText} :`}
            </Typography>
            <FoodsSelectServerPagination
                onChange={(data) => {
                    const newIngredient = {
                        ...ingredient,
                        ingredientLine: data,
                    };
                    onChange(newIngredient);
                }}
                options={allIngredientLines}
                selectedOption={ingredient.ingredientLine}
                getId={(item) => item["@id"]}
                getName={(item) => item.name}
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
                search={search}
                setSearch={setSearch}
                isSearchFound={isSearchFound}
                BoxProps={{ width: "75%" }}
                BoxOptionsProps={{ width: "450px" }}
            />
        </FormControl>
    );
};
