import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTransformationReference } from "src/api/sure-api/useTransformationProcess";
import RecycleBlackIcon from "src/assets/icons/recycle-black.svg?react";

type PTransform = {
    productTransformationProcessId: number;
};

type ProductTransformationSummaryProps = {
    productTransformations: PTransform[];
};
export const ProductTransformationSummary = (props: ProductTransformationSummaryProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { productTransformations } = props;

    const { transformationReference } = useTransformationReference();

    if (productTransformations.length === 0) {
        return (
            <Typography
                variant="h3"
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                }}
            >
                {t("Pas de mode production associé à ce produit")}
            </Typography>
        );
    }

    const noValue = "-";
    return (
        <Stack direction="row">
            <div style={{ width: "66%" }}>
                <Stack direction="row" alignItems="center">
                    <RecycleBlackIcon
                        style={{
                            background: theme.custom.grey[400],
                            borderRadius: "100%",
                            width: "32px",
                            height: "32px",
                            padding: "5px",
                        }}
                    />
                    <Typography variant="h6" sx={{ marginLeft: "12px" }}>
                        {t("Etapes de transformation")}
                    </Typography>
                </Stack>
                <Box
                    component="table"
                    sx={{
                        width: "100%",
                        display: "table",
                        "&>tr>td": {
                            width: "530px",
                            borderBottom: `1px solid ${theme.custom.grey[500]}`,
                            padding: "16px 0px",
                        },
                    }}
                >
                    {productTransformations.map((transformation, index) => {
                        const foundprocess = transformationReference.find(
                            (process) => process.id === transformation.productTransformationProcessId,
                        );

                        return (
                            <tr key={index}>
                                <td>
                                    <Typography variant="body-bold" sx={{ paddingLeft: "12px" }}>
                                        {foundprocess?.label ?? noValue}
                                    </Typography>
                                </td>
                            </tr>
                        );
                    })}
                </Box>
            </div>
        </Stack>
    );
};
