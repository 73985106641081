import { Route, redirect, isRouteErrorResponse, useRouteError, Outlet } from "react-router-dom";
import { Stack, useTheme } from "@mui/material";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { AuthWithToken } from "./AuthWithToken";
import { useTranslation } from "react-i18next";
import { Footer } from "src/components/Footer";
import { useGetSettings } from "src/api/client-api/settings/useSettings";
import { LocalStorage } from "src/services/LocalStorage";
import { usersMeSession } from "src/api/axiosClient/userApi";
import { OAuthLogin } from "./OAuthLogin";
const logout = async () => {
    try {
        // Invalidating my cookie at logout.
        // Maybe I shouldn't but for now, if the cookie fails to be invalidated
        // I just consider that it's too bad.
        await usersMeSession.post("/api/token/invalidate");
    } catch (err) {
        // Kilian: Don't do the same mistake like me. Don't early return here.
        console.log("Logging out...");
    }

    // Make sure we delete the Impersonate;
    LocalStorage.deleteItem("connectedAs");

    return redirect("/login");
};

export const useAuthRouter = () => {
    return (
        <Route element={<AuthLayout />} errorElement={<AuthError />}>
            <Route path="logout" loader={logout} />
            <Route path="login" element={<Login />} />
            <Route path="oauth/:providerName" element={<OAuthLogin />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="auth" element={<AuthWithToken />} />
        </Route>
    );
};

const AuthLayout = () => {
    const theme = useTheme();
    const { settings } = useGetSettings();

    return (
        <Stack
            width="100%"
            height="100%"
            alignItems="stretch"
            justifyContent="space-between"
            sx={{
                backgroundColor: theme.palette.primary[50],
                px: "80px",
                paddingTop: "52px",
            }}
        >
            <Stack flex={1} alignItems="center" justifyContent="center">
                {settings?.contentUrl && (
                    <Stack alignItems="center" gap="32px" width="calc(100% / 3)">
                        <img src={settings.contentUrl} alt="theme" width="200" />
                        <Outlet />
                    </Stack>
                )}
            </Stack>
            <Footer />
        </Stack>
    );
};

const AuthError = () => {
    const error = useRouteError();
    const { t } = useTranslation();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>{t("Page inexistante")} 404</div>;
        } else if (error.status === 401) {
            return <div>{t("Unauthorized")}: 401</div>;
        } else if (error.status === 503) {
            return <div>API down: 503</div>;
        } else if (error.status === 500) {
            return <div>API error: 500</div>;
        }
    }
    return <div>Hello, Error in the AuthRouter</div>;
};
