import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";
import { getOneCMSContentKey } from "../useGetOneContent";

type CreateBlock = {
    newBlock: BlockWrite;
};

export const useCreateOneBlock = () => {
    const queryClient = useQueryClient();

    const query = useMutation({
        mutationFn: async (options: CreateBlock) => {
            const { newBlock } = options;

            const url = `/api/cms/contents/pages/blocks/contents`;

            const response =
                newBlock.id === undefined ?
                    await loggedInApiCall.post(url, newBlock)
                :   await loggedInApiCall.patch(`${url}/${newBlock.id}`, newBlock, {
                        headers: {
                            "Content-Type": "application/merge-patch+json",
                        },
                    });

            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneCMSContentKey]);
        },
    });

    return query;
};

export type BlockWrite = {
    id?: UUID;
    title: string;
    subtitle: string;
    description: string;
    ctaLink: string;
    ctaLabel: string;
    media?: IRI;
    position: number;
    section: IRI;
};

export const BlockWriteSchema: z.ZodType<BlockWrite> = z.object({
    id: z.string().optional(),
    title: z.string(),
    subtitle: z.string(),
    description: z.string(),
    ctaLink: z.string(),
    ctaLabel: z.string(),
    media: z.string(),
    position: z.number(),
    section: z.string(),
});
