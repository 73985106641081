import { FoodsIcon } from "@foodpilot/foods";
import { Typography, TypographyProps, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

type TextLinkProps = {
    url: string;
    text: string;
    color?: string;
    typographyProps?: TypographyProps;
};
export const TextLink = (props: TextLinkProps) => {
    const theme = useTheme();
    const { url, text, color = theme.palette.primary[700], typographyProps = {} } = props;
    return (
        <Link
            to={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                color: color,
                textDecoration: "none",
            }}
        >
            <Typography variant="body-bold" sx={{ color: color }} {...typographyProps}>
                {text}
            </Typography>
            <FoodsIcon icon={"openNewTab"} size={1} />
        </Link>
    );
};
