import { useEffect, useState } from "react";
import { FoodsSelectServerPagination, SelectWrapperWithServerPaginationProps } from "@foodpilot/foods";
import { Box } from "@mui/material";
import SelectedItems from "src/components/Select/SelectedItems";

type MultipleSelectionSelectProps<T> = Omit<
    SelectWrapperWithServerPaginationProps<T>,
    "selectedOption" | "onChange"
> & {
    selectedOptions: T[];
    onChange: (selectedOptions: T[]) => void;
};

const MultipleSelectionSelect = <T,>({
    onChange,
    getId,
    getName,
    options,
    selectedOptions,
    search,
    setSearch,
    isSearchFound,
    ...props
}: MultipleSelectionSelectProps<T>) => {
    const [selectedItems, setSelectedItems] = useState<Array<T>>(selectedOptions);
    const selectedItemsId = selectedItems.map((item: T) => getId(item));
    const unselectedItems = options.filter((option) => !selectedItemsId.includes(getId(option)));

    const addItem = (newItem: T | null) => {
        if (newItem) {
            const newItems = [...selectedItems, newItem];
            setSelectedItems(newItems);
        }
    };

    useEffect(() => {
        onChange(selectedItems);
    }, [selectedItems]);

    return (
        <Box
            sx={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
                marginBottom: "1rem",
            }}
        >
            <FoodsSelectServerPagination
                {...props}
                selectedOption={null}
                options={unselectedItems}
                onChange={(newItem: T | null) => {
                    addItem(newItem);
                }}
                getId={getId}
                getName={getName}
                search={search}
                setSearch={setSearch}
                isSearchFound={isSearchFound}
            />

            <SelectedItems
                list={selectedItems}
                getName={getName}
                getId={getId}
                onDelete={(deletedItem: T) =>
                    setSelectedItems(selectedItems.filter((item: T) => getId(item) !== getId(deletedItem)))
                }
            />
        </Box>
    );
};
export default MultipleSelectionSelect;
