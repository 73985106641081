import { Fragment } from "react";
import { Route, isRouteErrorResponse, useRouteError } from "react-router-dom";
import OAuthPopup from "src/components/OAuth2Popup";
import { Inexweb } from "../Inexweb";
import { InexwebForm } from "./InexwebForm";
import { getInexwebInfo } from "src/utils/token";
import { QueryClient, useQueryClient } from "react-query";
import { getAccounts } from "src/api/sure-api/inexweb/useAccounts";
import { getFiscalYears } from "src/api/sure-api/inexweb/useFiscalYears";

const loader = async (queryClient: QueryClient) => {
    const { company } = getInexwebInfo();

    queryClient.prefetchQuery({
        queryKey: ["accounts", { company }],
        queryFn: () => getAccounts(),
    });
    queryClient.prefetchQuery({
        queryKey: ["fiscalYears", { company }],
        queryFn: () => getFiscalYears(),
    });

    return null;
};

export const useInexwebRouter = () => {
    const queryClient = useQueryClient();

    return (
        <Fragment>
            <Route id="inexweb" errorElement={<InexwebError />}>
                <Route element={<OAuthPopup />} path="/api/auth/callback" />
                <Route path="/inexweb">
                    <Route index element={<Inexweb />} />
                    <Route path="form/:pageId" element={<InexwebForm />} loader={() => loader(queryClient)} />
                </Route>
            </Route>
        </Fragment>
    );
};

const InexwebError = () => {
    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>Page inexistante 404</div>;
        } else if (error.status === 401) {
            return <div>Unauthorized: 401</div>;
        } else if (error.status === 503) {
            return <div>API down: 503</div>;
        } else if (error.status === 500) {
            return <div>API error: 500</div>;
        }
    }
    console.error(error);
    return <div>Hello this is an Error from Inexweb</div>;
};
