import { Stack } from "@mui/material";
import { InfoSection } from "./InfoSection";
import { IngredientSection } from "./IngredientSection";
import { ContributorsSection } from "./Contributors";
import { SupplierFormType } from "../../SupplierForm.types";

type Page4Props = {
    supplier: SupplierFormType;
};
const Page4 = (props: Page4Props) => {
    const { supplier } = props;
    return (
        <div>
            <Stack direction="column" useFlexGap gap="8px">
                <InfoSection supplier={supplier} />
                <ContributorsSection users={supplier.users} />
                <IngredientSection supplier={supplier} />
            </Stack>
        </div>
    );
};
export default Page4;
