import z from "zod";
import { useQuery } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "../../apiErrorLogs";

export const getOneBrandKey = "oneBrand" as const;

type Options = {
    enabled?: boolean;
};

export const useGetOneBrand = (brandId: string, options?: Options) => {
    const { enabled = true } = options || {};

    const query = useQuery({
        queryKey: getOneBrandKey,
        enabled: enabled,
        cacheTime: 0,
        queryFn: async () => {
            const url = `/api/brands/${brandId}`;
            return await loggedInApiCall.get(url);
        },
        select: (request) => {
            const brands = request.data;
            const data = zodParseWithLog(brands, BrandSchema, getOneBrandKey);
            return data;
        },
    });

    return {
        brand: query.data,
        ...query,
    };
};

type BrandCompany = {
    "@id": string;
    name: string;
};

export type Brand = {
    "@id": IRI;
    id: number;
    name: string;
    company: BrandCompany;
    logoUrl?: string | null;
};

export const BrandSchema: z.ZodSchema<Brand> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    company: z.object({
        "@id": z.string(),
        name: z.string(),
    }),
    logoUrl: z.string().optional(),
});
