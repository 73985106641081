import { useQuery } from "react-query";
import { inexwebApiCall } from "src/api/axiosClient/sureApi";
import { accountEntriesZodToAccountEntries, AccountEntriesSchema } from "src/types";
import { getInexwebInfo } from "src/utils/token";

export const useAccountEntries = (energyAccount?: string, dateStart?: string, dateEnd?: string, enabled = false) => {
    const url = `/api/inexweb/accounting/entries?account=${energyAccount}&date_start=${dateStart}&date_end=${dateEnd}`;
    const { company } = getInexwebInfo();

    return useQuery({
        queryKey: ["useAccountEntries", { energyAccount, dateStart, dateEnd, company }],
        queryFn: async () => {
            const response = await inexwebApiCall.get(url);
            return response.data;
        },
        select: (data) => {
            const res = AccountEntriesSchema.parse(data);

            return accountEntriesZodToAccountEntries(res).entries;
        },
        staleTime: 5 * 60 * 1000,
        enabled: !!energyAccount && !!dateStart && !!dateEnd && enabled,
    });
};
