import z from "zod";
import { useQuery } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "../apiErrorLogs";

export const useTags = () => {
    const query = useQuery({
        queryKey: "allTags",
        queryFn: async () => {
            return await loggedInApiCall.get("/api/tags");
        },
        select: (request) => {
            const tags = request.data["hydra:member"];
            const data = zodParseWithLog(tags, TagSchema.array(), "allTags");
            return data;
        },
    });

    return {
        tags: query.data,
        ...query,
    };
};

export type Tag = {
    "@id": IRI;
    id: number;
    name: string;
};

export const TagSchema: z.ZodSchema<Tag> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});
