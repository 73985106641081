import z from "zod";
import { useQuery } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { MainScoresSchema, MainScores } from "../post/scoreSchema";
import { mrtSortingStateToObject, SortType } from "src/api/apiHelpers.ts";

type Options = {
    cacheTime?: number;
    page?: number;
    itemsPerPage?: number;
    search?: string;
    productLine?: string;
    campaignId?: number;
    sorting?: Array<SortType>;
};

export const productGridKey = "productGrid" as const;

export const useGetAllProduct = (options?: Options) => {
    const {
        cacheTime = 0,
        page = 1,
        itemsPerPage = 20,
        search = "",
        productLine = "",
        sorting = [],
        campaignId,
    } = options || {};

    const query = useQuery({
        queryKey: [productGridKey, page, itemsPerPage, search, productLine, campaignId, sorting],
        staleTime: 0,
        cacheTime: cacheTime,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const url = `/api/products`;

            return await loggedInApiCall.get(url, {
                params: {
                    itemsPerPage: itemsPerPage,
                    page: page,
                    q: search !== "" ? search : undefined,
                    campaign: campaignId,
                    "productLine.name": productLine !== "" ? productLine : undefined,
                    order: mrtSortingStateToObject(sorting),
                },
            });
        },
        select: (request) => {
            const totalItems = request.data["hydra:totalItems"];
            const productGrid = request.data["hydra:member"] as GridProduct[];
            const data = zodParseWithLog(productGrid, GridProductSchema.array(), "allProduct");
            return {
                items: data,
                totalItems: totalItems,
            };
        },
    });

    return {
        products: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};

export type ProductLine = {
    "@id": IRI;
    id: number;
    name: string;
};

const ProductLineZodSchema: z.ZodSchema<ProductLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

export type Tag = {
    "@id": IRI;
    id: number;
    name: string;
};

const TagSchema: z.ZodSchema<Tag> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

export type Brand = {
    "@id": IRI;
    id: number;
    name: string;
    logoUrl?: string | null;
};

const BrandSchema: z.ZodSchema<Brand> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    logoUrl: z.string().optional(),
});

export type Campaign = {
    "@id": string;
};

export const CampaignSchema: z.ZodSchema<Campaign> = z.object({
    "@id": z.string(),
});

export type PostGrid = {
    id: number;
    scores: MainScores;
    campaign?: Campaign;
};

export const PostGridSchema: z.ZodSchema<PostGrid> = z.object({
    id: z.number(),
    scores: MainScoresSchema,
    campaign: CampaignSchema.optional(),
});

export type GridProduct = {
    id: number;
    title: string;
    productLine?: ProductLine;
    clientId?: string;
    tags?: Tag[];
    brand?: Brand;
    canView: boolean;
    canModify: boolean;
    canDelete: boolean;
    scores: MainScores;
};

export const GridProductSchema: z.ZodSchema<GridProduct> = z.object({
    id: z.number(),
    title: z.string(),
    productLine: ProductLineZodSchema.optional(),
    clientId: z.string().optional(),
    tags: TagSchema.array().optional(),
    brand: BrandSchema.optional(),
    canView: z.boolean(),
    canModify: z.boolean(),
    canDelete: z.boolean(),
    scores: MainScoresSchema,
});
