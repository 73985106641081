import { useGetOneSupplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { CompanyContext, CompanyContextInitialValues } from "./context";
import { useFoodpilotContext } from "../FoodpilotContext";
import { ScoreService } from "../EntityBasedScore/ScoreService";

type CompanyProviderProps = {
    children: JSX.Element;
};
export const CompanyProvider = (props: CompanyProviderProps) => {
    const { children } = props;
    const { currentCampaign, selectedCompany, getScoresByType } = useFoodpilotContext();
    const companyScores = getScoresByType("company");

    const { supplier, isFetched, isRefetching, scoreState } = useGetOneSupplier(selectedCompany?.id, {
        campaign: currentCampaign?.id,
        initDimensions: {
            dimensions: [["main"], ["properties"]],
        },
        initVariations: {
            defaultScoreId:
                companyScores.length === 0 ?
                    undefined
                :   {
                        scoreId: companyScores[0].id,
                        scoreVariationId: null,
                    },
        },
    });

    if (isRefetching === false) {
        if (isFetched === false) {
            // It's loading, cheers.
            return;
        }
    }

    if (supplier === undefined) {
        // Error ? The company must exist;
        return;
    }

    const allScoreWithVariations = ScoreService.getAllScoreVariations(
        companyScores,
        supplier.scores,
        (scoreId, scoreVariationId) => {
            scoreState.selectVariation(scoreId, scoreVariationId);
        },
    );

    const items: CompanyContextInitialValues = {
        company: supplier,
        companyScores,
        scoreState,
        allScoreWithVariations,
    };

    return <CompanyContext.Provider value={items}>{children}</CompanyContext.Provider>;
};
