import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getOneCMSContentKey } from "../useGetOneContent";
import { useSnackbar } from "src/utils/useSnackbar";

type ToggleSection = {
    sectionId: UUID;
    currentSectionState: boolean;
};

export const useToggleSectionVisibility = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();

    const query = useMutation({
        mutationFn: async (options: ToggleSection) => {
            const { sectionId, currentSectionState } = options;

            const sectionIdUrlFriendly = encodeURI(sectionId);
            const url = `/api/cms/contents/pages/sections/${sectionIdUrlFriendly}`;
            const payload: SectionTogglePayload = {
                visible: !currentSectionState,
            };
            const resp = await loggedInApiCall.patch(url, payload, {
                headers: {
                    "Content-Type": "application/merge-patch+json",
                },
            });

            return resp;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneCMSContentKey]);
            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "info",
                message: "Visibility updated !",
            });
        },
    });

    return query;
};

export type SectionTogglePayload = {
    visible: boolean;
};
