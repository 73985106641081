import { useQuery } from "react-query";
import { usersMeSession } from "../axiosClient/userApi";
import { zodParseWithLog } from "../apiErrorLogs";
import { z } from "zod";
import { CompanySectorZod } from "src/api/client-api/companySectors/useSupplierSectors.tsx";
import { ScoresSchema } from "./post/scoreSchema";
import { getDimensionsQueryParam, ScoreEntityEndpointOptions } from "../apiHelpers";

export const userMeKey = "usersMe" as const;

export const useUserNew = (options?: ScoreEntityEndpointOptions) => {
    const { campaign, variations, dimensions = [["main"]] } = options || {};

    const query = useQuery({
        retry: 0,
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        queryKey: [userMeKey, campaign, variations],
        queryFn: async (_context) => {
            const url = "/api/users/me";

            return await usersMeSession.get(url, {
                headers: {
                    Accept: "application/ld+json",
                },
                params: {
                    ...options,
                    dimensions: getDimensionsQueryParam(dimensions),
                },
            });
        },
        select: (request) => {
            return zodParseWithLog(request.data, UserZod, userMeKey);
        },
    });

    return {
        user: query.data,
        ...query,
    };
};

const CompanyZod = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    type: z.string().optional(),
    companySector: CompanySectorZod.optional(),
    scores: ScoresSchema.optional(),
    prevScores: ScoresSchema.optional(),
    prevCampaignName: z.string().optional(),
});

export type Company = z.infer<typeof CompanyZod>;

const UserZod = z
    .object({
        email: z.string(),
        id: z.number(),
        is_verified: z.boolean(),
        roles: z.array(z.string()),
        company: CompanyZod.optional(),
        companies: CompanyZod.array(),
        lastname: z.string(),
        firstname: z.string(),
        permissions: z.array(z.tuple([z.string(), z.string()])),
    })
    .readonly();

export type User = z.infer<typeof UserZod>;
