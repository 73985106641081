import { useTheme } from "@mui/material";
import { WhiteBox } from "@foodpilot/foods";
import {
    ScoresSection as ListScoresSection,
    ScoresSectionProps,
} from "src/components/QuestionnairesList/Scores/ScoresSection";
import { useScoreContext } from "src/context/ScoreContext";

type ScoresUpdateSectionProps = ScoresSectionProps & {
    scoresIds?: number[];
};
export const ScoresSection = (props: ScoresUpdateSectionProps) => {
    const theme = useTheme();
    const { post, headings, scoresIds } = props;
    const { formattedScores } = useScoreContext();

    return (
        <WhiteBox padding="20px">
            <ListScoresSection
                post={post}
                headings={headings}
                formattedScores={formattedScores}
                scoresIds={scoresIds}
                sx={{
                    border: "none",
                    ">.MuiBox-root": {
                        "&:first-of-type": {
                            borderRadius: "8px",
                            border: `1px solid ${theme.custom.grey[500]}`,
                            boxShadow: "none",
                        },
                        "&:last-child": {
                            border: "none",
                            padding: 0,
                        },
                    },
                }}
            />
        </WhiteBox>
    );
};
