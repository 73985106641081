import {
    MRT_ColumnDef,
    MRT_ColumnSizingState,
    MRT_RowData,
    MRT_Updater,
    createMRTColumnHelper,
} from "material-react-table";
import { useState, useEffect } from "react";
import { ValueWithScores } from "src/types";
import { Row } from "@tanstack/react-table";
import { GridScore } from "src/components/Grid/GridScore";
import { Score } from "src/types";
import { ColumnsService } from "./ColumnsService";
import { CircularProgress } from "@mui/material";
import { doesScoreExist } from "src/api/client-api/post/scoreSchema";

export type ColumnId = string;
export type ColumnConfigMapping = Partial<Record<ColumnId, ColumnConfig>>;
export type ColumnConfig = {
    visible: boolean;
    displayName: string;
};

export type MrtColumns<GridValue extends MRT_RowData> = MRT_ColumnDef<GridValue, string>[];

export type GridColumnsType<GridValue extends MRT_RowData> = {
    columnOrder: string[];
    setColumnOrder: (values: string[]) => void;
    columnConfig: ColumnConfigMapping;
    toggleVisibility: (columnKey: string, visible: boolean) => void;

    mrt_columns: MrtColumns<GridValue>;
    columnVisibility: Record<string, boolean>;
    columnSizing: Record<string, number>;
    setColumnSizing: (value: MRT_Updater<MRT_ColumnSizingState>) => void;
};
export const useGridColumns = <GridValue extends MRT_RowData>(
    mrt_columns: MrtColumns<GridValue>,
    gridKey: string,
): GridColumnsType<GridValue> => {
    const currentInitialColumnOrder = ColumnsService.getColumnOrder(gridKey);
    const currentInitialColumnConfig = ColumnsService.getColumnVisibility(gridKey);
    const currentColumnsSizing = ColumnsService.getColumnWidth(gridKey);

    ColumnsService.registerGrid(gridKey);

    const initialColumnOrder = currentInitialColumnOrder.concat(
        mrt_columns
            .map((col) => {
                // If the default value occurs, we need to find a key that is consistent
                // inside MRT Grid
                return col.accessorKey ?? col.id ?? "DefaultValueThatShouldNeverHappen";
            })
            .filter((id) => !currentInitialColumnOrder.some((colId) => colId === id)),
    );

    const initialColumnConfig = mrt_columns.reduce<ColumnConfigMapping>((accColumnConfig, mrt_col) => {
        // If the default value occurs, we need to find a key that is consistent
        // inside MRT Grid
        const colId = mrt_col.accessorKey ?? mrt_col.id ?? "DefaultValueThatShouldNeverHappen";
        const isVisible = currentInitialColumnConfig.get(colId) ?? true;

        accColumnConfig[colId] = {
            visible: isVisible,
            displayName: mrt_col.header,
        };

        return accColumnConfig;
    }, {});

    const [columnOrder, _setColumnOrder] = useState(initialColumnOrder);
    const [columnConfig, _setColumnConfig] = useState(initialColumnConfig);
    const [columnSizing, setColumnSizing] = useState(currentColumnsSizing);

    const setColumnOrder = (values: string[]) => {
        _setColumnOrder(values);
        ColumnsService.saveColumnOrder(gridKey, values);
    };

    const toggleVisibility = (columnKey: string, visible: boolean) => {
        const configKey = columnConfig[columnKey];

        // Trying to toggle a column that doesn't exist;
        if (configKey === undefined) return;
        const newConfigKey = {
            ...configKey,
            visible: visible,
        };

        const newConfig = {
            ...columnConfig,
            [columnKey]: newConfigKey,
        };
        _setColumnConfig(newConfig);

        currentInitialColumnConfig.set(columnKey, visible);
        ColumnsService.saveColumnVisibility(gridKey, currentInitialColumnConfig);
    };

    useEffect(() => {
        ColumnsService.saveColumnWidth(gridKey, columnSizing);
    }, [columnSizing, gridKey]);

    useEffect(() => {
        _setColumnOrder(initialColumnOrder);
        _setColumnConfig(initialColumnConfig);
        ColumnsService.saveColumnVisibility(gridKey, currentInitialColumnConfig);
    }, [mrt_columns.length]);

    const configArray = Object.entries(columnConfig);

    const columnVisibility = configArray.reduce<Record<string, boolean>>((accVisibility, [columnName, conf]) => {
        const unknownColumnVisibility = false;
        accVisibility[columnName] = conf?.visible ?? unknownColumnVisibility;
        return accVisibility;
    }, {});

    return {
        columnConfig,
        toggleVisibility,
        columnOrder,
        setColumnOrder,
        mrt_columns,
        columnVisibility,
        columnSizing,
        setColumnSizing,
    };
};

export const extendColumns = <GridValue extends MRT_RowData>(
    mrt_columns: MRT_ColumnDef<GridValue, string>[],
    column: MRT_ColumnDef<GridValue, string>,
    position: number,
) => {
    mrt_columns.splice(position, 0, column);
};

export const extendColumnsByScores = <GridValue extends ValueWithScores>(
    mrt_columns: MRT_ColumnDef<GridValue, string>[],
    scores: Score[],
    position?: number,
    isRefetching: boolean = false,
) => {
    const columnHelper = createMRTColumnHelper<GridValue>();
    const columnsLength = mrt_columns.length;

    for (const [i, score] of scores.entries()) {
        const scoreId = score.id;
        const scoreAccessorKey = `score-${scoreId}`;

        // Elipsis in header only work with &nbsp;
        const title = score.title.replace(/ /g, "\u00a0");

        extendColumns(
            mrt_columns,
            columnHelper.accessor(() => scoreAccessorKey, {
                id: scoreAccessorKey,
                header: title,
                enableSorting: false,
                Cell: (value) => {
                    if (isRefetching) return <CircularProgress size="18px" />;

                    return <GridScore scoreId={scoreId} value={value.cell.row.original} precision={score.precision} />;
                },
                sortingFn: (rowA, rowB) => {
                    return sortGridRowsByScores<GridValue>(scoreId, rowA, rowB);
                },
            }),
            (position ?? columnsLength) + i,
        );
    }
};

export const sortGridRowsByScores = <GridValue extends ValueWithScores>(
    scoreId: number,
    rowA: Row<GridValue>,
    rowB: Row<GridValue>,
) => {
    const scoreA = rowA.original.scores[scoreId];
    const scoreB = rowB.original.scores[scoreId];

    if (!doesScoreExist(scoreA)) return 1;
    if (!doesScoreExist(scoreB)) return -1;

    return scoreB.score - scoreA.score;
};
