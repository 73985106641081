import { Article, RoundInput } from "@foodpilot/foods";
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectMedia } from "src/pages/Settings/CMS/Common/SelectMedia";

export type ArticleBlock = {
    id: UUID | undefined;
    title: string;
    description: string;
    ctaLink: string;
    position: number;
    media: {
        "@id": IRI;
        url: string;
        alt: string;
        dimensions: [number, number];
    } | null;
};

type ArticleFormFieldsProps = {
    articleBlockForm: ArticleBlock;
    setArticleBlockForm: (newArticle: ArticleBlock) => void;
};
export const ArticleFormFields = (props: ArticleFormFieldsProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { articleBlockForm, setArticleBlockForm } = props;

    return (
        <Stack gap={theme.spacing(2)} padding={theme.spacing(2, 8)}>
            <Article
                title={articleBlockForm.title}
                description={articleBlockForm.description}
                imageSrc={articleBlockForm.media?.url ?? ""}
                onClick={() => {}}
                isFirst={false}
            />
            <Stack>
                <Typography variant="body-medium">{t("cms.articles.form.externalLink")}</Typography>
                <RoundInput
                    value={articleBlockForm.ctaLink}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setArticleBlockForm({
                            ...articleBlockForm,
                            ctaLink: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="body-medium">{t("glossary.title")}</Typography>
                <RoundInput
                    value={articleBlockForm.title}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setArticleBlockForm({
                            ...articleBlockForm,
                            title: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="body-medium">{t("glossary.description")}</Typography>
                <RoundInput
                    value={articleBlockForm.description}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setArticleBlockForm({
                            ...articleBlockForm,
                            description: newValue,
                        });
                    }}
                />
            </Stack>
            <SelectMedia
                selectedMediaIRI={articleBlockForm.media?.["@id"] ?? ""}
                selectNewMedia={(newMedia) => {
                    const newBlock: ArticleBlock = {
                        ...articleBlockForm,
                        media: newMedia,
                    };
                    setArticleBlockForm(newBlock);
                }}
            />
        </Stack>
    );
};
