import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography, useTheme, CircularProgress, Box } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { RoundInput, FormUnit } from "@foodpilot/foods";
import { DefaultValueButton } from "../DefaultValueButton";
import { formatUnitAbbreviation } from "src/utils/formatting";
import { useScoreContext } from "src/context/ScoreContext";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { FormTooltip } from "../FormTooltip";

export const Text = (props: FormPropertyProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { displayedScoreId, displayedVariationId, formattedScores } = useScoreContext();
    const { property, post, element, canEdit = true, isSubproperty, isMultivalued } = props;

    const defaultValue = property.defaultValue ?? "";
    const initialValue = element && !property.readonly ? element.value ?? "" : defaultValue;

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const propertyScore = formattedScore?.properties?.[property.id];

    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations?.[displayedVariationId ?? 0];

    const propertyVariations = propertyScore?.variations ?? {};
    const propertyVariation = propertyVariations?.[displayedVariationId ?? 0];

    const [previousValue, setPreviousValue] = useState(initialValue);
    const [value, setValue] = useState(initialValue);
    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    const genericValue = property.genericValue;

    if (!post) return <CircularProgress />;

    const unit = formatUnitAbbreviation(property.unit);

    const updatePropertyValue = (value?: string, isGenericValue?: boolean) => {
        const sanitizedValue = value?.trim() ?? "";

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: sanitizedValue,
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
        });
    };

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    const roundInput = (
        <FormTooltip title={tooltipText} arrow placement="right">
            <RoundInput
                type="text"
                value={value !== "_empty_" ? value : ""}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                onFocus={(e) => {
                    const isFocusDirect = !e.relatedTarget;

                    if (!isFocusDirect) {
                        return;
                    }

                    setPreviousValue(value);
                }}
                onBlur={(e) => {
                    const relatedTarget = e.nativeEvent.relatedTarget as Element;
                    const isTargetPopover = relatedTarget && relatedTarget.classList.contains("MuiPopover-paper");

                    if ((e.nativeEvent.type === "focusout" && isTargetPopover) || value === previousValue) {
                        return;
                    }

                    updatePropertyValue(value);
                }}
                disabled={property.readonly || !canEdit || genericValueSelected}
                sx={{
                    minWidth: "340px",
                    height: "40px",
                    borderRadius: "40px",
                    borderColor: theme.custom.grey[500],
                    ":hover": {
                        backgroundColor: theme.custom.grey[300],
                    },
                    "&.Mui-focused": {
                        outline: `2px solid ${theme.custom.grey[2800]}`,
                    },
                    "&.Mui-disabled": {
                        background: theme.custom.grey[500],
                        color: theme.custom.grey[2000],
                    },
                }}
            />
        </FormTooltip>
    );

    return (
        <Stack direction="column" gap="8px">
            <Stack direction="row" alignItems="center" gap="12px">
                {roundInput}
                {unit && <Typography variant="body">{unit}</Typography>}
                {!property.readonly && defaultValue && !genericValueSelected && (
                    <DefaultValueButton
                        onClick={() => {
                            if (previousValue === value && value === defaultValue) {
                                return;
                            }

                            setPreviousValue(defaultValue);
                            setValue(defaultValue);

                            updatePropertyValue(defaultValue);
                        }}
                    />
                )}
                {!!propertyScore?.score && isSubproperty && !isMultivalued && (
                    <FormUnit
                        value={Number((propertyVariation?.score ?? Number(propertyScore?.score)).toFixed(3))}
                        unit={scoreVariation?.unit_abbreviation ?? scoreVariation?.unit}
                    />
                )}
            </Stack>
            {defaultValue && (
                <Typography variant="caption" color={theme.custom.grey[1500]}>
                    {!property.readonly ?
                        t("Valeur par défaut :") + " " + defaultValue
                    :   t("Cette valeur est en lecture seule")}
                </Typography>
            )}
            {!property.readonly && genericValue && (
                <Box>
                    <GenericValueCheckbox
                        isSelected={genericValueSelected}
                        onChange={() => {
                            setGenericValueSelected((prevState) => !prevState);
                            setValue(!genericValueSelected ? genericValue : "");
                            updatePropertyValue(!genericValueSelected ? genericValue : "", !genericValueSelected);
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
};
