import { FormBox } from "@foodpilot/foods";
import { Drawer, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClickMe from "src/assets/icons/click-me.svg?react";
import Hint from "src/assets/icons/hint.svg?react";

type AgribalyseInfoDrawerProps = {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
};
export const AgribalyseInfoDrawer = (props: AgribalyseInfoDrawerProps) => {
    const { isOpen, setOpen } = props;
    const { t } = useTranslation();

    return (
        <Drawer open={isOpen} anchor={"right"} onClose={() => setOpen(false)}>
            <Box
                sx={{
                    height: "100svh",
                    width: "680px",
                }}
            >
                <Box sx={{ padding: "20px 40px" }}>
                    <Typography variant="h2">
                        {t("Pourquoi associer votre produit à une référence Agribalyse® ?")}
                    </Typography>
                </Box>
                <hr />
                <Box sx={{ padding: "20px 40px", display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Typography variant="body">{t("info.agribalyse-1")}</Typography>

                    <Typography variant="body">{t("info.agribalyse-2")}</Typography>
                </Box>
                <Box sx={{ padding: "20px 40px", display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Typography variant="h3">{t("À propos du score PEF")}</Typography>
                    <Typography variant="body">{t("info.agribalyse-3")}</Typography>
                </Box>
                <Box sx={{ padding: "20px 40px", display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Typography variant="h3">{t("À propos du coût environnemental")}</Typography>
                    <Typography variant="body">{t("info.agribalyse-4")}</Typography>

                    <Typography variant="body">{t("info.agribalyse-5")}</Typography>
                </Box>
                <Box>
                    <FormBox
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            margin: "20px 20px",
                        }}
                    >
                        <Box>
                            <Hint />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "1rem" }}>
                            <Typography variant="h5">{t("En savoir plus") + ":"}</Typography>

                            <Button
                                variant="text"
                                endIcon={<ClickMe />}
                                href="https://doc.agribalyse.fr/documentation"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t("Agribalyse®")}
                            </Button>
                            <Button
                                variant="text"
                                endIcon={<ClickMe />}
                                href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=PI_COM%3AC%282021%299332"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t("PEF – Product Environemental Footprint")}
                            </Button>
                            <Button
                                variant="text"
                                endIcon={<ClickMe />}
                                href="https://affichage-environnemental.ademe.fr/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t("Affichage environnemental (Ademe)")}
                            </Button>
                        </Box>
                    </FormBox>
                </Box>
            </Box>
        </Drawer>
    );
};
