import { BlockOptions, TextBlock } from "@foodpilot/foods";
import { useState } from "react";
import { ActionPropertiesWithAnswers } from "src/context/Actions/ActionService";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

export type TextProps = {
    propertyWithElements: ActionPropertiesWithAnswers;
};
export const Text = (props: TextProps) => {
    const { currentCampaign, targetCampaign } = useFoodpilotContext();
    const { propertyWithElements } = props;

    const initialValue = propertyWithElements.currentElement?.value;
    const [value, setValue] = useState(initialValue);

    const previousComparisonValue = {
        year: `Value in ${currentCampaign?.label}`,
        value: propertyWithElements.previousElement?.value ?? "",
    };
    const currentComparisonValue = {
        year: `Value in 222`,
        value: propertyWithElements.currentElement?.value ?? "",
    };

    const blockOptions: BlockOptions = {
        rightSubtitle: `Objectif ${targetCampaign?.label}`,
        title: propertyWithElements.property.title ?? "",
        comparisonValues: {
            start: previousComparisonValue,
            current: currentComparisonValue,
        },
    };

    return (
        <TextBlock
            blockOptions={blockOptions}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            onBlur={(e) => {
                const relatedTarget = e.nativeEvent.relatedTarget as Element;
                const isTargetPopover = relatedTarget && relatedTarget.classList.contains("MuiPopover-paper");

                if ((e.nativeEvent.type === "focusout" && isTargetPopover) || value === initialValue) {
                    return;
                }

                console.log(`Update the data ${propertyWithElements.property.title}!`);
                // Here, update the Data;
            }}
        />
    );
};
