import { useNavigate, useRevalidator } from "react-router-dom";
import { Box, Container, Button, TextField, FormLabel, MenuItem } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { CsrThemeWrite, CsrThemeWriteSchema, useCreateCsrTheme } from "src/api/client-api/csrTheme/useCreateCsrTheme";
import { useGetAllCsrTheme } from "src/api/client-api/csrTheme/useGetAllCsrTheme";
import { Loading } from "src/components/utils/Loading";
import { CsrTheme } from "src/api/client-api/csrTheme/useGetOneCsrTheme";
import { URLHandler } from "src/services/URLHandler";

export type CsrThemeCreateProps = {
    existingCsrTheme?: CsrTheme;
};

export const CsrThemeCreate = (props: CsrThemeCreateProps) => {
    const { existingCsrTheme } = props;

    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const { t } = useTranslation();

    const { csrThemes } = useGetAllCsrTheme({
        itemsPerPage: 200, // Will need to be fixed in the future
    });
    const createCsrTheme = useCreateCsrTheme();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<CsrThemeWrite>({
        mode: "onChange",
        resolver: zodResolver(CsrThemeWriteSchema),
    });

    const onSubmitHandler: SubmitHandler<CsrThemeWrite> = (values) => {
        createCsrTheme.mutate(
            { csrTheme: values },
            {
                onSuccess: () => {
                    revalidator.revalidate();
                    navigate(URLHandler.settings.csrTheme.list());
                },
            },
        );
    };

    if (csrThemes === undefined) return <Loading />;

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <h1>{"CsrTheme"}</h1>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="id"
                        control={control}
                        defaultValue={existingCsrTheme?.id}
                        render={({ field: { ref, ...field } }) => <input type="hidden" {...field}></input>}
                    />
                    <Controller
                        name="title"
                        control={control}
                        defaultValue={existingCsrTheme?.title}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={"Titre"}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.title)}
                                helperText={errors.title?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={existingCsrTheme?.description}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={"Description"}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.description)}
                                helperText={errors.description?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            minWidth: "400px",
                            flexDirection: "column",
                        }}
                    >
                        <FormLabel>{"Parent CsrTheme"}</FormLabel>
                        <Controller
                            name="parent"
                            control={control}
                            defaultValue={existingCsrTheme?.parent?.["@id"]}
                            render={({ field: { ref, ...field } }) => (
                                <TextField select variant="outlined" inputRef={ref} {...field}>
                                    {csrThemes &&
                                        csrThemes.flatMap((csrTheme, index) => {
                                            if (csrTheme["@id"] === existingCsrTheme?.["@id"]) {
                                                return [];
                                            }
                                            return [
                                                <MenuItem key={index} value={csrTheme["@id"]}>
                                                    {csrTheme.title}
                                                </MenuItem>,
                                            ];
                                        })}
                                </TextField>
                            )}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button sx={{ marginTop: "1rem", width: "10rem" }} variant="primary" type="submit">
                        {t("Enregistrer")}
                    </Button>
                </Box>
            </form>
        </Container>
    );
};
