import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { EnergyTypeSchema, energyTypeZodToEnergyType } from "src/types";

export const useEnergyTypes = () => {
    return useQuery({
        queryKey: ["energyTypes"],
        queryFn: async () => {
            const response = await sureApiCall.get(`/api/energy_types`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            return response.data;
        },
        select: (data) => {
            const res = EnergyTypeSchema.parse(data);
            return energyTypeZodToEnergyType(res);
        },
    });
};
