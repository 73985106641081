import { MRT_ColumnDef, createMRTColumnHelper } from "material-react-table";
import { Typography } from "@mui/material";
import { GridText, GridTextList } from "src/components/Grid/GridText";
import { useTranslation } from "react-i18next";
import { MrtColumns } from "src/utils/useGridColumns";
import { SupplierGrid } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { GridLink } from "src/components/Grid/GridLink";

export const useSupplierColumns = (): MrtColumns<SupplierGrid> => {
    const { t } = useTranslation();

    const columnHelper = createMRTColumnHelper<SupplierGrid>();

    const mrt_columns = [
        columnHelper.accessor("name", {
            header: t("name"),
            Cell: (value) => (
                <GridLink sx={{ color: (theme) => theme.palette.primary[700] }}>{value.renderedCellValue}</GridLink>
            ),
            grow: false,
        }),
        columnHelper.accessor((row) => row.clientId ?? "-", {
            id: "clientId",
            header: t("supplier_code"),
            Cell: (value) => {
                return <Typography variant="body">{value.renderedCellValue ?? "-"}</Typography>;
            },
        }),
        columnHelper.accessor("sectors", {
            header: t("industry"),
            enableSorting: false,
            Cell: (value) => {
                const v = value.cell.getValue();
                if (Array.isArray(v)) {
                    return <GridTextList value={v} separator=", " />;
                }

                return <GridText value={value.renderedCellValue} />;
            },
        }),
        columnHelper.accessor(
            (row) => {
                const ingList = row.ingredientSuppliers?.map((v) => v.ingredient.name) ?? [];
                return ingList;
            },
            {
                id: "ingredientSuppliers",
                header: t("ingredients"),
                enableSorting: false,
                Cell: (value) => {
                    const v = value.cell.getValue();
                    if (Array.isArray(v)) {
                        return <GridTextList value={v} separator=", " />;
                    }

                    return <GridText value={value.renderedCellValue} />;
                },
            },
        ),
    ] as MRT_ColumnDef<SupplierGrid, string>[];

    return mrt_columns;
};
