import { MRTGrid } from "@foodpilot/foods";
import { createMRTColumnHelper, MRT_PaginationState } from "material-react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SupplierGrid, useGetAllSupplier } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { GridLink } from "src/components/Grid/GridLink";
import { GridText, GridTextList } from "src/components/Grid/GridText";
import { IngredientFormIngredientSupplier } from "../../../IngredientForm.types";

type RowNumber = number;
type IsSelected = boolean;

// 2 different states because the MRT library needs its own state !
export type RowSelectionState = {
    rowSelection: Record<RowNumber, IsSelected>;
    existingSuppliersMapping: Record<RowNumber, IngredientFormIngredientSupplier | undefined>;
    supplierListMapping: Record<RowNumber, SupplierGrid>;
};

const getInitialSelection = (
    supplierList: SupplierGrid[] | undefined,
    existingSuppliers: IngredientFormIngredientSupplier[],
) => {
    const emptyRowSelectionState: RowSelectionState = {
        rowSelection: {},
        existingSuppliersMapping: {},
        supplierListMapping: {},
    };

    if (supplierList === undefined) return emptyRowSelectionState;

    return supplierList.reduce((acc, supplier, rowNumber) => {
        const foundExistingIng = existingSuppliers.find((ing) => {
            return ing.supplier["@id"] === supplier["@id"];
        });
        const isFound = foundExistingIng !== undefined;

        acc["rowSelection"][rowNumber] = isFound;
        acc["existingSuppliersMapping"][rowNumber] = foundExistingIng;
        acc["supplierListMapping"][rowNumber] = supplier;

        return acc;
    }, emptyRowSelectionState);
};

type SupplierListGridProps = {
    currentIngredientSuppliers: IngredientFormIngredientSupplier[];
    setNewIngredientsSuppliers: (newItems: IngredientFormIngredientSupplier[]) => void;
};
export const SupplierListGrid = (props: SupplierListGridProps) => {
    const { t } = useTranslation();
    const { currentIngredientSuppliers, setNewIngredientsSuppliers } = props;

    const [supplierPage, setSupplierPage] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 6,
    });
    const { suppliers, totalItems } = useGetAllSupplier({
        page: supplierPage.pageIndex + 1,
        itemsPerPage: supplierPage.pageSize,
    });

    const initialSelection = getInitialSelection(suppliers, currentIngredientSuppliers);
    const [rowSelection, setRowSelection] = useState(initialSelection["rowSelection"]);

    useEffect(() => {
        setRowSelection(initialSelection["rowSelection"]);
    }, [suppliers]);

    useEffect(() => {
        // The RowSelection is a Record<RowNumber, ...>;
        // The problem comes from MRT actually deleting the key, instead of setting it to False
        // when we unselect the element.
        // So we want to make sure the keys who don't exist are still set to at least FALSE;
        const rowSelectionFixed = Array(supplierPage.pageSize)
            .fill(undefined)
            .map((_, index) => index);
        const rowSelectionArray = rowSelectionFixed.map((row) => {
            const rowString = row.toString();
            const selectedRow = rowSelection[row];
            if (selectedRow !== undefined) {
                return [rowString, selectedRow];
            }
            return [rowString, false];
        });
        if (rowSelectionArray.length === 0) {
            // Means the component is not loaded yet.
            return;
        }

        const existingIngredientCopy = [...currentIngredientSuppliers];
        const newProductIngredients = rowSelectionArray.reduce((previousValue, [rowNumber, isSelected]) => {
            if (isSelected === false) {
                // Since it's NOT selected, we want to DELETE the item if it existed.
                const row = Number(rowNumber);
                const existingIng = initialSelection["existingSuppliersMapping"][row];
                if (existingIng === undefined) return previousValue;

                return previousValue.filter((item) => item.supplier["@id"] !== existingIng.supplier["@id"]);
            } else {
                // The item is actively selected.
                const row = Number(rowNumber);
                const existingIng = initialSelection["existingSuppliersMapping"][row];

                // Does it already exists ?
                if (existingIng !== undefined) return previousValue;

                // Now we're sure we just selected it.
                const supplierList = initialSelection["supplierListMapping"][row];

                const newIngredient: IngredientFormIngredientSupplier = {
                    supplier: {
                        "@id": supplierList["@id"],
                        name: supplierList.name,
                        clientId: supplierList?.clientId ?? null,
                        sectors: supplierList.sectors,
                    },
                };

                return [...previousValue, newIngredient];
            }
        }, existingIngredientCopy);

        // Here, we have the Full previous selected ingredients
        // In addition of the new ones;
        // In deletion of those that were deleted;
        setNewIngredientsSuppliers(newProductIngredients);
    }, [rowSelection]);

    const columnHelper = createMRTColumnHelper<SupplierGrid>();
    const mrt_columns = [
        columnHelper.accessor("name", {
            header: t("Nom"),
            Cell: (props) => <GridLink>{props.renderedCellValue}</GridLink>,
        }),
        columnHelper.accessor((row) => row.clientId ?? "-", {
            id: "clientId",
            header: t("Code fournisseur"),
            Cell: (value) => <GridText value={value.renderedCellValue} />,
        }),
        columnHelper.accessor("sectors", {
            header: t("Filière"),
            Cell: (value) => {
                return <GridTextList value={value.cell.getValue()} />;
            },
        }),
    ];

    if (suppliers === undefined) return;

    return (
        <MRTGrid
            columns={mrt_columns}
            data={suppliers}
            globalFilterFn="contains"
            enableRowSelection={true}
            enablePagination={true}
            onRowSelectionChange={setRowSelection}
            state={{
                isLoading: suppliers === undefined,
                rowSelection: rowSelection,
                pagination: supplierPage,
            }}
            rowCount={totalItems}
            manualPagination
            onPaginationChange={setSupplierPage}
        />
    );
};
