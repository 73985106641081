import { FoodsIcon } from "@foodpilot/foods";
import { Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Score as ScoreDisplay } from "src/components/Score";
import { EmptyScore, Score, ScoreDimensionBaseValue, doesScoreExist } from "src/api/client-api/post/scoreSchema";

export const LinkStyled = styled("a")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    background: "#FFF6E8",
    padding: ".5rem",
    fontWeight: 600,
    fontSize: "1rem",
    textDecoration: "none",
    color: theme.custom.grey[3000],
    width: "100%",
    borderRadius: theme.shape.borderRadius,
}));

export type ScatterScoreBoxCoreProps = {
    title: string;
    score: Score | EmptyScore;
    value?: ScoreDimensionBaseValue;
    quantity?: number;
    prevScore?: Score | EmptyScore;
    prevScoreValue?: ScoreDimensionBaseValue;
    prevCampaignName?: string;
    referenceScoreValue?: number;
};
export const ScatterScoreBoxCorePopover = (props: ScatterScoreBoxCoreProps) => {
    const { title, score, value, quantity, prevScore, prevScoreValue, prevCampaignName, referenceScoreValue } = props;
    const { t } = useTranslation();

    const valueExists = value && doesScoreExist(score);

    return (
        <Stack sx={{ padding: "4px 8px 4px 8px" }}>
            <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                {title}
            </Typography>

            {valueExists && (
                <ScoreDisplay
                    value={value.score}
                    unit={score.unit}
                    quantity={quantity}
                    prevScoreValue={
                        prevScoreValue !== undefined && prevScore !== undefined && doesScoreExist(prevScore) ?
                            prevScoreValue.score
                        :   undefined
                    }
                    prevCampaignName={prevCampaignName}
                    referenceScoreValue={referenceScoreValue}
                />
            )}

            <LinkStyled href="#">
                <FoodsIcon icon="clickIndicator" size={4} />
                <Typography variant="body-bold" sx={{ marginLeft: "0.5rem" }}>
                    {t("click_for_more_details")}
                </Typography>
            </LinkStyled>
        </Stack>
    );
};
