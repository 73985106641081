import { Fragment } from "react";
import { Outlet, Route, isRouteErrorResponse, useRouteError } from "react-router-dom";
import { SuppliersList } from "./SupplierList";
import { CreateSupplierPage } from "./SupplierForm/CreateSupplier";
import { UpdateSupplierPage } from "./SupplierForm/UpdateSupplier";
import { useTranslation } from "react-i18next";
import { Slugs } from "src/context/ProtectedContext";
import { COMPANY, CREATE, LIST, MODIFY, useFoodpilotContext } from "src/context/FoodpilotContext";
import { Forbiddenpage } from "src/components/PageState/ForbiddenPage";

export const useSupplierRouter = () => {
    return (
        <Fragment>
            <Route path="suppliers" errorElement={<SupplierError />}>
                <Route element={<SupplierActionProtection slug={LIST} />}>
                    <Route index element={<SuppliersList />} />
                </Route>

                <Route path={"new"} element={<SupplierActionProtection slug={CREATE} />}>
                    <Route element={<CreateSupplierPage />}>
                        <Route index />
                        <Route path={":startingPageId"} />
                    </Route>
                </Route>
                <Route path=":supplierId">
                    <Route path={"edit"} element={<SupplierActionProtection slug={MODIFY} />}>
                        <Route element={<UpdateSupplierPage />}>
                            <Route index />
                            <Route path={":startingPageId"} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Fragment>
    );
};

type SupplierActionProtectionProps = {
    slug: Slugs;
};
const SupplierActionProtection = (props: SupplierActionProtectionProps) => {
    const { slug } = props;
    const { canUser } = useFoodpilotContext();

    if (canUser(slug, COMPANY) === false) {
        return <Forbiddenpage />;
    }

    return <Outlet />;
};

const SupplierError = () => {
    const { t } = useTranslation();
    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>{t("Page inexistante")} 404</div>;
        } else if (error.status === 401) {
            return <div>{t("Unauthorized")}: 401</div>;
        } else if (error.status === 503) {
            return <div>API down: 503</div>;
        } else if (error.status === 500) {
            return <div>API error: 500</div>;
        }
    }
    console.error(error);
    return <div>Hello this is an Error from supplier</div>;
};
