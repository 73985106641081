import { Box, useTheme } from "@mui/material";
import { IngredientsGrid } from "./IngredientsGrid";
import { IngredientListContextProvider, useIngredientListContext } from "./IngredientListContext";
import { GridPageHeader } from "src/components/Grid/PageGridHeader";
import { useTranslation } from "react-i18next";
import { GridHeader } from "./IngredientsGrid/GridHeader";

const _IngredientsList = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { searchIngredient, setSearchIngredient } = useIngredientListContext();

    return (
        <Box sx={{ padding: "40px 52px" }}>
            <GridPageHeader
                title={t("Ingrédients")}
                mainBoardName={t("Tableau de bord")}
                listItemName={t("Liste des ingrédients")}
                filter={searchIngredient}
                setFilter={setSearchIngredient}
            />
            <Box
                component="div"
                sx={{
                    borderRadius: "16px",
                    border: `1px solid ${theme.custom.grey[500]}`,
                    background: "#FFF",
                    paddingTop: "32px",
                    marginTop: "40px",
                }}
            >
                <GridHeader />
                <IngredientsGrid />
            </Box>
        </Box>
    );
};

const IngredientsList = () => {
    return (
        <IngredientListContextProvider>
            <_IngredientsList />
        </IngredientListContextProvider>
    );
};
export default IngredientsList;
