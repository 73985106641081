import { BigPopoverLayout } from "@foodpilot/foods";
import { Box, CircularProgress, Dialog, Typography, styled, useTheme } from "@mui/material";
import _CheckIcon from "src/assets/icons/check.svg?react";

const CheckIcon = styled(_CheckIcon)(({ theme }) => ({
    path: {
        fill: theme.palette.primary[500],
    },
}));

type TransmitReportDialogProps = {
    open: boolean;
    isTransmitting: boolean;
    done: boolean;
};

export const TransmitReportDialog = (props: TransmitReportDialogProps) => {
    const { open, isTransmitting, done } = props;
    const theme = useTheme();

    return (
        <Dialog
            open={!!open}
            sx={{
                ".MuiPaper-root": {
                    backgroundColor: "transparent",
                },
                hr: { display: "none" },
                ".MuiPaper-rounded": {
                    borderRadius: "12px",
                },
            }}
        >
            <BigPopoverLayout
                headerIcon={<></>}
                headerText="Transmission du bilan RSE simplifié en cours"
                onClose={() => {}}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ mb: 3 }}>Cela prendra quelques secondes…</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <Box
                            sx={{
                                backgroundColor: "#fff6e7",
                                p: 3,
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            {isTransmitting && (
                                <Box sx={{ color: theme.palette.primary[500] }}>
                                    <CircularProgress color="inherit" />
                                </Box>
                            )}
                            {done && <CheckIcon />}
                            <Typography variant="body1">Envoi du bilan</Typography>
                        </Box>
                    </Box>
                </Box>
            </BigPopoverLayout>
        </Dialog>
    );
};
