import { Box } from "@mui/material";
import { Loading } from "src/components/utils/Loading";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { QuestionnaireUpdateForm } from "./QuestionnaireUpdateForm";

export const QuestionnaireUpdate = () => {
    const { selectedCompany } = useFoodpilotContext();

    if (selectedCompany === null) {
        // We probably want to display an error
        console.error("Contact your admin for the Error (10)");
        return <Loading />;
    }

    return (
        <Box sx={{ marginLeft: "auto", marginRight: "auto", padding: "40px 52px", paddingTop: "28px" }}>
            <QuestionnaireUpdateForm company={selectedCompany} />
        </Box>
    );
};
