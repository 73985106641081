import { FoodsIcon, WhiteBox } from "@foodpilot/foods";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import companies from "./placeholderData";
import { t } from "i18next";
import { URLHandler } from "src/services/URLHandler";

export type SummaryCardProps = {
    isNew: boolean;
    logo: string;
    title: string;
    subtitle?: string;
    description: string;
    name: string;
    id: string;
};

const SummaryCard = (props: SummaryCardProps) => {
    const { description, isNew, id, logo, name, title } = props;
    const navigate = useNavigate();

    return (
        <WhiteBox sx={{ p: 3, height: "100%" }}>
            <Stack height={"100%"} justifyContent={"space-between"}>
                <Stack flex={1} spacing={2} justifyContent={"space-between"}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <img src={logo} alt="logo" height={56} />
                        {isNew && (
                            <Button variant="black-filled" sx={{ padding: "3px 8px" }}>
                                {t("bred.support.new")}
                            </Button>
                        )}
                    </Stack>
                    <Typography variant="h3">{title}</Typography>
                    <Typography variant="body-medium">{description}</Typography>
                    <Box
                        sx={{
                            border: (theme) => `1px solid ${theme.custom.grey[500]}`,
                            borderRadius: "4px",
                            alignSelf: "start",
                            paddingX: "8px",
                        }}
                    >
                        <Typography component={"div"} variant="small-bold">
                            {name}
                        </Typography>
                    </Box>
                    <Button
                        variant="ternary"
                        onClick={() => {
                            const url = URLHandler.cms.view(Number(id));
                            navigate(url);
                        }}
                    >
                        <Stack direction={"row"} justifyContent={"space-between"} flex={1}>
                            <Typography variant="body-bold">{t("bred.support.go_to_detail")}</Typography>
                            <FoodsIcon icon="arrowRight" size={3} />
                        </Stack>
                    </Button>
                </Stack>
            </Stack>
        </WhiteBox>
    );
};

export const Support = () => {
    return (
        <Box component="div" sx={{ maxWidth: "1280px" }}>
            <Box
                sx={{
                    width: "auto",
                    padding: "28px 52px",
                    position: "relative",
                    borderBottom: (theme) => `1px solid ${theme.custom.grey[500]}`,
                }}
            >
                <Grid container spacing={3.5}>
                    {companies.map((c, i) => (
                        <Grid item xs={4} key={i}>
                            <SummaryCard {...c} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};
