import { useState } from "react";
import { Box, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { FoodsRadioList } from "@foodpilot/foods";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { useTranslation } from "react-i18next";
import { DefaultValueButton } from "src/components/FormProperty/DefaultValueButton.tsx";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { FormTooltip } from "../FormTooltip";

export const Radio = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { property, post, element, canEdit = true, isSubproperty } = props;

    const options =
        property.dataList?.options.sort(
            (optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position,
        ) ?? [];

    const defaultValueOption = options.find((option) => option.value === property.defaultValue);
    const genericValueOption = options.find((option) => option.value === property.genericValue);

    const _initialValue = element && !property.readonly ? element.value ?? "" : defaultValueOption?.value ?? "";
    const initialValue = options.find((option: DataListOption) => option.value === _initialValue);

    const [value, setValue] = useState<DataListOption | undefined>(initialValue);
    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    if (!post) return <CircularProgress />;

    const updatePropertyValue = (value?: DataListOption, isGenericValue?: boolean) => {
        const sanitizedValue = value?.value.trim() ?? "_empty_";

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: sanitizedValue,
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
        });
    };

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    return (
        <FormTooltip title={tooltipText} arrow placement="right">
            <Box>
                <Stack direction="column">
                    <Stack direction="row" alignItems="center">
                        <FoodsRadioList
                            values={options}
                            selectedItem={value}
                            onChange={(newValue: DataListOption) => {
                                setValue(newValue);
                                updatePropertyValue(newValue);
                            }}
                            getId={(option: DataListOption) => option.value}
                            getName={(option: DataListOption) => option.label}
                            FormControlProps={{
                                sx: {
                                    flexDirection: "column",
                                },
                            }}
                            FormControlLabelProps={{
                                sx: {
                                    gap: "3px",
                                    marginRight: "28px",
                                    ".MuiTypography-root": {
                                        ...theme.typography.body,
                                    },
                                },
                            }}
                            RadioProps={{
                                disabled: !canEdit || property.readonly || genericValueSelected,
                            }}
                        />
                        {!property.readonly && defaultValueOption !== undefined && !genericValueSelected && (
                            <DefaultValueButton
                                onClick={() => {
                                    if (value?.value === defaultValueOption?.value) {
                                        return;
                                    }

                                    setValue(defaultValueOption);
                                    updatePropertyValue(defaultValueOption);
                                }}
                            />
                        )}
                    </Stack>
                    {defaultValueOption !== undefined && !genericValueSelected && (
                        <Typography variant="caption" color={theme.custom.grey[1500]}>
                            {!property.readonly ?
                                t("Valeur par défaut :") + " " + (defaultValueOption?.label ?? "")
                            :   t("Cette valeur est en lecture seule")}
                        </Typography>
                    )}
                    {!property.readonly && genericValueOption && (
                        <Box>
                            <GenericValueCheckbox
                                isSelected={genericValueSelected}
                                onChange={() => {
                                    setGenericValueSelected((prevState) => !prevState);
                                    setValue(!genericValueSelected ? genericValueOption : undefined);
                                    updatePropertyValue(
                                        !genericValueSelected ? genericValueOption : undefined,
                                        !genericValueSelected,
                                    );
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Box>
        </FormTooltip>
    );
};
