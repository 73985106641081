import { IngredientSupplier } from "src/api/client-api/ingredients/useGetOneIngredient";
import { NoSupplierScores } from "./IngredientScores/NoSupplierScores";
import { OneSupplierScores } from "./IngredientScores/OneSupplierScores";
import { MultipleSupplierScores } from "./IngredientScores/MultipleSupplierScores";
import { useTranslation } from "react-i18next";
import { useIngredientContext } from "./ExistingIngredientContext";
import { useGetIngredientReferenceScores } from "src/api/client-api/ingredientReferenceScores/useGetIngredientReferenceScores";

export type IngredientScoresProps = unknown;
export const IngredientScores = (_props: IngredientScoresProps) => {
    const { t } = useTranslation();

    const {
        ingredient,
        ingredientReference,
        isIngRefLoading,
        variations,
        setVariations,
        isRefetching = false,
    } = useIngredientContext();

    const { ingredientReferenceScores = [] } = useGetIngredientReferenceScores(ingredient?.id);

    if (isIngRefLoading || ingredient === undefined) return <div>{t("word.loading-wait")}</div>;

    const suppliers = ingredient.ingredientSuppliers;

    switch (getNumberOfSuppliers(suppliers)) {
        case 0:
            return <NoSupplierScores ingredientReference={ingredientReference} ingredient={ingredient} />;
        case 1:
            return (
                <OneSupplierScores
                    ingredientReference={ingredientReference}
                    ingredient={ingredient}
                    variations={variations}
                    setVariations={setVariations}
                    isRefetching={isRefetching}
                    referenceScores={ingredientReferenceScores}
                />
            );
        default:
            return (
                <MultipleSupplierScores
                    suppliers={suppliers}
                    ingredientReference={ingredientReference}
                    ingredient={ingredient}
                    variations={variations}
                    setVariations={setVariations}
                    isRefetching={isRefetching}
                    referenceScores={ingredientReferenceScores}
                />
            );
    }
};

const getNumberOfSuppliers = (suppliers: IngredientSupplier[] | undefined) => {
    if (!suppliers) return 0;

    return suppliers.length;
};
