import { PrimaryDialog, SearchBox } from "@foodpilot/foods";
import { Typography, Box, Stack, Button } from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ProductFormProductIngredient } from "../../../ProductForm.types";
import { IngredientListGrid } from "./IngredientListGrid";
import { useDelayedSearch } from "src/utils/useDelayedSearch";

type IngredientsModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onValidate: (newRefIngredients: ProductFormProductIngredient[]) => void;
    productIngredients: ProductFormProductIngredient[];
};

export const IngredientsModal = (props: IngredientsModalProps) => {
    const { t } = useTranslation();
    const { onValidate, isOpen, setOpen, productIngredients } = props;

    const [selectedIngredients, setSelectedIngredients] = useState(productIngredients);

    useEffect(() => {
        setSelectedIngredients(productIngredients);
    }, [productIngredients]);

    const title = <Typography variant="h3">{t("Ajouter des ingrédients")}</Typography>;

    const content = (
        <DisplayIngredients currentIngredients={selectedIngredients} setNewIngredients={setSelectedIngredients} />
    );

    return (
        <Fragment>
            <PrimaryDialog
                open={isOpen}
                setOpen={setOpen}
                options={{
                    title: title,
                    content: content,
                }}
                onValidate={() => {
                    onValidate(selectedIngredients);
                }}
                onCancel={() => {
                    setSelectedIngredients(productIngredients);
                }}
                fullWidth
                maxWidth="md"
                cancelText={t("primaryDialog.cancel")}
                validateText={t("primaryDialog.validate")}
            />
        </Fragment>
    );
};

type DisplayIngredientsProps = {
    currentIngredients: ProductFormProductIngredient[];
    setNewIngredients: (selectedIngredients: ProductFormProductIngredient[]) => void;
};
const DisplayIngredients = (props: DisplayIngredientsProps) => {
    const { setNewIngredients, currentIngredients } = props;

    const search = useDelayedSearch();

    return (
        <Box
            component="div"
            sx={{
                borderRadius: "16px",
                minHeight: "200px",
                maxHeight: "750px",

                overflowX: "hidden",
            }}
        >
            <Stack direction="row" justifyContent="space-between" sx={{ padding: "24px" }}>
                <SearchBox
                    variant="standalone"
                    value={search.search}
                    onChange={(value) => {
                        search.setSearch(value);
                    }}
                    placeholder={t("Rechercher un ingrédient")}
                />
                <Button
                    variant="white-filled-no-shadow"
                    id="filter-anchor"
                    disabled
                    startIcon={<FilterListIcon />}
                    sx={{
                        height: "40px",
                        padding: "0px 20px",
                    }}
                >
                    {t("Filtrer la liste")}
                </Button>
            </Stack>
            <IngredientListGrid
                delayedSearch={search.delayedSearch}
                existingIngredient={currentIngredients}
                setNewIngredients={setNewIngredients}
            />
        </Box>
    );
};
