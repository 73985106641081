import { z } from "zod";
import { useQuery } from "react-query";
import { publicApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";

export const useGetSettings = () => {
    const query = useQuery({
        queryKey: ["getSettings"],
        queryFn: async () => {
            return await publicApiCall.get(`/api/settings/1`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
        },
        select: (request) => {
            const settings = request.data;

            // Kilian: WHY IS IT PUBLIC ?!
            const data = zodParseWithLog(settings, SettingsZodSchema, "allSettings");
            return data;
        },
    });

    return {
        settings: query.data,
        ...query,
    };
};

export type Settings = {
    id?: number;
    title?: string;
    contentUrl?: string;
    coverUrl?: string;
    crispToken?: string;
    homepage?: string;
    theme?: string;
};

export const SettingsZodSchema: z.ZodSchema<Settings> = z.object({
    id: z.number().optional(),
    title: z.string().optional(),
    contentUrl: z.string().optional(),
    coverUrl: z.string().optional(),
    crispToken: z.string().optional(),
    homepage: z.string().optional(),
    theme: z.string().optional(),
});
