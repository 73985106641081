import { Company } from "src/api/client-api/useUserNew";
import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { QuestionnaireUpdateFormSsqPost } from "./QuestionnaireUpdateFormSsqPost";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { ScoreProvider } from "src/context/ScoreContext";
import { Post } from "src/api/client-api/post/postSchema.ts";

type QuestionnaireUpdateFormSsqProps = {
    company: Company;
    ssq: Ssq;
};
export const QuestionnaireUpdateFormSsq = (props: QuestionnaireUpdateFormSsqProps) => {
    const { company, ssq } = props;

    const { currentCampaign } = useFoodpilotContext();
    const { post, isLoading, refetch } = useGetCompanyPost(company.id ?? 0, currentCampaign?.id);

    const refetchPost = (callback: (post: Post) => void) => {
        refetch().then((response) => {
            if (response.data) {
                callback(response.data);
            }
        });
    };

    return (
        <ScoreProvider type="company" formattedScores={post?.formattedScores}>
            <QuestionnaireUpdateFormSsqPost
                company={company}
                ssq={ssq}
                post={post}
                isPostLoading={isLoading}
                changePost={refetchPost}
            />
        </ScoreProvider>
    );
};
