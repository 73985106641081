import { Unit } from "src/api/sure-api/ssq/useGetOneSsq";

export const formatNumber = (value: string | number, language?: string, options?: Intl.NumberFormatOptions): string => {
    const sanitizedValue = value.toString().replace(/\s/g, "").replace(/,/g, ".");
    const valueToFormat = Number(sanitizedValue);

    return Intl.NumberFormat(language, {
        maximumFractionDigits: 3,
        ...options,
    }).format(valueToFormat);
};

export const formatDateTime = (
    value: Date | number,
    language?: string,
    options?: Intl.DateTimeFormatOptions,
): string => {
    return Intl.DateTimeFormat(language, options).format(value);
};

export const formatStringForBackend = (value: string | undefined | null): string | null => {
    if (value === undefined || value === null || value.replaceAll(" ", "") === "") {
        return null;
    }

    return value.trim();
};

type ThresholdRange = {
    low: number;
    high: number;
};

type Options = {
    maxLength: number;
    separator?: string;
};
export const limitStringList = (value: string[], options: Options): string => {
    // Deprecated algorithm.
    // Keeping it here in case we want it.
    // (kilian) note to self : better delete it at once ? idk
    const { maxLength, separator = "," } = options;

    if (maxLength === 0) {
        return "";
    }

    const totalLength = value.join("").length;
    if (maxLength > totalLength) {
        return value.join(separator);
    }

    let currentMax = 0;
    const wordThresholds = value.reduce((previousValue, currentWord) => {
        const newMax = currentMax + currentWord.length;
        const thresholdRange: ThresholdRange = {
            low: currentMax,
            high: newMax,
        };
        currentMax = newMax;
        previousValue.push(thresholdRange);

        return previousValue;
    }, [] as ThresholdRange[]);

    const limitPosition = wordThresholds.findIndex((value) => value.low < maxLength && maxLength <= value.high);
    const wordsToDisplay = limitPosition + 1;
    const leftoverWords = wordThresholds.length - wordsToDisplay;

    const isLastWordFullyDisplayed = wordThresholds[limitPosition].high === maxLength;
    const ellipsis = isLastWordFullyDisplayed ? "" : "…";

    const separatorLength = wordsToDisplay * separator.length;
    const valueListAsString = value.join(separator).slice(0, maxLength - 1 + separatorLength);
    const leftoverDisplay = leftoverWords ? `+${leftoverWords}` : ``;
    const stringToDisplay = `${valueListAsString}${ellipsis} ${leftoverDisplay}`;

    return stringToDisplay;
};

export const formatUnitAbbreviation = (unit?: Unit): string => {
    if (!unit) return "";

    switch (unit.title.toLowerCase()) {
        case "boolean":
        case "decimal":
        case "integer":
        case "string":
            return "";
        default:
            return unit.abbreviation;
    }
};
