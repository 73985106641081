import { Navigate, Route, Routes } from "react-router-dom";
import { useUserNew } from "src/api/client-api/useUserNew.ts";
import { CompaniesService } from "src/services/Companies/CompaniesService";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { BredHome } from "./CustomHome/BredHome";
import { URLHandler } from "src/services/URLHandler";
import { Homepage } from "../CMS/Homepage";
import { useGetOneContent } from "src/api/client-api/cms/useGetOneContent";
import { useApplicationParameters } from "src/api/client-api/appParams";
import { HomeCMS_ID } from "../Settings/CMS/Pages/useCreateHomePage";

export const HomeSwitch = () => {
    const { user, isFetched } = useUserNew();
    const clientSwitch = useClientSwitch();
    const { applicationParameters } = useApplicationParameters();
    const { content, isFetched: isContentFetched } = useGetOneContent({ contentId: HomeCMS_ID });

    // Wait for request to be complete;
    if (isFetched === false) return;
    if (isContentFetched === false) return;

    if (user === undefined) {
        return <Navigate to={"/login"} replace />;
    }

    const currentCompany = CompaniesService.selectCurrentCompany(user.companies);

    if (currentCompany === null && user.companies.length === 0) {
        // Logout if user has no company.
        // Maybe we want to redirect to some outside page.
        return <Navigate to={"/logout"} replace />;
    }

    // At component instantiation, if we have no company... take the first company ID;
    const selectedCompany = currentCompany === null ? user.companies[0] : currentCompany;

    const newClientSwitchForHome = clientSwitch({
        defaultOption: <Homepage />,
        bred: <BredHome />,
        inexweb: <Navigate to={"/inexweb"} replace />,
    });

    const routeElement = clientSwitch({
        defaultOption: undefined,
        bred: <BredHome />,
        foodpilot: (
            <Navigate
                to={
                    ["supplier", "provider"].includes(selectedCompany.type ?? "CannotMatch") ?
                        URLHandler.ingredient.list(selectedCompany.id)
                    :   URLHandler.product.list(selectedCompany.id)
                }
                replace
            />
        ),
        inexweb: <Navigate to={"/inexweb"} replace />,
        collective: <Navigate to={URLHandler.company.ssq(selectedCompany.id)} replace />,
    });

    const routerToUse =
        content === undefined || applicationParameters?.["FS-homepage"] !== "true" ?
            routeElement
        :   newClientSwitchForHome;

    return (
        <Routes>
            <Route path="" element={routerToUse} />
        </Routes>
    );
};
