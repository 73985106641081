import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Select,
} from "@mui/material";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridRowModesModel,
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowId,
    GridRowModel,
    useGridApiContext,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
    Campaign,
    useGetAllCampaigns,
    useCreateCampaign,
    useDeleteCampaign,
    CampaignSchema,
} from "src/api/client-api/useCampaign";
import { useSnackbar } from "src/utils/useSnackbar";
import locale from "dayjs/locale/*";
import { useQueryClient } from "react-query";
import { useSnackbarWrapperApiFieldErrorHandler } from "src/api/axiosClient/errorHandler.ts";
import { isAxiosError } from "axios";

export const Campaigns = () => {
    const { campaigns, isLoading: isCamapignsLoading } = useGetAllCampaigns({ orderByDate: true });
    const createCampaign = useCreateCampaign();
    const deleteCampaign = useDeleteCampaign();

    const [rows, setRows] = React.useState<GridRowsProp<Campaign>>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [displayDialog, setDisplayDialog] = useState(false);
    const [idToDelete, setIdToDelete] = useState<{ id: string }>({ id: "" });
    const [loading, setLoading] = useState<boolean>(false);
    const { i18n, t } = useTranslation();
    const snackbar = useSnackbar();
    const apiErrorHandler = useSnackbarWrapperApiFieldErrorHandler();
    const [locale, setLocale] = useState<locale.Locale | null>(null);
    const query = useQueryClient();

    // Original way:
    // Error:
    // Campaigns.tsx:65 Warning:
    // Maximum update depth exceeded. This can happen when a component calls setState inside useEffect,
    // but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render.
    //
    // [ds] to localize for other langs, doing dynamic import based on i18n.resolvedLanguage can be done.
    // For now dynamic imports break Vite which has to be properly configured. This may do the trick:
    // https://github.com/vite-plugin/vite-plugin-dynamic-import#readme
    //
    // Code:
    // import("dayjs/locale/fr").then((locale: locale.Locale) => {
    //     setLocale(locale);
    // });

    // Solved with:
    useEffect(() => {
        const importDaysJs = async () => {
            const module = await import("dayjs/locale/fr");
            const locale = module.default;
            setLocale(locale);
        };

        importDaysJs();
    }, []);

    useEffect(() => {
        setRows(campaigns);
    }, [campaigns]);

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: string) => () => {
        setIdToDelete({ id: id });
        setDisplayDialog(true);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
        const editedRow = campaigns.find((row) => row.id === id);
        if (!editedRow) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow: GridRowModel<Campaign>, _originalRow: GridRowModel<Campaign>) => {
        const campaign = campaigns.find((campaign) => campaign.id === newRow.id);
        setLoading(true);

        createCampaign.mutate(
            { ...newRow, id: campaign ? campaign.id : undefined },
            {
                onSuccess: () => {
                    setLoading(false);
                    query.refetchQueries("getAllCampaigns");
                },
                onError: (error) => {
                    if (isAxiosError(error)) {
                        const errorsData = apiErrorHandler(error);
                        const fullErrorMessage = errorsData?.fieldErrors?.errors.reduce(
                            // @ts-expect-error translation may not exists, same issue as in 'src/utils/forms.ts'
                            (message, error) => message.concat(t(error.message)),
                            "",
                        );

                        snackbar.setSnackbarProps({
                            message: fullErrorMessage,
                            type: "error",
                        });
                    }

                    setRows(rows.filter((row) => row.id !== newRow.id));
                    setLoading(false);
                },
            },
        );

        return newRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const renderConfirmDialog = () => {
        return (
            <Dialog maxWidth="xs" open={displayDialog}>
                <DialogTitle>Êtes-vous sûr ?</DialogTitle>
                <DialogContent dividers>{t("settings.campaigns.clickToRemoveProductLine")}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Non")}
                    </Button>
                    <Button
                        onClick={async () => {
                            const id = parseInt(idToDelete.id);
                            if (id) {
                                setLoading(true);

                                deleteCampaign.mutate(id, {
                                    onSuccess: () => {
                                        setLoading(false);
                                        query.refetchQueries("getAllCampaigns");
                                    },
                                    onError: () => {
                                        setLoading(false);

                                        snackbar.setSnackbarProps({
                                            message: t("settings.campaigns.errorOnCampaignRemoval"),
                                            type: "error",
                                        });
                                    },
                                });
                            }
                            setDisplayDialog(false);
                        }}
                    >
                        {t("Oui")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "label",
            headerName: t("campaign.name"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: true,
        },
        {
            field: "startDate",
            headerName: t("startDate"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    {dayjs(params.value).format(locale?.formats.L)}
                </Typography>
            ),
            renderEditCell: (params) => {
                const { id, field, value } = params;
                const apiRef = useGridApiContext();

                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage}>
                        <DatePicker
                            value={dayjs(value)}
                            onChange={(date) => {
                                apiRef.current.setEditCellValue({ id, field, value: date });
                            }}
                        />
                    </LocalizationProvider>
                );
            },
            editable: true,
        },
        {
            field: "endDate",
            headerName: t("endDate"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    {dayjs(params.value).format(locale?.formats.L)}
                </Typography>
            ),
            renderEditCell: (params) => {
                const { id, field, value } = params;
                const apiRef = useGridApiContext();

                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.resolvedLanguage}>
                        <DatePicker
                            value={dayjs(value)}
                            onChange={(date) => {
                                apiRef.current.setEditCellValue({ id, field, value: date });
                            }}
                        />
                    </LocalizationProvider>
                );
            },
            editable: true,
        },
        {
            field: "active",
            headerName: t("active"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    {params.value ? "Oui" : "Non"}
                </Typography>
            ),
            renderEditCell: (params) => {
                const { id, field, value } = params;
                const apiRef = useGridApiContext();

                return (
                    <Select
                        value={value ? 1 : 0}
                        onChange={(e) => {
                            const value = e.target.value;

                            apiRef.current.setEditCellValue({ id, field, value: Boolean(value) });
                        }}
                        sx={{
                            width: "100px",
                        }}
                    >
                        <MenuItem key={0} value={0}>
                            <Typography variant="body">{t("Non")}</Typography>
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                            <Typography variant="body">{t("Oui")}</Typography>
                        </MenuItem>
                    </Select>
                );
            },
            editable: true,
        },
        {
            field: "isTarget",
            headerName: t("glossary.referenceCampaign"),
            flex: 2,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    {params.value ? "Oui" : "Non"}
                </Typography>
            ),
            renderEditCell: (params) => {
                const { id, field, value } = params;
                const apiRef = useGridApiContext();

                return (
                    <Select
                        value={value ? 1 : 0}
                        onChange={(e) => {
                            const value = e.target.value;

                            apiRef.current.setEditCellValue({ id, field, value: Boolean(value) });
                        }}
                        sx={{
                            width: "100px",
                        }}
                    >
                        <MenuItem key={0} value={0}>
                            <Typography variant="body">{t("Non")}</Typography>
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                            <Typography variant="body">{t("Oui")}</Typography>
                        </MenuItem>
                    </Select>
                );
            },
            editable: true,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} key={id} />,
                        // <GridActionsCellItem icon={<SaveIcon />} label="Save" key={id} />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                            key={id}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                        key={id}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id as string)}
                        color="inherit"
                        key={id}
                    />,
                ];
            },
        },
    ];

    interface EditToolbarProps {
        setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
        setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
    }

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const id = randomId();
            setRows((oldRows) => [
                ...oldRows,
                {
                    id,
                    label: "",
                    startDate: dayjs(),
                    endDate: dayjs(),
                    active: false,
                },
            ]);
            setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" } }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t("campaign.add")}
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("campaigns")}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            {renderConfirmDialog()}
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                loading={isCamapignsLoading || loading}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                sx={{
                    border: "none",
                    backgroundColor: "transparent",
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
                        marginBottom: "2px",
                        border: 0,
                        backgroundColor: "transparent",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                        fontSize: "12px",
                        lineHeight: "24px",
                        color: "#797A77",
                    },
                    "& .MuiDataGrid-iconSeparator": {
                        display: "none",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-row": {
                        backgroundColor: "white",
                        borderRadius: "15px",
                        marginTop: "10px",
                        marginBottom: "10px",
                    },
                    "& .MuiDataGrid-row.Mui-selected": {
                        background: " #FFFFFF",
                        border: 0,
                    },
                    "& .MuiDataGrid-cell": {
                        border: 0,
                        paddingLeft: "20px",
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        display: "none",
                    },
                    "& .MuiDataGrid-virtualScrollerContent": {
                        paddingBottom: rows.length * 7,
                        height: "fit-content",
                        boxSizing: "content-box",
                    },
                }}
            />
        </Container>
    );
};
