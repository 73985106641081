import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetOneBrand } from "src/api/client-api/brand/useGetOneBrand";
import { BrandCreate } from "./Create";

export const BrandEdit = () => {
    const { brandId } = useParams();
    const { brand, refetch, isFetched } = useGetOneBrand(brandId ?? "A bad ID", { enabled: false });

    useEffect(() => {
        if (brand === undefined) {
            refetch();
        }
    }, []);

    if (isFetched === false) {
        return null;
    }

    if (brand == undefined) {
        return null;
    }

    return <BrandCreate existingBrand={brand} />;
};
