import { TableHead, TableRow, TableCell, Typography, TableBody, useTheme, Button } from "@mui/material";
import { useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { EnergyType, Entry, RSEFormProps, SelectedAccount } from "src/types";
import { AccountRow } from "./AccountRow";
import { RoundedTable } from "./AccountSection";
import { AddAccountDialog } from "./AddAccountDialog";
import { GrandTotals } from "./GrandTotals";

type AccountTableProps = {
    categoryIndex: number;
    energyIndex: number;
    energyType: EnergyType;
};

export const AccountTable = (props: AccountTableProps) => {
    const { categoryIndex, energyIndex, energyType } = props;

    const theme = useTheme();
    const { control, getValues } = useFormContext<RSEFormProps>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: `categories.${categoryIndex}.energies.${energyIndex}.accounts`,
    });
    const [open, setOpen] = useState(false);
    const [previousFiscalYear, fiscalYear] = getValues(["previousFiscalYear", "fiscalYear"]);

    const grandTotal = fields.reduce((acc, f) => acc + f.total, 0);
    const previousGrandTotal = fields.reduce((acc, f) => acc + f.previousTotal, 0);

    const handleAccountSelected = (
        account: SelectedAccount,
        entries: Omit<Entry, "type" | "subtype">[],
        previousEntries: Omit<Entry, "type" | "subtype">[],
    ) => {
        append({
            account,
            entries: entries?.map((a) => ({
                type: energyType.category,
                subtype: energyType.code,
                ...a,
            })),
            previousEntries: previousEntries?.map((a) => ({
                type: energyType.category,
                subtype: energyType.code,
                ...a,
            })),
            total: entries.reduce((acc, e) => acc + e.debit - e.credit, 0),
            previousTotal: previousEntries.reduce((acc, e) => acc + e.debit - e.credit, 0),
        });
    };
    const handleCloseDialog = () => setOpen(false);

    return (
        <>
            {open && (
                <AddAccountDialog open={open} onAccountSelected={handleAccountSelected} onClose={handleCloseDialog} />
            )}
            {fields.length > 0 && (
                <RoundedTable>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
                            <TableCell>
                                <Typography variant="h5">Comptes comptables :</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body">
                                    {previousFiscalYear?.datestart} - {previousFiscalYear?.dateend}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body">
                                    {fiscalYear?.datestart} - {fiscalYear?.dateend}
                                </Typography>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((account, accountIndex) => (
                            <AccountRow
                                key={account.id}
                                label={account.account.label}
                                previousTotal={account.previousTotal}
                                total={account.total}
                                handleOnDelete={() => {
                                    remove(accountIndex);
                                }}
                            />
                        ))}

                        <GrandTotals grandTotal={grandTotal} previousGrandTotal={previousGrandTotal} />
                    </TableBody>
                </RoundedTable>
            )}
            <Button onClick={() => setOpen(true)}>+ Ajouter un compte comptable</Button>
        </>
    );
};
