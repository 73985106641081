export default [
    {
        logo: "/public/images/BRED.svg",
        isNew: true,
        title: "Appliquer correctement les exigences réglementaires dans votre entreprise",
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam corrupti saepe harum delectus deleniti accusantium officia, quaerat nobis? Perferendis asperiores incidunt pariatur officia minima eum tempora earum ipsam atque molestias!",
        name: "BRED",
        id: "1",
    },
    {
        logo: "/public/images/Huglo Lepage Avocats.svg",
        isNew: false,
        title: "Accompagnement juridique dans la mise en place d'une stratégie RSE",
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam corrupti saepe harum delectus deleniti accusantium officia, quaerat nobis? Perferendis asperiores incidunt pariatur officia minima eum tempora earum ipsam atque molestias!",
        name: "Huglo Lepage Avocats",
        id: "2",
    },
    {
        logo: "/public/images/logo-in-extenso-innovation-croissance.png",
        isNew: true,
        title: "Définir sa stratégie RSE : Du diagnostic à la mise en place et à la valorisation",
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam corrupti saepe harum delectus deleniti accusantium officia, quaerat nobis? Perferendis asperiores incidunt pariatur officia minima eum tempora earum ipsam atque molestias!",
        name: "In Extenso Innovation Croissance",
        id: "3",
    },
    {
        logo: "/public/images/Cabinet EVEA.svg",
        isNew: false,
        title: "Conseil en analyse de cycle de vie et éco-conception",
        subtitle: "dolor sit amet consectetur",
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam corrupti saepe harum delectus deleniti accusantium officia, quaerat nobis? Perferendis asperiores incidunt pariatur officia minima eum tempora earum ipsam atque molestias!",
        name: "Cabinet EVEA",
        id: "4",
    },
    {
        logo: "/public/images/BRED.svg",
        isNew: false,
        title: "Effectuer le scoring environnemental de vos produits",
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam corrupti saepe harum delectus deleniti accusantium officia, quaerat nobis? Perferendis asperiores incidunt pariatur officia minima eum tempora earum ipsam atque molestias!",
        name: "BRED",
        id: "5",
    },
    {
        logo: "/public/images/Huglo Lepage Avocats.svg",
        isNew: false,
        title: "Prise en charge par un avocat de vos dossiers RSE",
        subtitle: "lorem ipsum",
        description:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam corrupti saepe harum delectus deleniti accusantium officia, quaerat nobis? Perferendis asperiores incidunt pariatur officia minima eum tempora earum ipsam atque molestias!",
        name: "Huglo Lepage Avocats",
        id: "6",
    },
];
