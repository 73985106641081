import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useConsumptionReference } from "src/api/sure-api/useConsumptionReference";

type ConsumptionProps = {
    consumptionIds: number[];
};
export const ProductConsumptionSummary = (props: ConsumptionProps) => {
    const { consumptionIds } = props;
    const { t } = useTranslation();

    const { consumptionReference } = useConsumptionReference();

    if (consumptionIds.length === 0) {
        return (
            <Typography
                variant="h3"
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                }}
            >
                {t("Pas de mode de consommation associé a ce produit")}
            </Typography>
        );
    }
    const noValue = "-";
    return (
        <Box>
            <Typography variant="h4">{t("Mode de consommation du produit:")}</Typography>
            <ul>
                {consumptionIds.map((c, index) => {
                    const foundConsumption = consumptionReference?.find((consumption) => consumption.id === c);
                    return (
                        <li key={index}>
                            <Typography variant="body">{foundConsumption?.label ?? noValue}</Typography>
                        </li>
                    );
                })}
            </ul>
        </Box>
    );
};
