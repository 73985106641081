import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";

export const useGetAllSectors = () => {
    const query = useQuery({
        queryKey: ["sectorsValue"],
        queryFn: async () => {
            const response = await loggedInApiCall.get("/api/company_sectors");
            return response;
        },
        select: (request) => {
            const companySectors = request.data["hydra:member"];
            const data = zodParseWithLog(companySectors, CompanySectorZod.array(), "companySectors");
            return data;
        },
    });

    return {
        companySectors: query.data,
        ...query,
    };
};

export type Company = {
    id: number;
    name: string;
    updatedAt?: string;
};

export type CompanySector = {
    "@id"?: string;
    id: number;
    name: string;
    ssqId?: number;
    updatedAt?: string;
    companies?: Company[];
};

export const CompanyZod: z.ZodSchema<Company> = z.object({
    id: z.number(),
    name: z.string(),
    updatedAt: z.string().optional(),
});

export const CompanySectorZod: z.ZodSchema<CompanySector> = z.object({
    "@id": z.string().optional(),
    id: z.number(),
    name: z.string(),
    ssqId: z.number().optional(),
    updatedAt: z.string().optional(),
    companies: CompanyZod.array().optional(),
});

export type CompanySectorForm = {
    id?: number;
    name: string;
    ssqId?: number;
    updatedAt?: string;
};
