import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { SupplierFormIngredientSupplier } from "../../../SupplierForm.types";
import { SupplierIngredientsGrid } from "./SupplierIngredientsGrid";
import { IRI } from "src/types";

type SupplierIngredientsModalProps = {
    existingIngredientSuppliers: SupplierFormIngredientSupplier[];
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onValidate: (newIngredientSuppliers: SupplierFormIngredientSupplier[]) => void;
};
export const SupplierIngredientsModal = (props: SupplierIngredientsModalProps): JSX.Element | null => {
    const { t } = useTranslation();
    const { existingIngredientSuppliers, isOpen, setOpen, onValidate } = props;

    const [selectedIngredientSuppliers, setSelectedIngredientsSuppliers] = useState(existingIngredientSuppliers);

    useEffect(() => {
        setSelectedIngredientsSuppliers(existingIngredientSuppliers);
    }, [existingIngredientSuppliers]);

    const title = <Typography variant="h2">{t("Ajouter ou dissocier des ingrédients")}</Typography>;
    const content = (
        <SupplierIngredientsGrid
            existingIngredientSuppliers={selectedIngredientSuppliers}
            setNewIngredientSuppliers={setSelectedIngredientsSuppliers}
        />
    );

    const allOriginalIds = existingIngredientSuppliers.map((i) => i.ingredient["@id"]);
    const allNewlySelectedIds = selectedIngredientSuppliers.map((i) => i.ingredient["@id"]);
    const shouldConfirmOnClose = shouldConfirmAction(allOriginalIds, allNewlySelectedIds);

    return (
        <PrimaryDialog
            maxWidth={"lg"}
            fullWidth
            open={isOpen}
            setOpen={setOpen}
            options={{
                title: title,
                content: content,
            }}
            onValidate={() => {
                onValidate(selectedIngredientSuppliers);
            }}
            onCancel={() => {
                setSelectedIngredientsSuppliers(existingIngredientSuppliers);
            }}
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
            confirmAction={
                shouldConfirmOnClose ?
                    {
                        title: <Typography variant="h3">{t("suppliers.edit.confirmDeleteIngSup.title")}</Typography>,
                        content: (
                            <Typography variant="body-medium">
                                {t("suppliers.edit.confirmDeleteIngSup.message")}
                            </Typography>
                        ),
                        cancelText: t("suppliers.edit.confirmDeleteIngSup.cancel"),
                        validateText: t("suppliers.edit.confirmDeleteIngSup.confirm"),
                    }
                :   undefined
            }
        />
    );
};

const shouldConfirmAction = (originalIds: IRI[], newIds: IRI[]) => {
    if (originalIds.length === 0) return false;

    for (const key of originalIds) {
        const doesKeyExist = newIds.includes(key);
        if (doesKeyExist === false) {
            return true;
        }
    }

    return false;
};
