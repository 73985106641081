import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./i18n";
import { FoodsThemeProvider, Themes, isTheme, themes } from "@foodpilot/foods";
import { FoodpilotSnackbar } from "./utils/useSnackbar";
import { useApplicationParameters } from "./api/client-api/appParams/useApplicationParameters";
import { createTheme, ThemeProvider } from "@mui/material";
import { getApiUrl, getSureApiUrl } from "src/api/axiosClient/utils.ts";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { getEnv } from "src/utils/getEnv";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const envName = getEnv("VITE_APP_ENV_NAME");

Sentry.init({
    dsn: "https://4f07d96d1d1768cd4b7265be9b5438d0@o4507050960551936.ingest.us.sentry.io/4508091974156288",
    environment: envName,
    enabled: envName !== "local",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [getApiUrl(), getSureApiUrl()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true, // default is true.
        },
    },
    queryCache: new QueryCache({
        // This is the place we handle all the global Errors/Success behavior concerning the UI.
    }),
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const ThemedApp = () => {
    const { applicationParameters } = useApplicationParameters();
    const themeParameter = applicationParameters?.theme ?? "";
    const theme: Themes = isTheme(themeParameter) ? themeParameter : "foodpilot";

    // FoodsThemeProvider is essential to be able to use the Foods component
    // with the correct theme.
    // The ThemeProvider is essential because if we directly use MUI component
    // inside this app, we want them to also have the Theme.

    // One solution would be to export the MUI component from Foods, and not
    // install MUI at all in foodpilot-app.
    return (
        <FoodsThemeProvider theme={theme}>
            <ThemeProvider theme={createTheme(themes[theme])}>
                <App />
            </ThemeProvider>
        </FoodsThemeProvider>
    );
};

root.render(
    <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
            <FoodpilotSnackbar>
                <Suspense fallback={<div>Loading...</div>}>
                    <ThemedApp />
                </Suspense>
            </FoodpilotSnackbar>
        </DndProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>,
);
