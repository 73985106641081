import { CompaniesService } from "src/services/Companies/CompaniesService";
import { LocalStorage } from "src/services/LocalStorage";

// The Tokens are always handled together. There is no reason to split them.
export type Token = {
    token: string | null;
    refreshToken: string | null;
    connectedAs: string | null;
};

const tokenKey = "token";
const refreshTokenKey = "refresh-token";

export const getFullToken = (): Token => {
    return {
        token: localStorage.getItem(tokenKey),
        refreshToken: localStorage.getItem(refreshTokenKey),
        connectedAs: LocalStorage.getItem("connectedAs"),
    };
};

export const unimpersonateUser = (): void => {
    LocalStorage.deleteItem("connectedAs");
    LocalStorage.deleteItem("company");
    window.location.href = "/";
};

export const isImpersonationActive = (): boolean => {
    return LocalStorage.getItem("connectedAs") !== null;
};

const inexwebToken = "inexweb_token";
const inexwebCompany = "inexweb_company";
const company_corporate_name = "company_corporate_name";
const company_identification_number = "company_identification_number";

type InexwebInfo = {
    token: string | null;
    company: string | null;
    company_corporate_name: string | null;
    company_identification_number: string | null;
};

export const getInexwebInfo = (): InexwebInfo => {
    return {
        token: JSON.parse(localStorage.getItem(inexwebToken) || '""'),
        company: JSON.parse(localStorage.getItem(inexwebCompany) || '""'),
        company_corporate_name: JSON.parse(localStorage.getItem(company_corporate_name) || '""'),
        company_identification_number: JSON.parse(localStorage.getItem(company_identification_number) || '""'),
    };
};

export const setInexwebInfo = (
    token: string,
    company: string,
    corporate_name: string,
    identification_number: string,
): void => {
    localStorage.setItem(inexwebToken, JSON.stringify(token));
    localStorage.setItem(inexwebCompany, JSON.stringify(company));
    localStorage.setItem(company_corporate_name, JSON.stringify(corporate_name));
    localStorage.setItem(company_identification_number, JSON.stringify(identification_number));
};

export const removeInexwebInfo = () => {
    localStorage.removeItem(inexwebCompany);
    localStorage.removeItem(inexwebToken);
    localStorage.removeItem(company_corporate_name);
    localStorage.removeItem(company_identification_number);
};
