import { FoodsBadge, FoodsIcon } from "@foodpilot/foods";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { VerticalDivider } from "../../../Common/VerticalDivider";
import { useTranslation } from "react-i18next";

type EditHeaderProps = {
    title: string;
    subtitle: string;
    isActivated: boolean;
    deleteAction?: () => void;
    disableAction: () => void;
    goBackAction: () => void;
};
export const EditHeader = (props: EditHeaderProps) => {
    const { title, subtitle, deleteAction, disableAction, goBackAction } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack
            flexDirection={"row"}
            padding={theme.spacing(2, 4)}
            justifyContent={"space-between"}
            borderBottom={`1px solid black`}
        >
            <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                <Box onClick={goBackAction}>
                    <FoodsIcon size={3} icon="arrowLeft" />
                </Box>
                <Stack gap={theme.spacing(0.5)}>
                    <Typography variant={"big-bold"}>{title}</Typography>
                    <Typography variant={"big-medium"}>{subtitle}</Typography>
                </Stack>
            </Stack>
            <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                {deleteAction && (
                    <FoodsBadge
                        size={4}
                        icon="delete"
                        boxProps={{
                            onClick: deleteAction,
                        }}
                    />
                )}
                <VerticalDivider />
                {disableAction && (
                    <Button variant="primary" onClick={disableAction}>
                        {props.isActivated ? t("glossary.deactivate") : t("glossary.activate")}
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};
