import { FormBox } from "@foodpilot/foods";
import { Box } from "@mui/material";
import { ProductFormProductIngredient } from "../../../ProductForm.types";
import { ProductWeightReminder } from "./ProductWeightReminder";
import { IngredientBox } from "./IngredientBox";
import { Header } from "./Header";

type DisplayIngredientsProps = {
    ingredients: ProductFormProductIngredient[];
    productWeight: number;
    setProductIngredient: (newIngredients: ProductFormProductIngredient[]) => void;
    openModal: () => void;
};
export const IngredientsFormGrid = (props: DisplayIngredientsProps) => {
    const totalWeight = props.ingredients.reduce((acc, ingredient) => {
        return acc + (ingredient.quantity || 0);
    }, 0);

    const weightToDisplay = props.productWeight ?? 0;

    return (
        <Box style={{ display: "flex", gap: "2rem" }}>
            <FormBox sx={{ flex: 3 }}>
                <Header openModal={props.openModal} />
                <IngredientBox ingredients={props.ingredients} setProductIngredient={props.setProductIngredient} />
            </FormBox>
            <FormBox sx={{ flex: 1, position: "sticky", top: "120px" }}>
                <ProductWeightReminder productWeight={weightToDisplay} currentWeight={totalWeight} />
            </FormBox>
        </Box>
    );
};
