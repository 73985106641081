import { CreateOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface IGenericAccordionProps {
    children: JSX.Element | JSX.Element[];
    headerIcon: JSX.Element;
    headerText: string;
    controls?: JSX.Element | JSX.Element[];
    modify?: () => void;
}

export function GenericBox(props: IGenericAccordionProps) {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            component="div"
            sx={{
                borderRadius: "8px",
                background: "#FFF",
                boxShadow: " 0px 2px 8px 0px rgba(42, 43, 39, 0.04)",
                border: `1px solid ${theme.custom.grey[500]}`,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%", padding: "30px 24px" }}
            >
                <Stack direction="row" useFlexGap gap="12px" alignItems="center">
                    {props.headerIcon}
                    <Typography variant="h3">{props.headerText}</Typography>
                </Stack>
                <Stack direction="row" useFlexGap gap="24px" alignItems="center">
                    {props.controls}
                    {!props.controls && (
                        <Button
                            onClick={props.modify}
                            variant="primary"
                            startIcon={<CreateOutlined />}
                            sx={{ marginRight: "24px", borderRadius: "44px" }}
                        >
                            {t("Modifier")}
                        </Button>
                    )}
                </Stack>
            </Stack>
            <div>
                <Divider />
                <Box component="div" sx={{ padding: "44px" }}>
                    {props.children}
                </Box>
            </div>
        </Box>
    );
}
