import { ScoreType } from "src/api/client-api/scoreType/useGetAllScoreType";

export class ColumnsService {
    private static readonly orderGridKey = "order";
    private static readonly registerGridKey = "registered-grid";
    private static readonly widthGridKey = "witdth";

    static saveColumnVisibility = (gridKey: string, columnVisibility: Map<string, boolean>) => {
        const columnVisibilityString = JSON.stringify(Array.from(columnVisibility.entries()));

        localStorage.setItem(ColumnsService.keyBuilder("visibility", gridKey), columnVisibilityString);
    };

    static saveColumnOrder = (gridKey: string, columnOrder: string[]) => {
        const columnOrderString = JSON.stringify(columnOrder);

        localStorage.setItem(ColumnsService.keyBuilder("order", gridKey), columnOrderString);
    };

    static saveColumnWidth = (gridKey: string, columnWidth: Record<string, number>) => {
        const columnWidthString = JSON.stringify(columnWidth);

        localStorage.setItem(ColumnsService.keyBuilder("width", gridKey), columnWidthString);
    };

    static getColumnVisibility = (gridKey: string): Map<string, boolean> => {
        const columnVisibilityString = localStorage.getItem(ColumnsService.keyBuilder("visibility", gridKey));

        if (columnVisibilityString) {
            const columnVisibilityArray = JSON.parse(columnVisibilityString);
            return new Map(columnVisibilityArray);
        }

        return new Map<string, boolean>();
    };

    static getColumnOrder = (gridKey: string): string[] => {
        const columnOrderString = localStorage.getItem(ColumnsService.keyBuilder("order", gridKey));

        if (columnOrderString) {
            const columnOrderArray = JSON.parse(columnOrderString);
            return columnOrderArray;
        }

        return new Array<string>();
    };

    static getColumnWidth = (gridKey: string): Record<string, number> => {
        const columnSizingString = localStorage.getItem(ColumnsService.keyBuilder("width", gridKey));

        if (columnSizingString) {
            const columnSizingRecord = JSON.parse(columnSizingString);
            return columnSizingRecord;
        }

        return {};
    };

    static deleteColumnVisibility = (gridKey: string) => {
        localStorage.removeItem(gridKey);
    };

    static deleteColumnOrder = (gridKey: string) => {
        const buildedKey = ColumnsService.keyBuilder("order", gridKey);
        localStorage.removeItem(buildedKey);
    };

    static registerGrid = (gridKey: string) => {
        const buildedKey = ColumnsService.keyBuilder("register", undefined);
        const buildedKeyOfGrid = ColumnsService.keyBuilder("visibility", gridKey);

        const currentRegisteredGrid = ColumnsService.getRegisteredGrid();

        if (!currentRegisteredGrid.some((g) => g === buildedKeyOfGrid)) {
            currentRegisteredGrid.push(buildedKeyOfGrid);
        }

        const stringifyCurrentRegisteredGrid = JSON.stringify(currentRegisteredGrid);

        localStorage.setItem(buildedKey, stringifyCurrentRegisteredGrid);
    };

    static getRegisteredGrid = () => {
        const result = localStorage.getItem(ColumnsService.keyBuilder("register", undefined));

        if (result) {
            const parsedResult = JSON.parse(result);

            if (Array.isArray(parsedResult)) {
                return parsedResult as string[];
            } else {
                console.warn("Parsed result is not an array", parsedResult);
            }
        }

        return new Array<string>();
    };

    private static keyBuilder = (gridKeyType: GridKeyType, gridKey: string | undefined) => {
        let key = "";

        if (!gridKey && (gridKeyType === "visibility" || gridKeyType === "order" || gridKeyType === "width"))
            throw Error(`Can't use ${gridKeyType} without gridKey`);

        switch (gridKeyType) {
            case "order": {
                key = `${gridKey}-${ColumnsService.orderGridKey}`;
                break;
            }
            case "register": {
                key = `${ColumnsService.registerGridKey}`;
                break;
            }
            case "visibility": {
                key = `${gridKey}`;
                break;
            }
            case "width": {
                key = `${ColumnsService.widthGridKey}-${gridKey}`;
                break;
            }
        }

        return key;
    };

    private static deleteColumnsSettingsOfGridByGridKeyAndColumnsKey = (gridKey: string, columnsKeys: string[]) => {
        const registeredGrid = ColumnsService.getRegisteredGrid();

        if (!registeredGrid.includes(gridKey)) return;

        const currentColumnVisibility = ColumnsService.getColumnVisibility(gridKey);
        const currentColumnOrder = ColumnsService.getColumnOrder(gridKey);

        // First we clear every scores key from the map
        const currentScoresKeys = Array.from(currentColumnVisibility.keys()).filter((key) => key.includes("score"));
        currentScoresKeys.forEach((key) => currentColumnVisibility.delete(key));

        // Remove the necessary key from columnOrder
        const keyToRemoveFromColumnOrder = currentScoresKeys.filter((element) => !columnsKeys.includes(element));
        keyToRemoveFromColumnOrder.forEach((key) => {
            const indexToDelete = currentColumnOrder.findIndex((k) => k === key);
            if (indexToDelete !== -1) currentColumnOrder.splice(indexToDelete, 1);
        });

        // We put visible for the selected keys
        columnsKeys.forEach((columKey) => currentColumnVisibility.set(columKey, true));

        ColumnsService.saveColumnVisibility(gridKey, currentColumnVisibility);
        ColumnsService.saveColumnOrder(gridKey, currentColumnOrder);
    };

    static updateScoresColumnsSettings = (type: ScoreType["type"], scoreIds: number[]) => {
        mapScoreWithGrid.forEach((gridKey, scoreKey) => {
            if (scoreKey !== type) return;

            const scoresKeys = scoreIds.map((scoreId) => `score-${scoreId}`);
            ColumnsService.deleteColumnsSettingsOfGridByGridKeyAndColumnsKey(gridKey, scoresKeys);
        });
    };
}

type GridKeyType = "order" | "visibility" | "register" | "width";

const mapScoreWithGrid = new Map<ScoreType["type"], string>([
    ["product", "product-grid"],
    ["ingredient", "ingredients-grid"],
    ["company", "supplier-grid"],
]);
