import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SupplierForm } from "./SupplierForm";
import { useGetOneSupplier } from "src/api/client-api/suppliers/useGetOneSupplier";

export const UpdateSupplierPage = () => {
    const { supplierId, startingPageId } = useParams();
    const { supplier, refetch, ...query } = useGetOneSupplier(supplierId);

    // useEffect(() => {
    //     if (supplier === undefined) {
    //         // Fetch the supplier ONLY on first render.
    //         refetch();
    //     }
    // }, []);

    if (query.isFetched === false) {
        return null;
    }

    if (supplier === undefined) {
        // Should display an Error screen
        // It means the ID is wrong
        return null;
    }

    return <SupplierForm existingSupplier={supplier} startingPageId={startingPageId ?? "0"} />;
};
