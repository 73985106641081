import { Box, BoxProps, CircularProgress } from "@mui/material";

export function Loading(props: BoxProps) {
    return (
        <Box
            sx={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                marginTop: "5%",
                marginBottom: "5%",
            }}
            {...props}
        >
            <CircularProgress />
        </Box>
    );
}
