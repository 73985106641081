import { useRef, useState } from "react";
import { Box, MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { FormPropertyProps } from "../../FormProperty";
import { FoodsTreeview } from "@foodpilot/foods";
import { DefaultValueButton } from "src/components/FormProperty/DefaultValueButton.tsx";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";

type SelectTreeViewProps = FormPropertyProps & {
    groupedOptions: Partial<Record<string, DataListOption[]>>;
    options: DataListOption[];
};

const itemIdFromOption = (option: DataListOption): string => `${option.groupOption}-${option.value}-${option.position}`;

export const SelectTreeView = (props: SelectTreeViewProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { groupedOptions, post, property, options, element } = props;

    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    const defaultValue = property.defaultValue ?? "";
    const defaultValueOption = options.find((option) => option.value === defaultValue) ?? null;
    const genericValueOption = options.find((option) => option.value === property.genericValue) ?? null;

    const initialValue = element && !property.readonly ? element.value ?? "" : defaultValue;

    const _selectedOption = options.find((option) => option.value === initialValue) ?? null;
    const selectedOption = useRef(_selectedOption);

    if (post === undefined) return;

    const selectedValue = selectedOption.current?.label ? itemIdFromOption(selectedOption.current) : t("No selection");

    const updatePropertyValue = (value: DataListOption | null, isGenericValue?: boolean) => {
        const sanitizedValue = value?.value ?? "_empty";

        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: sanitizedValue,
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
        });
    };

    return (
        <Stack direction="column" gap="1rem">
            <Stack direction="row" gap="1.5rem" alignItems="center">
                <Select
                    sx={{ borderRadius: "24px", width: "280px", fontSize: "12px" }}
                    value={selectedValue}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        PaperProps: {
                            sx: {
                                mt: "13px",
                                maxHeight: "200px",
                                px: "28px",
                                py: "24px",
                                borderRadius: "12px",
                                width: "30%",
                                "& .MuiMenuItem-root": {},
                            },
                        },
                    }}
                    disabled={genericValueSelected}
                >
                    {/* Hack to make MUI Select work with something else than MenuItem.
                     *  This is bad, but we need it ASAP
                     */}
                    {options.map((o, i) => (
                        <MenuItem sx={{ display: "none" }} key={i} value={itemIdFromOption(o)}>
                            {o.label}
                        </MenuItem>
                    ))}
                    <FoodsTreeview
                        defaultSelectedId={selectedValue}
                        items={Object.keys(groupedOptions).map((k) => ({
                            id: k,
                            label: k,
                            children: groupedOptions[k]!.map((o) => ({
                                id: itemIdFromOption(o),
                                label: o.label,
                            })),
                        }))}
                        onSelectedItem={(itemId) => {
                            const option = options.find((option) => itemIdFromOption(option) === itemId);
                            selectedOption.current = option!;
                            option &&
                                props.onUpdate({
                                    postId: post.id,
                                    propertyId: property.id,
                                    value: option.value,
                                    position: Number(props.position ?? 0),
                                });
                        }}
                    />
                </Select>
                {!property.readonly && defaultValueOption !== null && !genericValueSelected && (
                    <DefaultValueButton
                        onClick={() => {
                            if (selectedOption.current?.value === defaultValueOption.value) {
                                return;
                            }

                            selectedOption.current = defaultValueOption;
                            updatePropertyValue(defaultValueOption);
                        }}
                    />
                )}
            </Stack>
            {defaultValueOption !== null && !genericValueSelected && (
                <Typography variant="caption" color={theme.custom.grey[1500]}>
                    {!property.readonly ?
                        t("Valeur par défaut :") + " " + (defaultValueOption?.label ?? "")
                    :   t("Cette valeur est en lecture seule")}
                </Typography>
            )}
            {!property.readonly && genericValueOption && (
                <Box>
                    <GenericValueCheckbox
                        isSelected={genericValueSelected}
                        onChange={() => {
                            setGenericValueSelected((prevState) => !prevState);
                            selectedOption.current = !genericValueSelected ? genericValueOption : null;

                            updatePropertyValue(
                                !genericValueSelected ? genericValueOption : null,
                                !genericValueSelected,
                            );
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
};
