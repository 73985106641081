import { Route, Navigate, Outlet } from "react-router-dom";
import { useProductRouter } from "../Product/productRouter";
import { useIngredientRouter } from "../Ingredient/ingredientRouter";
import { useSupplierRouter } from "../Supplier/supplierRouter";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { CompanyTabs } from "./CompanyTabs";
import { companySlug } from "src/services/URLHandler";
import { RouterClientSwitch } from "src/components/ClientSwitch/RouterClientSwitch";
import { QuestionnaireUpdate } from "./Questionnaire/QuestionnaireUpdate/QuestionnaireUpdate";
import { MainLayout } from "../Actions/MainLayout";
import { ActionsProvider } from "src/context/Actions/provider";
import { ListPlanning } from "../Actions/Planning/List/ListPlanning";
import { Synthesis } from "../Actions/Synthesis/Synthesis";
import { EditPlanningProperties } from "../Actions/Planning/EditProperties/EditPlanningProperties";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { CompanyProvider } from "src/context/Company/provider";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { useCompanyContext } from "src/context/Company/useCompanyContext";

export const useCompanyRouter = () => {
    const productRouter = useProductRouter();
    const ingredientRouter = useIngredientRouter();
    const supplierRouter = useSupplierRouter();
    const clientSwitch = useClientSwitch();

    const cs_defaultRedirect = clientSwitch({
        defaultOption: <Navigate to="questionnaire" replace />,
        bred: <Navigate to="synthese" replace />,
        collective: <Navigate to="scores" replace />,
    });

    return (
        <Route path={`${companySlug}/:companyId`}>
            <Route index element={cs_defaultRedirect} />

            <Route element={<CompanyContext />}>
                <Route path="scores" element={<RouterClientSwitch whitelistedMode={["collective"]} />}>
                    <Route index element={<CompanyTabs section="scores" />} />
                </Route>

                <Route path="synthese" element={<RouterClientSwitch whitelistedMode={["bred"]} />}>
                    <Route index element={<CompanyTabs section="synthese" />} />
                </Route>

                <Route path="questionnaire" element={<RouterClientSwitch whitelistedMode={["bred", "collective"]} />}>
                    <Route index element={<CompanyTabs section="questionnaire" />} />
                    <Route path="edit/:headingId" element={<QuestionnaireUpdate />} />
                </Route>

                <Route path="requirements" element={<RouterClientSwitch whitelistedMode={["bred"]} />}>
                    <Route index element={<CompanyTabs section="exigences" />} />
                </Route>

                <Route path="actions" element={<ActionCompanyContext />}>
                    <Route index element={<Navigate to="synthesis" replace />} />
                    <Route path="synthesis">
                        <Route element={<MainLayout selectedSection="synthesis" />}>
                            <Route index element={<Synthesis />}></Route>
                        </Route>
                    </Route>

                    <Route path="planning">
                        <Route element={<MainLayout selectedSection="planning" />}>
                            <Route index element={<ListPlanning />} />
                            <Route path=":actionId" element={<EditPlanningProperties />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            {productRouter}
            {ingredientRouter}
            {supplierRouter}
        </Route>
    );
};

const CompanyContext = () => {
    return (
        <CompanyProvider>
            <Outlet />
        </CompanyProvider>
    );
};

const ActionCompanyContext = () => {
    const { currentCampaign } = useFoodpilotContext();
    const { company, companyScores, scoreState, allScoreWithVariations } = useCompanyContext();

    const { post, isFetched } = useGetCompanyPost(company.id, currentCampaign?.id);

    if (isFetched === false) {
        return;
    }

    if (post === undefined) {
        return <>You must answer a SSQ first !</>;
    }

    // Get Action.
    // With the Action, that contains the Reference SSQ ? Or use SSQ Reference anyway.
    // THis links to the starting one.
    // If starting one is different than the current one.
    // Then current is current + fetch the start.
    // Else current is start.

    if (company.scores === undefined) {
        return <>No Scores !</>;
    }

    return (
        <ActionsProvider
            start={{
                campaign: currentCampaign?.label ?? "",
                post: post,
                scores: company.scores,
            }}
            current={{
                campaign: currentCampaign?.label ?? "",
                post: post,
                scores: company.scores,
            }}
            target={{
                campaign: currentCampaign?.label ?? "",
                post: post,
                scores: company.scores,
            }}
            scoresEnabled={companyScores}
            scoreState={scoreState}
            allScoreWithVariations={allScoreWithVariations}
        >
            <Outlet />
        </ActionsProvider>
    );
};
