import { BlockItemContainer, BlockListContainer, ListHeader, Typography } from "@foodpilot/foods";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { PropertySwitch } from "./PropertySwitch";
import { useNavigate, useParams } from "react-router-dom";
import { PlanningLayout } from "../PlanningLayout";
import { ActionService } from "src/context/Actions/ActionService";
import { useTranslation } from "react-i18next";
import { Stack, useTheme } from "@mui/material";
import { URLHandler } from "src/services/URLHandler";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

export type EditPlanningPropertiesProps = unknown;
export const EditPlanningProperties = (_props: EditPlanningPropertiesProps) => {
    const { actionId } = useParams();
    const { selectedCompany } = useFoodpilotContext();
    const { actions, start, current, target, scoreByProperties } = useActionsContext();
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    if (actions === undefined) return null;

    // Maybe I want to make a `getOne`;
    const selectedAction = actions.find((item) => item.id === Number(actionId));
    if (selectedAction === undefined) return;

    const propertiesWithElements = ActionService.matchByPropertyId(
        selectedAction.ssqProperties,
        start.post.elements ?? [],
        current?.post.elements ?? [],
        target.post.elements ?? [],
    );

    const blocks = selectedAction.ssqProperties.map((ssqProperty) => {
        const type = ssqProperty.field?.type;
        const fields =
            type === "group" ?
                ssqProperty.children?.map((subProperty) => {
                    return <PropertySwitch propertyWithElements={propertiesWithElements[subProperty.id]} />;
                })
            :   [<PropertySwitch propertyWithElements={propertiesWithElements[ssqProperty.id]} />];

        const propertyScore = scoreByProperties?.[ssqProperty.id];
        return (
            <BlockItemContainer
                title={ssqProperty.title ?? ""}
                value={propertyScore?.score ?? null}
                unit={propertyScore?.label}
                fields={fields ?? []}
            />
        );
    });

    return (
        <PlanningLayout>
            <BlockListContainer
                header={
                    <ListHeader
                        title={
                            <Stack flexDirection="row" gap={theme.spacing(1)}>
                                <Typography variant={"h3"}>{t("actionPlan.activeActions")}</Typography>
                                <Typography variant={"huge"}>{"-"}</Typography>
                                {/* <Typography variant={"huge"}>{`${numberofActions} / ${totalActions}`}</Typography> */}
                            </Stack>
                        }
                        cancel={{
                            label: t("glossary.cancel"),
                            action: () => {
                                if (selectedCompany === null) return;
                                const url = URLHandler.actions.planning(selectedCompany.id);
                                navigate(url);
                            },
                        }}
                        confirm={{
                            label: t("actionPlan.validate"),
                            action: function (): void {
                                throw new Error("Function not implemented.");
                            },
                            disabled: undefined,
                        }}
                    />
                }
                blocks={blocks}
            />
        </PlanningLayout>
    );
};
