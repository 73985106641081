import { BigPopoverLayout } from "@foodpilot/foods";
import { Dialog, Box, Typography, useTheme, Button } from "@mui/material";

type ConfirmDialogProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
    const { onClose, open, onConfirm } = props;

    const handleClickValidate = () => {
        onConfirm();
    };

    return (
        <Dialog
            open={!!open}
            sx={{
                height: "380px",
                ".MuiPaper-root": {
                    backgroundColor: "transparent",
                },
                hr: { display: "none" },
                ".MuiPaper-rounded": {
                    borderRadius: "12px",
                },
            }}
        >
            <BigPopoverLayout
                headerIcon={<></>}
                headerText="Êtes-vous sûr(e) de vouloir quitter la création du bilan ?"
                onClose={onClose}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ mb: 3 }}>
                        Si vous quittez le parcours, vos données ne seront pas sauvegardées.
                    </Typography>
                    <CustomDivider />
                    <Box sx={{ mt: 4 }} />
                    <Button variant="primary" onClick={handleClickValidate}>
                        Arrêter et quitter la création du bilan
                    </Button>
                    <Button onClick={onClose}>Ne pas quitter la création</Button>
                </Box>
            </BigPopoverLayout>
        </Dialog>
    );
};

const CustomDivider = () => {
    const theme = useTheme();
    return <Box sx={{ borderBottom: `1px solid ${theme.custom.grey[500]}`, width: "120%", ml: "-10%" }} />;
};
