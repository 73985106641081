import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

export const PlanningListHeader = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { currentCampaign, targetCampaign } = useFoodpilotContext();

    if (currentCampaign === null) {
        // Do nothing now, but maybe have some screen about campaign needing to be selected ?
        console.warn("No Current campaign !");
    }

    if (targetCampaign === undefined) {
        console.warn("You must have a targetCampaign !");
    }

    return (
        <Stack flexDirection={"row"} gap={theme.spacing(2)}>
            <Typography variant="h2">{t("actions.planning.title")}</Typography>
            <Box sx={{ height: "40px", borderLeft: `1px solid ${theme.custom.grey[500]}` }} />
            <Stack flexDirection={"row"} gap={theme.spacing(0.5)} alignItems={"center"}>
                <Typography variant="body">{t("glossary.from")}</Typography>
                <Typography variant="body-bold">{currentCampaign?.label}</Typography>
                <Typography variant="body">{t("glossary.to")}</Typography>
                <Typography variant="body-bold">{targetCampaign?.label}</Typography>
            </Stack>
        </Stack>
    );
};
