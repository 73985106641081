import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import InformationIcon from "src/assets/icons/information.svg?react";
import { FoodsIcon, SectionTab, SectionTabs } from "@foodpilot/foods";
import { useIngredientContext } from "./ExistingIngredientContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { CampaignSelector } from "src/components/Selector";
import { URLHandler } from "src/services/URLHandler";

type SectionKeys = "scores" | "questionnaire" | "activities" | "informations";
type IngredientTab = SectionTab & { title?: string };
type IngredientSections = Partial<Record<SectionKeys, IngredientTab>>;

type IngredientDetailsProps = {
    selectedSection: SectionKeys;
};
export const IngredientPrimaryTabs = (props: IngredientDetailsProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const { selectedSection } = props;
    const { selectedCompany, isUserOfTypeSupplier } = useFoodpilotContext();
    const { ingredient, activeSupplierId } = useIngredientContext();

    if (ingredient === undefined || selectedCompany === null) {
        return;
    }

    const isSupplier = isUserOfTypeSupplier();

    const sections: IngredientSections = {};

    sections["scores"] = {
        name: t("Scores & indicateurs clés"),
        icon: <FoodsIcon size={2} icon="scoresIndicators" />,
        onClick: () => {
            const url = URLHandler.ingredient.scores(selectedCompany.id, ingredient.id);
            navigate(url);
        },
    };

    sections["questionnaire"] = {
        name: t("Questionnaires"),
        icon: <FoodsIcon size={2} icon="questionnaires" />,
        onClick: () => {
            const firstSupplier = ingredient.ingredientSuppliers?.[0];
            const supplierId = activeSupplierId ?? firstSupplier?.supplier.id;
            const url = URLHandler.ingredient.ssq(selectedCompany.id, ingredient.id, supplierId);

            navigate(url);
        },
    };

    if (isSupplier === false) {
        sections["activities"] = {
            name: t("Données d’activité"),
            icon: <FoodsIcon size={2} icon="activityData" />,
            onClick: () => {
                const url = URLHandler.ingredient.activity(selectedCompany.id, ingredient.id);
                navigate(url);
            },
        };
    }

    sections["informations"] = {
        name: t("Informations"),
        icon: <FoodsIcon size={2} icon="informations" />,
        onClick: () => {
            const url = URLHandler.ingredient.information(selectedCompany.id, ingredient.id);
            navigate(url);
        },
    };

    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto" }}>
            <Box
                sx={{
                    width: "auto",
                    background: "#fff",
                    padding: "28px 52px",
                    paddingBottom: 0,
                    position: "relative",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                }}
            >
                <Stack direction="row" alignItems="center" gap="16px">
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <ArrowBackIcon
                            style={{
                                cursor: "pointer",
                                margin: "8px",
                            }}
                            onClick={() => navigate(URLHandler.ingredient.list(selectedCompany.id))}
                        />
                        <Typography variant="h1">{ingredient.name}</Typography>
                    </Box>
                    <CampaignSelector />
                </Stack>

                <Divider sx={{ marginTop: "28px" }} />
                <SectionTabs
                    tabs={Object.values(sections)}
                    value={Object.keys(sections).indexOf(selectedSection)}
                    renderComponents={false}
                    sx={{
                        position: "relative",
                        bottom: "-1px",
                    }}
                />
            </Box>

            <Stack gap="28px" sx={{ padding: "40px 52px" }}>
                <Outlet />
            </Stack>
        </Box>
    );
};
