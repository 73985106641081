import { WhiteBox } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import QuestionnaireIcon from "src/assets/icons/questionnaire-huge.svg?react";

export const NoSsq = () => {
    const { t } = useTranslation();
    return (
        <WhiteBox
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    marginBlock: "80px",
                    maxWidth: "600px",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                }}
            >
                <QuestionnaireIcon />
                <Typography variant="h3" sx={{ textAlign: "justify", textAlignLast: "center" }}>
                    {t("ingredient.questionnaire.nossq.title")}
                </Typography>
                <Typography variant="body-medium" sx={{ textAlign: "justify", textAlignLast: "center" }}>
                    {t("ingredient.questionnaire.nossq.info")}
                </Typography>
            </Box>
        </WhiteBox>
    );
};
