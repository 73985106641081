import { useParams } from "react-router-dom";
import { useGetOneUser } from "src/api/client-api/user/useGetOneUser";
import { UserCreate } from "./UserCreate";

export const UserEdit = () => {
    const { id } = useParams();

    const { user, isFetched } = useGetOneUser(id);

    if (isFetched === false) {
        return;
    }

    if (user === undefined) {
        return;
    }

    return <UserCreate user={user} />;
};
