import { createContext } from "react";
import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { ScoreState } from "../EntityBasedScore/useEntityBasedScoreParams";
import { ScoreVariation } from "../EntityBasedScore/ScoreService";
import { Score } from "src/types";

export type CompanyContextInitialValues = {
    company: Supplier;
    companyScores: Score[];
    scoreState: ScoreState;
    allScoreWithVariations: ScoreVariation[];
};
export const CompanyContext = createContext<CompanyContextInitialValues | null>(null);
