import { Stack, Typography } from "@mui/material";
import { formatNumber } from "src/utils/formatting";
import { useTranslation } from "react-i18next";

export type ScoreExplicitValueProps = {
    score: number;
    precision?: number;
};

export const ScoreExplicitValue = (props: ScoreExplicitValueProps) => {
    const { i18n } = useTranslation();
    const { score, precision } = props;

    const value = formatNumber(score ?? 0, i18n.resolvedLanguage, { maximumFractionDigits: precision });

    return (
        <>
            <Stack alignItems="center" gap="10px">
                <Typography
                    variant="h1"
                    sx={{
                        fontWeight: 800,
                        position: "relative",
                    }}
                >
                    {value}
                </Typography>
            </Stack>
        </>
    );
};
