import { useContext } from "react";
import { SettingsCMSContext } from "./context";

export const useSettingsCMSContext = () => {
    const context = useContext(SettingsCMSContext);

    if (context) {
        return context;
    }

    throw new Error(`useSettingsCMSContext must be used within the contexta`);
};
