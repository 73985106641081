import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { ApplicationParameterKeys } from "./useApplicationParameters";

type UpsertAppParam = {
    isNew: boolean;
    key: ApplicationParameterKeys;
    value: string;
};

export const useUpsertAppParam = () => {
    const query = useMutation({
        mutationFn: async (options: UpsertAppParam) => {
            const { isNew, key, value } = options;

            const payload = {
                key: key,
                value: value,
                public: 1,
            };
            const response =
                isNew ?
                    await loggedInApiCall.post(`/api/application_parameters`, payload)
                :   await loggedInApiCall.put(`/api/application_parameters/${key}`, payload);

            return response;
        },
        onSuccess: () => {
            // For now, in order to immediately apply the changes, we want to reload the page;
            // Maybe later, we'll find a way to do it more properly.
            location.reload();
        },
    });

    return query;
};

export type UpdateFile = {
    key: ApplicationParameterKeys;
    value: File;
};

export const useUpdateFile = () => {
    const query = useMutation({
        mutationFn: async (props: UpdateFile) => {
            const { key, value } = props;

            const payload = new FormData();
            payload.append("key", key);
            payload.append("file", value);

            const url = `/api/application_parameters/upload_file`;
            const resp = await loggedInApiCall.post(url, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            return resp;
        },
        onSuccess: () => {
            // For now, in order to immediately apply the changes, we want to reload the page;
            // Maybe later, we'll find a way to do it more properly.
            location.reload();
        },
    });

    return query;
};
