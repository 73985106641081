import { Box, Stack, useTheme } from "@mui/material";
import { Footer } from "../../components/Footer.tsx";
import { FoodsNavbar } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isImpersonationActive, unimpersonateUser } from "src/utils/token.ts";
import { LanguageSelector } from "../../components/Selector/LanguageSelector/LanguageSelector.tsx";
import { CompanySelectorSidebar } from "src/components/Sidebar/CompanySelectorSidebar.tsx";
import { useClientButton } from "./useClientButton.tsx";

export interface IBaseTemplateProps {
    children: JSX.Element | JSX.Element[];
}

export const BaseTemplate = (props: IBaseTemplateProps) => {
    const theme = useTheme();
    const { user, selectedCompany } = useFoodpilotContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // 80 = width of closed nav, 280 = width of opened nav
    const [currentMarginLeft, setCurrentMarginLeft] = useState<"80px" | "280px">("80px");

    const handleLayout = (isOpen: boolean) => {
        isOpen ? setCurrentMarginLeft("280px") : setCurrentMarginLeft("80px");
    };

    const handleUnimpersonateUser = () => {
        unimpersonateUser();
    };

    const buttons = useClientButton();

    const userActions = [<LanguageSelector key="languageSelector" />];

    return (
        <Stack direction="row" spacing={0} useFlexGap justifyContent="center" sx={{ minHeight: "100%" }}>
            <Box sx={{ position: "fixed", left: "0", zIndex: 1000 }}>
                <FoodsNavbar
                    buttons={buttons}
                    connectedUser={user.trustedUser}
                    organization={{
                        name: selectedCompany?.name ?? "FoodPilot",
                        popover: {
                            element: <CompanySelectorSidebar allCompanies={user.trustedUser.companies} />,
                        },
                    }}
                    sidebarTexts={{
                        shortenSidebar: t("Réduire la barre de navigation"),
                        logout: isImpersonationActive() ? t("auth.finish_impersonation") : t("Se déconnecter"),
                    }}
                    sidebarStatusHandler={handleLayout}
                    logoutAction={() => (isImpersonationActive() ? handleUnimpersonateUser() : navigate("/logout"))}
                    userActions={userActions}
                />
            </Box>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: `calc(100% - ${currentMarginLeft})`,
                    minHeight: "100%",

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: theme.custom.grey[300],

                    marginLeft: currentMarginLeft,
                    transition: "margin .3s ease, max-width .3s ease",
                }}
            >
                <Box>{props.children}</Box>
                <Box>
                    <Footer />
                </Box>
            </Box>
        </Stack>
    );
};
