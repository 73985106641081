import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { formatNumber } from "src/utils/formatting";

export type Statistic = {
    title: string;
    value: number;
    unit: string;
};

export type SuppliersStatisticsProps = {
    statistics: Statistic[];
};

export const SuppliersStatistics = (props: SuppliersStatisticsProps) => {
    const { statistics } = props;

    return (
        <Box
            sx={{
                backgroundColor: "#FEFCFB",
                border: "1px solid #E7E6E4",
                borderRadius: "8px",
                padding: "32px",
                display: "flex",
                justifyContent: "space-around",
                gap: "32px",
            }}
        >
            {statistics &&
                statistics.map((statistic, index) => {
                    return (
                        <React.Fragment key={index}>
                            <StatisticCard statistic={statistic} />
                            {index !== statistics.length - 1 && <Divider orientation="vertical" flexItem />}
                        </React.Fragment>
                    );
                })}
        </Box>
    );
};

type StatisticCardProps = { statistic: Statistic };

const StatisticCard = (props: StatisticCardProps) => {
    const { statistic } = props;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}>
            <Typography variant="h5">{statistic.title}</Typography>
            <Box sx={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
                <Typography variant="h3">{formatNumber(statistic.value)}</Typography>
                <Typography variant="body-medium" sx={{ color: "#A3A2A0" }}>
                    {statistic.unit}
                </Typography>
            </Box>
        </Box>
    );
};
