import { WhiteBox } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";

export const HelpPlease = () => (
    <Box sx={{ flex: 1 }}>
        <WhiteBox sx={{ py: 3, px: "14px" }}>
            <Typography sx={{ mb: 2 }} variant="big-bold" component={"h3"}>
                Besoin d'aide ?
            </Typography>
            <Typography sx={{ textAlign: "justify" }} component={"span"} variant="body">
                Pour toute question, vous pouvez nous envoyer un mail à
            </Typography>
            <Typography variant="body">
                &nbsp;<a href="mailto:bilans-rse@inextenso.fr">bilans-rse@inextenso.fr</a>
            </Typography>
        </WhiteBox>
    </Box>
);
