import { MRT_ColumnDef, createMRTColumnHelper } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GridText } from "src/components/Grid/GridText";
import { GridCategoryBox } from "src/components/Grid/GridCategoryBox";
import { MrtColumns, extendColumnsByScores } from "src/utils/useGridColumns";
import { GridProduct } from "src/api/client-api/products/useGetAllproduct";
import { GridLink } from "src/components/Grid/GridLink";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

export const useProductColumns = (isRefetching: boolean = false): MrtColumns<GridProduct> => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const columnHelper = createMRTColumnHelper<GridProduct>();
    const { selectedCompany, getScoresByType } = useFoodpilotContext();
    const productsScores = getScoresByType("product");

    const noValue = "-";
    const mrt_columns = [
        columnHelper.accessor("title", {
            header: t("Nom"),
            Cell: (value) =>
                value.row.original.canView ?
                    <GridLink
                        onClick={() => {
                            if (selectedCompany === null) return;
                            const productId = value.row.original.id;
                            const url = URLHandler.product.scores(selectedCompany.id, productId);
                            navigate(url);
                        }}
                        sx={{ color: (theme) => theme.palette.primary[700] }}
                    >
                        {value.renderedCellValue}
                    </GridLink>
                :   <GridText value={value.renderedCellValue}></GridText>,
            grow: false,
        }),
        columnHelper.accessor((row) => row.clientId ?? noValue, {
            id: "clientId",
            header: t("Identifiant unique"),
            Cell: (value) => <GridText value={value.renderedCellValue} />,
        }),
        columnHelper.accessor((row) => row.brand?.name ?? noValue, {
            id: "name",
            header: t("Marque"),
            Cell: (value) => <GridText value={value.renderedCellValue} />,
        }),
        columnHelper.accessor(
            (row) => {
                return row.productLine?.name ?? noValue;
            },
            {
                id: "productLine.name",
                header: t("Gamme"),
                Cell: (value) => <GridText value={value.renderedCellValue} />,
            },
        ),
        columnHelper.accessor("tags", {
            enableSorting: false,
            header: t("Catégories"),
            Cell: (value) => {
                const tags = value.cell.getValue()?.map((tag) => tag.name);
                return <GridCategoryBox value={tags} />;
            },
        }) as MRT_ColumnDef<GridProduct, string>,
    ];

    extendColumnsByScores<GridProduct>(mrt_columns, productsScores, mrt_columns.length, isRefetching);

    return mrt_columns;
};
