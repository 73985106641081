import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { FoodsCheckbox, WhitePopover } from "@foodpilot/foods";
import GenericValueIcon from "src/assets/icons/hint.svg?react";

type GenericValueCheckboxProps = {
    onChange: () => void;
    isSelected: boolean;
};

export const GenericValueCheckbox = (props: GenericValueCheckboxProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { onChange, isSelected } = props;

    const GenericValueButtonElement = (
        <Stack
            width="1rem"
            height="1rem"
            alignItems="center"
            justifyContent="center"
            borderRadius="100%"
            sx={{
                ":hover": {
                    background: theme.custom.grey[500],
                    cursor: "pointer",
                },
                ":active": {
                    background: theme.custom.grey[700],
                },
            }}
        >
            <GenericValueIcon />
        </Stack>
    );

    const GenericValuePopoverBody = (
        <Box padding="0.5rem" textAlign="left">
            <Typography variant="body">{t("property.generic_value_info")}</Typography>
        </Box>
    );

    return (
        <Stack direction="column">
            <Stack direction="row" alignItems="center">
                <FoodsCheckbox
                    selectedItems={isSelected ? [true] : []}
                    values={[true]}
                    onChange={onChange}
                    getName={() => t("property.use_generic_value")}
                    FormControlLabelProps={{
                        sx: {
                            gap: "3px",
                            ".MuiTypography-root": {
                                ...theme.typography.body,
                            },
                        },
                    }}
                />
                <WhitePopover icon={GenericValueButtonElement} body={GenericValuePopoverBody} size="15rem" />
            </Stack>
        </Stack>
    );
};
