import { Box, Link, Stack, SvgIcon, Typography, useTheme } from "@mui/material";

import LogoFoodpilot from "../assets/icons/logo-foodpilot-progress-colored.svg?react";
import { t } from "i18next";
import { useApplicationParameters } from "src/api/client-api/appParams";

export function Footer() {
    const theme = useTheme();
    const { applicationParameters } = useApplicationParameters();

    return (
        <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            useFlexGap
            sx={{
                borderTop: `1px solid ${theme.custom.grey[500]}`,
                height: "116px",
                margin: "auto",
                marginTop: "80px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "calc(100% - 80px)",

                    position: "relative",
                    left: "40px",
                }}
            >
                <Stack direction="row" alignItems="center">
                    {
                        applicationParameters?.footerLogo ?
                            <Box sx={{ marginRight: "56px" }}>
                                <img alt="" src={applicationParameters?.footerLogo} width="110px" height="36px" />
                            </Box>
                            // This is used for a non-breaking change.
                            // Once all client have the `footerLogo` filled, we can delete this line.
                        :   <SvgIcon
                                component={LogoFoodpilot}
                                sx={{ width: "110px", height: "36px", flexShrink: "0", marginRight: "56px" }}
                                viewBox="0 0 110 36"
                            />

                    }
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        useFlexGap
                        sx={{}}
                        gap="32px"
                    >
                        <Link href="https://foodpilot.io/fr/" rel="noreferrer" target="_blank" underline="hover">
                            <Typography variant="body-bold">{t("Site officiel")}</Typography>
                        </Link>
                        <Link
                            href="https://foodpilot.io/fr/contact/"
                            rel="noreferrer"
                            target="_blank"
                            underline="hover"
                        >
                            <Typography variant="body-bold">{t("Contact")}</Typography>
                        </Link>
                    </Stack>
                </Stack>
                <Typography sx={{ color: "#A3A2A0" }} variant="body">
                    &#169;&nbsp;{`Foodpilot - ${new Date().getFullYear()}`}
                </Typography>
            </Box>
        </Stack>
    );
}
