import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { z } from "zod";
import { zodParseWithLog } from "../../apiErrorLogs";

type Options = {
    itemsPerPage?: number;
    page?: number;
    name?: string;
};

export const useGetAllIngredientLines = (options?: Options) => {
    const { name = "" } = options || {};

    const query = useQuery({
        queryKey: ["allIngredientLines", name],
        queryFn: async () => {
            const filteredName = encodeURIComponent(name);

            const url = `/api/ingredient_lines?name=${filteredName}`;
            return await loggedInApiCall.get(url);
        },
        select: (request) => {
            const ingredientLines = request.data["hydra:member"];
            return zodParseWithLog(ingredientLines, IngredientLineZodSchema.array(), "allIngredientLines");
        },
    });

    return {
        allIngredientLines: query.data,
        ...query,
    };
};

export type IngredientLine = {
    "@id": IRI;
    id: number;
    name: string;
};

export const IngredientLineZodSchema: z.ZodSchema<IngredientLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});
