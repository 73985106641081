import { useTranslation } from "react-i18next";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { QuestionnaireUpdateFormBase } from "./QuestionnaireUpdateFormBase";
import { QuestionnaireUpdateFormSsq } from "./QuestionnaireUpdateFormSsq";
import { Product } from "src/api/client-api/products/useGetOneProduct";

type QuestionnaireUpdateFormProps = {
    product: Product;
};
export const QuestionnaireUpdateForm = (props: QuestionnaireUpdateFormProps) => {
    const { product } = props;
    const { t } = useTranslation();

    const { ssq, isLoading } = useGetOneSsq(product.ssqId);

    if (isLoading) return <QuestionnaireUpdateFormBase product={product} isLoading={true} />;

    if (!ssq) return t("Le questionnaire pour ce produit n'a pas été trouvé");

    return <QuestionnaireUpdateFormSsq product={product} ssq={ssq} />;
};
