import { FoodsIllustrations, WhiteBox } from "@foodpilot/foods";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Forbiddenpage = () => {
    const { t } = useTranslation();

    return (
        <WhiteBox>
            <Stack gap="32px" alignItems="center" padding="32px">
                <FoodsIllustrations illustration="chemicals" />
                <Typography variant="h3">{t("pages.forbidden")}</Typography>
            </Stack>
        </WhiteBox>
    );
};
