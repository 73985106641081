import { ProductFormProductIngredient } from "../../../ProductForm.types";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IngredientAccordion } from "./IngredientLine/IngredientAccordion";
import { Fragment } from "react/jsx-runtime";

type IngredientBoxProps = {
    ingredients: ProductFormProductIngredient[];
    setProductIngredient: (newIngredients: ProductFormProductIngredient[]) => void;
};
export const IngredientBox = (props: IngredientBoxProps) => {
    const { ingredients, setProductIngredient } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Fragment>
            <Box
                style={{
                    height: "48px",
                    border: `1px solid rgb(231, 230, 228)`,

                    borderRadius: "8px",
                    boxShadow: `0px 2px 8px 0px  ${theme.custom.grey[500]}}}`,
                    backgroundColor: "#FEFCFB",

                    display: "grid",
                    gridTemplateColumns: "auto 200px 200px",
                }}
            >
                <Box
                    sx={{
                        gridColumn: "1",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                        paddingLeft: "20px",
                    }}
                >
                    <Typography variant="body">
                        {t("pages.product.product_form.pages.page2.ingredient_box.ingredient")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        gridColumn: "2",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="body">
                        {t("pages.product.product_form.pages.page2.ingredient_box.quantity_used")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        gridColumn: "3",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="body">
                        {t("pages.product.product_form.pages.page2.ingredient_box.final_quantity")}
                    </Typography>
                </Box>
            </Box>
            <IngredientAccordion ingredients={ingredients} setProductIngredient={setProductIngredient} />
        </Fragment>
    );
};
