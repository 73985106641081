import { useRef, useState } from "react";

type Options = {
    startSearch?: string;
};
export const useDelayedSearch = (options?: Options) => {
    const { startSearch = "" } = options ?? {};
    const searchTimeoutId = useRef<NodeJS.Timeout | null>(null);

    const [search, _setSearch] = useState(startSearch);
    const [delayedSearch, setDelayedSearch] = useState(startSearch);

    const setSearchHandler = (newSearch: string) => {
        // Immediate feedback for the search bar.
        _setSearch(newSearch);

        const existingTimeout = searchTimeoutId.current;
        if (existingTimeout !== null) {
            clearTimeout(existingTimeout);
        }

        // But the fetch is delayed.
        const newTimeoutId = setTimeout(() => {
            setDelayedSearch(newSearch);
        }, 1000);
        searchTimeoutId.current = newTimeoutId;
    };

    const resetSearch = () => {
        _setSearch("");
        setDelayedSearch("");
    };

    return {
        search: search,
        delayedSearch: delayedSearch,
        setSearch: setSearchHandler,
        resetSearch,
    };
};
