import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HintBox } from "src/components/HintBox";
import { ScoreBox } from "src/components/Score/ScoreBox";
import { NoReferenceIngredient } from "./NoReferenceIngredient";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient.ts";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Score } from "src/types";

export type NoSupplierScoresProps = {
    ingredientReference?: IngredientReference;
    ingredient: Ingredient;
};

export const NoSupplierScores = (props: NoSupplierScoresProps) => {
    const { t } = useTranslation();
    const { getScoresByType } = useFoodpilotContext();
    const theme = useTheme();

    const ingredientsScores = getScoresByType("ingredient");

    return (
        <Box>
            {props.ingredientReference && (
                <Box
                    sx={{
                        marginTop: "-1rem",
                        display: "flex",
                        bgcolor: theme.palette.background.paper,
                        width: "fit-content",
                        padding: ".5rem",
                        borderRadius: "6px",
                        gap: "8px",
                        border: "1px solid #E7E6E4",
                    }}
                >
                    <Typography variant="h5">{t("Référence")}:</Typography>{" "}
                    <Typography variant="body-medium">{props.ingredientReference.name}</Typography>
                </Box>
            )}

            <Box sx={{ marginTop: "1rem" }}>
                <HintBox
                    title={t(
                        "Les indicateurs spécifiques de cet ingrédient ne sont pas calculés car son impact sur les produits l’utilisant n’est pas significatif",
                    )}
                    content={t("ingredient.noSupplierScore")}
                />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", padding: "1rem 0", flexWrap: "wrap" }}>
                {!props.ingredientReference && <NoReferenceIngredient ingredient={props.ingredient} />}

                {ingredientsScores.map((score: Score, index: number) => (
                    <ScoreBox key={index} score={score} />
                ))}
            </Box>
        </Box>
    );
};
