import { FoodsIcon } from "@foodpilot/foods";
import { Stack, useTheme } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDragAndDrop } from "./useDragAndDrop";

type DraggingRowsProps<T> = {
    rows: T[];
    renderRow: (row: T, rowIndex: number) => JSX.Element;
    onHoverDrop?: (reorderedRows: T[]) => void;
};
export const DraggingRows = <T,>(props: DraggingRowsProps<T>) => {
    const theme = useTheme();
    const { rows, renderRow, onHoverDrop } = props;

    console.log("Hello World", props);
    const [temporaryRows, setTemporaryRows] = useState<T[]>(rows);

    useEffect(() => {
        // Each time the rows I get as input change, I want to make sure
        // The internal state is reset.
        setTemporaryRows(rows);
    }, [rows]);

    const moveColumn = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const columnsWithoutColumn = temporaryRows.toSpliced(dragIndex, 1);
            const newColumns = columnsWithoutColumn.toSpliced(hoverIndex, 0, temporaryRows[dragIndex]);

            setTemporaryRows(newColumns);
        },
        [temporaryRows, setTemporaryRows],
    );

    return (
        <Stack gap={theme.spacing(2)}>
            {temporaryRows.map((row, index) => {
                return (
                    <Fragment key={`${index}-rowIndex`}>
                        <Row
                            rowIndex={index}
                            onHoverDrop={() => {
                                if (onHoverDrop) {
                                    onHoverDrop(temporaryRows);
                                }
                            }}
                            onHoverMove={moveColumn}
                        >
                            {renderRow(row, index)}
                        </Row>
                    </Fragment>
                );
            })}
        </Stack>
    );
};

type RowProps = {
    rowIndex: number;
    onHoverDrop?: () => void;
    onHoverMove: (dragIndex: number, hoverIndex: number) => void;
    children: JSX.Element;
};
const Row = (props: RowProps) => {
    const theme = useTheme();
    const { rowIndex, onHoverDrop, onHoverMove, children } = props;

    const { ref, handlerId } = useDragAndDrop({
        currentIndex: rowIndex,
        onHoverMove: onHoverMove,
        onHoverDrop: onHoverDrop,
    });

    return (
        <Stack
            key={`${rowIndex}-${handlerId?.toString()}`}
            ref={ref}
            data-handler-id={handlerId}
            flexDirection={"row"}
            gap={theme.spacing(2)}
            justifyContent={"start"}
            alignItems={"center"}
        >
            <FoodsIcon size={2} icon="drag" />
            {children}
        </Stack>
    );
};
