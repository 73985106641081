import { Button, useFormBaseContext } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { CreateOutlined } from "@mui/icons-material";

type ModifyButtonProps = {
    pageNumber: number;
};
export const ModifyButton = (props: ModifyButtonProps) => {
    const { setPage } = useFormBaseContext();
    const { t } = useTranslation();

    const modifyText = t("Modifier");
    return (
        <Button
            variant="white-filled"
            startIcon={<CreateOutlined />}
            sx={{ marginRight: "24px", borderRadius: "44px" }}
            onClick={() => setPage(props.pageNumber)}
        >
            {modifyText}
        </Button>
    );
};
