import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IngredientLineCreate } from "./Create";
import { useGetOneIngredientLine } from "src/api/client-api/ingredientLines/useGetOneIngredientLine.ts";

export const IngredientLineEdit = () => {
    const { ingredientLineId } = useParams();
    const { ingredientLine, refetch, isFetched } = useGetOneIngredientLine(ingredientLineId ?? "A bad ID", {
        enabled: false,
    });

    useEffect(() => {
        if (ingredientLine === undefined) {
            refetch();
        }
    }, []);

    if (!isFetched) {
        return null;
    }

    if (ingredientLine == undefined) {
        return null;
    }

    return <IngredientLineCreate existingIngredientLine={ingredientLine} />;
};
