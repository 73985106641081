import { useParams } from "react-router-dom";
import { CompanyType } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { useGetOneSupplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { CompanyCreate } from "./CompanyCreate";

type CompanyEditProps = {
    type: CompanyType[];
};
export const CompanyEdit = (props: CompanyEditProps) => {
    const { id } = useParams();

    const { supplier, isFetched } = useGetOneSupplier(id ?? "Completly bad ID");

    if (isFetched === false) {
        return;
    }

    if (supplier === undefined) {
        return <>Err</>;
    }

    return <CompanyCreate type={props.type} supplier={supplier} />;
};
