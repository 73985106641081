import { LightIndicatorBlock, RoundInput } from "@foodpilot/foods";
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export type IndicatorBlock = {
    id: UUID | undefined;
    title: string;
    subtitle: string;
    description: string;
    position: number;
};

type IndicatorFormFieldsProps = {
    indicatorBlockForm: IndicatorBlock;
    setIndicatorBlockForm: (newArticle: IndicatorBlock) => void;
};
export const IndicatorFormFields = (props: IndicatorFormFieldsProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { indicatorBlockForm, setIndicatorBlockForm } = props;

    const subtitleForPreview = indicatorBlockForm.subtitle === "" ? undefined : indicatorBlockForm.subtitle;
    return (
        <Stack gap={theme.spacing(2)} padding={theme.spacing(2, 8)}>
            <LightIndicatorBlock
                title={indicatorBlockForm.title}
                subtitle={subtitleForPreview}
                description={indicatorBlockForm.description}
            />
            <Stack>
                <Typography variant="body-medium">{t("glossary.title")}</Typography>
                <RoundInput
                    value={indicatorBlockForm.title}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setIndicatorBlockForm({
                            ...indicatorBlockForm,
                            title: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="body-medium">{t("glossary.helperText")}</Typography>
                <RoundInput
                    value={indicatorBlockForm.subtitle}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setIndicatorBlockForm({
                            ...indicatorBlockForm,
                            subtitle: newValue,
                        });
                    }}
                />
            </Stack>
            <Stack>
                <Typography variant="body-medium">{t("glossary.description")}</Typography>
                <RoundInput
                    value={indicatorBlockForm.description}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setIndicatorBlockForm({
                            ...indicatorBlockForm,
                            description: newValue,
                        });
                    }}
                />
            </Stack>
        </Stack>
    );
};
