import { Route } from "react-router-dom";
import { Support } from "./Support";
import { Details } from "./Details";

export const usePagesRouter = () => {
    return (
        <Route path="pages">
            <Route index element={<Support />} />
            <Route path=":id" element={<Details />} />
        </Route>
    );
};
