import { useState } from "react";
import { Stack, Typography, Button, useTheme } from "@mui/material";
import { DottedBox, FoodsCheckbox } from "@foodpilot/foods";
import { FormPropertyProps } from "../../FormProperty";
import { PostElement } from "src/api/client-api/post/postSchema";
import { PostElementWrite } from "src/api/client-api/property/useUpdateProperty";
import AddIcon from "@mui/icons-material/Add";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq";
import { useTranslation } from "react-i18next";
import { useFillDefaultsMultivalueProperty } from "src/api/client-api/property/useFillDefaultsMultivalueProperty.ts";
import { useScoreContext } from "src/context/ScoreContext";
import { Score } from "src/types";
import { MultivalueElement } from "./MultivalueElement";

export const GroupMultivalued = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const fillDefaultsMultivalueProperty = useFillDefaultsMultivalueProperty();
    const { displayedScoreId, displayedVariationId, formattedScores, scores } = useScoreContext();
    const { property, post, element, canEdit = true } = props;

    const sureScore = scores.find((score: Score) => score.id === displayedScoreId);
    const displayedScore = displayedScoreId ? formattedScores[displayedScoreId] : undefined;

    const childProperties = (property.children ?? [])
        .filter((childProperty) => !childProperty.hidden)
        .sort(
            (childPropertyA: Property, childPropertyB: Property) =>
                childPropertyA.position ?? 0 - (childPropertyB.position ?? 0),
        );
    const childPropertiesIds = childProperties.map((childProperty: Property) => childProperty.id);

    const initialElements = (post?.elements ?? [])
        .filter((element: PostElement | PostElementWrite) => {
            return childPropertiesIds.find((id: number) => id === element.ssqPropertyId);
        })
        .reduce((acc: Record<number, (PostElement | PostElementWrite)[]>, post: PostElement | PostElementWrite) => {
            if (!acc[post.position]) {
                acc[post.position] = [];
            }

            acc[post.position].push(post);

            return acc;
        }, {});

    const [elements, setElements] = useState<Record<number, (PostElement | PostElementWrite)[]>>(initialElements);

    const addElement = () => {
        const position =
            Object.keys(elements).length ?
                Object.keys(elements).reduce((acc: number, value: string) => Math.max(acc, Number(value)), 0) + 1
            :   0;

        const emptyValues = childProperties.map((childProperty: Property): PostElementWrite => {
            return {
                ssqPropertyId: childProperty.id,
                value: childProperty.defaultValue ?? "",
                position: position,
            };
        });

        setElements({
            ...elements,
            [position]: emptyValues,
        });

        fillDefaultsMultivalueProperty.mutate({
            postId: post?.id,
            propertyId: property.id,
            position: position,
        });
    };

    const deleteElement = (position: number) => {
        if (!post) {
            return;
        }

        const { [position]: removedElement, ...updatedElements } = elements;

        setElements(updatedElements);

        if (!props.onDelete) {
            return;
        }

        property.children?.forEach((childProperty: Property) => {
            props.onDelete!({
                postId: post.id,
                propertyId: Number(childProperty.id),
                position: position,
            });
        });
    };

    const markAsNotApplicable = (isNotApplicable: boolean) => {
        if (!post || !props.onUpdate || !props.onDelete) {
            return;
        }

        const postId = post.id;
        const propertyId = Number(property.id);

        if (isNotApplicable) {
            props.onUpdate({
                postId: postId,
                propertyId: propertyId,
                value: "n/a",
            });

            return;
        }

        props.onDelete({
            postId: postId,
            propertyId: propertyId,
        });
    };

    return Object.keys(elements).length ?
            <Stack direction="column" alignItems="flex-start" gap="20px">
                <Stack width="100%" border={`1px solid ${theme.custom.grey[500]}`} borderRadius="8px">
                    {Object.entries(elements).map(([position, posts]: [string, (PostElement | PostElementWrite)[]]) => {
                        const displayedVariation =
                            displayedVariationId ? displayedScore["scoreVariations"][displayedVariationId] : undefined;

                        const variationUnit = displayedVariation?.unit_abbreviation ?? displayedVariation?.unit;
                        const unit = displayedVariationId ? variationUnit : sureScore?.unit?.abbreviation ?? "";

                        const displayedProperty = displayedScore?.["properties"][property.id];
                        const multivalueScore = displayedProperty?.["multivalues"]?.[position];
                        const totalScore =
                            displayedVariationId ?
                                multivalueScore?.["variations"]?.[displayedVariationId]?.["score"]
                            :   multivalueScore?.["score"];

                        return (
                            <MultivalueElement
                                key={position}
                                properties={childProperties}
                                position={Number(position)}
                                posts={posts}
                                deleteElement={deleteElement}
                                totalScore={totalScore}
                                unit={unit}
                                {...props}
                            />
                        );
                    })}
                </Stack>
                {canEdit && (
                    <Button variant="text" color="primary" startIcon={<AddIcon />} onClick={addElement}>
                        {t("Ajouter un élément")}
                    </Button>
                )}
            </Stack>
        :   <NoProperties
                element={element}
                onAdd={addElement}
                onNotApplicable={markAsNotApplicable}
                canEdit={canEdit}
            />;
};

type NoPropertiesProps = {
    element?: PostElement | PostElementWrite;
    onAdd: () => void;
    onNotApplicable: (isNotApplicable: boolean) => void;
    canEdit: boolean;
};
const NoProperties = (props: NoPropertiesProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { element, onAdd, onNotApplicable, canEdit = true } = props;

    const isNotApplicableValue = !!element && element.value === "n/a";
    const [isNotApplicable, setNotApplicable] = useState<boolean>(isNotApplicableValue);

    return (
        <Stack direction="column" gap="16px">
            <FoodsCheckbox
                values={["not_applicable"]}
                selectedItems={isNotApplicable === true ? ["not_applicable"] : []}
                onChange={(selectedValues: string[]) => {
                    const isNotApplicable = selectedValues.length > 0;

                    setNotApplicable(isNotApplicable);
                    onNotApplicable(isNotApplicable);
                }}
                getName={() => t("question_not_applicable")}
                FormControlLabelProps={{
                    sx: {
                        gap: "3px",
                        ".MuiTypography-root": {
                            ...theme.typography["body-medium"],
                        },
                    },
                }}
                CheckboxProps={{ disabled: !canEdit }}
            />
            <DottedBox>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="21px 24px"
                    sx={{ pointerEvents: isNotApplicable ? "none" : "all" }}
                >
                    <Typography
                        variant="big-medium"
                        color={theme.custom.grey[!canEdit || isNotApplicable ? 1400 : 2000]}
                    >
                        {t("Aucun élément ajouté")}
                    </Typography>
                    {canEdit && (
                        <Button
                            variant="text"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={onAdd}
                            sx={{
                                height: "auto",
                                padding: 0,
                                opacity: isNotApplicable ? 0.36 : 1,
                            }}
                        >
                            {t("Ajouter un élément")}
                        </Button>
                    )}
                </Stack>
            </DottedBox>
        </Stack>
    );
};
