import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ScoreComparisonCard } from "../ScoreComparisonCard/ScoreComparisonCard";
import { useTranslation } from "react-i18next";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useActionsContext } from "src/context/Actions/useActionsContext";

export const Synthesis = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { scores } = useFoodpilotContext();
    const { scoresEnabled, allScoreWithVariations, scoreOptions } = useActionsContext();

    if (scores === undefined || scores.length === 0) {
        return <>Select some score first ! </>;
    }

    const targetCampaign = "2035";
    return (
        <Stack gap={theme.spacing(3)}>
            <Typography variant="h2">{t("actions.synthesis.title", { year: targetCampaign })}</Typography>
            <Stack flexDirection={"row"} flexWrap={"wrap"} gap={theme.spacing(1)}>
                {scoresEnabled.map((score) => {
                    const scoreOpts = allScoreWithVariations.filter(
                        (scoreVariation) => scoreVariation.group === score.title,
                    );
                    const selectedScore = scoreOpts.find(
                        (filteredScore) => filteredScore.id === scoreOptions.selectedVariations[score.id],
                    );

                    if (scoreOpts.length === 0) return;
                    return (
                        <Box
                            key={`score-synthesis-${score.id}`}
                            sx={{
                                display: "flex",
                                width: "49%",
                            }}
                        >
                            <ScoreComparisonCard
                                position={"horizontal"}
                                allOptions={scoreOpts}
                                selectedOption={selectedScore ?? scoreOpts[0]}
                                precision={score.precision}
                            />
                        </Box>
                    );
                })}
            </Stack>
        </Stack>
    );
};
