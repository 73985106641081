import { useMutation } from "react-query";
import { inexwebApiCall } from "src/api/axiosClient/sureApi";

export const useTransmitReportMutation = () => {
    return useMutation({
        mutationKey: ["upload-document"],
        mutationFn: async (reportUrl: string) => {
            const response = await inexwebApiCall.postForm("/api/inexweb/upload-document", {
                fileName: reportUrl.split("/").reverse()[0],
            });
            return response.data;
        },
    });
};
