import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

export const getAllMediaContentKey = "getAllMediaContent" as const;
const MEDIA_TYPE = "MediaContent" as const;

export const useGetAllMediaContent = () => {
    const query = useQuery({
        queryKey: [getAllMediaContentKey],
        retry: 0,
        queryFn: async () => {
            const url = `/api/cms/contents`;

            // Waiting for pagination, just put a high number
            return await loggedInApiCall.get(url, {
                params: { page: 1, itemsPerPage: 200 },
            });
        },
        select: (request) => {
            // const baseUrl = getApiUrl();
            const data: CMSContentMedia[] = request.data["hydra:member"] as CMSContentMedia[];
            const filteredByMedia = data.flatMap((item) => {
                if (item["@type"] !== MEDIA_TYPE) {
                    return [];
                }

                // const contentDirectUrl = `${baseUrl}/media/cms/media/${item.url}`;
                return [
                    {
                        ...item,
                        // url: contentDirectUrl,
                    },
                ];
            });
            return filteredByMedia;
        },
    });

    return {
        allMedias: query.data,
        ...query,
    };
};

export type CMSContentMedia = {
    "@id": IRI;
    "@type": string;
    name: string;
    alt: string;
    url: string;
    dimensions: [number, number];
};
