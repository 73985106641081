import { Typography } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { useTheme, Stack } from "@mui/material";
import { ValueWithScores } from "src/types";
import { formatNumber } from "src/utils/formatting";

import EmptySetIcon from "src/assets/icons/empty-set.svg?react";
import { doesScoreExist } from "src/api/client-api/post/scoreSchema";

type GridScoreProps = {
    scoreId: number;
    value: ValueWithScores;
    precision?: number;
};
export const GridScore = (props: GridScoreProps) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    const { value, precision, scoreId } = props;

    const entityScore = value.scores[scoreId];

    if (!doesScoreExist(entityScore)) return <EmptySetIcon width="20px" />;

    const score = entityScore.score;
    const unit = entityScore.unit;

    const scoreStr = formatNumber(
        score,
        i18n.resolvedLanguage,
        precision !== undefined ? { maximumFractionDigits: precision } : {},
    );

    return (
        <Stack direction="row" alignItems="baseline" gap="6px" marginRight="20px">
            <Typography variant="h3" sx={{ color: theme.custom.grey[2800] }}>
                {scoreStr}
            </Typography>
            <Typography variant="body" sx={{ color: theme.custom.grey[1400], whiteSpace: "nowrap" }}>
                {unit}
            </Typography>
        </Stack>
    );
};
