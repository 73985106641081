import { AnchoredPopover } from "@foodpilot/foods";
import { StackedBarChart, Shortcut, AssignmentIndOutlined, Edit, ContentCopy, Delete } from "@mui/icons-material";
import { SxProps, Stack, MenuItem, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useIngredientDelete } from "src/api/client-api/ingredients/useDeleteIngredient";
import { useIngredientDuplicate } from "src/api/client-api/ingredients/useDuplicateIngredient";
import { CREATE, INGREDIENT, useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { ingredientGridKey, IngredientList } from "src/api/client-api/ingredients/useGetAllIngredients";
import { URLHandler } from "src/services/URLHandler";

type ActionsPopoverProps = {
    allIngredients: IngredientList[];
    selectedRowItemId: number | null;
    anchor: HTMLElement | null;
    closeWindow: () => void;
};
export const ActionsPopover = (props: ActionsPopoverProps) => {
    const { anchor, closeWindow, selectedRowItemId, allIngredients } = props;
    const isRowMenuOpen = Boolean(anchor);

    return (
        <AnchoredPopover
            variant="themed"
            anchor={anchor}
            open={isRowMenuOpen}
            onClose={closeWindow}
            anchorPosition="rightside"
        >
            <RowMenu allIngredients={allIngredients} selectedRowItemId={selectedRowItemId} closeWindow={closeWindow} />
        </AnchoredPopover>
    );
};

type RowMenuProps = {
    allIngredients: IngredientList[];
    selectedRowItemId: number | null;
    closeWindow: () => void;
};

const RowMenu = (props: RowMenuProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { selectedRowItemId, closeWindow, allIngredients } = props;
    const queryClient = useQueryClient();
    const duplicateIngredient = useIngredientDuplicate();
    const deleteIngredient = useIngredientDelete();
    const { canUser, selectedCompany } = useFoodpilotContext();

    const itemStyle: SxProps = {
        padding: "5px",
    };

    if (selectedRowItemId === null || selectedCompany === null) {
        return <div>{t("Aucune commande n'est disponible pour le produit")}</div>;
    }

    const mappedIngredients = Object.groupBy(allIngredients, (item) => item.id);
    const selectedIngredient = mappedIngredients[selectedRowItemId] ?? null;

    if (selectedIngredient === null || selectedIngredient.length <= 0) return;

    const firstIngredient = selectedIngredient[0];

    return (
        <Fragment>
            <Stack direction="column" gap="20px" useFlexGap>
                {firstIngredient.canView && (
                    <Fragment>
                        <MenuItem
                            onClick={() => {
                                const url = URLHandler.ingredient.scores(selectedCompany.id, selectedRowItemId);
                                navigate(url);
                            }}
                            sx={itemStyle}
                        >
                            <StackedBarChart />
                            <Typography sx={theme.custom.rowMenuElement}>{t("Scores de l'ingrédient")}</Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                const url = URLHandler.ingredient.view(selectedCompany.id, selectedRowItemId);
                                navigate(url);
                            }}
                            sx={itemStyle}
                        >
                            <AssignmentIndOutlined />
                            <Typography sx={theme.custom.rowMenuElement}>
                                {t("Questionnaires relatifs à l'ingrédient")}
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                const url = URLHandler.ingredient.information(selectedCompany.id, selectedRowItemId);
                                navigate(url);
                            }}
                            sx={itemStyle}
                        >
                            <Shortcut />
                            <Typography sx={theme.custom.rowMenuElement}>
                                {t("Informations sur l'ingrédient")}
                            </Typography>
                        </MenuItem>
                    </Fragment>
                )}

                {canUser(CREATE, INGREDIENT) && (
                    <MenuItem
                        onClick={() => {
                            const ingredient = allIngredients.find((p) => p.id === selectedRowItemId);
                            if (ingredient !== undefined) {
                                const options = {
                                    ingredientId: ingredient.id,
                                    dupSuffix: t("copy"),
                                };
                                duplicateIngredient.mutate(options, {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries(["allIngredientsGrid"]);
                                        props.closeWindow();
                                    },
                                });
                            }
                        }}
                        sx={itemStyle}
                    >
                        <ContentCopy />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Dupliquer")}</Typography>
                    </MenuItem>
                )}

                {firstIngredient.canModify && (
                    <MenuItem
                        onClick={() => {
                            const url = URLHandler.ingredient.edit(selectedCompany.id, selectedRowItemId, 0);
                            navigate(url);
                        }}
                        sx={itemStyle}
                    >
                        <Edit />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Modifier")}</Typography>
                    </MenuItem>
                )}
                {firstIngredient.canDelete && (
                    <MenuItem
                        onClick={() => {
                            deleteIngredient.mutate(selectedRowItemId, {
                                onSuccess: () => {
                                    queryClient.invalidateQueries([ingredientGridKey]);
                                    closeWindow();
                                },
                            });
                        }}
                        sx={itemStyle}
                    >
                        <Delete />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Supprimer")}</Typography>
                    </MenuItem>
                )}
            </Stack>
        </Fragment>
    );
};
