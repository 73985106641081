import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { FoodsIcon, SectionTab, SectionTabs } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import QuestionnaireIcon from "src/assets/icons/questionnaire.svg?react";
import ScoresIcon from "src/assets/icons/scores.svg?react";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { CampaignSelector } from "src/components/Selector";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { Questionnaire } from "./Questionnaire/Questionnaire";
import { LegalRequirements } from "./LegalRequirements/LegalRequirements";
import { CompanyScores } from "./Scores/CompanyScores";
import { Header } from "./Header";
import { URLHandler } from "src/services/URLHandler";
import { Synthesis } from "./Synthesis/Synthesis";

type Sections = "scores" | "questionnaire" | "exigences" | "synthese";
export type CompanyTabsProps = {
    section: Sections;
};

type CompanySectionTab = SectionTab & { title?: string };
type CompanySections = Partial<Record<Sections, CompanySectionTab>>;

export const CompanyTabs = (props: CompanyTabsProps) => {
    const { section } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const clientSwitch = useClientSwitch();

    const {
        currentCampaign,
        selectedCompany,
        selectedCompanyVariations,
        setSelectedCompanyVariations,
        areCompaniesRefetching,
    } = useFoodpilotContext();

    const ssqId = selectedCompany?.companySector?.ssqId;
    const { ssq, isLoading: isSsqLoading } = useGetOneSsq(ssqId);

    if (!selectedCompany) return <Typography variant="big-bold">{t("error.wrongCompany")}</Typography>;

    const sections = clientSwitch<CompanySections>({
        defaultOption: {},
        collective: {
            scores: {
                name: t("Scores & indicateurs clés"),
                title: t("Indicateurs de l'entreprise"),
                icon: <ScoresIcon width="22px" />,
                component: (
                    <CompanyScores
                        company={selectedCompany}
                        variations={selectedCompanyVariations}
                        setVariations={setSelectedCompanyVariations}
                        isRefetching={areCompaniesRefetching}
                    />
                ),
                onClick: () => {
                    const url = URLHandler.company.scores(selectedCompany.id);
                    navigate(url);
                },
            },
            questionnaire: {
                name: t("Questionnaire"),
                title: t("Questionnaire"),
                icon: <QuestionnaireIcon width="16px" />,
                component: (
                    <Questionnaire
                        key="questionnaire-collective"
                        company={selectedCompany}
                        ssq={ssq}
                        isSsqLoading={isSsqLoading}
                    />
                ),
                onClick: () => {
                    const url = URLHandler.company.ssq(selectedCompany.id);
                    navigate(url);
                },
            },
        },
        bred: {
            synthese: {
                name: t("bred.synthesis"),
                title: t("bred.synthesis.title", { year: currentCampaign?.label }),
                icon: <FoodsIcon icon="scoresIndicators" size={3} />,
                component: <Synthesis />,
                onClick: () => {
                    const url = URLHandler.company.synthesis(selectedCompany.id);
                    navigate(url);
                },
            },
            questionnaire: {
                name: t("Questionnaire"),
                title: t("bred.questionnaire.title", { year: currentCampaign?.label }),
                icon: <FoodsIcon icon="questionnaires" size={3} />,
                component: (
                    <Questionnaire
                        key="questionnaire-bred"
                        company={selectedCompany}
                        ssq={ssq}
                        isSsqLoading={isSsqLoading}
                    />
                ),
                onClick: () => {
                    const url = URLHandler.company.ssq(selectedCompany.id);
                    navigate(url);
                },
            },
            exigences: {
                name: t("bred.requirement"),
                title: t("bred.requirement.title"),
                icon: <FoodsIcon icon="rules" size={3} />,
                component: <LegalRequirements />,
                onClick: () => {
                    const url = URLHandler.company.requirements(selectedCompany.id);
                    navigate(url);
                },
            },
        },
    });

    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto" }}>
            <Box
                sx={{
                    width: "auto",
                    background: "#fff",
                    padding: "28px 52px",
                    paddingBottom: 0,
                    position: "relative",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="16px"
                    sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                    {/* I still have to adjust a little the design here */}
                    <Header />
                    {/* <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                        }}
                    >
                        <FoodsIcon icon="diagnostic" size={8} />
                        <Typography variant="h1">{`${t("page.diagnostics.title")}`}</Typography>
                        <Box
                            sx={{
                                borderLeft: `1px solid ${theme.custom.grey[500]}`,
                                width: "1px",
                                height: "36px",
                            }}
                        />
                        <CompanySelector />
                    </Box> */}
                    <CampaignSelector />
                </Stack>

                <Divider sx={{ marginTop: "28px" }} />
                <SectionTabs
                    tabs={Object.values(sections)}
                    value={Object.keys(sections).indexOf(section)}
                    renderComponents={false}
                    sx={{
                        position: "relative",
                        bottom: "-1px",
                    }}
                />
            </Box>

            <Stack gap="28px" sx={{ padding: "40px 52px" }}>
                <Typography variant="h2">{sections[section]?.title ?? sections[section]?.name}</Typography>
                {sections[section]?.component}
            </Stack>
        </Box>
    );
};
