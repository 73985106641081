import { MRTGrid } from "@foodpilot/foods";
import { useIngredientListContext } from "../IngredientListContext";
import { IconButton } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useState } from "react";
import { ActionsPopover } from "../Popover/ActionsPopover";

const IngredientsGrid = () => {
    const {
        mrt_columns,
        ingredients = [],
        isLoading,
        delayedSearchIngredient,
        ingredientPage,
        setIngredientPage,
        sorting,
        setSorting,
        totalItems,
    } = useIngredientListContext();
    const [selectedRowItemId, setSelectedRowItemId] = useState<number | null>(null);
    const [rowMenuAnchor, setRowMenuAnchor] = useState<null | HTMLElement>(null);

    return (
        <>
            <MRTGrid
                columns={mrt_columns.mrt_columns}
                data={ingredients}
                globalFilterFn="contains"
                enableRowActions={true}
                enableRowSelection={false}
                enablePagination={true}
                enableSelectAll={false}
                enableColumnPinning={true}
                displayColumnDefOptions={{
                    "mrt-row-actions": {
                        size: 80,
                        grow: false,
                    },
                }}
                renderRowActions={(actionProps) => {
                    const { row } = actionProps;
                    const ingredientId = row.original.id;

                    return (
                        <IconButton
                            onClick={(e) => {
                                setSelectedRowItemId(ingredientId);
                                setRowMenuAnchor(e.currentTarget);
                            }}
                            sx={{
                                transform: "translateX(-4px)",
                            }}
                        >
                            <MoreHoriz />
                        </IconButton>
                    );
                }}
                state={{
                    isLoading: isLoading,
                    columnVisibility: mrt_columns.columnVisibility,
                    columnOrder: mrt_columns.columnOrder,
                    globalFilter: delayedSearchIngredient,
                    pagination: ingredientPage,
                    columnPinning: {
                        left: [mrt_columns.columnOrder[0]],
                        right: ["mrt-row-actions"],
                    },
                    columnSizing: mrt_columns.columnSizing,
                    sorting,
                }}
                onColumnSizingChange={mrt_columns.setColumnSizing}
                // Server-side pagination
                rowCount={totalItems}
                manualPagination={true}
                onPaginationChange={setIngredientPage}
                localization={{
                    actions: "",
                }}
                manualSorting={true}
                onSortingChange={setSorting}
            />
            <ActionsPopover
                allIngredients={ingredients}
                selectedRowItemId={selectedRowItemId}
                anchor={rowMenuAnchor}
                closeWindow={() => setRowMenuAnchor(null)}
            />
        </>
    );
};
export default IngredientsGrid;
