import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useGetOneIngredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { Loading } from "src/components/utils/Loading";
import { QuestionnaireUpdateForm } from "./QuestionnaireUpdateForm";

export const QuestionnaireUpdate = () => {
    const { ingredientId } = useParams();
    const { ingredient, isLoading } = useGetOneIngredient(ingredientId ?? "A completly bad ID");

    if (isLoading) return <Loading />;
    if (!ingredient) return "Ingredient not found";

    return (
        <Box sx={{ marginLeft: "auto", marginRight: "auto", padding: "40px 52px", paddingTop: "28px" }}>
            <QuestionnaireUpdateForm ingredient={ingredient} />
        </Box>
    );
};
