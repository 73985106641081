import axios from "axios";
import { refreshAccessTokenFn, refreshTokenUrl } from "../authApi";

export const refreshTokenAxiosHandler = async (error: unknown) => {
    if (axios.isAxiosError(error)) {
        const isUnauthorized = error.response?.status === 401;
        const isFromRefreshUrl = error.config?.url === refreshTokenUrl;

        if (isFromRefreshUrl) {
            const redirectToLogin = `${window.location.origin}/logout`;
            return (window.location.href = redirectToLogin);
        }

        if (isUnauthorized) {
            await refreshAccessTokenFn();
            if (error.config) {
                return axios.request(error.config);
            }
        }
    }
};
