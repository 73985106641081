import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type ToggleButtonProps = {
    isActivated: boolean;
    onClick: () => void;
};
export const ToggleButton = (props: ToggleButtonProps) => {
    const { t } = useTranslation();
    const { isActivated, onClick } = props;
    return (
        <Button variant="primary" onClick={onClick}>
            {isActivated ? t("glossary.deactivate") : t("glossary.activate")}
        </Button>
    );
};
