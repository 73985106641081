import z from "zod";
import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { IRI } from "src/types";
import { zodParseWithLog } from "src/api/apiErrorLogs.ts";
import { ReferenceScore } from "src/types/applicationTypes/referenceScore.ts";

export const productReferenceScoresKey = "productReferenceScores" as const;

export const useGetProductReferenceScores = (productId: string) => {
    const query = useQuery({
        queryKey: ["getProductReferenceScores", productId],
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const url = `/api/products/${productId}/reference_scores`;

            return await loggedInApiCall.get<ProductReferenceScores>(url);
        },
        select: (request) => {
            const productReferenceScores = request.data;

            return zodParseWithLog(productReferenceScores, ProductReferenceScoresSchema, productReferenceScoresKey);
        },
    });

    return {
        productReferenceScores: query.data,
    };
};

export interface ProductReferenceScore extends ReferenceScore {
    productReference: IRI;
}

export interface ProductReferenceScores extends Array<ProductReferenceScore> {}

const ProductReferenceScoresSchema: z.ZodSchema<ProductReferenceScores> = z.array(
    z.object({
        id: z.number(),
        productReference: z.string(),
        score: z
            .object({
                id: z.number(),
            })
            .optional(),
        value: z.number().optional(),
    }),
);
