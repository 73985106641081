import { Dispatch, SetStateAction, useContext, createContext, useState, ReactNode } from "react";
import { getScoreIdsByType, getScoresByIds } from "src/utils/scores";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { ScoreType } from "src/api/client-api/scoreType/useGetAllScoreType";
import { FormattedScores } from "src/api/client-api/post/postSchema";
import { getDefaultDisplayVariationId, getFormattedScoreByScoreId } from "src/utils/ssq";
import { Score } from "src/types";

type ScoreContext = {
    displayedScoreId: number | undefined;
    setDisplayedScoreId: Dispatch<SetStateAction<number | undefined>>;
    displayedVariationId: number | undefined;
    setDisplayedVariationId: Dispatch<SetStateAction<number | undefined>>;
    formattedScores?: FormattedScores;
    setFormattedScores: Dispatch<SetStateAction<FormattedScores>>;
    scores: Score[];
};

const ScoreContext = createContext<ScoreContext | undefined>(undefined);

type ScoreProviderProps = {
    type: ScoreType["type"];
    formattedScores?: FormattedScores;
    children: ReactNode;
};

export const ScoreProvider = (props: ScoreProviderProps) => {
    const { type, formattedScores: initialFormattedScores = {}, children } = props;
    const { scoreTypes, scores } = useFoodpilotContext();

    const productsScoresIds = getScoreIdsByType(type, scoreTypes);
    const scoresToDisplay = getScoresByIds(productsScoresIds, scores);
    const initialScoreId = scoresToDisplay[0]?.id;

    const formattedScore = getFormattedScoreByScoreId(initialFormattedScores, initialScoreId);
    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const initialVariationId = getDefaultDisplayVariationId(scoreVariations);

    const [displayedScoreId, setDisplayedScoreId] = useState<number | undefined>();
    const [displayedVariationId, setDisplayedVariationId] = useState<number | undefined>();
    const [formattedScores, setFormattedScores] = useState<number | undefined>();

    return (
        <ScoreContext.Provider
            value={{
                displayedScoreId: displayedScoreId ?? initialScoreId,
                setDisplayedScoreId,
                displayedVariationId: displayedVariationId ?? initialVariationId,
                setDisplayedVariationId,
                formattedScores: formattedScores ?? initialFormattedScores,
                setFormattedScores,
                scores: scoresToDisplay,
            }}
        >
            {children}
        </ScoreContext.Provider>
    );
};

export const useScoreContext = () => {
    const scoreContext = useContext(ScoreContext);

    if (scoreContext === undefined) {
        throw new Error("useScoreContext must be used within ScoreProvider");
    }

    return scoreContext;
};
