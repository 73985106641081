import { BlockListContainer, EmptyForm, InfoHeaderWithIllustration, InitHeader, Typography } from "@foodpilot/foods";
import { Stack, useTheme } from "@mui/material";
import { PlanningListHeader } from "./PlanningListHeader";
import { PlanningLayout } from "../PlanningLayout";
import { useTranslation } from "react-i18next";

export type EmptyPlanningProps = {
    setIsPlanningStarted: (isStarted: boolean) => void;
};
export const EmptyPlanning = (props: EmptyPlanningProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const numberOfActions = 0;
    return (
        <Stack gap={theme.spacing(3)}>
            <InfoHeaderWithIllustration
                boldText={t("actionPlan.startingInfoTitle")}
                smallInfoText={t("actionPlan.startingInfoDescription")}
                illustration={"selectStartingYear"}
                buttonAction={{
                    action: () => {},
                    label: t("actionPlan.startCampaignButton"),
                }}
            />
            <PlanningListHeader />
            <PlanningLayout>
                <BlockListContainer
                    header={
                        <InitHeader
                            title={
                                <Stack flexDirection="row" gap={theme.spacing(1)}>
                                    <Typography variant={"h3"}>{t("actionPlan.activeActions")}</Typography>
                                    <Typography variant={"huge"}>{"-"}</Typography>
                                    <Typography variant={"huge"}>{numberOfActions}</Typography>
                                </Stack>
                            }
                            actionButton={{
                                label: t("actionPlan.init"),
                                onClick: () => {
                                    props.setIsPlanningStarted(true);
                                },
                            }}
                        />
                    }
                    blocks={[
                        <EmptyForm
                            illustration={"actions"}
                            emptyText={<Typography variant="body-bold">{t("actionPlan.noActionsTaken")}</Typography>}
                        />,
                    ]}
                />
            </PlanningLayout>
        </Stack>
    );
};
