import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useGetIngredientPosts } from "src/api/client-api/post/useGetIngredientPosts";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { QuestionnaireUpdateFormSsqPost } from "./QuestionnaireUpdateFormSsqPost";
import { ScoreProvider } from "src/context/ScoreContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useIngredientContext } from "../../ExistingIngredientContext";
import { Post } from "src/api/client-api/post/postSchema.ts";

type QuestionnaireUpdateFormSsqAdminProps = {
    ingredient: Ingredient;
    ssq: Ssq;
};
export const QuestionnaireUpdateFormSsqAdmin = (props: QuestionnaireUpdateFormSsqAdminProps) => {
    const { ingredient, ssq } = props;

    const { currentCampaign } = useFoodpilotContext();
    const { posts, isLoading, refetch } = useGetIngredientPosts(ingredient.id, currentCampaign?.id);
    const { activeSupplierId } = useIngredientContext();

    const post = posts?.find((post) => !activeSupplierId || post.company?.id === activeSupplierId);

    const refetchPost = (callback: (post: Post) => void) => {
        refetch().then((response) => {
            if (response.data) {
                const post = response.data.find((post) => !activeSupplierId || post.company?.id === activeSupplierId);

                if (post) {
                    callback(post);
                }
            }
        });
    };

    return (
        <ScoreProvider type="ingredient" formattedScores={post?.formattedScores}>
            <QuestionnaireUpdateFormSsqPost
                ingredient={ingredient}
                ssq={ssq}
                post={post}
                isPostLoading={isLoading}
                changePost={refetchPost}
            />
        </ScoreProvider>
    );
};
