import { Grid, Paper, Container, Typography, Stack, Box, Divider } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ClassIcon from "@mui/icons-material/Class";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useTranslation } from "react-i18next";
import { useGetAllSupplier } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { useGetAllProductLines, ProductLine } from "src/api/client-api/productLines/useProductLines";

export default function SettingsHome() {
    const { data: suppliersData } = useGetAllSupplier();
    const { productLines } = useGetAllProductLines();

    const nbProviders = suppliersData?.totalItems ?? 0;
    const nbProductLines = productLines?.length ?? 0;
    const nbProducts = productLines?.reduce((acc: number, item: ProductLine) => acc + item.products.length, 0) ?? 0;

    const { t } = useTranslation();

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
                flexGrow: 1,
                overflow: "auto",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("Tableau de bord")}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <Box sx={{ py: 2 }}></Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 140,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                {t("Fournisseurs")}
                            </Typography>
                            <LocalShippingIcon />
                            <Typography component="p" variant="h4">
                                {nbProviders}
                            </Typography>
                        </>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 140,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                {t("Gammes")}
                            </Typography>
                            <ClassIcon />
                            <Typography component="p" variant="h4">
                                {nbProductLines}
                            </Typography>
                        </>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 140,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                {t("Produits")}
                            </Typography>
                            <ShoppingBasketIcon />
                            <Typography component="p" variant="h4">
                                {nbProducts}
                            </Typography>
                        </>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
