import { AnchoredPopover, FoodsBadge, FoodsBadgeProps, Typography } from "@foodpilot/foods";
import { Box, useTheme } from "@mui/material";
import { Fragment, useState } from "react";
import { CompanyList } from "./CompanyList";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useTranslation } from "react-i18next";
import { useSwitchCompany } from "src/services/Companies/useSwitchCompany";

export const CompanySelector = () => {
    const { user, selectedCompany } = useFoodpilotContext();
    const theme = useTheme();
    const { t } = useTranslation();
    const { changeCompany } = useSwitchCompany();

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchor);

    const icon: FoodsBadgeProps["icon"] = isOpen ? "arrowUpShort" : "arrowDownShort";

    if (selectedCompany === null) return;
    return (
        <Fragment>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                }}
            >
                <Typography variant="big-bold">{selectedCompany.name}</Typography>
                <Box onClick={(e) => setAnchor(e.currentTarget)}>
                    <FoodsBadge icon={icon} size={2} />
                </Box>
                {selectedCompany.companySector && (
                    <Typography
                        variant="body-medium"
                        sx={{
                            color: theme.custom.grey[1400],
                        }}
                    >
                        {`${t("companySelector.sectorName")}: ${selectedCompany.companySector.name}`}
                    </Typography>
                )}
            </Box>
            <AnchoredPopover
                variant="headless"
                anchor={anchor}
                open={isOpen}
                onClose={() => setAnchor(null)}
                anchorPosition="rightside"
            >
                <CompanyList
                    companies={user.trustedUser.companies}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={(newCompany) => {
                        changeCompany(newCompany.id);
                    }}
                />
            </AnchoredPopover>
        </Fragment>
    );
};
