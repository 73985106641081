import { useLocation, useNavigate } from "react-router-dom";
import { CompaniesService } from "./CompaniesService";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

export const useSwitchCompany = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedCompany } = useFoodpilotContext();

    // This logic is going to be handled inside the hook `useSelectCompany` inside FoodpilotContext
    const switchUrl = (companyId: number) => {
        const newUrl = CompaniesService.switchCompanyURL(companyId, location.pathname);
        if (newUrl === undefined) {
            return;
        }
        navigate(newUrl);
    };
    if (selectedCompany === null) {
        CompaniesService.resetCompany();
    }

    return {
        changeCompany: switchUrl,
    };
};
