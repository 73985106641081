import { KeyboardArrowDown, AddCircleOutline } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AnchoredPopover, Button } from "@foodpilot/foods";
import { Fragment, useState } from "react";
import ImportIcon from "src/assets/icons/import.svg?react";
import { ComingSoonBadge } from "src/components/ComingSoonBadge";
import { CreateNewMenuItem } from "src/components/CreateNewMenuItem";
import { CREATE, COMPANY, useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { URLHandler } from "src/services/URLHandler";

export const SupplierPopover = () => {
    const { t } = useTranslation();
    const { canUser } = useFoodpilotContext();

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchor);

    return (
        <Fragment>
            {canUser(CREATE, COMPANY) && (
                <Button
                    variant="primary"
                    id="create-product-button"
                    onClick={(e) => setAnchor(e.currentTarget)}
                    endIcon={<KeyboardArrowDown />}
                >
                    {t("Créer un nouveau fournisseurs")}
                </Button>
            )}
            <AnchoredPopover
                variant="themed"
                anchor={anchor}
                open={isOpen}
                onClose={() => setAnchor(null)}
                anchorPosition="rightside"
            >
                <SupplierMenu />
            </AnchoredPopover>
        </Fragment>
    );
};

const SupplierMenu = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { selectedCompany } = useFoodpilotContext();

    if (selectedCompany === null) return;

    return (
        <Stack padding="0" gap="20px">
            <CreateNewMenuItem
                onClick={() => {
                    const url = URLHandler.supplier.create(selectedCompany.id, 0);
                    navigate(url);
                }}
            >
                <AddCircleOutline />
                <Typography sx={theme.custom.rowMenuElement}>{t("Créer un fournisseur")}</Typography>
            </CreateNewMenuItem>
            <CreateNewMenuItem disabled>
                <ImportIcon />
                <Typography sx={theme.custom.rowMenuElement}>{t("Importer des fournisseurs")}</Typography>
                <ComingSoonBadge />
            </CreateNewMenuItem>
        </Stack>
    );
};
