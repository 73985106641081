import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useGetOneProduct } from "src/api/client-api/products/useGetOneProduct";
import { Loading } from "src/components/utils/Loading";
import { QuestionnaireUpdateForm } from "./QuestionnaireUpdateForm";

export const QuestionnaireUpdate = () => {
    const { productId } = useParams();
    const { product, isLoading } = useGetOneProduct(productId ?? "A completly bad ID");

    if (isLoading) return <Loading />;
    if (!product) return "Product not found";

    return (
        <Box sx={{ marginLeft: "auto", marginRight: "auto", padding: "40px 52px", paddingTop: "28px" }}>
            <QuestionnaireUpdateForm product={product} />
        </Box>
    );
};
