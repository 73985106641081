import { ApiResponse, FieldError } from "src/api/axiosClient/errorHandler.ts";
import { t } from "i18next";
import { Path, UseFormSetError } from "react-hook-form";

export const handleFieldErrors = <T extends Record<string, any>>(
    response: ApiResponse,
    setError: UseFormSetError<T>,
) => {
    response.fieldErrors.errors.forEach((fieldError: FieldError) => {
        // @ts-expect-error translation may not exists, I don't know yet how to solve this properly
        setError(fieldError.fieldPath as Path<T>, { type: "server", message: t(fieldError.message) });
    });
};
