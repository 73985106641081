import { useMutation, useQueryClient } from "react-query";
import { CMSContentBlock, getOneCMSContentKey } from "../useGetOneContent";
import { useSnackbar } from "src/utils/useSnackbar";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { reorderBlocksReqs } from "./useReorderBlocks";

const url = `/api/cms/contents/pages/blocks` as const;

const deleteBlock = async (entityId: string | number) => {
    const deleteUrl = `${url}/${entityId}`;
    const req = await loggedInApiCall.delete(deleteUrl);

    return req;
};

type DeletionPayload = {
    deleteId: string | number;
    allBlocksToReorder: CMSContentBlock[];
};

export const useDeleteOneBlock = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();

    const query = useMutation({
        mutationFn: async (options: DeletionPayload) => {
            const { deleteId, allBlocksToReorder } = options;

            try {
                await deleteBlock(deleteId);

                const blocksFiltered = allBlocksToReorder.filter((item) => item.id !== deleteId);
                await reorderBlocksReqs(blocksFiltered);
            } catch (err) {
                //
            }
            // const url = `/api/cms/contents/${contentId}`;
            // return await loggedInApiCall.patch(url, payload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneCMSContentKey]);

            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "info",
                message: "Your section has been deleted !",
            });
        },
    });

    return query;
};
