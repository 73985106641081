import {
    Dialog,
    Box,
    Autocomplete,
    TextField,
    Button,
    DialogContent,
    DialogActions,
    Typography,
    Divider,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAccounts } from "src/api/sure-api/inexweb/useAccounts";
import { Loading } from "src/components/utils/Loading";
import { RSEFormProps, SelectedAccount, UncategorizedEntry } from "src/types";
import { useAccountEntries } from "src/api/sure-api/inexweb/useAccountEntries";
import { useFormContext } from "react-hook-form";

type AddAccountDialogProps = {
    open: boolean;
    onClose: () => void;
    onAccountSelected: (
        entry: SelectedAccount,
        entries: UncategorizedEntry[],
        previousEntries: UncategorizedEntry[],
    ) => void;
};
export const AddAccountDialog = (props: AddAccountDialogProps) => {
    const { onClose, open, onAccountSelected } = props;

    const { getValues } = useFormContext<RSEFormProps>();
    const { data: accounts, isLoading: isLoadingAccounts } = useAccounts();
    const [selected, setSelected] = useState<Parameters<typeof onAccountSelected>[0] | undefined>();
    const [previousFiscalYear, fiscalYear] = getValues(["previousFiscalYear", "fiscalYear"]);

    const [enabled, setEnabled] = useState(false);

    const { data: accountEntries, isLoading: isLoadingCurrent } = useAccountEntries(
        selected?.id,
        fiscalYear?.datestart,
        fiscalYear?.dateend,
        enabled,
    );
    const { data: previousAccountEntries, isLoading: isLoadingPrevious } = useAccountEntries(
        selected?.id,
        previousFiscalYear?.datestart,
        previousFiscalYear?.dateend,
        enabled,
    );

    const handleChange = (e: Parameters<typeof onAccountSelected>[0]) => setSelected(e);
    const handleClickValidate = () => {
        selected && setEnabled(true);
    };
    const isLoading = isLoadingCurrent || isLoadingPrevious || isLoadingAccounts;

    useEffect(() => {
        if (!enabled || !selected || !accountEntries || !previousAccountEntries) return;
        onAccountSelected(selected, accountEntries, previousAccountEntries);
        onClose();
    }, [enabled, selected, accountEntries, previousAccountEntries]);

    const handleOnAutocompleteChange = (entry: SelectedAccount | null) => {
        if (entry) {
            handleChange(entry);
        } else {
            setSelected(undefined);
        }
    };

    return (
        <Dialog open={!!open} fullWidth>
            <Box sx={{ padding: "1.25rem" }}>
                <Typography variant="h3">Ajouter un compte comptable</Typography>
            </Box>
            <Divider />
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {isLoading && <Loading />}
                    {!isLoading && (
                        <Box sx={{ gap: ".5rem", display: "flex", flexDirection: "column" }}>
                            <Typography variant="body-bold">Compte comptable :</Typography>

                            <Autocomplete
                                options={accounts || []}
                                onChange={(_event, entry) => handleOnAutocompleteChange(entry)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        sx={{
                                            borderRadius: "24px",
                                            border: "1px solid #E7E6E4",
                                            padding: "0px 6px 0px  20px",
                                        }}
                                        placeholder="Sélectionnez un compte comptable"
                                    />
                                )}
                                sx={{
                                    border: "none",
                                    "& .MuiAutocomplete-inputRoot::before": {
                                        border: "transparent",
                                    },
                                    "& .MuiAutocomplete-inputRoot::after": {
                                        border: "transparent",
                                        content: "none",
                                    },
                                }}
                            />
                        </Box>
                    )}{" "}
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "1rem",
                    }}
                >
                    <Button onClick={onClose} endIcon={<Close />}>
                        Annuler
                    </Button>
                    <Button variant="primary" disabled={enabled} onClick={handleClickValidate}>
                        Valider
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
