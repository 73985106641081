import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { loginUserUsingSingleTimeUseJWTFn } from "src/api/authApi.ts";
import { useMutation } from "react-query";
import { useEffect } from "react";
import { Loading } from "src/components/utils/Loading.tsx";

export const AuthWithToken = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get("token");

    const { mutate: doLogin } = useMutation(loginUserUsingSingleTimeUseJWTFn, {
        onSuccess: () => {
            navigate("/");
        },
        onError: (error) => {
            throw new Error(error?.toString());
        },
    });

    if (!token) {
        return <Navigate to="/login" />;
    }

    useEffect(() => {
        doLogin(token);
    }, [token]);

    return <Loading />;
};
