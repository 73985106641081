import { AnchoredPopover, Button } from "@foodpilot/foods";
import { Fragment, useState } from "react";
import { SelectProductLinePopover } from "../Popover/SelectProductLinePopover";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

type MandatoryKeys = {
    id: number;
    name: string;
};
type SwitchGammeButtonProps<T> = {
    allItems: T[] | undefined;
    selectedItem: string | undefined;
    setSelectedItem: (newItem: string | undefined) => void;
    noSelectedItemPlaceholder: string;
    label?: string;
    popoverLibelle?: string;
};
export const SwitchGammeButton = <T extends MandatoryKeys>(props: SwitchGammeButtonProps<T>) => {
    const theme = useTheme();
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchor);

    const { t } = useTranslation();
    const { allItems, selectedItem, setSelectedItem, label } = props;
    return (
        <Fragment>
            <Button
                id="select-gamme-anchor"
                variant="ternary"
                onClick={(e) => setAnchor(e.currentTarget)}
                endIcon={<KeyboardArrowDown />}
                sx={{
                    borderRadius: "44px",
                    height: "40px",
                    border: `1px solid ${theme.custom.grey[500]}`,
                    background: "linear-gradient(180deg, #FFF 0%, #FAFAFA 100%)",
                    padding: "0px 20px",
                }}
            >
                {label ?? t("Changer de gamme")}
            </Button>
            <AnchoredPopover
                variant="headless"
                anchor={anchor}
                open={isOpen}
                onClose={() => setAnchor(null)}
                anchorPosition="rightside"
            >
                <SelectProductLinePopover
                    onClose={() => setAnchor(null)}
                    allItems={allItems}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    noSelectedItemPlaceholder={props.noSelectedItemPlaceholder}
                    libelle={props.popoverLibelle}
                />
            </AnchoredPopover>
        </Fragment>
    );
};
