import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useGetRole } from "src/api/client-api/roles/useGetRole.ts";
import { RoleCreate } from "src/pages/Settings/Roles/Create.tsx";

export const RoleEdit = () => {
    const { roleCode } = useParams();
    const { role, refetch, isFetched } = useGetRole(roleCode ?? "Bad role code", { enabled: false });

    useEffect(() => {
        if (role === undefined) {
            refetch();
        }
    }, [refetch, role]);

    if (!isFetched) {
        return null;
    }

    return <RoleCreate existingRole={role} />;
};
