type LocalStorageConfigurationKeys = "company" | "connectedAs" | "language";

export class LocalStorage {
    static getItem(key: LocalStorageConfigurationKeys): string | null {
        const item = localStorage.getItem(key);
        return item;
    }

    static setItem(key: LocalStorageConfigurationKeys, value: string) {
        localStorage.setItem(key, value);
    }

    static deleteItem(key: LocalStorageConfigurationKeys) {
        localStorage.removeItem(key);
    }
}
