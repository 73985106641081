import { useRevalidator } from "react-router-dom";
import { Box, Container, Button, TextField, FormLabel, MenuItem, Autocomplete } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";

import { useGetAllSupplier } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { UserWrite, useCreateUser } from "src/api/client-api/user/useCreateUser";
import { UserFormSchemaZod, UserService } from "../Services/UserServices";
import MultipleSelectionSelect from "src/components/Select/MultipleSelectionSelect";
import { FoodsSelectServerPagination } from "@foodpilot/foods";
import { useDelayedSearch } from "src/utils/useDelayedSearch";
import { useGetAllRoles } from "src/api/client-api/roles/useGetAllRoles";
import { isAxiosError } from "axios";
import { User } from "src/api/client-api/user/useGetOneUser";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { URLHandler } from "src/services/URLHandler";
import { useSnackbarWrapperApiFieldErrorHandler } from "src/api/axiosClient/errorHandler.ts";
import { handleFieldErrors } from "src/utils/forms.ts";

type UserCreateProps = {
    user?: User;
};
export const UserCreate = (props: UserCreateProps) => {
    const { user } = props;

    const formattedUser = UserService.getUserToUserForm(user);
    const apiErrorHandler = useSnackbarWrapperApiFieldErrorHandler();
    const revalidator = useRevalidator();
    const clientSwitch = useClientSwitch();

    const { search, delayedSearch, setSearch } = useDelayedSearch();
    const isSearchFound = (value: string) => {
        if (delayedSearch === "") {
            return true;
        }

        return value.toLowerCase().includes(delayedSearch.toLowerCase());
    };
    const { suppliers = [] } = useGetAllSupplier({
        type: ["branch", "main", "provider"],
        itemsPerPage: 100,
        search: delayedSearch,
    });

    const { allRoles } = useGetAllRoles({
        itemsPerPage: 100,
        page: 1,
    });
    const roles = allRoles?.map((role) => role.code) ?? [];
    roles.push("ROLE_ADMIN");

    const createUser = useCreateUser();

    const { t } = useTranslation();

    type Schema = z.infer<typeof UserFormSchemaZod>;

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<Schema>({
        mode: "onChange",
        resolver: zodResolver(UserFormSchemaZod),
        values: formattedUser,
    });

    const onSubmitHandler: SubmitHandler<Schema> = (values) => {
        const userWrite: UserWrite = UserService.getUserFormToUserWrite(values, user === undefined);

        createUser.mutate(
            { user: userWrite },
            {
                onSuccess: () => {
                    revalidator.revalidate();
                    // Kilian: This hard location is done because when I go back to editting the same user
                    // the .companies key do not update properly after a single update.
                    // If page refresh it works.
                    // So I do this...
                    window.location.href = URLHandler.settings.users.list();
                },
                onError: (error) => {
                    if (isAxiosError(error)) {
                        handleFieldErrors<Schema>(apiErrorHandler(error), setError);
                    }
                },
            },
        );
    };

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <h1>Utilisateur</h1>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="id"
                        control={control}
                        defaultValue={formattedUser?.id}
                        render={({ field: { ...field } }) => <input type="hidden" {...field}></input>}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                label={t("Email")}
                                type="mail"
                                variant="outlined"
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                label={t("Telephone")}
                                type="tel"
                                variant="outlined"
                                error={Boolean(errors.phone)}
                                helperText={errors.phone?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="firstname"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Prénom")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.firstname)}
                                helperText={errors.firstname?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="lastname"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Nom")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.lastname)}
                                helperText={errors.lastname?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FormLabel sx={{ color: errors.companies ? "red" : undefined }}>{t("Entreprise")}</FormLabel>

                    {clientSwitch({
                        defaultOption: (
                            <Controller
                                name="companies"
                                control={control}
                                render={(props) => {
                                    const { field } = props;

                                    const selectedOptions =
                                        field.value && field.value.length > 0 ? field.value[0] : null;
                                    return (
                                        <Box sx={{ marginBottom: "1rem" }}>
                                            <FoodsSelectServerPagination
                                                selectedOption={selectedOptions}
                                                options={suppliers}
                                                onChange={(selectedOption) => {
                                                    field.onChange([selectedOption]);
                                                }}
                                                getId={(enterprise) => enterprise["@id"]}
                                                getName={(enterprise) => enterprise.name}
                                                closeOnSelect
                                                textOptions={{
                                                    placeholder: t("component.select.placeholder"),
                                                    searchPlaceholder: t("component.select.searchPlaceholder"),
                                                    emptyOptionsText: t("component.select.noOptions"),
                                                    defaultValueText: t("component.select.defaultValue"),
                                                    noResult: t("component.select.noResultFound"),
                                                }}
                                                search={search}
                                                setSearch={setSearch}
                                                isSearchFound={isSearchFound}
                                            />
                                        </Box>
                                    );
                                }}
                            />
                        ),
                        collective: (
                            <Controller
                                name="companies"
                                control={control}
                                render={(props) => {
                                    const { field } = props;

                                    const selectedOptions = field.value && field.value.length > 0 ? field.value : [];
                                    return (
                                        <Box sx={{ marginBottom: "1rem" }}>
                                            <MultipleSelectionSelect
                                                selectedOptions={selectedOptions}
                                                options={suppliers}
                                                onChange={(selectedOptions) => {
                                                    field.onChange(selectedOptions);
                                                }}
                                                getId={(enterprise) => enterprise["@id"]}
                                                getName={(enterprise) => enterprise.name}
                                                textOptions={{
                                                    placeholder: t("component.select.placeholder"),
                                                    searchPlaceholder: t("component.select.searchPlaceholder"),
                                                    emptyOptionsText: t("component.select.noOptions"),
                                                    defaultValueText: t("component.select.defaultValue"),
                                                    noResult: t("component.select.noResultFound"),
                                                }}
                                                search={search}
                                                setSearch={setSearch}
                                                isSearchFound={isSearchFound}
                                            />
                                        </Box>
                                    );
                                }}
                            />
                        ),
                    })}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FormLabel>{t("Roles")}</FormLabel>
                    <Controller
                        name="roles"
                        control={control}
                        render={({ field: { ref, onChange, value, ...field } }) => (
                            <Autocomplete
                                multiple={true}
                                options={roles}
                                onChange={(event, newValue) => {
                                    onChange(newValue);
                                }}
                                value={value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        inputRef={ref}
                                        {...field}
                                        error={Boolean(errors.roles)}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <MenuItem {...props} key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                )}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button sx={{ marginTop: "1rem", width: "10rem" }} variant="primary" type="submit">
                        {t("Enregistrer")}
                    </Button>
                </Box>
            </form>
        </Container>
    );
};
