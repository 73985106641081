import { Box, useTheme } from "@mui/material";

export const VerticalDivider = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                height: "30px",
                borderLeft: `1px solid ${theme.custom.grey[800]}`,
            }}
        />
    );
};
