import { z } from "zod";

export const CompanySchema = z.object({
    id: z.number(),
    name: z.string(),
});

export type Company = z.infer<typeof CompanySchema>;

const MultivalueVariationSchema = z.object({
    score: z.number(),
});

const MultivalueSchema = z.object({
    score: z.number(),
    variations: z.record(z.number(), MultivalueVariationSchema).optional(),
});

export const PostElementSchema = z.object({
    id: z.number(),
    value: z.string(),
    ssqPropertyId: z.number(),
    position: z.number(),
    multivalues: z.record(z.number(), MultivalueSchema).optional(),
    updatedAt: z.string().optional(),
    isGenericValue: z.boolean().optional(),
});

export type PostElement = z.infer<typeof PostElementSchema>;

// [ds] this schema should be removed after we move from formattedScores to the scores
export const FormattedScoresSchema = z.any();

export type FormattedScores = z.infer<typeof FormattedScoresSchema>;
// end of deprecated schema

export type ScoreCompletion = {
    answers: number;
    questions: number;
    result: number;
};

export const CompletionSchema: z.ZodSchema<ScoreCompletion> = z.object({
    answers: z.number(),
    questions: z.number(),
    result: z.number(),
});

export type HeadingScoreCompletion = Partial<Record<string, ScoreCompletion>>;
export const CompletionsHeadingsSchema: z.ZodSchema<HeadingScoreCompletion> = z.record(z.string(), CompletionSchema);

export const PostSchema = z.object({
    id: z.number(),
    ssqId: z.number().optional(),
    elements: PostElementSchema.array().optional(),
    formattedScores: FormattedScoresSchema.optional(),
    hiddenElements: z.array(z.string()),
    company: CompanySchema.optional(),
    completions: z.object({
        headings: CompletionsHeadingsSchema,
        ssq: CompletionSchema,
    }),
    updatedAt: z.string(),
});

export type Post = z.infer<typeof PostSchema>;
