import { Box, Button, Link } from "@mui/material";
import { FormBox, MRTGrid } from "@foodpilot/foods";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoSuppliers } from "./NoSuppliers";
import { createMRTColumnHelper } from "material-react-table";
import { SupplierModal } from "./SupplierModal/SupplierModal";
import { GridText, GridTextList } from "src/components/Grid/GridText";
import { IngredientFormIngredientSupplier, IngredientFormType } from "../../IngredientForm.types";

type Page2Props = {
    ingredient: IngredientFormType;
    setIngredient: (newIngredient: IngredientFormType) => void;
};

export const Page2 = (props: Page2Props) => {
    const { ingredient, setIngredient } = props;
    const [isOpen, setOpen] = useState(false);
    const openModal = () => setOpen(true);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
            }}
        >
            <SupplierModal
                existingIngredientSuppliers={ingredient.ingredientSuppliers}
                isOpen={isOpen}
                setOpen={setOpen}
                onValidate={(newIngredientSuppliers) => {
                    setIngredient({
                        ...ingredient,
                        ingredientSuppliers: newIngredientSuppliers,
                    });
                }}
            />
            {ingredient.ingredientSuppliers.length > 0 ?
                <Fragment>
                    <DisplaySuppliers suppliers={ingredient.ingredientSuppliers} openModal={openModal} />
                </Fragment>
            :   <NoSuppliers openModal={openModal} />}
        </div>
    );
};

type DisplaySuppliersProps = {
    suppliers: IngredientFormIngredientSupplier[];
    openModal: () => void;
};
const DisplaySuppliers = (props: DisplaySuppliersProps) => {
    const { suppliers, openModal } = props;
    const { t } = useTranslation();

    const columnHelper = createMRTColumnHelper<IngredientFormIngredientSupplier>();
    const mrt_columns = [
        columnHelper.accessor((row) => row.supplier.name, {
            enableSorting: false,
            id: "name",
            header: t("Nom"),
            Cell: (value) => (
                <Link
                    // href={`/ingredients/valid/url/to/go`}
                    sx={{ color: "inherit", textDecoration: "none" }}
                >
                    {value.renderedCellValue}
                </Link>
            ),
        }),
        columnHelper.accessor((row) => row.supplier.clientId, {
            id: "clientId",
            header: t("Code fournisseur"),
            Cell: (value) => <GridText value={value.renderedCellValue} />,
        }),
        columnHelper.accessor((row) => row.supplier.sectors, {
            id: "sectors",
            header: t("Filière"),
            Cell: (value) => <GridTextList value={value.cell.getValue()} />,
        }),
    ];

    return (
        <Box style={{ display: "flex", gap: "2rem" }}>
            <FormBox sx={{ flex: 3 }}>
                <MRTGrid enableSorting={false} enableRowSelection={false} columns={mrt_columns} data={suppliers} />
                <Button variant="text" onClick={openModal}>
                    {t("ingredient.form.supplierModal.title")}
                </Button>
            </FormBox>
        </Box>
    );
};
