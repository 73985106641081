import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUpsertAppParam } from "src/api/client-api/appParams";
import { FoodsSelect, themes } from "@foodpilot/foods";

type ThemeProps = {
    currentTheme: string | null;
};
export const Theme = (props: ThemeProps) => {
    const { currentTheme } = props;
    const upsertAppParam = useUpsertAppParam();
    const { t } = useTranslation();

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "75px", gap: "16px" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="big-bold">{`Theme`}</Typography>
                <FoodsSelect
                    selectedOption={currentTheme}
                    options={Object.keys(themes)}
                    onChange={(selectedItem) => {
                        if (selectedItem === null) return;

                        upsertAppParam.mutate({
                            isNew: currentTheme === null ? true : false,
                            key: "theme",
                            value: selectedItem,
                        });
                    }}
                    getId={(item) => item}
                    getName={(item) => item}
                    textOptions={{
                        placeholder: t("component.select.placeholder"),
                        searchPlaceholder: t("component.select.searchPlaceholder"),
                        emptyOptionsText: t("component.select.noOptions"),
                        defaultValueText: t("component.select.defaultValue"),
                        noResult: t("component.select.noResultFound"),
                    }}
                />
            </Box>
        </Box>
    );
};
