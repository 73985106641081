import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { IngredientFormIngredientSupplier } from "../../../IngredientForm.types";
import { SupplierListGrid } from "./SupplierListGrid";
import { IRI } from "src/types";

type SupplierModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onValidate: (newSuppliers: IngredientFormIngredientSupplier[]) => void;
    existingIngredientSuppliers: IngredientFormIngredientSupplier[];
};
export const SupplierModal = (props: SupplierModalProps): JSX.Element | null => {
    const { t } = useTranslation();
    const { existingIngredientSuppliers, onValidate, isOpen, setOpen } = props;

    const [selectedIngredientSuppliers, setSelectedIngredientsSuppliers] = useState(existingIngredientSuppliers);

    useEffect(() => {
        setSelectedIngredientsSuppliers(existingIngredientSuppliers);
    }, [existingIngredientSuppliers]);

    const title = <Typography variant="h2">{t("Ajouter ou retirer vos fournisseurs")}</Typography>;
    const content = (
        <SupplierListGrid
            currentIngredientSuppliers={selectedIngredientSuppliers}
            setNewIngredientsSuppliers={setSelectedIngredientsSuppliers}
        />
    );

    const allOriginalIds = existingIngredientSuppliers.map((i) => i.supplier["@id"]);
    const allNewlySelectedIds = selectedIngredientSuppliers.map((i) => i.supplier["@id"]);
    const shouldConfirmOnClose = shouldConfirmAction(allOriginalIds, allNewlySelectedIds);

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setOpen}
            options={{
                title: title,
                content: content,
            }}
            onValidate={() => {
                onValidate(selectedIngredientSuppliers);
            }}
            onCancel={() => {
                setSelectedIngredientsSuppliers(existingIngredientSuppliers);
            }}
            confirmAction={
                shouldConfirmOnClose ?
                    {
                        title: <Typography variant="h3">{t("ingredientSupplier.confirmActionTitle")}</Typography>,
                        content: (
                            <Typography variant="body-medium">
                                {t("ingredientSupplier.confirmActionContent")}
                            </Typography>
                        ),
                        cancelText: t("ingredientSupplier.confirmActionCancel"),
                        validateText: t("ingredientSupplier.confirmActionValidate"),
                    }
                :   undefined
            }
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};

const shouldConfirmAction = (originalIds: IRI[], newIds: IRI[]) => {
    if (originalIds.length === 0) return false;

    for (const key of originalIds) {
        const doesKeyExist = newIds.includes(key);
        if (doesKeyExist === false) {
            return true;
        }
    }

    return false;
};
