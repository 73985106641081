import { Stack, Typography, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useState, Dispatch, SetStateAction } from "react";
import { AnchoredPopover, OptionsPopover, OptionsPopoverOption } from "@foodpilot/foods";
import { Score as SureScore } from "src/types";
import { EmptyScore, Score, ScoreVariation } from "src/api/client-api/post/scoreSchema";

export type UnitSwitcherProps = {
    score: SureScore;
    value?: Score | EmptyScore;
    displayedVariations: Record<number, number>;
    setDisplayedVariations: (id: Record<number, number>) => void;
};
export const UnitSwitcher = (props: UnitSwitcherProps) => {
    const { score, value, displayedVariations, setDisplayedVariations } = props;
    const theme = useTheme();

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const active = value && "variations" in value && value.variations.length > 0;
    const scoreLabel = score.label ?? score.title;
    const displayedVariationId = displayedVariations[score.id];

    const displayedVariation =
        active && value.variations.find((variation: ScoreVariation) => variation.id === displayedVariationId);

    const displayedVariationLabel = displayedVariation ? displayedVariation.label : scoreLabel;

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                gap="4px"
                onClick={(e) => {
                    if (!active) return;

                    setAnchor(e.currentTarget);
                }}
                sx={{
                    cursor: active ? "pointer" : "default",
                    opacity: active ? 1 : 0.36,
                }}
            >
                <Typography variant="body">{displayedVariationLabel}</Typography>
                <KeyboardArrowDown sx={{ width: "16px", height: "16px", color: theme.custom.grey[2800] }} />
            </Stack>

            {active && (
                <UnitSwitcherPopover
                    score={score}
                    value={value}
                    displayedVariations={displayedVariations}
                    setDisplayedVariations={setDisplayedVariations}
                    anchor={anchor}
                    setAnchor={setAnchor}
                />
            )}
        </>
    );
};

type UnitSwitcherPopoverProps = {
    score: SureScore;
    value: Score | EmptyScore;
    displayedVariations: Record<number, number>;
    setDisplayedVariations: (variations: Record<number, number>) => void;
    anchor: null | HTMLElement;
    setAnchor: Dispatch<SetStateAction<null | HTMLElement>>;
};
export const UnitSwitcherPopover = (props: UnitSwitcherPopoverProps) => {
    const { score, value, anchor, setAnchor, displayedVariations, setDisplayedVariations } = props;

    const isOpen = Boolean(anchor);

    const mainScoreOption = {
        id: 0,
        label: score.label ?? score.title,
    };

    return (
        <AnchoredPopover
            variant="headless"
            anchor={anchor}
            open={isOpen}
            onClose={() => setAnchor(null)}
            anchorPosition="center"
        >
            <OptionsPopover
                content={[
                    {
                        header: score.title,
                        options: [mainScoreOption, ...value.variations],
                    },
                ]}
                onChange={(option: OptionsPopoverOption) => {
                    const variationId = Number(option.id);

                    const variations = {
                        ...displayedVariations,
                        [score.id]: variationId,
                    };

                    if (!variationId) {
                        delete variations[score.id];
                    }

                    setDisplayedVariations(variations);
                    setAnchor(null);
                }}
                defaultIndex={displayedVariations[score.id]}
                minWidth="287px"
            />
        </AnchoredPopover>
    );
};
