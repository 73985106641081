import { useMutation, useQueryClient } from "react-query";
import { CMSPageSection, getOneCMSContentKey } from "../useGetOneContent";
import { useSnackbar } from "src/utils/useSnackbar";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { reorderSectionsReqs } from "./useReorderSections";

const url = `/api/cms/contents/pages/sections` as const;

const deleteSection = async (entityId: string | number) => {
    const deleteUrl = `${url}/${entityId}`;
    const req = await loggedInApiCall.delete(deleteUrl);

    return req;
};

type DeletionPayload = {
    deleteId: string | number;
    allSectionsToReorder: CMSPageSection[];
};

export const useDeleteOneSection = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();

    // Since backend doesn't handle yet the `repositionning` of elements when they are deleted
    // or added. I implement an easy loop here in order to do that.

    const query = useMutation({
        mutationFn: async (options: DeletionPayload) => {
            const { deleteId, allSectionsToReorder } = options;

            try {
                await deleteSection(deleteId);

                const sectionsFiltered = allSectionsToReorder.filter((item) => item.id !== deleteId);
                await reorderSectionsReqs(sectionsFiltered);
            } catch (err) {
                //
            }
            // const url = `/api/cms/contents/${contentId}`;
            // return await loggedInApiCall.patch(url, payload);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneCMSContentKey]);

            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "info",
                message: "Your section has been deleted !",
            });
        },
    });

    return query;
};
