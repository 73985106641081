import { useParams } from "react-router-dom";
import { IngredientForm } from "./IngredientForm";
import { useIngredientContext } from "../IngredientDetails/ExistingIngredientContext";

export const UpdateQuestionnaire = () => {
    const { startingPageId } = useParams();

    const { ingredient } = useIngredientContext();

    if (ingredient === undefined) {
        // Should display an Error screen
        // It means the ID is wrong
        return null;
    }

    return <IngredientForm existingIngredient={ingredient} startingPageId={startingPageId ?? "0"} />;
};
