import { useQueries } from "react-query";
import { fetchIngredientPost } from "src/api/client-api/post/useGetIngredientPost";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

// currently there is no endpoint to retrieve N ingredientPosts
export const useIngredientPosts = (ingredientIds: number[]) => {
    const { selectedCompany, currentCampaign } = useFoodpilotContext();
    const companyId = selectedCompany?.id;
    const campaignId = currentCampaign?.id;
    return useQueries(
        ingredientIds.map((ingredientId) => {
            return {
                queryKey: ["getIngredientPost", ingredientId, companyId, campaignId],
                cacheTime: 0,
                retry: 0,
                enabled: ingredientId !== undefined,
                queryFn: () => fetchIngredientPost(ingredientId, companyId, campaignId),
            };
        }),
    );
};
