import { TextField } from "@foodpilot/foods";
import { FormControl, InputProps, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

type IFormInputProps = {
    name: string;
    label: string;
    placeholder?: string;
    hideError?: boolean;
} & InputProps;

export const FormInput = (props: IFormInputProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const methods = useFormContext();

    const { name, label, placeholder, hideError = false, ...otherProps } = props;
    const { error } = methods.getFieldState(name, methods.formState);

    return (
        <Controller
            defaultValue={otherProps.defaultValue ?? ""}
            control={methods.control}
            name={name}
            render={({ field }) => (
                <FormControl
                    fullWidth
                    sx={{
                        "> div": {
                            gap: "8px !important",
                        },
                    }}
                >
                    <TextField
                        title={label}
                        placeholder={placeholder ? placeholder : ""}
                        sx={{
                            width: "100%",
                            height: "40px",
                            border: `1px solid ${theme.custom.grey[500]}`,
                            borderRadius: "40px",
                        }}
                        {...otherProps}
                        {...field}
                    />
                    {error && !hideError && (
                        <Typography variant="body" color="error">
                            {t("Une erreur s'est produite")}
                        </Typography>
                    )}
                </FormControl>
            )}
        />
    );
};
