import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HintBox } from "src/components/HintBox";
import { NoReferenceIngredient } from "./NoReferenceIngredient";
import { Loading } from "src/components/utils/Loading";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient.ts";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { ScoreBox } from "src/components/Score/ScoreBox";
import { Score } from "src/types";
import { IngredientReferenceScores } from "src/api/client-api/ingredientReferenceScores/useGetIngredientReferenceScores.ts";

export type OneSupplierScoresProps = {
    ingredientReference: IngredientReference | undefined;
    ingredient: Ingredient;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isRefetching?: boolean;
    referenceScores: IngredientReferenceScores;
};

export const OneSupplierScores = (props: OneSupplierScoresProps) => {
    const { ingredient, ingredientReference, variations, setVariations, referenceScores, isRefetching = false } = props;
    const { scoreTypes, scores, isUserOfTypeSupplier, getScoresByType } = useFoodpilotContext();
    const { t } = useTranslation();

    const isSupplier = isUserOfTypeSupplier();

    if (!scoreTypes || !scores) {
        return <Loading />;
    }

    const ingredientsScores = getScoresByType("ingredient");

    if (!ingredientsScores.length) {
        return (
            <Stack gap="28px" sx={{ padding: "44px 52px" }}>
                <Box sx={{ textAlign: "center", padding: "28px" }}>{t("ingredient.no-score")}</Box>
            </Stack>
        );
    }

    if (!ingredientReference) {
        return <NoReferenceIngredient ingredient={ingredient} />;
    }

    const mappedReferenceScores = Object.fromEntries(referenceScores.map((score) => [score?.score?.id, score]));

    return (
        <Box sx={{ dislay: "flex", gap: "1rem", flexDirection: "column" }}>
            <ReferenceBox name={props?.ingredientReference?.name} />
            {!isSupplier && (
                <Box sx={{ marginTop: "1rem" }}>
                    <HintBox
                        title={t("Attention ! Cet ingrédient n’a qu’un seul fournisseur associé")}
                        content={t(
                            "Ses indicateurs ne dépendent donc que des données et des informations de ce fournisseur",
                        )}
                    />
                </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", padding: "1rem 0", flexWrap: "wrap" }}>
                {ingredientsScores.map((score: Score, index: number) => {
                    return (
                        <ScoreBox
                            key={index}
                            score={score}
                            value={ingredient.scores[score.id]}
                            variations={variations}
                            setVariations={setVariations}
                            isRefetching={isRefetching}
                            referenceScore={mappedReferenceScores[score.id] ?? null}
                            prevScore={ingredient?.prevScores?.[score.id]}
                            prevCampaignName={ingredient?.prevCampaignName}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

type ReferenceBoxProps = {
    name: string | undefined;
};

const ReferenceBox = (props: ReferenceBoxProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    if (!props.name) return null;

    return (
        <Box
            sx={{
                marginTop: "-1rem",
                display: "flex",
                alignItems: "center",
                bgcolor: theme.palette.background.paper,
                width: "fit-content",
                padding: ".5rem",
                borderRadius: "6px",
                gap: "8px",
                border: "1px solid #E7E6E4",
            }}
        >
            <Typography variant="h5">{t("Référence")}:</Typography>{" "}
            <Typography variant="body-medium">{props.name}</Typography>
        </Box>
    );
};
