import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleBlock, ArticleFormFields } from "./ArticleFormFields";
import { CMSContentBlock } from "src/api/client-api/cms/useGetOneContent";
import { BlockWrite, useCreateOneBlock } from "src/api/client-api/cms/blocks/useCreateOneBlock";

const blockToArticleBlock = (block: CMSContentBlock | null, totalExistingElements: number): ArticleBlock => {
    if (block === null) {
        return {
            id: undefined,
            title: "",
            description: "",
            ctaLink: "",
            media: null,
            position: totalExistingElements,
        };
    }

    return {
        id: block.id,
        title: block.title,
        description: block.description ?? "",
        ctaLink: block.ctaLink,
        media: block.media ?? null,
        position: block.position,
    };
};

const articleBlockToWrite = (sectionIRI: IRI, articleBlock: ArticleBlock): BlockWrite => {
    const mediaIRI = articleBlock.media === null ? "" : articleBlock.media["@id"];
    const blockWrite: BlockWrite = {
        id: articleBlock.id,
        title: articleBlock.title,
        subtitle: "",
        description: articleBlock.description,
        ctaLink: articleBlock.ctaLink,
        ctaLabel: "",
        media: mediaIRI,
        position: articleBlock.position,
        section: sectionIRI,
    };
    return blockWrite;
};

type AddArticlesModalProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    currentSection: {
        "@id": IRI;
        totalExistingElements: number;
    };
    selectedExistingBlock: CMSContentBlock | null;
};
export const AddArticlesModal = (props: AddArticlesModalProps) => {
    const { isOpen, setIsOpen, selectedExistingBlock, currentSection } = props;
    const { t } = useTranslation();

    const createBlock = useCreateOneBlock();
    const articleBlock = blockToArticleBlock(selectedExistingBlock, currentSection.totalExistingElements);
    const [articleBlockForm, setArticleBlockForm] = useState<ArticleBlock>(articleBlock);

    useEffect(() => {
        const articleBlock = blockToArticleBlock(selectedExistingBlock, currentSection.totalExistingElements);
        setArticleBlockForm(articleBlock);
    }, [selectedExistingBlock]);

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setIsOpen}
            options={{
                title: <ModalTitle />,
                content: (
                    <ArticleFormFields articleBlockForm={articleBlockForm} setArticleBlockForm={setArticleBlockForm} />
                ),
            }}
            onValidate={() => {
                if (articleBlockForm === null) {
                    // Invalid data. Snackbar ?
                    return;
                }
                const newBlock: BlockWrite = articleBlockToWrite(currentSection["@id"], articleBlockForm);
                createBlock.mutate({ newBlock: newBlock });
            }}
            fullWidth
            maxWidth="xs"
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};

function ModalTitle() {
    const { t } = useTranslation();
    return <Typography variant="big-bold">{t("cms.articles.addModal.title")}</Typography>;
}
