import { Button, Stack } from "@mui/material";
import { CreateOutlined } from "@mui/icons-material";
import { InfoOutlined } from "@mui/icons-material";

import BigPot from "src/assets/icons/SsqTitleIcon/big-pot.svg?react";
import BigBox from "src/assets/icons/SsqTitleIcon/big-box.svg?react";
import BigCutlery from "src/assets/icons/SsqTitleIcon/big-cutlery.svg?react";
import BigMarket from "src/assets/icons/SsqTitleIcon/big-market.svg?react";
import BigProduction from "src/assets/icons/SsqTitleIcon/big-production.svg?react";

import { GenericAccordion, useFormBaseContext } from "@foodpilot/foods";

import { useTranslation } from "react-i18next";
import { ProductFormType } from "../ProductForm.types";
import { ProductInformationSummary } from "src/components/ProductSummary/Information";
import { ProductRecipeSummary } from "src/components/ProductSummary/Recipe";
import { ProductPackagingsSummary } from "src/components/ProductSummary/Packaging";
import { ProductTransformationSummary } from "src/components/ProductSummary/Production";
import { ProductDistributionSummary } from "src/components/ProductSummary/Distribution";
import { ProductConsumptionSummary } from "src/components/ProductSummary/Consumption";

type ModifyButtonProps = {
    pageNumber: number;
};
const ModifyButton = (props: ModifyButtonProps) => {
    const { setPage } = useFormBaseContext();
    const { t } = useTranslation();

    const modifyText = t("Modifier");
    return (
        <Button
            variant="white-filled"
            startIcon={<CreateOutlined />}
            sx={{ marginRight: "24px", borderRadius: "44px" }}
            onClick={() => setPage(props.pageNumber)}
        >
            {modifyText}
        </Button>
    );
};

type Page7Props = {
    product: ProductFormType;
};

export const Page7 = (props: Page7Props) => {
    const { t } = useTranslation();
    const { product } = props;

    const acc: Set<string> = new Set();
    const productSuppliersName = product.productIngredients.reduce((previousValue, currentValue) => {
        const names = currentValue.ingredient.suppliersName;
        names.forEach((name) => previousValue.add(name.name));

        return previousValue;
    }, acc);
    const sortedSuppliersName = Array.from(productSuppliersName).toSorted();
    return (
        <div>
            <Stack direction="column" useFlexGap gap="8px">
                <GenericAccordion
                    headerIcon={<InfoOutlined />}
                    headerText={t("general_information")}
                    controls={<ModifyButton pageNumber={0} />}
                >
                    <ProductInformationSummary
                        title={product.title}
                        clientId={product.clientId}
                        productLine={product.productLine?.name}
                        productReferenceId={product.productReferenceId}
                        brandName={product.brand?.name}
                        netWeight={product.netWeight}
                        tags={product.tags?.map((t) => t.name) ?? []}
                    />
                </GenericAccordion>

                <GenericAccordion
                    headerIcon={<BigPot />}
                    headerText={t("recipe")}
                    controls={<ModifyButton pageNumber={1} />}
                >
                    <ProductRecipeSummary
                        productIngredients={product.productIngredients}
                        suppliersName={sortedSuppliersName}
                    />
                </GenericAccordion>

                <GenericAccordion
                    headerIcon={<BigBox />}
                    headerText={t("packaging")}
                    controls={<ModifyButton pageNumber={2} />}
                >
                    <ProductPackagingsSummary productPackagings={product.productPackagings} />
                </GenericAccordion>

                <GenericAccordion
                    headerIcon={<BigProduction />}
                    headerText={t("Production")}
                    controls={<ModifyButton pageNumber={3} />}
                >
                    <ProductTransformationSummary productTransformations={product.productTransformations} />
                </GenericAccordion>

                <GenericAccordion
                    headerIcon={<BigMarket />}
                    headerText={t("Distribution")}
                    controls={<ModifyButton pageNumber={4} />}
                >
                    <ProductDistributionSummary distributionChannelId={product.distributionChannelId} />
                </GenericAccordion>

                <GenericAccordion
                    headerIcon={<BigCutlery />}
                    headerText={t("consumption")}
                    controls={<ModifyButton pageNumber={5} />}
                >
                    <ProductConsumptionSummary consumptionIds={product.productConsumptions} />
                </GenericAccordion>
            </Stack>
        </div>
    );
};
