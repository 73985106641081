import { FoodsIcon, WhiteBox } from "@foodpilot/foods";
import { Link, Button, Container, Stack, Typography } from "@mui/material";
import companies from "./placeholderData";
import { SummaryCardProps } from "./Support";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { URLHandler } from "src/services/URLHandler";

export const Details = () => {
    const { id } = useParams();
    const company = (companies as SummaryCardProps[]).find((c) => c.id === id);

    const goBackUrl = URLHandler.cms.list();
    if (!company) {
        return (
            <Container
                component={"main"}
                sx={{
                    backgroundColor: (theme) => theme.palette.grey[300],
                    ml: 6.5,
                    mt: 5.5,
                }}
            >
                <Link
                    href={goBackUrl}
                    underline="none"
                    sx={{
                        display: "flex",
                        color: (theme) => theme.palette.primary[800],
                        mb: 3,
                    }}
                >
                    <FoodsIcon icon="arrowLeft" size={3} />
                    <Typography variant="big-bold" sx={{ color: (theme) => theme.palette.primary[800] }}>
                        Revenir à la liste des offres
                    </Typography>
                </Link>
                <Typography variant="big-bold">{t("bred.support.not_found")}</Typography>
            </Container>
        );
    }

    return (
        <Container
            component={"main"}
            sx={{
                backgroundColor: (theme) => theme.palette.grey[300],
                ml: 6.5,
                mt: 5.5,
            }}
        >
            <Link
                href={goBackUrl}
                underline="none"
                sx={{
                    display: "flex",
                    color: (theme) => theme.palette.primary[800],
                    mb: 3,
                }}
            >
                <FoodsIcon icon="arrowLeft" size={3} />
                <Typography variant="big-bold" sx={{ color: (theme) => theme.palette.primary[800] }}>
                    {t("bred.support.go_back")}
                </Typography>
            </Link>
            <Stack direction={"row"} spacing={3}>
                <WhiteBox p={3} flex={3}>
                    <img src={company.logo} alt="logo" height={56} />
                    <Stack spacing={3}>
                        <Typography variant="h2">{company?.title}</Typography>
                        {company.subtitle && <Typography variant="h3">{company.subtitle}</Typography>}
                        <Typography variant="body-medium">{company.description}</Typography>
                    </Stack>
                </WhiteBox>
                <WhiteBox p={3} flex={1} sx={{ height: "fit-content" }}>
                    <Stack spacing={3}>
                        <Typography variant="h3">Vous êtes intéressé ?</Typography>
                        <Typography variant="body-medium">
                            Contactez- nous pour obtenir plus d’informations et effectuer les démarches nécessaires.
                        </Typography>
                        <Link
                            href="https://foodpilot.io/fr/contact/"
                            rel="noreferrer"
                            target="_blank"
                            underline="hover"
                        >
                            <Button variant="primary">
                                <Typography>Nous contacter</Typography>
                                <FoodsIcon icon="openNewTab" size={3} />
                            </Button>
                        </Link>
                    </Stack>
                </WhiteBox>
            </Stack>
        </Container>
    );
};
