import { Box, Divider, Typography } from "@mui/material";
import { AccountSection } from "./Page4/AccountSection";
import { useFormContext, useFieldArray } from "react-hook-form";
import { RSEFormProps } from "src/types";
import { Energies } from "./Page4/Energies";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WhiteBox } from "@foodpilot/foods";
import { HelpPlease } from "./components/HelpPlease";

type Page4Props = {
    onError: (hasError: boolean) => void;
};

export const Page4 = (props: Page4Props) => {
    const { onError } = props;
    const { control, getValues, watch } = useFormContext<RSEFormProps>();
    const { fields } = useFieldArray({ control, name: "categories" });
    const fiscalYear = getValues("fiscalYear");

    const navigate = useNavigate();
    useEffect(() => {
        if (!fiscalYear) navigate("/inexweb/form/0");
    }, [fiscalYear]);

    const accountsLength = watch("categories")
        ?.flatMap((c) => c.energies)
        ?.flatMap((e) => e.accounts).length;

    useEffect(() => {
        if (accountsLength === undefined) return;
        onError(accountsLength === 0);
    }, [accountsLength]);

    return (
        <Box sx={{ display: "flex", gap: "1rem", position: "relative" }}>
            <Box sx={{ flex: 2.5 }}>
                <Divider />
                {fields.map((category, categoryIndex) => (
                    <AccountSection title={category.label} key={category.id}>
                        <Energies categoryIndex={categoryIndex} />
                    </AccountSection>
                ))}
            </Box>
            <HelpPlease />
        </Box>
    );
};
