import { PrimaryDialog, FoodsCheckbox } from "@foodpilot/foods";
import { Box, Typography, useTheme } from "@mui/material";
import { useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ConsumptionReference } from "src/api/sure-api/useConsumptionReference";

type ConsumptionModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    currentConsumptions: number[];
    infoText: string | null;
    allConsumptions: ConsumptionReference[];
    onValidate: (values: number[]) => void;
};

export const ConsumptionModal = (props: ConsumptionModalProps): JSX.Element | null => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { isOpen, setOpen, currentConsumptions, allConsumptions, infoText } = props;

    const originalSelectedItems = allConsumptions.filter((allT) => currentConsumptions.includes(allT.id));
    const [selectedItems, setSelectedItems] = useState(originalSelectedItems);

    const title = (
        <Box sx={{ display: "flex", flexDirection: "column", margin: "4px", gap: "4px" }}>
            <Typography variant="h3">{t("Ajouter ou retirer des modes de consommation")}</Typography>
            <Typography variant="body-medium" sx={{ color: theme.custom.grey[2000] }}>
                {infoText ?? "-"}
            </Typography>
        </Box>
    );
    const content = (
        <Box style={{ padding: "1.5rem", backgroundColor: theme.custom.grey[200] }}>
            <FoodsCheckbox
                selectedItems={selectedItems}
                values={allConsumptions}
                onChange={(selectedValues) => setSelectedItems(selectedValues)}
                getName={(item) => item.label}
            />
        </Box>
    );

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setOpen}
            maxWidth={"sm"}
            fullWidth
            options={{
                title: title,
                content: content,
            }}
            onValidate={() => {
                const newConsumptions = selectedItems.map((item) => item.id);
                props.onValidate(newConsumptions);
            }}
            onCancel={() => {
                setSelectedItems(originalSelectedItems);
            }}
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};
