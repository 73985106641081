import { RoundInput } from "@foodpilot/foods";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpsertAppParam } from "src/api/client-api/appParams";
import {
    ApplicationParameterKeys,
    useApplicationParameters,
} from "src/api/client-api/appParams/useApplicationParameters";
import { useDeleteAppParam } from "src/api/client-api/appParams/useDeleteAppParam";

type BaseStringUploadProps = {
    appParamKey: ApplicationParameterKeys;
    label: string;
    description?: string;
};
export const BaseStringUpload = (props: BaseStringUploadProps) => {
    const { appParamKey, label, description } = props;

    const { applicationParameters } = useApplicationParameters();

    const upsertAppParam = useUpsertAppParam();
    const deleteAppParam = useDeleteAppParam();
    const { t } = useTranslation();

    const originalValue = applicationParameters?.[appParamKey];
    const [value, setValue] = useState(originalValue ?? null);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "75px", gap: "16px" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", gap: "16px" }}>
                    <Typography variant="big-bold">{`${label}`}</Typography>
                    <Typography variant="big-bold">{`-`}</Typography>
                    <Typography variant="body-medium">{`${description}`}</Typography>
                </Box>
                <RoundInput
                    sx={{ backgroundColor: "white", width: "500px" }}
                    value={value}
                    onChange={(e) => {
                        const newValue = e.currentTarget.value;
                        setValue(newValue);
                    }}
                />
                <Button
                    sx={{ display: "flex", justifyContent: "left" }}
                    variant="text"
                    onClick={() => {
                        if (value === null) {
                            return deleteAppParam.mutate({ key: appParamKey });
                        }

                        upsertAppParam.mutate({
                            isNew: originalValue === undefined ? true : false,
                            key: appParamKey,
                            value: value,
                        });
                    }}
                >
                    {t("primaryDialog.validate")}
                </Button>
            </Box>
        </Box>
    );
};
