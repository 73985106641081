import { useMutation } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { ReportMutation, ReportSchema, reportZodToReport } from "src/types";

export const useCreateReportMutation = () => {
    return useMutation({
        mutationKey: ["addReport"],
        mutationFn: async (report: ReportMutation) => {
            const response = await sureApiCall.post(`/api/inexweb/feed-report-new`, report);

            const res = ReportSchema.parse(response.data);

            return reportZodToReport(res);
        },
    });
};
