import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { z } from "zod";
import { zodParseWithLog } from "src/api/apiErrorLogs";

export const getAllActionsKey = "getAllActions" as const;

export const useGetAllActions = () => {
    const query = useQuery({
        queryKey: [getAllActionsKey],
        cacheTime: 0,
        queryFn: async () => {
            return await sureApiCall.get(`/api/actions`);
        },
        select: (response) => {
            const scores = response.data["hydra:member"];
            const data = zodParseWithLog(scores, ActionsSchema.array(), getAllActionsKey);
            return data;
        },
    });

    return {
        actions: query.data,
        ...query,
    };
};

const FieldSchema = z.object({
    id: z.number(),
    type: z.string(),
});

const DataListOptionSchema = z.object({
    value: z.string(),
    label: z.string(),
    position: z.number(),
    groupOption: z.string().optional(),
});

export type DataListOption = z.infer<typeof DataListOptionSchema>;

const DataListSchema = z.object({
    options: DataListOptionSchema.array(),
});

export type SsqPropertyAction = z.infer<typeof SsqPropertiesSchema>;
export type SsqPropertyActionHead = z.infer<typeof SsqPropertiesSchema>;
const SsqPropertiesSchemaHead = z.object({
    id: z.number(),
    title: z.string().optional(),
    field: FieldSchema.optional(),
    unit: z.object({}).optional(),
    position: z.number().optional(),
    multivalue: z.boolean().optional(),
    dataList: DataListSchema.optional(),

    // defaultValue: z.string().optional(),
});
const SsqPropertiesSchema = SsqPropertiesSchemaHead.extend({
    // The optional here is to handle a level of nested.
    children: SsqPropertiesSchemaHead.array().optional(),
});

const ActionsSchema = z.object({
    id: z.number(),
    title: z.string(),
    slug: z.string(),
    ssqProperties: SsqPropertiesSchema.array(),
});
export type ActionsType = z.infer<typeof ActionsSchema>;
