import { Accordion, AccordionSummary, Typography, Box, styled, Table } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode } from "react";

type AccountSectionProps = {
    title: string;
    children?: ReactNode;
};

export const AccountSection = (props: AccountSectionProps) => {
    const { title, children } = props;

    return (
        <Accordion
            defaultExpanded
            title={title}
            elevation={0}
            sx={{
                "&.MuiAccordion-rounded": {
                    borderRadius: "8px",
                },
                borderRadius: "8px",
                backgroundColor: "transparent",
            }}
        >
            <AccordionSummary sx={{ p: "16px 24px" }} expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">{title}</Typography>
            </AccordionSummary>
            {children}
            <Box sx={{ height: 20 }} />
        </Accordion>
    );
};

export const RoundedTable = styled(Table)(({ theme }) => ({
    borderRadius: "16px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderCollapse: "separate",
    borderSpacing: 0,
    borderColor: theme.custom.grey[500],
    overflow: "hidden",
}));
