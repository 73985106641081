import { FoodsToggleButton, FoodsToggleButtonType, FormBox } from "@foodpilot/foods";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ProductFormType } from "../ProductForm.types";
import { useDistributionReference } from "src/api/sure-api/useDistributionReference";
import { Box } from "@mui/material";

type DistributionSelection = {
    selected?: FoodsToggleButtonType;
    choices: FoodsToggleButtonType[];
};

type Page5Props = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};
export const Page5 = (props: Page5Props) => {
    const { t } = useTranslation();
    const { product, setProduct } = props;
    const { distributionReference } = useDistributionReference();

    if (distributionReference === undefined) {
        return <div>{t("Aucune méthode de distribution disponible")}</div>;
    }

    const selectionState = distributionReference.reduce<DistributionSelection>(
        (previousValue, distRef) => {
            const choice = {
                id: distRef.id,
                label: distRef.label,
                onClick: () => {
                    setProduct({
                        ...product,
                        distributionChannelId: distRef.id,
                    });
                },
            };

            if (distRef.id === product.distributionChannelId) {
                previousValue.selected = choice;
            }

            previousValue.choices.push(choice);
            return previousValue;
        },
        { selected: undefined, choices: [] },
    );

    return (
        <FormBox sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ marginBottom: "10px" }}>
                <Typography variant="h3">{t("Mode de distribution du produit")}</Typography>
            </Box>
            <FoodsToggleButton
                initialSelected={selectionState["selected"]}
                buttons={selectionState["choices"]}
                buttonGroup={true}
            />
        </FormBox>
    );
};
