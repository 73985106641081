import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Company } from "src/api/client-api/useUserNew";
import { CompaniesService } from "src/services/Companies/CompaniesService";

export const useSelectCompany = (allCompanies: Company[] | null) => {
    const location = useLocation();
    const { companyId } = useParams();

    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

    // We must here put the logic that says : IF, and only IF I change the URL.
    // Then, I want to alter the selectedCompany accordingly.

    // BUT.
    // We also want to change the URL when we change a company.

    // 1. useEffect that watches over the location + companyId.
    // ---> The effect is : It changes the selectedCompany, IF, the companyId chosen is...
    // -----> 1. Different  -> doesn't do anything.
    // -----> 2. Valid      -> triggers a problem if invalid -> logout with snackbar ?

    // 2. useEffect that watches over the selectedCompany.
    // ---> The effect is : It will REPLACE in the URL the company that exist.
    // WARNING : If the currentUrl and the newUrl are not different -> We MUSNT make a reload.
    // It'll trigger a circumference.

    useEffect(() => {
        if (allCompanies === null) return;

        // Note from Kilian
        // This big condition is mandatory for the `/` url.
        // It probably shows a misconception problem.
        // Since we need to be able to access the Sidebar buttons.
        // BUT, they require a Company ID.
        //
        // Maybe I implemented it wrong. I thought that using the URL id to define the `selectedCompany`
        // was the way. But maybe it should be done the other way around.
        const selectedCompanyId =
            companyId !== undefined ? Number(companyId)
            : allCompanies.length === 0 ? undefined
            : allCompanies[0].id;

        const selectedCompany = CompaniesService.selectCurrentCompany(allCompanies, selectedCompanyId);
        if (selectedCompany !== null) {
            setSelectedCompany(selectedCompany);
        }
    }, [allCompanies, location, companyId]);

    return {
        selectedCompany,
    };
};
