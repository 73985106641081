import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { mrtSortingStateToObject, SortType } from "src/api/apiHelpers";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { SupplierService } from "src/pages/Supplier/SupplierService";
import { IRI } from "src/types";
import { z } from "zod";

export const allSupplierKey = "getAllSupplier" as const;

type Options = {
    cacheTime?: number;
    page?: number;
    itemsPerPage?: number;
    search?: string;
    type?: CompanyType[] | CompanyType;
    ingredientLine?: string;
    sorting?: Array<SortType>;
};

export type CompanyType = "provider" | "main" | "branch";

export const useGetAllSupplier = (options?: Options) => {
    const {
        cacheTime = 0,
        page = 1,
        itemsPerPage = 20,
        search = "",
        type = "provider",
        ingredientLine = "",
        sorting = [],
    } = options || {};

    const query = useQuery({
        queryKey: [allSupplierKey, page, itemsPerPage, search, ingredientLine, sorting],
        staleTime: 0,
        cacheTime: cacheTime,
        queryFn: async () => {
            const url = `/api/companies`;

            return loggedInApiCall.get(url, {
                params: {
                    itemsPerPage: itemsPerPage,
                    page: page,
                    q: search !== "" ? search : undefined,
                    "ingredientSuppliers.ingredient.ingredientLine.name":
                        ingredientLine !== "" ? ingredientLine : undefined,
                    type: type,
                    order: mrtSortingStateToObject(sorting),
                },
            });
        },
        select: (request) => {
            const totalItems = request.data["hydra:totalItems"];
            const suppliersWithSectors = SupplierService.makeSupplierListWithSectors(request.data["hydra:member"]);
            const data = zodParseWithLog(suppliersWithSectors, SupplierGridZodSchema.array(), allSupplierKey);
            return {
                items: data,
                totalItems: totalItems,
            };
        },
    });

    return {
        suppliers: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};

export type IngredientLine = {
    "@id": IRI;
    id: number;
    name: string;
};

export const IngredientLineZodSchema = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

export type CompanySector = {
    "@id": string;
    id: number;
    name: string;
};

export const CompanySectorZod: z.ZodSchema<CompanySector> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

export type UserSupplier = {
    firstname: string;
    lastname: string;
    picture?: string;
};

export const UserSupplierZod: z.ZodSchema<UserSupplier> = z.object({
    firstname: z.string(),
    lastname: z.string(),
    picture: z.string().optional(),
});

export type NewIngredientFromSupplierGrid = {
    "@id"?: IRI;
    id?: number;
    name: string;
    ingredientLine?: IngredientLine;
};

export type IngredientSupplierGrid = {
    "@id"?: IRI;
    ingredient: NewIngredientFromSupplierGrid;
};

export type Domain = {
    "@id": IRI;
    name: string;
};

export const DomainSchema = z.object({
    "@id": z.string(),
    name: z.string(),
});

export type SupplierGrid = {
    "@id": IRI;
    id: number;
    name: string;
    clientId?: string;
    domains: Domain[];
    companySector?: CompanySector;
    ingredientSuppliers?: IngredientSupplierGrid[];
    sectors: string[];
    address1?: string;
    address2?: string;
    city?: string;
    country?: string;
    zipcode?: string;
    state?: string;
    phone?: string;
    email?: string;
    type: string;
    users?: UserSupplier[];
    updatedAt?: string;
    canView: boolean;
    canModify: boolean;
    canDelete: boolean;
};

export const SupplierGridZodSchema: z.ZodSchema<SupplierGrid> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    clientId: z.string().optional(),
    domains: DomainSchema.array(),
    companySector: CompanySectorZod.optional(),
    address1: z.string().optional(),
    address2: z.string().optional(),
    city: z.string().optional(),
    country: z.string().optional(),
    zipcode: z.string().optional(),
    state: z.string().optional(),
    phone: z.string().optional(),
    email: z.string().optional(),
    type: z.string(),
    ingredientSuppliers: z
        .object({
            "@id": z.string().optional(),
            ingredient: z.object({
                id: z.number(),
                name: z.string(),
                ingredientLine: z
                    .object({
                        "@id": z.string(),
                        id: z.number(),
                        name: z.string(),
                    })
                    .optional(),
            }),
        })
        .array()
        .optional(),
    sectors: z.string().array(),
    users: UserSupplierZod.array().optional(),
    updatedAt: z.string().optional(),
    canView: z.boolean(),
    canModify: z.boolean(),
    canDelete: z.boolean(),
});
