import { useState } from "react";
import { Box, Stack, styled, Typography } from "@mui/material";
import { UnitSwitcher } from "src/components/Score/UnitSwitcher.tsx";
import { Breadcrumbs } from "src/components/Breadcrumb/Breadcrumbs.tsx";
import { FoodsIcon, SecondaryTab, SecondaryTabs } from "@foodpilot/foods";
import { Tab1Content } from "src/pages/Product/ProductDetails/Scores/SecondaryTabs/Tab1Content.tsx";
import { useNavigate } from "react-router-dom";
import { URLHandler } from "src/services/URLHandler.ts";
import { CompaniesService } from "src/services/Companies/CompaniesService.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { useProductContext } from "src/pages/Product/ProductDetails/ProductContext.tsx";

const UnitSwitcherWrapper = styled(Box)(() => ({
    fontSize: "14px",
    display: "flex",
    "> div": {
        "> span": {
            color: "black",
        },
    },
}));

const TypographyStyled = styled(Typography)(() => ({
    "&::after": {
        content: '" "',
        borderRight: "1px solid #E7E6E4",
        height: "1.4rem",
        margin: "0 10px",
        position: "relative",
        top: "0.2rem",
    },
}));

export const ProductScore = () => {
    const [selected, setSelected] = useState<number>(1);
    const navigate = useNavigate();
    const { selectedCompany } = useFoodpilotContext();
    const { product } = useProductContext();
    const companyId = selectedCompany !== null ? selectedCompany.id : CompaniesService.getCurrentCompanyId();

    const tabs: SecondaryTab[] = [
        {
            id: 1,
            name: "Repartition",
            component: <Tab1Content />,
            onClick: () => {
                setSelected(1);
                navigate("distribution");
            },
            icon: <FoodsIcon size={2} icon="repartition" />,
        },
    ];

    return (
        <Box>
            <Stack gap={1} mb={3}>
                <Box display="flex">
                    <TypographyStyled variant="h2">PEF Score</TypographyStyled>
                    <UnitSwitcherWrapper>
                        <UnitSwitcher
                            score={{ id: 1, title: "Test", type: "test" }}
                            displayedVariations={{}}
                            setDisplayedVariations={() => {}}
                        />
                    </UnitSwitcherWrapper>
                </Box>
                <Box display="flex">
                    <Breadcrumbs
                        items={[
                            { label: "Home", action: () => navigate("/") },
                            {
                                label: "Product list",
                                action: () => {
                                    const productListUrl = URLHandler.product.list(Number(companyId));
                                    return navigate(productListUrl);
                                },
                            },
                            {
                                label: product?.title ?? "",
                                action: () => {
                                    const productScoreUrl = URLHandler.product.scores(Number(companyId), product!.id);
                                    return navigate(productScoreUrl);
                                },
                            },
                            {
                                label: "Score",
                            },
                        ]}
                    />
                </Box>
            </Stack>
            <SecondaryTabs tabs={tabs} selectedTab={(tab) => tab.id === selected} />
        </Box>
    );
};
