import { Box, Button, Typography, useTheme } from "@mui/material";
import { Fragment } from "react";

import DoubleCheck from "src/assets/icons/double-check.svg?react";
import { useTranslation } from "react-i18next";

type HeaderProps = {
    openModal: () => void;
};
export const Header = (props: HeaderProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Typography
                variant="h3"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                {t("Liste des emballages du produit :")}
            </Typography>
            <Box sx={{ borderTop: `1px solid ${theme.custom.grey[500]}` }}>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        marginBlock: "18px",
                    }}
                >
                    <Button variant="ternary" startIcon={<DoubleCheck />} onClick={props.openModal}>
                        <Typography variant="body-bold">{t("Ajouter ou retirer des emballages")}</Typography>
                    </Button>
                </Box>
            </Box>
        </Fragment>
    );
};
