import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

export const createImageKey = "";

export type UpdateFile = {
    fileName: string;
    value: File;
};

export const useCreateImage = () => {
    const query = useMutation({
        mutationFn: async (props: UpdateFile) => {
            const { fileName, value } = props;

            const payload = new FormData();
            payload.append("name", fileName);
            payload.append("file", value);

            const url = `/api/cms/contents/medias`;
            const resp = await loggedInApiCall.post(url, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            return resp;
        },
        onSuccess: () => {
            // // For now, in order to immediately apply the changes, we want to reload the page;
            // // Maybe later, we'll find a way to do it more properly.
            // location.reload();
        },
    });

    return query;
};
