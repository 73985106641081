import { FoodsFormBase, FoodsFormBaseProps } from "@foodpilot/foods";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { useTranslation } from "react-i18next";
import { Loading } from "src/components/utils/Loading";

type QuestionnaireUpdateFormBaseProps = Partial<FoodsFormBaseProps> & {
    ingredient: Ingredient;
    title?: string;
};
export const QuestionnaireUpdateFormBase = (props: QuestionnaireUpdateFormBaseProps) => {
    const { ingredient, title, pages, confirmExit, ...formBaseProps } = props;
    const { t } = useTranslation();

    return (
        <FoodsFormBase
            textOptions={{
                title: title ?? t("word.loading-wait"),
                subtitle: ingredient.name,
                nextPage: t("next"),
                previousPage: t("previous"),
                syncingMessage: t("Enregistrement en cours") + "...",
                savingDefaultMessage: t("Informations enregistrées il y’a quelques secondes"),
            }}
            pages={pages ?? [{ content: <Loading /> }]}
            confirmExit={false}
            isLoading={false}
            showSync={true}
            {...formBaseProps}
        />
    );
};
