import FrenchFlag from "src/assets/flags/fr.svg?react";
import EnglishFlag from "src/assets/flags/en.svg?react";
import DeutchFlag from "src/assets/flags/de.svg?react";
import SpanishFlag from "src/assets/flags/es.svg?react";
import ItalianFlag from "src/assets/flags/it.svg?react";

import { FoodsSelect } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { LocalStorage } from "src/services/LocalStorage";

export const LanguageSelector = () => {
    const translation = useTranslation();
    const { t } = translation;

    const languageChoice = [
        // If you change this hardcoded list, especially the `lng` key
        // Make sure the registered key for the user exist... ;)
        { id: 1, lng: "fr", name: t("language.fr"), icon: <FrenchFlag /> },
        { id: 2, lng: "en", name: t("language.en"), icon: <EnglishFlag /> },
        { id: 3, lng: "de", name: t("language.de"), icon: <DeutchFlag /> },
        { id: 4, lng: "es", name: t("language.es"), icon: <SpanishFlag /> },
        { id: 5, lng: "it", name: t("language.it"), icon: <ItalianFlag /> },
    ];

    const currentLanguage = languageChoice.find((lng) => lng.lng === translation.i18n.language) ?? languageChoice[0];

    return (
        <FoodsSelect
            selectedOption={currentLanguage}
            options={languageChoice}
            onChange={(selectedValue) => {
                if (selectedValue === null) return;
                translation.i18n.changeLanguage(selectedValue.lng);
                LocalStorage.setItem("language", selectedValue.lng);
                document.documentElement.lang = selectedValue.lng;
            }}
            getId={(item) => item.id}
            getName={(item) => item.name}
            getIcon={(item) => item.icon}
            textOptions={{
                noResult: "",
                searchPlaceholder: "",
                emptyOptionsText: t("select.generic.no-options"),
                placeholder: t("select.generic.placeholder"),
            }}
        />
    );
};
