import { Stack, Typography, BoxProps, CircularProgress } from "@mui/material";
import { SectionBox } from "@foodpilot/foods";
import { ScoresVariationsPopover } from "./ScoresVariationsPopover";
import { ChapterName, Score } from "src/types";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { ScoresChart } from "src/components/Chart/ScoresChart";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { getScoresByIds } from "src/utils/scores";
import { useScoreContext } from "src/context/ScoreContext";

type ScoresVariationsSectionProps = BoxProps & {
    headings?: Heading[];
    scoresIds: number[];
};
export const ScoresVariationsSection = (props: ScoresVariationsSectionProps) => {
    const { scoresIds, headings = [], ...boxProps } = props;
    const { scores = [] } = useFoodpilotContext();
    const { displayedScoreId, displayedVariationId = 0, formattedScores } = useScoreContext();

    const scoresToDisplay = getScoresByIds(scoresIds, scores);
    const initialScore = scoresToDisplay[0];

    const initialScoreTitle = initialScore?.title;
    const initialScoreLabel = initialScore?.label ?? initialScoreTitle;

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const chaptersScore = formattedScore?.chaptersScore;
    const scoreVariations = formattedScore?.scoreVariations;

    const selectedScore = (scores ?? []).find((score: Score) => score.id === chaptersScore?.score_id) ?? initialScore;
    const selectedScoreTitle = selectedScore?.title ?? initialScoreLabel;
    const selectedScoreLabel = selectedScore?.label ?? selectedScoreTitle;

    const displayedVariation = scoreVariations?.[displayedVariationId];
    const scoreVariation = displayedVariation ?? scoreVariations?.[0];
    const displayedVariationLabel = displayedVariationId ? scoreVariation?.label : selectedScoreLabel;

    return (
        <SectionBox
            width="100%"
            size="small"
            header={
                !initialScore ?
                    <CircularProgress />
                :   <Stack direction="row" alignItems="center">
                        <Stack flex={1} gap="4px">
                            <Typography variant="h4">{selectedScoreTitle}</Typography>
                            <Typography variant="body2">
                                {displayedVariation ? displayedVariationLabel : selectedScoreLabel}
                            </Typography>
                        </Stack>

                        <ScoresVariationsPopover scores={scoresToDisplay} />
                    </Stack>
            }
            {...boxProps}
        >
            <ScoresChart
                formattedScores={formattedScores}
                headings={headings}
                displayedScoreId={displayedScoreId}
                displayedVariationId={displayedVariation ? displayedVariationId : 0}
                displayMode={selectedScore?.displayMode as ChapterName}
                precision={selectedScore?.precision}
            />
        </SectionBox>
    );
};
