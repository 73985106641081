import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";

export type UpdateSettings = {
    settings: SettingsWrite;
};

export type UpdateFile = {
    file: File;
    type: "cover" | "image";
    formDataKey: "file" | "cover";
};

export const useUpdateSettings = () => {
    const query = useMutation({
        mutationFn: async (props: UpdateSettings) => {
            const { settings } = props;

            const response = await loggedInApiCall.put(`/api/settings/${settings.id}`, settings);

            return response;
        },
    });

    return query;
};

export const useUpdateFile = () => {
    const query = useMutation({
        mutationFn: async (props: UpdateFile) => {
            const { file, type, formDataKey } = props;

            const formData = new FormData();
            formData.append(formDataKey, file);

            const response = await loggedInApiCall.post(`/api/settings/1/${type}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            return response;
        },
    });

    return query;
};

export type SettingsWrite = {
    id?: number;
    title?: string;
    theme?: string;
    crispToken?: string;
};

export const SettingsWriteSchemaZod: z.ZodSchema<SettingsWrite> = z.object({
    id: z.number().optional(),
    title: z.string().optional(),
    theme: z.string().optional(),
    crispToken: z.string().optional(),
});
