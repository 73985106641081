import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";

type CreateIngredientLine = {
    ingredientLine: IngredientLineWrite;
};
export const useCreateIngredientLine = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: CreateIngredientLine) => {
            const { ingredientLine } = options;
            const response =
                ingredientLine.id === undefined ?
                    await loggedInApiCall.post("/api/ingredient_lines", ingredientLine)
                :   await loggedInApiCall.put("/api/ingredient_lines/" + ingredientLine.id, ingredientLine);

            return response;
        },
        onSuccess: () => queryClient.invalidateQueries(["allIngredientLines"]),
    });

    return query;
};

export type IngredientLineWrite = {
    id?: number;
    name: string;
};

export const IngredientLineWriteSchema: z.ZodSchema<IngredientLineWrite> = z.object({
    id: z.number().optional(),
    name: z.string(),
});
