import { WhiteBox } from "@foodpilot/foods";
import { Box, Button, Divider, Stack, SvgIcon, Typography, styled } from "@mui/material";
import { removeInexwebInfo } from "src/utils/token";
import CarboneEmissionsReduction from "src/assets/icons/carbon-emissions-reduction.svg?react";
import { useNavigate } from "react-router-dom";
import _CheckIcon from "src/assets/icons/check.svg?react";
import { Close } from "@mui/icons-material";
import { TransmitReportDialog } from "./components/TransmitReportDialog";
import { useTransmitReportMutation } from "src/api/sure-api/inexweb/useTransmitReportMutation";
import DownloadIcon from "@mui/icons-material/Download";
import methodology from "src/assets/pdfs/Bilan RSE Simplifié - Note méthodologique.pdf";

const CheckIcon = styled(_CheckIcon)(({ theme }) => ({
    height: 25,
    path: {
        fill: theme.custom.green[800],
    },
}));

const NopeIcon = styled(Close)(({ theme }) => ({
    color: theme.custom.red[800],
}));

type Page4BisProps = {
    reportUrl: string;
    deleteReportUrl: () => void;
};

export const Page4Bis = (props: Page4BisProps) => {
    const { reportUrl } = props;
    const navigate = useNavigate();

    const handleGenerateNew = () => {
        removeInexwebInfo();
        navigate("/inexweb");
    };

    const { mutate, isLoading, isSuccess, isError } = useTransmitReportMutation();

    const handleOnTransmit = () => {
        mutate(reportUrl);
    };

    return (
        <Box>
            <WhiteBox sx={{ p: 12, alignItems: "center", display: "flex", flexDirection: "column" }}>
                <SvgIcon
                    component={CarboneEmissionsReduction}
                    inheritViewBox
                    sx={{ height: "128px", width: "128px" }}
                />
                <h1>Votre bilan RSE simplifié est prêt !</h1>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateAreas: `"a b" "c d"`,
                        gridTemplateColumns: "repeat(2, 1fr)",
                        columnGap: "1rem",
                        justifyItems: "center",
                    }}
                >
                    <Typography sx={{ my: 3, textAlign: "center" }} variant="big">
                        Vous pouvez désormais le visualiser :
                    </Typography>
                    <Typography sx={{ my: 3, textAlign: "center" }} variant="big">
                        Vous pouvez le transmettre au client via Inexweb :
                    </Typography>
                    <Button href={reportUrl} target="_blank" rel="noreferrer" variant="primary">
                        Visualiser le bilan
                    </Button>
                    {!isSuccess && !isError && (
                        <Button rel="noreferrer" variant="primary" onClick={handleOnTransmit}>
                            Transmettre le bilan
                        </Button>
                    )}
                    {isSuccess && (
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <CheckIcon />
                            <Typography variant="body-bold" sx={{ color: (theme) => theme.custom.green[800] }}>
                                Bilan transmis
                            </Typography>
                        </Stack>
                    )}
                    {isError && (
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <NopeIcon />
                            <Typography variant="body-bold" sx={{ color: (theme) => theme.custom.red[800] }}>
                                Erreur lors de la transmission
                            </Typography>
                        </Stack>
                    )}
                </Box>
                <Box sx={{ height: 30 }} />
                <Divider sx={{ width: "80%" }} />
                <Box sx={{ height: 30 }} />
                <Typography sx={{ mb: 3 }} variant="big">
                    Générez un bilan pour un autre client :
                </Typography>
                <Button variant="ternary" onClick={handleGenerateNew}>
                    Générer un nouveau bilan
                </Button>
            </WhiteBox>

            <Box height={20} />
            <WhiteBox sx={{ p: 1 }}>
                <Box
                    sx={{
                        borderRadius: "8px",
                        p: "36px",
                        bgcolor: (theme) => theme.palette.primary[50],
                    }}
                >
                    <Box ml={"36px"} mt={"36px"}>
                        <Typography variant="h2">Notre méthodologie de calcul</Typography>
                    </Box>

                    <Box ml={"36px"} mt={"12px"}>
                        <Typography variant="body">
                            Les données collectées via le bilan comptable sont converties en consommation énergétique en
                            utilisant le prix moyen national de l'année concernée puis traduites en émission de gaz à
                            effet de serre en utilisant les facteurs d'émissions calculés par l'Ademe.
                        </Typography>
                    </Box>
                    <Box height={"32px"} />
                    <Box ml="36px">
                        <Button variant="primary" href={methodology} target="_blank">
                            <DownloadIcon /> Télécharger notre méthodologie
                        </Button>
                    </Box>
                </Box>
            </WhiteBox>
            {isLoading && <TransmitReportDialog isTransmitting={isLoading} done={isSuccess} open={isLoading} />}
        </Box>
    );
};
