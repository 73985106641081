import { FoodsSelectServerPagination, Typography } from "@foodpilot/foods";
import { FormControl } from "@mui/material";
import { useGetAllProductLines } from "src/api/client-api/productLines/useProductLines";
import { IRI } from "src/types";
import { useTranslation } from "react-i18next";
import { ProductFormProductLine } from "../../../ProductForm.types";
import { useDelayedSearch } from "src/utils/useDelayedSearch";

type GammeFieldProps = {
    productLineIRI: IRI | null;
    setGamme: (newGamme: ProductFormProductLine | null) => void;
};
export const GammeField = (props: GammeFieldProps) => {
    const { productLineIRI, setGamme } = props;
    const { t } = useTranslation();

    const { search, setSearch, delayedSearch } = useDelayedSearch();
    const { productLines = [] } = useGetAllProductLines({
        name: delayedSearch,
    });

    const isSearchFound = (value: string) => {
        if (delayedSearch === "") {
            return true;
        }

        return value.toLowerCase().includes(delayedSearch.toLowerCase());
    };

    const selectedItem = productLines.find((pLine) => pLine["@id"] === productLineIRI) ?? null;

    return (
        <FormControl size="small">
            <Typography variant="h5" sx={{ paddingBottom: "0.5rem" }}>
                {`${t("Gamme")}*:`}
            </Typography>
            <FoodsSelectServerPagination
                onChange={(data) => {
                    setGamme(data);
                }}
                options={productLines}
                selectedOption={selectedItem}
                getId={(item) => item["@id"]}
                getName={(item) => item.name}
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
                search={search}
                setSearch={setSearch}
                isSearchFound={isSearchFound}
                BoxProps={{ width: "75%" }}
                BoxOptionsProps={{ width: "450px" }}
            />
        </FormControl>
    );
};
