import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getAllRolesKey } from "src/api/client-api/roles/useGetAllRoles.ts";

type RoleCreate = {
    isNew: boolean;
    role: RolePayload;
};

export const useCreateRole = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (options: RoleCreate) => {
            const { role, isNew } = options;

            return isNew ?
                    await loggedInApiCall.post("/api/roles", role)
                :   await loggedInApiCall.put(`/api/roles/${role.code}`, { permission: role.permission });
        },
        onSuccess: () => queryClient.invalidateQueries([getAllRolesKey]),
    });
};

export type RolePayload = {
    code: string;
    permission: string[];
};
