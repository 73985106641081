import { createContext } from "react";
import { CMSContent } from "src/api/client-api/cms/useGetOneContent";

export type PageParams = {
    pageId: string | undefined;
    sectionId: string | undefined;
    blockId: string | undefined;
};

export type SettingsCMSContextInitialValues = {
    content: CMSContent | undefined;
    isContentFetched: boolean;
    pageParams: PageParams;
};
export const SettingsCMSContext = createContext<SettingsCMSContextInitialValues | null>(null);
