import { useTranslation } from "react-i18next";

export const allSectionTypes = ["hero", "indicator", "articles"] as const;
export type CMSSectionPageType = (typeof allSectionTypes)[number];

type SectionConfig = {
    title: string;
    subtitle: string;
};

export const useSectionsConfig = () => {
    const { t } = useTranslation();

    const sectionsConfig: Record<CMSSectionPageType, SectionConfig> = {
        hero: {
            title: t("cms.section.hero.title"),
            subtitle: t("cms.section.hero.subtitle"),
        },
        indicator: {
            title: t("cms.section.indicator.title"),
            subtitle: t("cms.section.indicator.subtitle"),
        },
        articles: {
            title: t("cms.section.articles.title"),
            subtitle: t("cms.section.articles.subtitle"),
        },
    } as const;

    return sectionsConfig;
};
