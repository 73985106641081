import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { z } from "zod";

export const useGetOneUser = (userId: string | undefined) => {
    const query = useQuery({
        queryKey: ["getOneUser", userId],
        enabled: userId !== undefined,
        queryFn: async () => {
            return await loggedInApiCall.get("/api/users/" + userId);
        },
        select: (request) => {
            const user = request.data;
            user.roles = user.roles.filter((role: string) => role !== "ROLE_USER");

            const data = zodParseWithLog(user, UserSchemaZod, "getOneUser");
            return data;
        },
    });

    return {
        user: query.data,
        ...query,
    };
};

export type Company = {
    "@id": string;
    id: number;
    name: string;
    type: string;
};

export const CompanySchemaZod: z.ZodSchema<Company> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    type: z.string(),
});

export type User = {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phone?: string;
    is_verified: boolean;
    company?: Company;
    companies: Company[];
    roles: string[];
};

export const UserSchemaZod: z.ZodSchema<User> = z.object({
    id: z.number(),
    firstname: z.string(),
    lastname: z.string(),
    email: z.string(),
    phone: z.string().optional(),
    is_verified: z.boolean(),
    company: CompanySchemaZod.optional(),
    companies: CompanySchemaZod.array(),
    roles: z.string().array(),
});
