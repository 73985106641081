import { FormBox, HelperBox, TextField, useFormBaseContext } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { SupplierFormType } from "../../SupplierForm.types";
import { useEffect } from "react";

type Page1Props = {
    supplier: SupplierFormType;
    setSupplier: (newSupplier: SupplierFormType) => void;
    existingSupplier?: Supplier;
};

const Page1 = (props: Page1Props) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
            }}
        >
            <FormBox
                sx={{
                    flex: 3,
                }}
            >
                <BaseInformation {...props} />
            </FormBox>
        </Box>
    );
};
export default Page1;

const BaseInformation = (props: Page1Props) => {
    const { supplier, setSupplier } = props;
    const { t } = useTranslation();
    const { lockForward, unlockForward } = useFormBaseContext();

    const isNameValid = (name: string | null) => {
        return name && name.trim().length > 0;
    };
    const isClientIdValid = (clientId: string | null) => {
        return clientId && clientId.trim().length > 0;
    };

    useEffect(() => {
        const isPageError = !isNameValid(supplier.name) || !isClientIdValid(supplier.clientId);
        if (isPageError) {
            lockForward(t("form.invalidStep"));
            return;
        }
        unlockForward();
    }, [supplier.name, supplier.clientId]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "50px",

                // [kb]: not sure how to handle this problem right now;
                maxWidth: "75%",
                minWidth: "630px",
                width: "630px",
            }}
        >
            <TextField
                title={t("Nom du fournisseur")}
                required={true}
                error={!isNameValid(supplier.name)}
                placeholder={""}
                value={supplier.name ?? ""}
                onChange={(e) => {
                    const value = e.target.value;
                    if (value === "") {
                        return setSupplier({
                            ...supplier,
                            name: null,
                        });
                    }

                    setSupplier({
                        ...supplier,
                        name: value,
                    });
                }}
            />
            <Box>
                <TextField
                    title={t("Code fournisseur")}
                    placeholder={""}
                    value={supplier.clientId ?? ""}
                    onChange={(e) => {
                        const newCLientId = e.target.value;
                        setSupplier({
                            ...supplier,
                            clientId: newCLientId,
                        });
                    }}
                    required={true}
                    error={!isClientIdValid(supplier.clientId)}
                />
                <HelperBox>
                    {t(
                        "Le code fournisseur correspond à la manière dont vous marquer vos fournisseurs dans votre entreprise.",
                    )}
                </HelperBox>
            </Box>
            <Box>
                <Typography variant="body">{t("form.mandatory-field")}</Typography>
            </Box>
        </div>
    );
};
