import { Box, Typography, Input, Button } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { useCreateImage } from "src/api/client-api/cms/useCreateImage";
import { useSnackbar } from "src/utils/useSnackbar";

export type UploadImgProps = {
    label: string;
    description?: string;
    fileName: string | undefined;
    originalValue: string | undefined;
};
export const UploadImg = (props: UploadImgProps) => {
    const { label, description, originalValue, fileName } = props;
    const { t } = useTranslation();
    const createMedia = useCreateImage();
    const snackbar = useSnackbar();

    const [hasBeenModified, setHasBeenModified] = useState<boolean>(false);
    const [temporarySelectedFile, setTemporarySelectedFile] = useState<File | null>(null);

    return (
        <Box>
            <Box sx={{ display: "flex", gap: "16px", flexDirection: "row" }}>
                <Typography variant="h4">{`${label}`}</Typography>
                <Typography variant="big-bold">{`-`}</Typography>
                <Typography variant="body-medium">{`${description}`}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", width: "400px" }}>
                {originalValue !== undefined ?
                    <Box
                        sx={{
                            maxWidth: 400,
                        }}
                    >
                        <img
                            alt=""
                            src={originalValue}
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                                width: "auto",
                            }}
                        />
                    </Box>
                :   <Typography variant="body-bold">{t("settings.media.noSelectedImage")}</Typography>}
                <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                    <Input
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const file = e?.target.files && e.target.files.length > 0 ? e.target.files[0] : null;

                            setHasBeenModified(true);
                            return setTemporarySelectedFile(file);
                        }}
                    />
                    <Button
                        sx={{ minWidth: "125px" }}
                        variant="primary"
                        onClick={() => {
                            setHasBeenModified(true);
                            setTemporarySelectedFile(null);
                        }}
                    >
                        {t("settings.media.unselect")}
                    </Button>
                </Box>
                <Box>
                    {temporarySelectedFile !== (originalValue ?? null) && hasBeenModified === true && (
                        <Fragment>
                            <Typography variant="body-medium">{t("settings.media.selectedImage")}</Typography>

                            {temporarySelectedFile !== null ?
                                <img alt="" src={URL.createObjectURL(temporarySelectedFile)} width="30" />
                            :   <Typography variant="body-bold">{`Click on validate to delete image`}</Typography>}
                        </Fragment>
                    )}
                </Box>

                <Button
                    sx={{ display: "flex", justifyContent: "left" }}
                    variant="text"
                    onClick={() => {
                        if (fileName === undefined || fileName.trim() === "") {
                            snackbar.forceClose();
                            snackbar.setSnackbarProps({
                                type: "error",
                                message: t("snackbar.filenameIsMandatory"),
                            });
                            return;
                        }

                        if (temporarySelectedFile === null) {
                            snackbar.forceClose();
                            snackbar.setSnackbarProps({
                                type: "error",
                                message: t("snackbar.fileIsMandatory"),
                            });
                            return;
                        }
                        createMedia.mutate(
                            {
                                fileName: fileName,
                                value: temporarySelectedFile,
                            },
                            {
                                onSuccess: () => {
                                    snackbar.forceClose(),
                                        snackbar.setSnackbarProps({
                                            type: "info",
                                            message: t("snackbar.file.createdSuccess"),
                                        });
                                },
                            },
                        );
                    }}
                >
                    {t("primaryDialog.validate")}
                </Button>
            </Box>
        </Box>
    );
};
