import { FoodsFormBase, FoodsFormBaseProps } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Product } from "src/api/client-api/products/useGetOneProduct";
import { Loading } from "src/components/utils/Loading";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

type QuestionnaireUpdateFormBaseProps = Partial<FoodsFormBaseProps> & {
    product: Product;
    title?: string;
};
export const QuestionnaireUpdateFormBase = (props: QuestionnaireUpdateFormBaseProps) => {
    const { product, title, pages, confirmExit, ...formBaseProps } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { selectedCompany } = useFoodpilotContext();

    return (
        <FoodsFormBase
            textOptions={{
                title: title ?? t("word.loading-wait"),
                subtitle: product.title,
                nextPage: t("next"),
                previousPage: t("previous"),
                syncingMessage: t("Enregistrement en cours") + "...",
                savingDefaultMessage: t("Informations enregistrées il y’a quelques secondes"),
            }}
            pages={pages ?? [{ content: <Loading /> }]}
            onClose={() => {
                if (selectedCompany === null) return;
                const url = URLHandler.product.ssq(selectedCompany.id, product.id);
                navigate(url);
            }}
            confirmExit={false}
            isLoading={false}
            showSync={true}
            {...formBaseProps}
        />
    );
};
