import { useQuery } from "react-query";
import { inexwebApiCall } from "src/api/axiosClient/sureApi";
import { FiscalYearsSchema, fiscalYearsZodToFiscalYears } from "src/types";
import { getInexwebInfo } from "src/utils/token";

export const useFiscalYears = () => {
    const { company } = getInexwebInfo();

    return useQuery({
        queryKey: ["fiscalYears", { company }],
        queryFn: () => getFiscalYears(),
        select: (data) => {
            const res = FiscalYearsSchema.parse(data);
            return fiscalYearsZodToFiscalYears(res).exercices;
        },
    });
};

export const getFiscalYears = async () => {
    const response = await inexwebApiCall.get(`/api/inexweb/accounting/fiscal_years`);

    return response.data;
};
