import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { ScoreType } from "./useGetAllScoreType";

export const useUpdateScoreType = () => {
    const query = useMutation({
        mutationFn: async (scoreType: ScoreType) => {
            const { type, scoreIds } = scoreType;

            return await loggedInApiCall.put(`/api/score_types/${type}`, {
                scoreIds: scoreIds,
            });
        },
    });

    return query;
};
