import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetOneProduct } from "src/api/client-api/products/useGetOneProduct";
import { ProductForm } from "./ProductForm";

export const UpdateQuestionnaire = () => {
    const { productId, startingPageId } = useParams();
    const { product, refetch, isFetched } = useGetOneProduct(productId ?? "A completly bad ID", { enabled: false });

    useEffect(() => {
        if (product === undefined) {
            // Fetch the product ONLY on first render.
            refetch();
        }
    }, []);

    if (isFetched === false) {
        return null;
    }

    if (product === undefined) {
        // Since it's fetched, and it's still undefined
        // We may want an Error screen
        return null;
    }

    return <ProductForm existingProduct={product} startingPageId={startingPageId ?? "0"} />;
};
