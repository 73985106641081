import { Box, Typography } from "@mui/material";
import FoodPilotIcon from "../assets/icons/foodpilot-white.svg";

export type GenericErrorProps = {
    message: string;
};

export const GenericError = (props: GenericErrorProps) => {
    return (
        <Box
            sx={{
                height: "100svh",
                background: "#000",
                color: "white",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                <img
                    src={FoodPilotIcon}
                    style={{
                        height: "50px",
                    }}
                    alt="Food Pilot logo"
                />
                <br />
                <Typography variant="h3" sx={{ color: "white", marginLeft: "auto", marginRight: "auto" }}>
                    {props.message}
                </Typography>
            </Box>
        </Box>
    );
};
