import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BlackCross from "src/assets/icons/close.svg?react";

type TitleSearchProps = {
    search: string;
    title: string;
    totalItems: number;
    resetSearch: () => void;
};
export const TitleSearch = (props: TitleSearchProps) => {
    const { t } = useTranslation();
    const { search, title, totalItems, resetSearch } = props;

    return (
        <Stack direction="row" gap="24px" useFlexGap alignItems="center">
            {search ?
                <Typography variant="h3">{`(${totalItems}) ${t("résultats pour")} "${search}"`}</Typography>
            :   <Typography variant="h3">
                    {title}
                    <span style={{ fontWeight: 400 }}>{` - ${totalItems}`}</span>
                </Typography>
            }
            {search && (
                <Button variant="ternary" endIcon={<BlackCross />} onClick={resetSearch}>
                    {t("Effacer la recherche")}
                </Button>
            )}
        </Stack>
    );
};
