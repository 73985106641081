import { BlockOptions, RadioBlock } from "@foodpilot/foods";
import { useState } from "react";
import { DataListOption } from "src/api/sure-api/actions/useGetAllActions";
import { ActionPropertiesWithAnswers } from "src/context/Actions/ActionService";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type RadioProps = {
    propertyWithElements: ActionPropertiesWithAnswers;
};
export const Radio = (props: RadioProps) => {
    const { currentCampaign, targetCampaign } = useFoodpilotContext();
    const { propertyWithElements } = props;

    console.log("Radio :", propertyWithElements);

    const options = propertyWithElements.property.dataList?.options ?? [];
    options.sort((optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position);

    const _previousValue = propertyWithElements.previousElement?.value;
    const previousValue = options.find((option: DataListOption) => option.value === _previousValue);

    const _initialValue = propertyWithElements.currentElement?.value;
    const initialValue = options.find((option: DataListOption) => option.value === _initialValue);

    const [value, setValue] = useState<DataListOption | undefined>(initialValue);

    const previousComparisonValue = {
        year: `Value in ${currentCampaign?.label}`,
        value: previousValue?.label ?? "",
    };

    const blockOptions: BlockOptions = {
        rightSubtitle: `Objectif ${targetCampaign?.label}`,
        title: propertyWithElements.property.title ?? "",
        comparisonValues: {
            start: previousComparisonValue,
            current: undefined,
        },
    };

    return (
        <RadioBlock
            blockOptions={blockOptions}
            selectedItem={value}
            values={options}
            getId={(option) => option.value}
            getName={(option) => option.label}
            onChange={(newValue: DataListOption) => {
                setValue(newValue);

                //  Make the request to /calculate
                //  UpdatePropertyValue(newValue);
            }}
            FormControlProps={{
                sx: {
                    flexDirection: "column",
                },
            }}
        />
    );
};
