export const getGridStyle = (length: number) => {
    return {
        border: "none",
        backgroundColor: "transparent",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            marginBottom: "2px",
            border: 0,
            backgroundColor: "transparent",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#797A77",
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        "& .MuiDataGrid-row.Mui-selected": {
            background: " #FFFFFF",
            border: 0,
        },
        "& .MuiDataGrid-cell": {
            border: 0,
            paddingLeft: "20px",
            outline: "none !important",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: length * 3,
            height: `fit-content`,
            boxSizing: "content-box",
        },
    };
};
