import { BigPopoverLayout } from "@foodpilot/foods";
import { Box, CircularProgress, Dialog, Typography, styled, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import _CheckIcon from "src/assets/icons/check.svg?react";

const WAIT_FOR = 500;

const CheckIcon = styled(_CheckIcon)(({ theme }) => ({
    path: {
        fill: theme.palette.primary[500],
    },
}));

type GenerateReportDialogProps = {
    open: boolean;
    onClose: () => void;
    onReadyToGenerate: () => void;
};

type Step = "one" | "two" | "three";

export const GenerateReportDialog = (props: GenerateReportDialogProps) => {
    const { onClose, open, onReadyToGenerate } = props;
    const theme = useTheme();
    const [step, setStep] = useState<Step>("one");

    return (
        <Dialog
            open={!!open}
            sx={{
                ".MuiPaper-root": {
                    backgroundColor: "transparent",
                },
                hr: { display: "none" },
                ".MuiPaper-rounded": {
                    borderRadius: "12px",
                },
            }}
        >
            <BigPopoverLayout
                headerIcon={<></>}
                headerText="Génération du bilan RSE simplifié en cours"
                onClose={onClose}
            >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ mb: 3 }}>Cela prendra quelques secondes…</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <Box
                            sx={{
                                backgroundColor: "#fff6e7",
                                p: 3,
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <LoadingAndCheck
                                start={step === "one"}
                                waitFor={WAIT_FOR}
                                onFinished={() => {
                                    setStep("two");
                                }}
                            />
                            <Typography variant="body1">Réalisation du calcul</Typography>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: "#fff6e7",
                                p: 3,
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <LoadingAndCheck
                                start={step === "two"}
                                waitFor={WAIT_FOR}
                                onFinished={() => {
                                    onReadyToGenerate();
                                    setStep("three");
                                }}
                            />
                            <Typography>Analyse des résultats</Typography>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: "#fff6e7",
                                p: 3,
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            {step === "three" ?
                                <Box sx={{ color: theme.palette.primary[500] }}>
                                    <CircularProgress color="inherit" />
                                </Box>
                            :   <Box sx={{ width: "40px" }} />}
                            <Typography>Préparation du PDF</Typography>
                        </Box>
                    </Box>
                </Box>
            </BigPopoverLayout>
        </Dialog>
    );
};

type Wait = "idle" | "wait" | "finished";

type LoadingAndCheckProps = {
    waitFor: number;
    start?: boolean;
    onFinished?: () => void;
};

const LoadingAndCheck = (props: LoadingAndCheckProps) => {
    const { waitFor, onFinished, start = false } = props;
    const theme = useTheme();
    const [wait, letsgo] = useState<Wait>("idle");

    const sleep = useCallback((ms: number) => new Promise((resolve) => setTimeout(resolve, ms)), []);

    useEffect(() => {
        if (!start) return;
        letsgo("wait");
        const go = async () => {
            await sleep(waitFor);
            letsgo("finished");
            onFinished && onFinished();
        };
        go();
    }, [start, letsgo, waitFor, sleep]);

    switch (wait) {
        case "finished":
            return <CheckIcon />;
        case "wait":
            return (
                <Box sx={{ color: theme.palette.primary[500] }}>
                    <CircularProgress color="inherit" />
                </Box>
            );
        default:
            return <Box sx={{ width: "40px" }} />;
    }
};
