import { AnchoredPopover, Button } from "@foodpilot/foods";
import { Fragment, useState } from "react";
import { ViewColumn } from "@mui/icons-material";
import { ColumnPositionPopover } from "src/components/Popover/ColumnPositionPopover";
// import { useTranslation } from "react-i18next";
import { useSupplierListContext } from "../SupplierListContext";

export const ColumnsPopover = () => {
    // const { t } = useTranslation();

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchor);

    const { mrt_columns } = useSupplierListContext();
    return (
        <Fragment>
            <AnchoredPopover
                variant="headless"
                anchor={anchor}
                open={isOpen}
                onClose={() => setAnchor(null)}
                anchorPosition="rightside"
            >
                <ColumnPositionPopover
                    onClose={() => setAnchor(null)}
                    columnConfig={mrt_columns.columnConfig}
                    columnOrder={mrt_columns.columnOrder}
                    setColumnOrder={mrt_columns.setColumnOrder}
                    toggleVisibility={mrt_columns.toggleVisibility}
                />
            </AnchoredPopover>
            <Button
                variant="ternary"
                id="column-visibility-anchor"
                onClick={(e) => setAnchor(e.currentTarget)}
                startIcon={<ViewColumn />}
                sx={{ justifyContent: "right" }} // Yes, it's a bandage for the icon only button
            />
            {/* {t("Modifier les colonnes affichées")}
            </Button> */}
        </Fragment>
    );
};
