import { useContext } from "react";
import { CompanyContext } from "./context";

export const useCompanyContext = () => {
    const context = useContext(CompanyContext);

    if (context) {
        return context;
    }

    throw new Error(`useCompanyContext must be used within the context`);
};
