import { createContext } from "react";
import { ActionsType } from "src/api/sure-api/actions/useGetAllActions";
import { ChaptersScoreWithMetadata, Score } from "src/types";
import { ActionsCore, ScoreVariation } from "./provider";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema";
import { ScoreState } from "../EntityBasedScore/useEntityBasedScoreParams";

export type ScoreWithVariation = {
    score: Score | undefined;
    variation: ChaptersScoreWithMetadata | undefined;
};

export type ActionsContextInitialValues = ActionsCore & {
    actions: ActionsType[] | undefined;
    allScoreWithVariations: ScoreVariation[];
    scoresEnabled: Score[];
    scoreOptions: ScoreState;
    scoreByProperties: Record<string, ScoreDimensionValue>;
};
export const ActionsContext = createContext<ActionsContextInitialValues | null>(null);
