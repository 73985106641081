import { HeroBlock } from "@foodpilot/foods";
import { CMSContentBlock } from "src/api/client-api/cms/useGetOneContent";

type HeroProps = {
    blocks: CMSContentBlock[];
};

export const Hero = (props: HeroProps) => {
    /* Props */

    const { blocks } = props;

    if (blocks.length === 0) {
        return <></>;
    }

    const { title, subtitle, description, ctaLink, ctaLabel, media } = blocks[0];

    /* Handlers */
    const handleExternalLink = (link: string) => () => {
        if (!link) return;

        link.startsWith("http") ? window.open(link, "_blank") : window.open(`https://${link}`, "_blank");
    };

    /* Render functions */
    const RenderDescription = (): JSX.Element => {
        return description ? <p dangerouslySetInnerHTML={{ __html: description }} /> : <></>;
    };

    return (
        <HeroBlock
            title={title}
            subtitle={subtitle}
            description={<RenderDescription />}
            callToAction={{
                label: ctaLabel,
                onClick: handleExternalLink(ctaLink),
            }}
            imageUrl={media?.url}
        />
    );
};
