import { Box, Button, Input, Typography } from "@mui/material";
import { ChangeEvent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ApplicationParameterKeys,
    useApplicationParameters,
} from "src/api/client-api/appParams/useApplicationParameters";
import { useDeleteAppParam } from "src/api/client-api/appParams/useDeleteAppParam";
import { useUpdateFile } from "src/api/client-api/appParams/useUpsertAppParam";

type BaseIconUploadProps = {
    appParamKey: ApplicationParameterKeys;
    description?: string;
    label: string;
    imgSize?: number;
};
export const BaseIconUpload = (props: BaseIconUploadProps) => {
    const { appParamKey, label, description, imgSize = 50 } = props;

    const { t } = useTranslation();
    const { applicationParameters } = useApplicationParameters();
    const originalValue = applicationParameters?.[appParamKey];

    const [hasBeenModified, setHasBeenModified] = useState<boolean>(false);
    const [temporarySelectedFile, setTemporarySelectedFile] = useState<File | null>(null);

    const deleteAppParam = useDeleteAppParam();
    const updateFile = useUpdateFile();

    return (
        <Box>
            <Box sx={{ display: "flex", gap: "16px", flexDirection: "row" }}>
                <Typography variant="h4">{`${label}`}</Typography>
                <Typography variant="big-bold">{`-`}</Typography>
                <Typography variant="body-medium">{`${description}`}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column", width: "400px" }}>
                {originalValue !== undefined ?
                    <Box>
                        <img alt="" src={originalValue} width={`${imgSize}px`} />
                    </Box>
                :   <Typography variant="body-bold">{`No current selection`}</Typography>}
                <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                    <Input
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const file = e?.target.files && e.target.files.length > 0 ? e.target.files[0] : null;

                            setHasBeenModified(true);
                            return setTemporarySelectedFile(file);
                        }}
                    />
                    <Button
                        sx={{ minWidth: "125px" }}
                        variant="primary"
                        onClick={() => {
                            setHasBeenModified(true);
                            setTemporarySelectedFile(null);
                        }}
                    >
                        {`Unselect item`}
                    </Button>
                </Box>
                <Box>
                    {temporarySelectedFile !== (originalValue ?? null) && hasBeenModified === true && (
                        <Fragment>
                            <Typography variant="body-medium">{`New selection :`}</Typography>

                            {temporarySelectedFile !== null ?
                                <img alt="" src={URL.createObjectURL(temporarySelectedFile)} width="30" />
                            :   <Typography variant="body-bold">{`Click on validate to delete image`}</Typography>}
                        </Fragment>
                    )}
                </Box>

                <Button
                    sx={{ display: "flex", justifyContent: "left" }}
                    variant="text"
                    onClick={() => {
                        if (temporarySelectedFile === null) {
                            return deleteAppParam.mutate({ key: appParamKey });
                        }
                        updateFile.mutate({
                            key: appParamKey,
                            value: temporarySelectedFile,
                        });
                    }}
                >
                    {t("primaryDialog.validate")}
                </Button>
            </Box>
        </Box>
    );
};
