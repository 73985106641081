import { AxiosError } from "axios";
import { t } from "i18next";
import { useSnackbar } from "src/utils/useSnackbar";
import { isRFC7807Error, mapRfc7807Payload, RFC7807Error } from "src/api/axiosClient/handlers/rfc7807.ts";
import { isRFC2616Error, mapRfc2616Payload, RFC2616Error } from "src/api/axiosClient/handlers/rfc2616.ts";

export interface FieldError {
    fieldPath: string;
    message: string;
}

export interface FieldErrors {
    errors: FieldError[];
}

export interface ApiResponse {
    status: number;
    message: string;
    fieldErrors: FieldErrors;
}

const handleAPIError = (error: AxiosError): ApiResponse => {
    if (isRFC7807Error(error)) {
        return {
            status: 422,
            message: t("validation.backend.form_errors"),
            fieldErrors: mapRfc7807Payload(error.response?.data as RFC7807Error),
        };
    }

    if (isRFC2616Error(error)) {
        return {
            status: 422,
            message: t("validation.backend.form_errors"),
            fieldErrors: mapRfc2616Payload(error.response?.data as RFC2616Error),
        };
    }

    return {
        status: error.response?.status ?? 400,
        message: t("generic.unexpected_error"),
        fieldErrors: { errors: [] },
    };
};

export const useApiFieldErrorHandler = () => {
    return (error: AxiosError): ApiResponse => {
        return handleAPIError(error);
    };
};

export const useSnackbarWrapperApiFieldErrorHandler = () => {
    const snackbar = useSnackbar();
    const errorHandler = useApiFieldErrorHandler();

    return (error: AxiosError): ApiResponse => {
        const response = errorHandler(error);

        snackbar.forceClose();
        snackbar.setSnackbarProps({ type: "error", message: response.message });

        return response;
    };
};

export const useSnackbarWrapperApiGenericValidationErrorHandler = () => {
    const snackbar = useSnackbar();
    const errorHandler = useApiFieldErrorHandler();

    return (error: AxiosError): ApiResponse => {
        const response = errorHandler(error);

        let errorMessage;
        if (response?.fieldErrors?.errors.length > 0) {
            errorMessage = response?.fieldErrors?.errors.reduce(
                // @ts-expect-error translation may not exist, same issue as in 'src/utils/forms.ts'
                (message, error) => message.concat(t(error.message)),
                "",
            );
        } else {
            errorMessage = response.message;
        }

        if (response.status !== 401) {
            snackbar.forceClose();
            snackbar.setSnackbarProps({ type: "error", message: errorMessage });
        }

        return response;
    };
};
