import { Stack, Box } from "@mui/material";
import { SwitchGammeButton } from "src/components/Button/SwitchGammeButton";
import { useIngredientListContext } from "../IngredientListContext";
import { ColumnsPopover } from "../Popover/ColumnsPopover";
import { IngredientPopover } from "../Popover/IngredientPopover";
import { useTranslation } from "react-i18next";
import { TitleSearch } from "src/components/Grid/TitleSearch";
import { useGetAllIngredientLines } from "src/api/client-api/ingredientLines/useGetAllIngredientLine";
import { CampaignSelector } from "src/components/Selector";

export const GridHeader = () => {
    const { t } = useTranslation();
    const { delayedSearchIngredient, resetSearch, totalItems, currentGamme, setCurrentGamme } =
        useIngredientListContext();

    const { allIngredientLines } = useGetAllIngredientLines();
    const title = currentGamme ? currentGamme : t("Tous les ingrédients");

    return (
        <Box sx={{ padding: "0px 32px" }}>
            <Stack direction="row" useFlexGap justifyContent="space-between" alignItems="center">
                <Stack direction="row" useFlexGap gap="12px">
                    <TitleSearch
                        title={title}
                        search={delayedSearchIngredient}
                        totalItems={totalItems ?? 0}
                        resetSearch={resetSearch}
                    />
                    <SwitchGammeButton
                        // label={t("Changer de fillière")}
                        label={""}
                        allItems={allIngredientLines}
                        selectedItem={currentGamme}
                        setSelectedItem={setCurrentGamme}
                        noSelectedItemPlaceholder={t("Tous les ingrédients")}
                        popoverLibelle={t("Changer de fillière")}
                    />
                </Stack>
                <Stack direction="row" useFlexGap gap="12px">
                    <CampaignSelector />
                    <ColumnsPopover />
                    <IngredientPopover />
                </Stack>
            </Stack>

            <Stack
                direction="row"
                useFlexGap
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginTop: "32px" }}
            />
        </Box>
    );
};
