import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CsrThemeCreate } from "./Create";
import { useGetOneCsrTheme } from "src/api/client-api/csrTheme/useGetOneCsrTheme";

export const CsrThemeEdit = () => {
    const { csrThemeId } = useParams();
    const { csrTheme, refetch, isFetched } = useGetOneCsrTheme(csrThemeId ?? "A bad ID", { enabled: false });

    useEffect(() => {
        if (csrTheme === undefined) {
            refetch();
        }
    }, []);

    if (isFetched === false) {
        return null;
    }

    if (csrTheme == undefined) {
        return null;
    }

    return <CsrThemeCreate existingCsrTheme={csrTheme} />;
};
