import { useGetAllActions } from "src/api/sure-api/actions/useGetAllActions";
import { ActionsContext, ActionsContextInitialValues } from "./context";
import { Post } from "src/api/client-api/post/postSchema";
import { Score } from "src/types";
import { Scores } from "src/api/client-api/post/scoreSchema";
import { ScoreState } from "../EntityBasedScore/useEntityBasedScoreParams";
import { ScoreService } from "../EntityBasedScore/ScoreService";

export type ScoreVariationState = {
    scoreId: number;
    scoreVariationId: number;
};

type PostWithMetadata = {
    campaign: string;
    post: Post;
};
type PostExtra = {
    scores: Scores;
};

export type ActionsCore = {
    start: PostWithMetadata & Required<PostExtra>;
    current?: PostWithMetadata & Partial<PostExtra>;
    target: PostWithMetadata & Required<PostExtra>;
};

export type ScoreVariation = {
    id: number;
    label: string;
    group: string;
    scoreId: number;
    onClick: () => void;
};

type ActionsProviderProps = ActionsCore & {
    children: JSX.Element;
    scoresEnabled: Score[];
    scoreState: ScoreState;
    allScoreWithVariations: ScoreVariation[];
};
export const ActionsProvider = (props: ActionsProviderProps) => {
    const { children, start, current, target, scoresEnabled, scoreState, allScoreWithVariations } = props;
    const { actions, isFetched } = useGetAllActions();

    if (isFetched === false) {
        return;
    }
    if (actions === undefined) {
        return; // error ?!
    }

    const scoreIdForProperty = scoreState.lastSelectedVariation?.scoreId;
    const scoreByProperties = ScoreService.getScoreProperties(scoreIdForProperty, target.scores);

    const items: ActionsContextInitialValues = {
        actions,
        allScoreWithVariations,
        start,
        current,
        target,
        scoresEnabled,
        scoreOptions: scoreState,
        scoreByProperties,
    };

    console.log("My Action state :", items);

    return <ActionsContext.Provider value={items}>{children}</ActionsContext.Provider>;
};
