import React from "react";

interface EnergyItem {
    amount: number;
    averagePrice: number;
    volume: number;
    baseCarboneId: number;
    baseCarboneValue: number;
    impact: number;
    impactUnit: string;
}

interface EnergyData {
    [key: string]: EnergyItem;
}

interface TableDisplayProps {
    data: EnergyData;
}

const TableDisplay: React.FC<TableDisplayProps> = ({ data }) => {
    if (!data || Object.keys(data).length === 0) {
        return <p>No data to display</p>;
    }

    const columns = Object.keys(data[Object.keys(data)[0]]);

    return (
        <table style={{ borderCollapse: "collapse", border: "1px solid #000", padding: "5px", textAlign: "right" }}>
            <thead style={{ borderCollapse: "collapse", border: "1px solid #000", padding: "5px", textAlign: "right" }}>
                <tr
                    style={{ borderCollapse: "collapse", border: "1px solid #000", padding: "5px", textAlign: "right" }}
                >
                    <th
                        style={{
                            borderCollapse: "collapse",
                            border: "1px solid #000",
                            padding: "5px",
                            textAlign: "right",
                        }}
                    >
                        Type
                    </th>
                    {columns.map((column, index) => (
                        <th
                            key={index}
                            style={{
                                borderCollapse: "collapse",
                                border: "1px solid #000",
                                padding: "5px",
                                textAlign: "right",
                            }}
                        >
                            {column}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{ borderCollapse: "collapse", border: "1px solid #000", padding: "5px", textAlign: "right" }}>
                {Object.entries(data).map(([type, item], rowIndex) => (
                    <tr
                        key={rowIndex}
                        style={{
                            borderCollapse: "collapse",
                            border: "1px solid #000",
                            padding: "5px",
                            textAlign: "right",
                        }}
                    >
                        <td
                            style={{
                                borderCollapse: "collapse",
                                border: "1px solid #000",
                                padding: "5px",
                                textAlign: "right",
                            }}
                        >
                            {type}
                        </td>
                        {columns.map((column, columnIndex) => (
                            <td
                                key={columnIndex}
                                style={{
                                    borderCollapse: "collapse",
                                    border: "1px solid #000",
                                    padding: "5px",
                                    textAlign: "right",
                                }}
                            >
                                {(item as any)[column]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableDisplay;
