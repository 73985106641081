import { AnchoredPopover } from "@foodpilot/foods";
import { StackedBarChart, Shortcut, AssignmentIndOutlined, Edit, Delete } from "@mui/icons-material";
import { SxProps, Stack, MenuItem, Typography, Divider, useTheme } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSupplierDelete } from "src/api/client-api/suppliers/useDeleteSupplier";
import { useSnackbar } from "src/utils/useSnackbar";
import { allSupplierKey, SupplierGrid } from "src/api/client-api/suppliers/useGetAllSuppliers.tsx";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

type ActionsPopoverProps = {
    selectedRowItemId: number | null;
    anchor: HTMLElement | null;
    closeWindow: () => void;
    companies: Array<SupplierGrid>;
};
export const ActionsPopover = (props: ActionsPopoverProps) => {
    const { anchor, selectedRowItemId, closeWindow, companies } = props;
    const isRowMenuOpen = Boolean(anchor);

    return (
        <AnchoredPopover
            variant="themed"
            anchor={anchor}
            open={isRowMenuOpen}
            onClose={closeWindow}
            anchorPosition="rightside"
        >
            <RowMenu selectedRowItemId={selectedRowItemId} closeWindow={closeWindow} companies={companies} />
        </AnchoredPopover>
    );
};

type RowMenuProps = {
    selectedRowItemId: number | null;
    closeWindow: () => void;
    companies: Array<SupplierGrid>;
};

const RowMenu = (props: RowMenuProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { selectedRowItemId, closeWindow, companies } = props;
    const queryClient = useQueryClient();
    const deleteSupplier = useSupplierDelete();
    const snackbar = useSnackbar();
    const { selectedCompany } = useFoodpilotContext();

    const itemStyle: SxProps = {
        padding: "5px",
    };

    if (selectedRowItemId === null || selectedCompany === null) {
        return <div>{t("Aucune commande n'est disponible pour le produit")}</div>;
    }

    const mappedCompanies = Object.groupBy(companies, (item) => item.id);
    const chosenCompany = mappedCompanies[selectedRowItemId] ?? null;

    if (chosenCompany === null || chosenCompany.length <= 0) return;

    const firstCompany = chosenCompany[0];

    return (
        <Fragment>
            <Stack direction="column" gap="20px" useFlexGap>
                {firstCompany.canView && (
                    <>
                        <MenuItem sx={itemStyle} disabled>
                            <StackedBarChart />
                            <Typography sx={theme.custom.rowMenuElement}>{t("Scores")}</Typography>
                        </MenuItem>
                        <MenuItem sx={itemStyle} disabled>
                            <Shortcut />
                            <Typography sx={theme.custom.rowMenuElement}>{t("Informations")}</Typography>
                        </MenuItem>
                        <MenuItem sx={itemStyle} disabled>
                            <AssignmentIndOutlined />
                            <Typography sx={theme.custom.rowMenuElement}>{t("Questionnaires")}</Typography>
                        </MenuItem>

                        <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
                    </>
                )}

                {firstCompany.canModify && (
                    <MenuItem
                        onClick={() => {
                            const url = URLHandler.supplier.edit(selectedCompany.id, selectedRowItemId, 0);
                            navigate(url);
                        }}
                        sx={itemStyle}
                    >
                        <Edit />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Modifier")}</Typography>
                    </MenuItem>
                )}

                {firstCompany.canDelete && (
                    <MenuItem
                        onClick={() => {
                            deleteSupplier.mutate(selectedRowItemId, {
                                onSuccess: () => {
                                    queryClient.invalidateQueries({ queryKey: allSupplierKey });
                                    closeWindow();
                                },
                                onError: () => {
                                    snackbar.setSnackbarProps({
                                        message: t(
                                            "La suppression du fournisseur n'est pas possible car un utilisateur lui est rattaché.",
                                        ),
                                        type: "error",
                                    });
                                },
                            });
                        }}
                        sx={itemStyle}
                    >
                        <Delete />
                        <Typography sx={theme.custom.rowMenuElement}>{t("Supprimer")}</Typography>
                    </MenuItem>
                )}
            </Stack>
        </Fragment>
    );
};
