import { ReactNode, useState } from "react";

import InfoIcon from "src/assets/icons/SsqTitleIcon/info.svg?react";
import { InfoOutlined } from "@mui/icons-material";
import MediumForklift from "src/assets/icons/forklift-medium.svg?react";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import { ButtonTabs } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { GenericBox } from "src/components/GenericBox";
import { CreateOutlined } from "@mui/icons-material";

import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { InformationSummary } from "src/components/IngredientSummary/InformationSummary";
import { SupplierSummary } from "src/components/IngredientSummary/SupplierSummary";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";
import { useIngredientContext } from "../ExistingIngredientContext";

type ModifyButtonProps = {
    ingredient: Ingredient;
    pageNumber: number;
};
const ModifyButton = (props: ModifyButtonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { selectedCompany } = useFoodpilotContext();
    const { ingredient, pageNumber } = props;

    if (selectedCompany === null) return;

    const modifyText = t("Modifier");
    if (ingredient.canModify === false) {
        return null;
    }

    return (
        <Button
            variant="primary"
            startIcon={<CreateOutlined />}
            sx={{ marginRight: "24px", borderRadius: "44px" }}
            onClick={() => {
                const url = URLHandler.ingredient.edit(selectedCompany.id, ingredient.id, pageNumber);
                navigate(url);
            }}
        >
            {modifyText}
        </Button>
    );
};

type IngredientInformationsProps = unknown;

type Page = {
    title: string;
    icon?: ReactNode;
    content: JSX.Element | JSX.Element[];
};

export const Informations = (props: IngredientInformationsProps) => {
    const { t } = useTranslation();
    const { isUserOfTypeSupplier } = useFoodpilotContext();
    const { ingredient } = useIngredientContext();

    const isSupplier = isUserOfTypeSupplier();
    const title = isSupplier ? t("Informations sur l'ingrédient") : t("Informations");

    if (ingredient === undefined) return;

    const allPages: Page[] = [
        {
            title: t("general_information"),
            icon: <InfoIcon />,
            content: (
                <GenericBox
                    headerIcon={<InfoOutlined />}
                    headerText={t("general_information")}
                    controls={<ModifyButton pageNumber={0} ingredient={ingredient} />}
                >
                    <InformationSummary ingredient={ingredient} />
                </GenericBox>
            ),
        },
        {
            title: t("Suppliers"),
            icon: <MediumForklift />,
            content: (
                <GenericBox
                    headerIcon={<MediumForklift />}
                    headerText={t("Suppliers")}
                    controls={<ModifyButton pageNumber={1} ingredient={ingredient} />}
                >
                    <SupplierSummary ingredientSuppliers={ingredient.ingredientSuppliers} />
                </GenericBox>
            ),
        },
    ];

    const [currentPage, setCurrentPage] = useState(0);

    const choices = allPages.map((p, index) => (
        <Stack key={index} direction="row" alignItems="center" gap="8px">
            {p.icon}
            {p.title}
        </Stack>
    ));

    return (
        <Stack gap="28px">
            <Typography variant="h2">{title}</Typography>
            <ButtonTabs choices={choices} onClick={(choiceIndex) => setCurrentPage(choiceIndex)} />
            {allPages[currentPage].content}
        </Stack>
    );
};
