import { CMSSectionPageType } from "src/api/client-api/cms/sections/sectionsConfig";
import { Hero } from "./WidgetBlocks/Hero";
import { CMSPageSection } from "src/api/client-api/cms/useGetOneContent";
import { IndicatorBlock } from "./WidgetBlocks/Indicators";
import { CMSArticles } from "./WidgetBlocks/Articles";

type SectionSelectorProps = {
    section: CMSPageSection;
};

export const SectionSelector = (props: SectionSelectorProps) => {
    const { section } = props;
    const { type } = section;

    const widgets: Record<CMSSectionPageType, JSX.Element> = {
        hero: <Hero blocks={section.blocks} />,
        articles: <CMSArticles blocks={section.blocks} />,
        indicator: <IndicatorBlock blocks={section.blocks} />,
    };

    return widgets[type] || <></>;
};
