import { DragIndicator } from "@mui/icons-material";
import { Stack, Checkbox, Typography } from "@mui/material";
import { useRef } from "react";
import { useDrop, useDrag } from "react-dnd";

import type { Identifier, XYCoord } from "dnd-core";

interface DragItem {
    index: number;
    column: string;
}

export interface IDraggableColumnProps {
    columnName: string;
    header: string;
    visibility: boolean;
    index: number;
    moveColumn: (dragIndex: number, hoverIndex: number) => void;
    toggleVisibility: (columnName: string, visible: boolean) => void;
}

export function DraggableColumn(props: IDraggableColumnProps) {
    const { toggleVisibility } = props;
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: "DraggableColumn",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY >= hoverMiddleY) return;

            props.moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: "DraggableColumn",
        item: () => {
            return { index: props.index, column: props.columnName };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Stack
            ref={ref}
            data-handler-id={handlerId}
            direction="row"
            alignContent="center"
            justifyContent="start"
            sx={{ height: "40px" }}
        >
            <DragIndicator sx={{ height: "20px", width: "20px" }} />
            <Checkbox
                sx={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "8px",
                }}
                checked={props.visibility}
                onChange={(_e, checked) => {
                    toggleVisibility(props.columnName, checked);
                }}
            />
            <Typography
                variant="body"
                sx={{
                    height: "20px",
                    marginLeft: "12px",
                }}
            >
                {props.header}
            </Typography>
        </Stack>
    );
}
