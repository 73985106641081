import { useEffect, useState } from "react";
import { Stack, CircularProgress, Button, Typography, Input, List, ListItem, useTheme } from "@mui/material";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { WhiteBox } from "@foodpilot/foods";
import { Link, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { FormInput } from "src/components/FormInput";
import { resetPasswordFn } from "src/api/authApi";
import { useTranslation } from "react-i18next";
import { z, ZodStringCheck, ZodInvalidStringIssue } from "zod";
import ArrowLeft from "src/assets/icons/arrow-left.svg?react";
import CheckIcon from "src/assets/icons/check.svg?react";

const passwordFieldSchema = z
    .string()
    .min(8, "Plus de 8 caractères")
    .regex(new RegExp(/@|!|#|\$|\*|€|%|£/), {
        message: "Au moins caractère spécial : !@#$*€%£",
    })
    .regex(new RegExp(/[A-Z]/), {
        message: "Au moins un caractère capital",
    })
    .regex(new RegExp(/[0-9]/), {
        message: "Au moins un chiffre",
    });

const resetPasswordSchema = z
    .object({
        password: passwordFieldSchema,
        confirmPassword: z.string().min(1, "Le mot de passe est requis"),
        token: z.string(),
    })
    .refine(
        (args) => {
            return args.password === args.confirmPassword;
        },
        {
            path: ["confirmPassword"],
            message: "Les mots de passe ne correspondent pas",
        },
    );

export type ResetPasswordInput = z.infer<typeof resetPasswordSchema>;

export const ResetPassword = () => {
    const { token } = useParams();
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const [passwordErrors, setPasswordErrors] = useState<ZodInvalidStringIssue[] | null>(null);

    const methods = useForm<ResetPasswordInput>({
        resolver: zodResolver(resetPasswordSchema),
    });

    const passwordChecks = passwordFieldSchema._def.checks;

    //  API Login Mutation
    const { mutate: resetPassword, isLoading } = useMutation(
        (userData: ResetPasswordInput) => resetPasswordFn(userData),
        {
            onSuccess: (response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                setPasswordChanged(true);
            },
            onError: () => {
                setError(true);
            },
        },
    );

    useEffect(() => {
        methods.reset();
    }, [methods.formState.isSubmitSuccessful]);

    if (passwordChanged && !error) {
        return <ResetPasswordSuccess />;
    }

    return (
        <FormProvider {...methods}>
            <WhiteBox
                padding="40px"
                paddingTop="32px"
                boxShadow="0 2px 8px 0 rgba(42, 43, 39, 0.04)"
                position="relative"
            >
                {isLoading && (
                    <Stack
                        sx={{
                            background: "rgba(255, 255, 255, 0.5)",
                            position: "absolute",
                            inset: "calc(40px / 2)",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress />
                    </Stack>
                )}
                <Stack
                    component="form"
                    onSubmit={methods.handleSubmit((values) => {
                        setError(false);
                        values.password = encodeURIComponent(values.password);
                        values.token = encodeURIComponent(values.token);
                        resetPassword(values);
                    })}
                    noValidate
                    autoComplete="off"
                    gap="36px"
                >
                    <Typography variant="h2" align="center">
                        {t("reset-password-title")}
                    </Typography>

                    {error && (
                        <Typography variant="h4" align="center" color="error">
                            {t("reset-failed")}
                        </Typography>
                    )}

                    <Stack gap="24px">
                        <Stack gap="20px">
                            <FormInput
                                type="password"
                                name="password"
                                label={t("reset-password-pwd")}
                                inputProps={{ maxLength: 32 }}
                                onKeyUp={(e) => {
                                    setPasswordErrors([]);

                                    try {
                                        passwordFieldSchema.parse((e.target as HTMLInputElement).value);
                                    } catch (e: unknown) {
                                        if (e instanceof Error && e.name === "ZodError") {
                                            setPasswordErrors(JSON.parse(e.message));
                                        }
                                    }
                                }}
                            />

                            <PasswordChecksList checks={passwordChecks} errors={passwordErrors} />
                        </Stack>

                        <FormInput
                            type="password"
                            name="confirmPassword"
                            label={t("reset-password-pwd-confirmation")}
                            inputProps={{ maxLength: 32 }}
                        />

                        <Controller
                            defaultValue={token}
                            control={methods.control}
                            name="token"
                            render={({ field }) => <Input {...field} type="hidden" sx={{ display: "none" }} />}
                        />

                        <Button variant="primary" type="submit" disabled={!methods.formState.isValid}>
                            {t("Réinitialiser votre mot de passe")}
                        </Button>

                        <Button
                            variant="text"
                            component={Link}
                            to="/login"
                            startIcon={<ArrowLeft />}
                            sx={{ width: "100%", height: "auto" }}
                        >
                            {t("Revenir à la connexion")}
                        </Button>
                    </Stack>
                </Stack>
            </WhiteBox>
        </FormProvider>
    );
};

type PasswordChecksListProps = {
    checks: ZodStringCheck[];
    errors: ZodInvalidStringIssue[] | null;
};
const PasswordChecksList = (props: PasswordChecksListProps) => {
    const theme = useTheme();
    const { checks, errors } = props;

    return (
        <List
            sx={{
                color: theme.custom.grey[2000],
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                padding: 0,
                listStyleType: "disc",
                listStylePosition: "inside",
                textAlign: "center",
                ">li": {
                    minHeight: "22px",
                    display: "list-item",
                    listStyleType: "disc",
                    padding: 0,
                    "&::marker": {
                        color: "inherit",
                    },
                    "&.satisfied": {
                        color: theme.custom.green[800],
                        position: "relative",
                        "&::marker": {
                            color: "transparent",
                        },
                    },
                },
            }}
        >
            {checks.map((check, index) => {
                const satisfied =
                    errors &&
                    !errors.find((e: ZodInvalidStringIssue) => {
                        return e.message === check.message;
                    });

                return (
                    <ListItem key={index} className={satisfied ? "satisfied" : ""}>
                        {satisfied && (
                            <CheckIcon
                                width="20px"
                                height="20px"
                                style={{
                                    position: "absolute",
                                    left: "-4px",
                                    top: "2px",
                                }}
                            />
                        )}
                        <Typography variant="h5" fontWeight={500} color="inherit" display="inline">
                            {check.message}
                        </Typography>
                    </ListItem>
                );
            })}
        </List>
    );
};

const ResetPasswordSuccess = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <WhiteBox padding="40px" boxShadow="0 2px 8px 0 rgba(42, 43, 39, 0.04)">
            <Stack gap="32px">
                <Stack gap="16px" alignItems="center">
                    <Stack
                        width="56px"
                        height="56px"
                        borderRadius="100%"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            backgroundColor: theme.palette.primary[50],
                        }}
                    >
                        <CheckIcon width="28px" />
                    </Stack>
                    <Typography variant="h2" align="center">
                        {t("Mot de passe réinitialisé")}
                    </Typography>
                    <Typography variant="body" align="center" sx={{ color: theme.custom.grey[2000] }}>
                        {t("Vous pouvez à nouveau vous connectez à votre plateforme")}
                    </Typography>
                </Stack>

                <Button variant="primary" component={Link} to="/login" startIcon={<ArrowLeft />}>
                    {t("Revenir à l’écran de connexion")}
                </Button>
            </Stack>
        </WhiteBox>
    );
};
