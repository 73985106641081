import { ReactElement, useEffect, useState } from "react";
import {
    Box,
    Container,
    Typography,
    Button,
    Divider,
    Stack,
    CircularProgress,
    Autocomplete,
    TextField,
} from "@mui/material";
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    GridRowModesModel,
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowId,
    GridRowModel,
    useGridApiContext,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { CompanySectorForm, useGetAllSectors } from "src/api/client-api/companySectors/useSupplierSectors";
import { useSnackbar } from "src/utils/useSnackbar";
import { useGetAllSsq } from "src/api/sure-api/ssq/useGetAllSsq";
import { Loading } from "src/components/utils/Loading";
import { useDeleteCompanySector } from "src/api/client-api/companySectors/useDeleteCompanySector";
import { CompanySectorService } from "./Services/CompanySectorService";
import { useCreateCompanySector } from "src/api/client-api/companySectors/useCreateCompanySector";

export const CompanySectorList = (): ReactElement => {
    const { t } = useTranslation();

    const { companySectors, isLoading } = useGetAllSectors();
    const [companySectorsForm, setCompanySectorsForm] = useState<CompanySectorForm[] | undefined>(undefined);

    const { ssqs } = useGetAllSsq();

    const createCompanySector = useCreateCompanySector();
    const deleteCompanySector = useDeleteCompanySector();

    const [rows, setRows] = useState<GridRowsProp>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const snackbar = useSnackbar();

    type CompanySectorsRow = {
        id: number | undefined;
        name: string;
        ssqId: number | undefined;
        col2: Date;
        actions: number | undefined;
    };

    useEffect(() => {
        if (!companySectors) return;

        setCompanySectorsForm(CompanySectorService.getCompanySectorToCompanySectorForms(companySectors));
    }, [companySectors]);

    useEffect(() => {
        const rows: CompanySectorsRow[] = [];
        companySectorsForm?.forEach((companySector) => {
            rows.push({
                id: companySector.id,
                name: companySector.name,
                ssqId: companySector.ssqId,
                col2: companySector.updatedAt ? new Date(companySector.updatedAt) : new Date(),
                actions: companySector.id,
            });

            setRows(rows);
        });
    }, [companySectorsForm]);

    if (isLoading) return <Loading />;

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: number | string) => async () => {
        if (typeof id === "string") return;

        deleteCompanySector.mutate(id, {
            onError: () => {
                snackbar.setSnackbarProps({
                    message: t("Des entreprises sont rattachées à cette catégorie"),
                    type: "error",
                });
            },
            onSuccess: () => {
                setRows(rows.filter((row) => row.id !== id));
            },
        });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

        const editedRow = rows.find((row) => row.id === id);

        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (initialRow: GridRowModel) => {
        const newRow: GridRowModel = {
            ...initialRow,
        };

        const updatedRow = {
            ...newRow,
            isNew: false,
        };

        const currentCompanySectorForm = companySectorsForm?.find((companySector) => companySector.id === newRow.id);

        const newCompanySector: CompanySectorForm = {
            ...currentCompanySectorForm,
            name: newRow.name,
            ssqId: newRow.ssqId,
        };

        const newCompanySectorWrite = CompanySectorService.getCompanySectorToCompanySectorWrite(newCompanySector);

        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

        createCompanySector.mutate({ companySector: newCompanySectorWrite });

        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: t("Nom de la filière"),
            flex: 1,
            renderCell: (params) => (
                <Typography variant="h4" sx={{ fontWeight: 700, whiteSpace: "break-spaces" }}>
                    {params.value}
                </Typography>
            ),
            editable: true,
        },
        {
            field: "ssqId",
            headerName: t("SSQ"),
            flex: 1,
            renderCell: (params) => {
                if (!ssqs) {
                    return <CircularProgress size="18px" />;
                }

                const label = ssqs.find((ssq) => ssq.id == params.row.ssqId)?.title ?? "-";

                return (
                    <Typography variant="h4" sx={{ fontWeight: 400 }}>
                        {label}
                    </Typography>
                );
            },
            renderEditCell: (params) => {
                const { id, field } = params;
                const apiRef = useGridApiContext();

                if (!ssqs) {
                    return <CircularProgress size="18px" />;
                }

                const value = ssqs.find((ssq) => ssq.id === params.row.ssqId);

                return (
                    <Autocomplete
                        defaultValue={{
                            id: value?.id,
                            title: value?.title ?? "No SSQ",
                        }}
                        onChange={(e, option) => {
                            apiRef.current.setEditCellValue({ id, field, value: option?.id });
                        }}
                        options={(ssqs ?? []).map((ssq) => {
                            return {
                                id: ssq.id,
                                title: ssq.title,
                            };
                        })}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => {
                            return <TextField {...params} />;
                        }}
                        sx={{
                            width: "100%",
                        }}
                    />
                );
            },
            editable: true,
        },
        {
            field: "col2",
            headerName: t("Dernière mise à j."),
            type: "date",
            flex: 0.3,
            editable: false,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("Actions"),
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem key={id} icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />,
                        <GridActionsCellItem
                            key={id}
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key={id}
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key={id}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id.valueOf())}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    interface EditToolbarProps {
        setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
        setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
    }

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            const id = randomId();
            setRows((oldRows) => [...oldRows, { id, name: "", isNew: true }]);
            setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" } }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t("Ajouter un secteur d'activité")}
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h1" variant="h2">
                        {t("Secteurs d'activité")}
                    </Typography>
                </Stack>
            </Stack>
            <Box sx={{ py: 2 }}></Box>
            <Divider />
            <DataGrid
                autoHeight
                rowHeight={80}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                sx={getGridStyle(companySectorsForm?.length ?? 0, rows.length ?? 0)}
            />
        </Container>
    );
};

const getGridStyle = (length: number, rowLength: number) => {
    return {
        border: "none",
        backgroundColor: "transparent",
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            marginBottom: "2px",
            border: 0,
            backgroundColor: "transparent",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#797A77",
        },
        "& .MuiDataGrid-iconSeparator": {
            display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "white",
            borderRadius: "15px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        "& .MuiDataGrid-row.Mui-selected": {
            background: " #FFFFFF",
            border: 0,
        },
        "& .MuiDataGrid-cell": {
            border: 0,
            paddingLeft: "20px",
            outline: "none !important",
        },
        "& .MuiDataGrid-footerContainer": {
            display: "none",
        },
        "& .MuiDataGrid-virtualScrollerContent": {
            paddingBottom: rowLength * 7,
            height: `${(length ?? 1 + 1) * 100}px !important`,
            boxSizing: "content-box",
        },
    };
};
