import { Score } from "src/types";
import { Post } from "src/api/client-api/post/postSchema";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { Box, Stack } from "@mui/material";
import { ScoresSection } from "src/components/QuestionnaireUpdate/ScoresSection";
import { PagesContent } from "./PagesContent";
import { CircularProgress } from "@mui/material";

type useQuestionnaireUpdatePagesProps = {
    headings: Heading[];
    post?: Post;
    scores: Score[] | undefined;
    scoresIds?: number[];
    isPostLoading: boolean;
    canEdit?: boolean;
    changePost: (callback: (post: Post) => void) => void;
};
export const useQuestionnaireUpdatePages = (props: useQuestionnaireUpdatePagesProps) => {
    const { headings, post, scoresIds = [], isPostLoading, canEdit = true, changePost } = props;

    return headings.map((heading: Heading) => {
        return {
            title: heading.title,
            content: (
                <Box display="grid" gap="28px" gridTemplateColumns="minmax(0, 2fr) minmax(0, 1fr)">
                    <PagesContent heading={heading} post={post} canEdit={canEdit} changePost={changePost} />

                    <Stack
                        sx={{
                            height: "fit-content",
                            position: "sticky",
                            top: `${96 + 28 * 2}px`,
                        }}
                    >
                        {!isPostLoading ?
                            <ScoresSection post={post} headings={headings} scoresIds={scoresIds} />
                        :   <CircularProgress sx={{ margin: "0 auto" }} />}
                    </Stack>
                </Box>
            ),
        };
    });
};
