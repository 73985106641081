import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { PostSchema } from "./postSchema";
import { isAxiosError } from "axios";
import { useSnackbarWrapperApiGenericValidationErrorHandler } from "src/api/axiosClient/errorHandler.ts";

export const useGetProductPost = (productId: number | undefined, companyId: number | null, campaignId?: number) => {
    const apiErrorHandler = useSnackbarWrapperApiGenericValidationErrorHandler();

    const query = useQuery({
        queryKey: ["getProductPost", productId, campaignId],
        cacheTime: 0,
        retry: 0,
        queryFn: async () => {
            if (!productId) {
                return;
            }

            const url = `/api/products/${productId}/ssq_post`;
            const params: { [key: string]: string } = {};

            if (companyId !== null) {
                params["company_id"] = companyId.toString();
            }

            if (campaignId) {
                params["campaign"] = campaignId.toString();
            }

            return await loggedInApiCall.get(url, {
                headers: {
                    Accept: "application/json",
                },
                params: params,
            });
        },
        select: (response) => {
            if (!response) {
                return;
            }

            const post = response.data;
            const data = zodParseWithLog(post, PostSchema, "getProductPost");
            return data;
        },
        enabled: productId !== undefined,
        onError: (error) => {
            if (isAxiosError(error)) {
                apiErrorHandler(error);
            }
        },
    });

    return {
        post: query.data,
        ...query,
    };
};
