import { ContextualContentBox, FoodsIcon, FoodsIllustrations, WhiteBox } from "@foodpilot/foods";
import { Box, Button, Container, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { getVisibleHeadings } from "src/utils/ssq";
import { CampaignSelector } from "src/components/Selector";

import { Loading } from "src/components/utils/Loading";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { Link, useNavigate } from "react-router-dom";
import { ScoreProvider } from "src/context/ScoreContext";
import { t } from "i18next";
import { getScoreIdsByType, getScoresByIds } from "src/utils/scores";
import { getApiUrl } from "src/api/axiosClient/utils";
import { WrapIf } from "src/components/WrapIf";
import { ScoreChart } from "./ScoreChart";
import { URLHandler } from "src/services/URLHandler";

export const BredHome = () => {
    const { user, currentCampaign, scoreTypes, scores, selectedCompany } = useFoodpilotContext();

    const navigate = useNavigate();

    const { post, isLoading } = useGetCompanyPost(selectedCompany?.id, currentCampaign?.id);
    const ssqId = selectedCompany?.companySector?.ssqId;

    const { ssq } = useGetOneSsq(ssqId);

    if (isLoading) return <Loading />;

    const headings = ssq ? getVisibleHeadings(ssq) : [];

    const companyScoresIds = getScoreIdsByType("company", scoreTypes);
    const scoresToDisplay = getScoresByIds(companyScoresIds, scores);

    const total = post?.completions.ssq.result ?? 0;

    const handleCompleteQuestionnaireClick = () => {
        if (selectedCompany === null) return;
        if (headings.length === 0) return;

        const headingId = headings[0].id;
        const url = URLHandler.company.ssqHeading(selectedCompany.id, headingId);
        navigate(url);
    };

    const downloadDisabled = total === 0;

    return (
        <Container
            component={"main"}
            sx={{
                backgroundColor: (theme) => theme.palette.grey[300],
                maxWidth: "1280px",
                m: "auto",
                paddingX: "5rem !important",
                paddingY: "2rem",
            }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={5}
                marginBottom={5}
            >
                <Typography variant="h1">
                    {t("hello")} {user.trustedUser.firstname}
                </Typography>
                <Typography>{selectedCompany?.name}</Typography>
            </Stack>
            <WhiteBox>
                <Stack p={3} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h2">{scoresToDisplay[0]?.title}</Typography>
                    <WrapIf
                        condition={downloadDisabled}
                        wrapper={(children) => (
                            <Tooltip title={t("bred.questionnaire_complete_needed")}>{children}</Tooltip>
                        )}
                    >
                        <Box>
                            <Button
                                variant="primary"
                                LinkComponent={downloadDisabled ? "button" : "a"}
                                disabled={downloadDisabled}
                                href={`${getApiUrl()}/api/ssq_posts/${post?.id}/pdf-report`}
                                target="_blank"
                            >
                                <FileDownloadOutlinedIcon />
                                {t("bred.download_report")}
                            </Button>
                        </Box>
                    </WrapIf>
                </Stack>

                <Stack>
                    <Stack
                        direction={"row"}
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{
                            m: 3,
                            border: (theme) => `1px solid ${theme.custom.grey[500]}`,
                            borderRadius: "8px",
                        }}
                    >
                        <Stack
                            spacing={2}
                            sx={{
                                p: 3,
                                justifyContent: "space-between",
                                alignItems: "center",
                                flex: 1,
                                borderRight: (theme) => `1px solid ${theme.custom.grey[500]}`,
                            }}
                        >
                            <Stack alignItems={"center"}>
                                <Typography variant="huge-bold">{t("Diagnostic")}</Typography>
                                <CampaignSelector />
                            </Stack>
                            {total > 0 && (
                                <>
                                    <ScoreProvider type="company" formattedScores={post?.formattedScores}>
                                        <ScoreChart headings={ssq?.headings} />
                                    </ScoreProvider>
                                    <Box height={10} />
                                    <Typography sx={{ mb: 2 }} variant="big" component={"p"}>
                                        {t("questionnaire.completion_questionnaire")} :{" "}
                                        <Typography
                                            component={"span"}
                                            sx={{ color: (theme) => theme.palette.success.main }}
                                        >
                                            {total}%
                                        </Typography>
                                    </Typography>
                                    <Button onClick={handleCompleteQuestionnaireClick} variant="ternary">
                                        {t("questionnaire.modify_answers")}
                                    </Button>
                                    <Box height={20} />
                                </>
                            )}
                            {total == 0 && (
                                <Box maxWidth={"328px"}>
                                    <ContextualContentBox size="small">
                                        <Stack alignItems={"center"} spacing={4}>
                                            <Stack
                                                sx={{
                                                    backgroundColor: "white",
                                                    borderRadius: 99,
                                                    width: 32,
                                                    height: 32,
                                                }}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                            >
                                                <FoodsIcon icon="questionnaires" size={3} />
                                            </Stack>
                                            <Typography variant="h4" textAlign={"center"}>
                                                {t("bred.questionnaire.start_with_questionnaire")}
                                            </Typography>
                                            <Button onClick={handleCompleteQuestionnaireClick} variant="primary">
                                                {t("questionnaire.complete-questionnaire")}
                                            </Button>
                                        </Stack>
                                    </ContextualContentBox>
                                </Box>
                            )}
                        </Stack>
                        <Stack
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                flex: 1,
                            }}
                        >
                            <Typography variant="huge-bold" sx={{ justifySelf: "start", mt: 2 }}>
                                {t("bred.path")}
                            </Typography>
                            <Box display={"flex"} flexDirection={"column"}>
                                <FoodsIllustrations illustration="underConstruction" />
                            </Box>
                            <Box sx={{ maxWidth: "80%", mb: 2 }}>
                                <Typography variant="huge-bold" textAlign={"center"} component={"p"}>
                                    {t("bred.under_construction")}
                                </Typography>
                                <Typography textAlign={"center"} variant="big" component={"p"}>
                                    {t("bred.soon_determine_path")}
                                </Typography>
                            </Box>
                            <Box height={10} />
                        </Stack>
                    </Stack>
                    <Box p={3}>
                        <ContextualContentBox size="small">
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Stack
                                    sx={{ backgroundColor: "white", borderRadius: 99, width: 64, height: 64 }}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    <FoodsIcon icon="support" size={6} />
                                </Stack>

                                <Stack flex={1}>
                                    <Typography variant="h4">{t("bred.info.benefit_from_best_support")}</Typography>
                                    <Typography variant="body-medium">{t("bred.info.bred_support")}</Typography>
                                </Stack>
                                <Link to={URLHandler.cms.list()}>
                                    <Button variant="primary">{t("bred.info_discover_offers")}</Button>
                                </Link>
                            </Stack>
                        </ContextualContentBox>
                    </Box>
                </Stack>
            </WhiteBox>
        </Container>
    );
};
